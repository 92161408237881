import QuestionModel from '@/models/question.model';
import StatModel from '@/models/stat.model';
import {createInstruction} from './helpers';
import Scenario from './Scenario';

const instructions = [
    createInstruction(
        '7e12294c-644f-4450-929f-a9bd559b4f69',
        'On what device are you testing this styling?',
        {
            uuid: '76c62161-a0e2-4e9b-82c7-a2e7baeb9984',
            type: 'choice',
            options: {
                columns: 1,
                randomize: false,
                charttype: 'piechart',
            },
            choices: [
                {
                    uuid: 'b93e2ef3-dca9-4fb5-a4be-173c98c21e10',
                    value: 1,
                    translations: {
                        locale: 'en',
                        content: 'Desktop / Laptop',
                    },
                },
                {
                    uuid: '7855cc71-6567-400e-a643-cab3d92b3a63',
                    value: 2,
                    translations: {
                        locale: 'en',
                        content: 'Tablet',
                    },
                },
                {
                    uuid: '03e415fd-24d1-4305-a030-8e3bec0c52b9',
                    value: 3,
                    translations: {
                        locale: 'en',
                        content: 'Smartphone',
                    },
                },
                {
                    uuid: '7f4342ab-43f5-4b82-9845-10665043c1cc',
                    value: 4,
                    translations: {
                        locale: 'en',
                        content: 'Other',
                    },
                },
            ],
        } as unknown as QuestionModel,
    ),
];

const stats = {
    // Pie chart.
    '7e12294c-644f-4450-929f-a9bd559b4f69': {
        instruction: '7e12294c-644f-4450-929f-a9bd559b4f69',
        selected: 130,
        answered: 130,
        statistics: {
            1: 1,
            2: 2,
            3: 1,
            4: 1,
        },
    },
} as Record<string, StatModel>;

export default {
    instructions,
    stats,
} as Scenario;
