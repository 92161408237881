<template>
    <div
        :id="id"
        role="dialog"
        class="modal"
        @click="closeNonStaticModal"
        @keyup.esc="closeWithKeyboard"
        :class="modalClasses"
        :aria-labelledby="titleId"
        aria-hidden="true"
        :data-bs-backdrop="backdrop"
        :data-bs-focus="focus"
        :data-bs-keyboard="keyboard"
        tabindex="-1">
        <div class="modal-dialog" :class="dialogClasses">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header">
                        <slot name="title">
                            <h5 :id="titleId" class="modal-title">{{ $t(title) }}</h5>
                        </slot>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            @click="close"
                            :aria-label="$t(labelClose)">
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer has-validation">
                    <slot name="footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="close"
                            data-bs-dismiss="modal">
                            {{ $t(labelClose) }}
                        </button>
                        <button
                            type="button"
                            :disabled="!canSubmit"
                            @click="$emit('submit')"
                            class="btn btn-primary"
                            :class="{'is-invalid': errorMessage.length > 0}">
                            {{ $t(labelSubmit) }}
                        </button>
                        <div v-if="errorMessage.length > 0" class="invalid-feedback">
                            <i class="bi-exclamation-triangle-fill"></i>
                            {{ $t(errorMessage) }}
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Breakpoint} from '@/components/bootstrap/breakpoint';

enum BooleanAttribute {
    Enabled = 'true',
    Disabled = 'false',
}

export enum ModalBackdrop {
    Enabled = 'true',
    Disabled = 'false',
    Static = 'static',
}

export enum ModalSize {
    None,
    XL = 'xl',
    L = 'lg',
    S = 'sm',
}

interface ComponentData {
    show: boolean;
}

export default Vue.extend({
    name: 'Modal',
    data(): ComponentData {
        return {
            show: false,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        size: {
            type: [Number, String] as PropType<ModalSize>,
            default: ModalSize.None,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        fullscreenBreakpoint: {
            type: [String, Number] as PropType<Breakpoint>,
            default: Breakpoint.None,
        },
        backdrop: {
            type: String as PropType<ModalBackdrop>,
            default: ModalBackdrop.Enabled,
        },
        focus: {
            type: String as PropType<BooleanAttribute>,
            default: BooleanAttribute.Enabled,
        },
        keyboard: {
            type: String as PropType<BooleanAttribute>,
            default: BooleanAttribute.Enabled,
        },
        fade: {
            type: Boolean,
            default: true,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        verticallyCentered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        canSubmit: {
            type: Boolean,
            default: true,
        },
        labelSubmit: {
            type: String,
            default: 'MODAL.ACTION.SUBMIT',
        },
        labelClose: {
            type: String,
            default: 'MODAL.ACTION.CLOSE',
        },
        errorMessage: {
            type: String,
            default: '',
        },
    },
    mounted() {
        this.$el.addEventListener('open', this.open);
        this.$el.addEventListener('close', this.close);
        this.$el.addEventListener('toggle', this.toggle);
    },
    beforeDestroy() {
        this.$el.removeEventListener('open', this.open);
        this.$el.removeEventListener('close', this.close);
        this.$el.removeEventListener('toggle', this.toggle);
    },
    methods: {
        open() {
            this.$emit('open');

            this.show = true;
            document.body.classList.add('modal-open');

            if (this.backdrop !== ModalBackdrop.Disabled) {
                const backdrop = document.createElement('div');
                backdrop.classList.add('modal-backdrop', 'show');
                if (this.fade) {
                    backdrop.classList.add('fade');
                }
                document.body.appendChild(backdrop);
            }

            if (this.focus === BooleanAttribute.Enabled
                && this.$el instanceof HTMLElement
            ) {
                this.$el.focus();
            }

            this.$emit('opened');
        },
        close() {
            this.$emit('close');
            this.show = false;
            document.body.classList.remove('modal-open');
            Array.from(document.body.getElementsByClassName('modal-backdrop')).forEach(
                (backdrop) => backdrop.remove(),
            );
            this.$emit('closed');
        },
        toggle() {
            this.$emit('toggle');
            this[this.show ? 'close' : 'open']();
            this.$emit('toggled');
        },
        closeNonStaticModal(event: Event) {
            if (event.target !== this.$el) {
                return;
            }

            if (this.backdrop !== ModalBackdrop.Static) {
                this.close();
            }
        },
        closeWithKeyboard(event: KeyboardEvent) {
            if (event.key !== 'Escape') {
                return;
            }

            if (this.keyboard === BooleanAttribute.Enabled) {
                this.close();
            }
        },
    },
    computed: {
        titleId(): string {
            return `${this.id}-title`;
        },
        modalClasses(): Array<string> {
            const classes = [];

            if (this.show) {
                classes.push('show');
            }

            if (this.fade) {
                classes.push('fade');
            }

            return classes;
        },
        dialogClasses(): Array<string> {
            const classes = [];

            if (this.size !== ModalSize.None) {
                classes.push(`modal-${this.size}`);
            }

            if (this.scrollable) {
                classes.push('modal-dialog-scrollable');
            }

            if (this.verticallyCentered) {
                classes.push('modal-dialog-centered');
            }

            if (this.fullscreen) {
                classes.push(
                    this.fullscreenBreakpoint === Breakpoint.None
                        ? 'modal-fullscreen'
                        : `modal-fullscreen-${this.fullscreenBreakpoint}-down`,
                );
            }

            return classes;
        },
    },
});
</script>
