<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: "ChartTypeSelectionControl",
    props: {
        value: String,
        options: Array,
        readonly: Boolean,
    },
    beforeMount() {
        this.selectedOption = this.value;
    },
    watch: {
        selectedOption(option: String): void {
            this.$emit('input', option);
        },
    },
    data() {
        return {
            selectedOption: null as string|null,
        };
    },
})
</script>

<template>
    <div v-if="options.length > 1">
        <span class="mr-2 font-weight-bold">Chart live stats:</span>
        <v-btn-toggle v-model="selectedOption" color="secondary" group mandatory>
            <v-btn v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</v-btn>
        </v-btn-toggle>
    </div>
</template>

<style scoped lang="scss">

</style>
