<template>
    <div :class="componentClass">
        <select
            :data-value="this.value"
            :value="this.value"
            :name="inputId"
            :id="inputId"
            @change="onInput">
            <option
                v-for="(option, index) in options"
                :style="{fontWeight: option.value}"
                :key="index"
                :value="option.value"
                :selected="option.value == value || options.length == 1"
                :label="getOptionLabel(option)"
                @click="onClickOption(option)"
            />
        </select>
    </div>
</template>

<script lang="ts">
import Input from '@/components/community/styling/Input/Input.vue';
import {PropType} from 'vue';

export interface Option {
    value: string|number;
    label?: string;
}

export interface DataListOptions {
    options: Array<Option>;
    legend?: boolean;
    vertical?: boolean;
}

let increment = 0;

export default Input.extend({
    props: {
        options: {
            type: Array as PropType<Array<Option>>,
            default: [],
        },
    },
    data() {
        increment++;
        return {increment};
    },
    computed: {
        inputId(): string {
            return `dataList-input-${this.increment}`;
        },
        listId(): string {
            return `dataList-values-${this.increment}`;
        },
        style(): {[key: string]: string} {
            const {$style} = this as unknown as {$style: {[key: string]: string}};
            return $style;
        },
        componentClass(): Array<string> {
            const classes = [this.style.component];

            return classes;
        },
    },
    methods: {
        getOptionLabel(option: Option): string {
            return option.label ?? this.transformToCssValue(option.value);
        },
        transformToCssValue(value: string|number): string {
            return `${value}`;
        },
        onClickOption(option: Option): void {
            this.$emit('input', this.transformToCssValue(option.value));
        },
        onInput(event: Event): void {
            const target = event.target as HTMLInputElement;

            if (target instanceof HTMLSelectElement) {
                this.$emit(
                    'input',
                    this.transformToCssValue(
                        target.value,
                    ),
                );
            }
        },
    },
});
</script>

<style lang="scss" module>
.component {
    width: 100%;
    min-width: 200px;
    padding: 4px 0;

    select {
        background-color: white;
        border-style: solid;
        appearance: auto;
        width: 100%;
        cursor: pointer;
    }

    option {
        padding: 0;
        text-align: left;
        cursor: pointer;
    }

    datalist {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &.legend-vertical {
        datalist {
            flex-direction: column;
            writing-mode: vertical-lr;
        }
    }
}
</style>
