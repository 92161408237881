import AbbiApi from '@/apis/abbi.api';

export default abstract class AccountApi extends AbbiApi {
    public static getLoginCSRF() {
        return AccountApi.get(`${AccountApi.url}/login`);
    }

    public static login(username: string, password: string, csrf: string) {
        return AccountApi
            .post(`${AccountApi.url}/api/login`, {
                username,
                password,
                csrf,
            })
            .catch((error) => {
                AccountApi.lastError = error;
            });
    }

    public static passwordReset(email: string) {
        return AccountApi
            .post(`${AccountApi.url}/reset-password`, {
                email,
            })
            .catch((error) => {
                AccountApi.lastError = error;
            });
    }

    public static getCurrentUser() {
        return AccountApi.get(`${AccountApi.url}/api/current/user`);
    }

    public static updateEmailAddress(email: string) {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/change-email`,
                {email},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static updatePassword(
        oldPassword: string,
        newPassword: string,
        confirmPassword: string,
    ) {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/change-password`,
                {oldPassword, newPassword, confirmPassword},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static resetPassword(
        resetToken: string,
        newPassword: string,
        confirmPassword: string,
    ) {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/reset-password`,
                {resetToken, newPassword, confirmPassword},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static passwordConstraints(token = '') {
        let uri = `${AccountApi.url}/api/account/password-constraints`;

        if (token.length > 0) {
            uri += `/${token}`;
        }

        return AccountApi.get(uri);
    }

    public static twoFactorConfiguration() {
        return AccountApi.get(
            `${AccountApi.url}/api/account/two-factor-configuration`,
        );
    }

    public static totpGenerateSecret() {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/totp/generate-secret`,
                {},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static totpConfirmSecret(
        currentPassword: string,
        verificationCode: string,
    ) {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/totp/confirm-secret`,
                {currentPassword, verificationCode},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static totpDisable(currentPassword: string) {
        return AccountApi
            .delete(
                `${AccountApi.url}/api/account/totp/disable`,
                {currentPassword},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }

    public static totpConfirmAuthentication(verificationCode: string) {
        return AccountApi
            .post(
                `${AccountApi.url}/api/account/totp/verify`,
                {verificationCode},
            )
            .catch(
                (error) => {
                    AccountApi.lastError = error;
                },
            );
    }
}
