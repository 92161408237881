<template>
    <Card
        class="row"
        title="ACCOUNT.EMAIL.HEADING"
        subtitle="ACCOUNT.EMAIL.INTRODUCTION">
        <form class="mt-3" @submit.prevent="submitForm()">
            <div class="mb-3 row">
                <label for="email" class="col-sm-3 col-form-label">
                    {{ $t('ACCOUNT.FIELD.EMAIL.LABEL') }}
                </label>
                <div class="col-sm-9">
                    <div class="input-group has-validation">
                        <i class="input-group-text bi-envelope-at"></i>
                        <input
                            type="email"
                            class="form-control"
                            :class="{
                                    'is-invalid': violations.hasError('email'),
                                    'is-valid': saved,
                                }"
                            @input="mutateForm()"
                            id="email"
                            name="email"
                            autocomplete="off"
                            required
                            aria-describedby="emailHelp"
                            :placeholder="$t('ACCOUNT.FIELD.EMAIL.PLACEHOLDER')"
                            v-model="email"/>
                        <ViolationList field="email" :violations="violations"/>
                        <div v-if="saved" class="valid-feedback">
                            {{ $t('ACCOUNT.EMAIL.SUBMIT.SUCCESS') }}
                        </div>
                    </div>
                    <div id="emailHelp" class="form-text">
                        {{ $t('ACCOUNT.FIELD.EMAIL.HELP') }}
                    </div>
                </div>
            </div>

            <div class="mt-4 input-group has-validation">
                <button
                    type="submit"
                    v-if="this.email !== this.$store.state.currentUser.email"
                    :disabled="!this.enabled"
                    class="btn btn-lg btn-primary w-100"
                    :class="{'is-valid': saved, 'is-invalid': errorMessage.length > 0}">
                        <span v-if="enabled">
                            {{ $t('ACCOUNT.EMAIL.SUBMIT.LABEL') }}
                        </span>
                    <span v-else class="spinner-border text-light" role="status">
                            <span class="visually-hidden">
                                {{ $t('ACCOUNT.FIELD.SUBMIT.ACTION') }}
                            </span>
                        </span>
                </button>
                <div v-if="errorMessage" class="invalid-feedback">
                    {{ $t(errorMessage) }}
                </div>
            </div>
        </form>
    </Card>
</template>

<script lang="ts">
import Vue from 'vue';
import AccountApi from '@/apis/account.api';
import ViolationList from '@/components/form/ViolationList.vue';
import ViolationListModel from '@/models/violation.list.model';
import Card from '@/components/bootstrap/Card.vue';

interface ComponentData {
    email: string | undefined;
    enabled: boolean;
    violations: ViolationListModel;
    saved: boolean;
    errorMessage: string;
}

export default Vue.extend({
    name: 'AccountEmail',
    components: {Card, ViolationList},
    data(): ComponentData {
        return {
            email: this.$store.state.currentUser?.email,
            enabled: false,
            violations: new ViolationListModel(),
            saved: false,
            errorMessage: '',
        };
    },
    methods: {
        mutateForm() {
            this.enabled = true;
            this.violations.reset();
            this.saved = false;
            this.errorMessage = '';
        },
        submitForm() {
            this.enabled = false;
            this.violations.reset();
            this.errorMessage = '';

            AccountApi
                .updateEmailAddress(this.email as string)
                .then(
                    (userData) => {
                        this.enabled = true;

                        if (userData !== undefined) {
                            this.$store.dispatch(
                                'updateUser',
                                {
                                    ...this.$store.state.currentUser,
                                    email: this.email,
                                },
                            );
                            this.violations.reset();
                            this.saved = true;
                            return;
                        }

                        AccountApi.getErrorData().then((errorData) => {
                            const violations = errorData.violations ?? [];

                            this.violations.apply(violations);

                            if (violations.length === 0) {
                                this.errorMessage = 'ACCOUNT.EMAIL.SUBMIT.FAILURE';
                            }
                        });
                    },
                );
        },
    },
});
</script>
