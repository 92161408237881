<template>
    <div id="account-page" class="container mt-3 mb-3">
        <BootstrapStylesheet />
        <div class="row">
            <div class="col-3 pr-3">
                <nav class="card">
                    <div class="card-text">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <router-link
                                    class="nav-link"
                                    :class="{active: this.$route.name === 'AccountOverview'}"
                                    :to="{name: 'AccountOverview'}">
                                    <i class="bi-gear-fill"></i>
                                    {{ $t('ROUTER.ACCOUNT.OVERVIEW') }}
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link
                                    class="nav-link"
                                    :class="{active: this.$route.name === 'AccountAuthentication'}"
                                    :to="{name: 'AccountAuthentication'}">
                                    <i class="bi-shield-lock-fill"></i>
                                    {{ $t('ROUTER.ACCOUNT.AUTHENTICATION') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <main class="col-9">
                <router-view></router-view>
            </main>
        </div>
    </div>
</template>

<script lang="ts">
import BootstrapStylesheet from '@/components/bootstrap/BootstrapStylesheet.vue';

export default {
    name: 'Account',
    components: {BootstrapStylesheet},
};
</script>
