<template>
    <div id="crm-auth-configurator">
        <div class="setting-control mt-05 pure-u-1-2">
            <AbbiTextInput v-model="action.params.placeholder" is-small type="text" max="1024" name="placeholder" :placeholder="$t('FIELDS.PLACEHOLDER')" />
        </div><br><br>
        <div class="flex-row pure-form">
            <div class="flex-1">
                <div class="text">
                    <p>Column</p>
                </div>
                <input type="text" v-model="action.params.column" class="pure-input-1-2 pure-u-1-2 crm-api-height"/>
            </div>
            <div class="flex-1">
                <div class="text">
                    <p>Platform</p>
                </div>
                <select v-model="action.params.platform" class="pure-input-1-2 pure-u-1-2 crm-api-height">
                    <option v-for="(platform, key) in platforms" :value="key" :key="key">
                        {{ platform}}
                    </option>
                </select>
            </div>
        </div>
        <div class="flex-row pure-form">
            <div class="flex-1">
                <div class="text">
                    <p>Choose connection</p>
                </div>
                <select v-model="action.params.connection" class="crm-api-height pure-input-1-2 pure-u-1-2">
                    <option v-for="({id, name}) in connections" :value="id" :key="id">
                        {{ name}}
                    </option>
                </select>
            </div>
            <div class="flex-1">
                <div class="text">
                    <p>Choose validation</p>
                </div>
                <select v-model="action.params.validation" class="crm-api-height pure-input-1-2 pure-u-1-2">
                    <option v-for="(value, key) in validationConstraints" :value="key" :key="key">
                        {{ value }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import vue from 'vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ActionModel from '@/models/action.model';
import ScriptModel from '@/models/script.model';
import {
    VALIDATION_CONSTRAINTS as validationConstraints,
    PLATFORMS as platforms,
} from '@/helpers/constants';
import CrmApi from '@/apis/crm.api';

export default vue.extend({
    name: 'CrmApiConfigurator',
    components: {AbbiTextInput},
    props: {
        action: [Object, ActionModel],
        script: [Object, ScriptModel],
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data() {
        return {
            validationConstraints,
            connections: [],
            platforms,
        };
    },
    created() {
        const {communitySlug, scriptUuid} = this.$route.params;
        CrmApi.list(communitySlug).then((responseData) => {
            if (!responseData.length) {
                this.flashMessage.error({
                    message: this.$i18n.t('ERROR.CRM_CONNECTION'),
                    icon: '/img/icons/error.svg',
                    time: 3500,
                });
                throw new Error(this.$i18n.t('ERROR.CRM_CONNECTION'));
            }
            this.connections = responseData.map(({id, name}) => ({id, name}));
            if (!this.$options.propsData.initialAction.params.connection) {
                this.$options.propsData.initialAction.params.connection = this.connections[0].id;
            }
        }).then(() => {
            CrmApi.showDataPoint(scriptUuid).then((data) => {
                if (!data) {
                    this.flashMessage.error({
                        message: this.$i18n.t('ERROR.DATA_POINTS'),
                        icon: '/img/icons/error.svg',
                        time: 3500,
                    });
                }
            });
        });
    },
});
</script>

<style lang="scss">
    .crm-api-height{
        height: 47% !important;
    }
</style>
