<template>
    <div class="abbi-numeric-input" :class="{small: isSmall, readonly}" :style="style">
        <div class="input-container">
            <div class="left"></div>
            <div class="middle">
                <label :for="name" class="label" v-if="placeholder">
                    <span>{{ placeholder }}</span>
                    <span class="ml-1" v-if="min">{{ min }} / {{ internalvalue }}</span>
                </label>
            </div>
            <div class="right"></div>
        </div>
        <!--suppress HtmlFormInputWithoutLabel -->
        <input autocomplete="off" ref="input" :name="name" :id="name" v-model="internalvalue" :type="type"
               @input="change($event.target.value)" @focus="active" :min="min" :max="max" :step="step" :readonly="readonly"
               @change="correctBounds"
               @keypress="inputValidate"
               @blur="inactive" />
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'AbbiNumericInput',
    props: {
        placeholder: String,
        name: String,
        value: Number,
        min: Number,
        max: Number,
        step: Number,
        isSmall: Boolean,
        readonly: Boolean,
        type: {
            type: String,
            default: 'number',
        },
    },
    data() {
        return {
            internalvalue: this.value as number | string,
            color: undefined as undefined | string,
        };
    },
    computed: {
        style(): object {
            return {
                '--abbi-text-input-border-color': this.color,
            };
        },
    },
    methods: {
        active() {
            this.$el.classList.add('active');
        },
        inactive() {
            if (this.internalvalue === '' || this.internalvalue === undefined) {
                this.$el.classList.remove('active');
            }
        },
        inputValidate($event: InputEvent) {
            this.$emit('validation', $event);
        },
        change(newValue: string | undefined) {
            let val = Number(newValue);
            this.color = undefined;
            if (this.min !== undefined && val < this.min) {
                this.color = 'red';
                val = this.min;
            } else if (this.max !== undefined && val > this.max) {
                this.color = 'red';
                val = this.max;
            }

            if (newValue === undefined || newValue === '') {
                this.$emit('input', undefined);
            } else {
                this.$emit('input', val);
            }
        },
        correctBounds($event: InputEvent) {
            const rawNumericInput = $event.target as HTMLInputElement;
            if (this.min !== undefined && rawNumericInput.valueAsNumber < this.min) {
                rawNumericInput.valueAsNumber = this.min;
            }
            if (this.max !== undefined && rawNumericInput.valueAsNumber > this.max) {
                rawNumericInput.valueAsNumber = this.max;
            }
            if (!Number.isNaN(rawNumericInput.valueAsNumber)) {
                this.$emit('input', rawNumericInput.valueAsNumber);
            }
        },
    },
    mounted() {
        if (this.internalvalue === undefined && this.min !== undefined) {
            this.internalvalue = this.min;
        } else {
            this.internalvalue = this.value;
        }

        if (this.internalvalue !== undefined) {
            this.$el.classList.add('active');
        }
    },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/colors';

$border-color: var(--abbi-text-input-border-color, $abbi-green);
$border-width-inactive: 1px;
$border-width-active: 2px;
$border-radius: 6px;

.abbi-numeric-input {
    --abbi-color-red: $abbi-red;
    position: relative;

    border: $border-width-active solid grey;
    border-radius: $border-radius;

    background-color: white;

    &.active {
        background-color: transparent;
        border-color: transparent;
    }

    input {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        border-radius: $border-radius;
        padding: 1em;
        position: relative;
        z-index: 1;

        &:focus {
            outline: none;
        }
    }

    .input-container {
        display: flex;
        position: absolute;
        top: -$border-width-inactive;
        left: -$border-width-inactive;
        right: -$border-width-inactive;
        bottom: -$border-width-inactive;

        .left {
            width: 1em;
        }

        .middle {
            display: flex;
            align-items: center;

            .label {
                padding: 0 0.25em;
                font-size: 1.25em;
                color: grey;
                transform: translateY(0px);
                transition: transform 0.2s ease-out, color 0.1s ease-out, font-size 0.2s ease-out;
            }
        }

        .right {
            flex: 1;
        }
    }

    &.active .input-container {
        > div {
            background-color: white;

            > label {
                background-color: white;
            }
        }
    }

    &.active .input-container {
        .left {
            border-left: $border-width-active solid var(--active-border-color, $border-color);
            border-top: $border-width-active solid var(--active-border-color, $border-color);
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        .middle {
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            .label {
                font-size: 1em;
                color: var(--active-border-color, $border-color);
                transform: translateY(calc(-50% - 1em));
                transition: transform 0.1s ease-in, color 0.1s ease-in, font-size 0.1s ease-out;
            }
        }

        .right {
            flex: 1;
            border-right: $border-width-active solid var(--active-border-color, $border-color);
            border-top: $border-width-active solid var(--active-border-color, $border-color);
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    &.disabled, &.readonly {
        background-color: lightgrey;

        .label, input {
            color: darkgrey !important;
        }

        .container {
            --active-border-color: lightgrey;
        }
    }

    &.small {
        .middle {
            font-size: 0.75em;
        }

        input {
            padding: 0.5em;
        }
    }
}
</style>
