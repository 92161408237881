<template>
    <div :class="componentClass" @click="onInput">
        <div :class="previewContainerClasses">
            <div :class="previewIncomingClass">
                <div :class="style['preview-content']" :style="incomingStyle">
                    {{ message }}
                </div>
            </div>
            <div v-if="reply.length > 0" :class="previewOutgoingClass">
                <div :class="style['preview-content']" :style="outgoingStyle">
                    {{ reply }}
                </div>
            </div>
            <div v-if="reply" :class="style.clear" />
        </div>
    </div>
</template>

<script lang="ts">
import Input from '@/components/community/styling/Input/Input.vue';
import {PropType} from 'vue';
import Shape from '@/components/community/styling/form/ShapeField/Shape';

export default Input.extend({
    props: {
        value: {
            type: Object as PropType<Shape>,
            required: true,
        },
        isCurrent: {
            type: Boolean,
            default: false,
        },
        hasTail: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            required: true,
        },
        reply: {
            type: String,
            default: '',
        },
    },
    computed: {
        style(): {[key: string]: string} {
            const {$style} = this as unknown as {$style: {[key: string]: string}};
            return $style;
        },
        componentClass(): Array<string> {
            const classes = [this.style.component];

            if (this.isCurrent) {
                classes.push(this.style.current);
            }

            return classes;
        },
        previewIncomingClass(): Array<string> {
            return [this.style.preview, this.style['preview-incoming']];
        },
        previewOutgoingClass(): Array<string> {
            return [this.style.preview, this.style['preview-outgoing']];
        },
        previewContainerClasses(): Array<string> {
            const classes = [this.style.previews];

            if (this.reply) {
                classes.push(this.style['has-reply']);
            }

            return classes;
        },
        previewStyle(): object {
            const {borderRadius, padding} = this.value;

            return {
                'border-radius': borderRadius,
                padding,
            };
        },
        incomingStyle(): object {
            const {previewStyle, hasTail} = this;
            const {tailRadius} = this.value;

            return hasTail
                ? {
                    ...previewStyle,
                    'border-top-left-radius': tailRadius,
                }
                : previewStyle;
        },
        outgoingStyle(): object {
            const {previewStyle, hasTail} = this;
            const {tailRadius} = this.value;

            return hasTail
                ? {
                    ...previewStyle,
                    'border-top-right-radius': tailRadius,
                }
                : previewStyle;
        },
    },
    methods: {
        onInput(): void {
            this.$emit('input', this.value);
        },
    },
});
</script>

<style lang="scss" module>
.component {
    cursor: pointer;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 1em;

    .clear {
        clear: both;
    }

    .previews {
        height: 100%;
        width: 100%;
        background: white;
        opacity: .5;
        padding: 4px 10px;

        .preview {
            max-width: 100%;

            .preview-content {
                transform: scale(75%);
                margin: 0 -16.5%;
            }

            // Single preview.
            &:first-child {
                &:last-child {
                    height: 100%;

                    .preview-content {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%) scale(75%);
                        margin: 0;
                        display: inline-block;
                    }
                }
            }

            &.preview-incoming {
                float: left;
                z-index: 1;

                .preview-content {
                    background: rgb(230, 230, 230);
                    border: 1px solid rgba(115, 115, 115, .75);
                }
            }

            &.preview-outgoing {
                float: right;
                z-index: 2;
                margin-top: -1.4em;

                .preview-content {
                    background: rgb(200, 200, 200);
                    border: 1px solid rgba(100, 100, 100, .90);
                }
            }
        }

        &:not(.has-reply) {
            .preview {
                max-width: 75%;
                float: none;
                margin: 0 auto;
            }
        }
    }

    &:hover {
        border-color: rgba(200, 200, 200, .75);
    }

    &.current, &:active {
        border-color: rgba(100, 100, 100, .75);
    }

    &.current, &:hover {
        .previews {
            opacity: 1;
        }
    }
}
</style>
