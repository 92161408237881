interface Normalizer {
    (value: string): string;
}

function defaultNormalizer(value: string): string {
    return value;
}

function backgroundImageNormalizer(url: string): string {
    return url.startsWith('url(') && url.endsWith(')')
        ? url
        : `url(${url})`;
}

const normalizers = {
    'header_background-image': backgroundImageNormalizer,
} as Record<string, Normalizer>;

export default function normalize(property: string, value: string): string {
    const normalizer: Normalizer = normalizers[property] ?? defaultNormalizer;

    return normalizer(value);
}
