// chat script statuses
export const STATUS_PREPARATION = 1;
export const STATUS_LIVE = 2;
export const STATUS_CLOSED = 3;
export const STATUS_DELETED = 4;

export const STATUSES = {
    [STATUS_PREPARATION]: 'preparation',
    [STATUS_LIVE]: 'live',
    [STATUS_CLOSED]: 'closed',
    [STATUS_DELETED]: 'deleted',
};

export const TYPE_API_KEY = 1;
export const TYPE_BASIC_AUTH = 2;
export const TYPE_BEARER_TOKEN = 3;
export const TYPE_OAUTH_2 = 4;
export const TYPE_OAUTH_2_REFRESH_TOKEN = 5;
export const AUTH_TYPES = {
    [TYPE_API_KEY]: 'Api Key',
    [TYPE_BASIC_AUTH]: 'Basic Auth',
    [TYPE_BEARER_TOKEN]: 'Bearer Token',
    // [TYPE_OAUTH_2]: 'OAuth 2.0',
    [TYPE_OAUTH_2_REFRESH_TOKEN]: 'OAuth 2.0: Refresh token',
};

export const AUTH_TYPES_REQUIRING_AUTHORIZATION = [
    TYPE_OAUTH_2_REFRESH_TOKEN,
];

export const API_FIELD_API_TOKEN = 'token';
export const API_FIELD_API_KEY = 'key';
export const API_FIELD_USERNAME = 'username';
export const API_FIELD_PASSWORD = 'password';
export const API_FIELD_CLIENT_ID = 'clientId';
export const API_FIELD_CLIENT_SECRET = 'clientSecret';
export const API_FIELD_SCOPE = 'scope';
export const API_FIELD_AUTH_ENDPOINT = 'authEndpoint';
export const API_FIELD_TOKEN_ENDPOINT = 'tokenEndpoint';

export const API_FIELDS_LABELS = {
    [API_FIELD_API_KEY]: 'Key',
    [API_FIELD_API_TOKEN]: 'Token',
    [API_FIELD_USERNAME]: 'Username',
    [API_FIELD_PASSWORD]: 'Password',
    [API_FIELD_CLIENT_ID]: 'Client Id',
    [API_FIELD_CLIENT_SECRET]: 'Client Secret',
    [API_FIELD_AUTH_ENDPOINT]: 'Authorization Endpoint',
    [API_FIELD_TOKEN_ENDPOINT]: 'Token Endpoint',
    [API_FIELD_SCOPE]: 'Scope',
};

export const API_FIELD_DEFAULTS = {
    [API_FIELD_API_KEY]: {
        secret: false,
    },
    [API_FIELD_API_TOKEN]: {
        secret: true,
    },
    [API_FIELD_USERNAME]: {
        secret: false,
    },
    [API_FIELD_PASSWORD]: {
        secret: true,
    },
    [API_FIELD_CLIENT_ID]: {
        secret: true,
    },
    [API_FIELD_CLIENT_SECRET]: {
        secret: true,
    },
    [API_FIELD_AUTH_ENDPOINT]: {
        secret: false,
    },
    [API_FIELD_TOKEN_ENDPOINT]: {
        secret: false,
    },
    [API_FIELD_SCOPE]: {
        secret: false,
    },
};

export const AUTH_TYPE_API_FIELDS = {
    [TYPE_API_KEY]: [
        API_FIELD_API_KEY,
        API_FIELD_API_TOKEN,
    ],
    [TYPE_BASIC_AUTH]: [
        API_FIELD_USERNAME,
        API_FIELD_PASSWORD,
    ],
    [TYPE_BEARER_TOKEN]: [
        API_FIELD_API_TOKEN,
    ],
    [TYPE_OAUTH_2]: [
        API_FIELD_CLIENT_ID,
        API_FIELD_CLIENT_SECRET,
        API_FIELD_USERNAME,
        API_FIELD_PASSWORD,
    ],
    [TYPE_OAUTH_2_REFRESH_TOKEN]: [
        API_FIELD_CLIENT_ID,
        API_FIELD_CLIENT_SECRET,
        API_FIELD_SCOPE,
        // Only required for generic Oauth providers.
        // Currently only Salesforce is allowed to use this method, which has
        // set values for these endpoints.
        // API_FIELD_AUTH_ENDPOINT,
        // API_FIELD_TOKEN_ENDPOINT,
    ],
};

export const PLATFORM_CM = 1;
export const PLATFORM_GOOGLE_SHEETS = 2;
export const PLATFORM_LOUWMAN_DATAROTONDE = 3;
export const PLATFORM_SALESFORCE = 4;
export const PLATFORMS = {
    [PLATFORM_CM]: 'CM.com',
    [PLATFORM_GOOGLE_SHEETS]: 'Google sheets',
    // [PLATFORM_MICROSOFT_DYNAMICS]: 'Microsoft Dynamics (not implemented)',
    [PLATFORM_LOUWMAN_DATAROTONDE]: 'Louwman Datarotonde',
    [PLATFORM_SALESFORCE]: 'Salesforce',
};

export const CRM_AUTH_TYPE_ANONYMOUS = 1;
export const CRM_AUTH_TYPE_AUTHENTICATED = 2;
export const CRM_AUTH_TYPE_PREAUTHENTICATED = 3;
export const CRM_AUTH_TYPES = {
    [CRM_AUTH_TYPE_ANONYMOUS]: 'Anonymous',
    [CRM_AUTH_TYPE_AUTHENTICATED]: 'Pre-identified',
    [CRM_AUTH_TYPE_PREAUTHENTICATED]: 'Authenticated',
};

export const CRM_AUTHENTICATED_TYPE_EMAIL = 1;
export const CRM_AUTHENTICATED_TYPE_SMS = 2;
export const CRM_AUTHENTICATED_TYPES = {
    [CRM_AUTHENTICATED_TYPE_EMAIL]: 'Email',
};

export const EMAIL_VALIDATION = 1;
export const NUMERIC_VALIDATION = 2;
export const NOT_EMPTY_VALIDATION = 3;
export const UUID_VALIDATION = 4;
export const VALIDATION_CONSTRAINTS = {
    [EMAIL_VALIDATION]: 'Email',
    [NUMERIC_VALIDATION]: 'Numeric',
    [NOT_EMPTY_VALIDATION]: 'Not empty',
    [UUID_VALIDATION]: 'Uuid',
};

// Source: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4456887/
export const HUMAN_VISUAL_RESPONSE_TIME = 0.180;

// Source: https://scholarwithin.com/average-reading-speed
// The eventually chosen reading speed is based on internal tests.
// Reading speed is in words per minute.
export const HUMAN_READING_SPEED = 280; // wpm
export const READING_TIME_PER_WORD = 1 / (HUMAN_READING_SPEED / 60);
export const MINIMAL_NUM_WORDS = 4;
