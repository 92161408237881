<template>
    <div id="logout">{{$t('MESSAGE.LOGGING_OUT')}}</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'Logout',
    created() {
        this.$store.dispatch('logout').then(() => {
            this.$router.push('/');
        });
    },
    template: '',
});
</script>

<style scoped lang="scss">
    #logout {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
