<template>
    <div class="cred-modal">
        <div v-for="(errorMessage, index) in errorMessages" class="error-message" :key="index">
            <h3>{{ errorMessage }}</h3>
        </div>
        <form class="pure-form pure-form-aligned" v-on:submit.prevent="onSubmit">
            <fieldset>
                <div class="pure-control-group">
                    <label for="aligned-platform">Platform</label>
                    <select v-model="platform" class="input-width" id="aligned-platform">
                        <option v-for="(platform, key) in platforms" :value="key" :key="key">
                            {{ platform}}
                        </option>
                    </select>
                </div>
                <div class="pure-control-group">
                    <label for="connection-name">Connection name</label>
                    <input required v-model="name" class="input-width" type="text" id="connection-name" />
                </div>
                <div class="pure-control-group">
                    <label for="external-key">External Key</label>
                    <input required v-model="externalKey" class="input-width" type="text" id="external-key" />
                </div>
                <div v-if="platform == PLATFORM_CM || platform == PLATFORM_SALESFORCE">
                    <div class="pure-control-group">
                        <label for="aligned-url">Api Host</label>
                        <input v-model="apiHost" class="input-width" type="url" id="aligned-url" />
                    </div>
                </div>
                <div v-if="platform == PLATFORM_SALESFORCE">
                    <div class="pure-control-group">
                        <label for="api-method">Request Method</label>
                        <select v-model="apiMethod" class="input-width" id="api-method">
                            <option v-for="(method, key) in httpMethods" :value="method" :key="key">
                                {{ method }}
                            </option>
                        </select>
                    </div>
                    <div class="pure-control-group">
                        <label for="api-path">Path</label>
                        <input required v-model="apiPath" class="input-width" type="text" id="api-path" />
                    </div>
                </div>
                <div v-else-if="platform == PLATFORM_GOOGLE_SHEETS">
                    <div class="pure-control-group">
                        <label for="spreadsheet-id">Spreadsheet Id</label>
                        <input required v-model="spreadsheetId" class="input-width" type="text" id="spreadsheet-id" />
                    </div>
                    <div class="pure-control-group">
                        <label for="sheet-name">Sheet name</label>
                        <input required v-model="sheetName" class="input-width" type="text" id="sheet-name" />
                    </div>
                    <div class="pure-control-group">
                        <label for="sheet-name">Sheet range</label>
                        <input required v-model="sheetRange" class="input-width" type="text" id="sheet-range" />
                    </div>
                    <div class="pure-control-group">
                        <label for="sheet-credentials">Credentials</label>
                        <textarea required v-model="sheetCredentials" class="input-width" type="text" id="sheet-credentials"></textarea>
                    </div>
                </div>
                <div v-else-if="platform == PLATFORM_LOUWMAN_DATAROTONDE">
                    <div class="pure-control-group">
                        <label for="use-production">Use Test Api</label>
                        <Toggle v-model="test">staging</Toggle>
                    </div>
                </div>
                <div v-if="numPlatformAuthTypes > 0">
                    <div class="pure-control-group">
                        <label for="api-auth-type">Auth Type</label>
                        <select v-model="authType" class="input-width" id="api-auth-type">
                            <option v-for="(authType, key) in platformAuthTypes" :value="key" :key="key" :selected="numPlatformAuthTypes === 1">
                                {{ authType }}
                            </option>
                        </select>
                    </div>
                    <div v-if="authType == TYPE_OAUTH_2_REFRESH_TOKEN">
                        <div class="pure-control-group">
                            <label for="redirectUri">
                                Redirect URI
                            </label>
                            <p style="display: inline-block">{{ redirectUri }}</p>
                        </div>
                    </div>
                    <div v-for="(apiField, index) in apiFields[authType]" :key="index">
                        <div class="pure-control-group">
                            <label :for="'api-field-' + apiField.key">
                                {{ API_FIELDS_LABELS[apiField.key] }}
                            </label>
                            <input
                                v-model="apiField.value"
                                required
                                class="input-width"
                                autocomplete="off"
                                :type="apiField.secret ? 'password' : 'text'"
                            />
                        </div>
                    </div>
                </div>
                <div class="ta-center">
                    <div class="mt-1">
                        <AbbiButton grow class="pure-u-1-3">Save</AbbiButton>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import CrmApi from '@/apis/crm.api';
import {
    API_FIELD_API_KEY,
    API_FIELD_API_TOKEN,
    API_FIELDS_LABELS,
    API_FIELD_DEFAULTS,
    AUTH_TYPES as authTypes,
    PLATFORM_CM,
    PLATFORM_GOOGLE_SHEETS,
    PLATFORM_LOUWMAN_DATAROTONDE,
    PLATFORM_SALESFORCE,
    PLATFORMS as platforms,
    TYPE_API_KEY,
    TYPE_OAUTH_2_REFRESH_TOKEN, AUTH_TYPE_API_FIELDS, TYPE_BEARER_TOKEN, TYPE_BASIC_AUTH,
} from '@/helpers/constants';
import Toggle from '@/components/input/Toggle.vue';

export default vue.extend({
    name: 'CrmConnectionModal',
    components: {Toggle, AbbiButton, AbbiTextInput},
    props: {
        options: Object,
    },
    data() {
        return {
            platform: PLATFORM_CM,
            name: null,
            externalKey: null,
            apiMethod: 'GET',
            apiHost: null,
            apiPath: '',
            authType: TYPE_API_KEY,
            spreadsheetId: null,
            sheetName: null,
            sheetRange: null,
            sheetCredentials: null,
            test: true,
            connectionId: null,
            authTypes,
            API_FIELDS_LABELS,
            PLATFORM_CM,
            PLATFORM_GOOGLE_SHEETS,
            PLATFORM_LOUWMAN_DATAROTONDE,
            PLATFORM_SALESFORCE,
            httpMethods: {
                GET: 'GET',
                POST: 'POST',
            },
            apiFields: {},
            TYPE_OAUTH_2_REFRESH_TOKEN,
            platforms,

            errorMessages: [],
        };
    },
    computed: {
        redirectUri(): string {
            return CrmApi.getVerificationLink();
        },
        numPlatformAuthTypes(): number {
            return Object.entries(this.platformAuthTypes).length;
        },
        platformAuthTypes() {
            let allowedTypes: Array<number>;

            switch (parseInt(String(this.platform), 10)) {
                case PLATFORM_SALESFORCE:
                    allowedTypes = [TYPE_OAUTH_2_REFRESH_TOKEN];
                    break;

                case PLATFORM_CM:
                    allowedTypes = [TYPE_API_KEY, TYPE_BASIC_AUTH, TYPE_BEARER_TOKEN];
                    break;

                case PLATFORM_LOUWMAN_DATAROTONDE:
                    allowedTypes = [TYPE_API_KEY];
                    break;

                default:
                    allowedTypes = [];
                    break;
            }

            // noinspection TypeScriptValidateTypes
            return Object.fromEntries(
                Object.entries(authTypes).filter(
                    ([key]) => allowedTypes.includes(parseInt(key, 10)),
                ),
            );
        },
    },
    methods: {
        initializeApiFields(fields: Array<string>): Array<object> {
            return fields.map(
                (field) => this.mapApiField({key: field, value: null}),
            );
        },
        mapApiField(field: {key: string; value: unknown}): object {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            return {
                // @ts-ignore
                ...(API_FIELD_DEFAULTS[field.key] || {}),
                ...field,
            };
        },
        mapApiFields(authType: number, fields: Array<{key: string}>): Array<object> {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            const mappedFields = Object.entries(fields).map(
                ([key, value]) => this.mapApiField({key, value}),
            );

            /* eslint-disable @typescript-eslint/no-explicit-any */
            const keys = mappedFields.map((field: any) => field.key ?? '');

            // @ts-ignore
            AUTH_TYPE_API_FIELDS[authType].forEach(
                (key: string) => {
                    if (!keys.includes(key)) {
                        mappedFields.push(
                            this.mapApiField({key, value: null}),
                        );
                    }
                },
            );

            return mappedFields;
        },
        onSubmit() {
            /* eslint-disable @typescript-eslint/ban-ts-comment, default-case, radix */
            const {communitySlug} = this.$route.params;
            let data: {} = {
                platform: this.platform,
                name: this.name,
                externalKey: this.externalKey,
            };

            /* eslint no-fallthrough: ["error", { "commentPattern": "no-break" }] */
            // noinspection FallThroughInSwitchStatementJS
            switch (parseInt(String(this.platform), 10)) {
                case PLATFORM_SALESFORCE:
                    data = {
                        ...data,
                        apiMethod: this.apiMethod,
                        apiPath: this.apiPath,
                    };
                // no-break

                case PLATFORM_CM: {
                    data = {
                        ...data,
                        apiHost: this.apiHost,
                        authType: this.authType,
                        // @ts-ignore
                        fields: Array.from(this.apiFields[this.authType]).reduce(
                            // @ts-ignore
                            (carry: object, field: {key: string; value: string}) => ({
                                ...carry,
                                [field.key]: field.value,
                            }),
                            {},
                        ),
                    };
                    break;
                }
                case PLATFORM_GOOGLE_SHEETS: {
                    data = {
                        ...data,
                        spreadsheetId: this.spreadsheetId,
                        sheetName: this.sheetName,
                        sheetRange: this.sheetRange,
                        sheetCredentials: JSON.parse(String(this.sheetCredentials)),
                    };
                    break;
                }

                case PLATFORM_LOUWMAN_DATAROTONDE: {
                    data = {
                        ...data,
                        authType: TYPE_API_KEY,
                        headers: {
                            // @ts-ignore
                            [this.apiFields[TYPE_API_KEY][0].value]: this.apiFields[TYPE_API_KEY][1].value,
                        },
                        production: !this.test,
                    };
                    break;
                }
            }

            if (this.connectionId) {
                // @ts-ignore
                CrmApi.edit(this.connectionId, data).then((connection) => {
                    if (connection) {
                        const event = new CustomEvent('addCrmConnection', {detail: connection});
                        document.dispatchEvent(event);
                        this.$emit('close');
                        this.$store.dispatch('closeModal');
                    }
                });
                return;
            }
            CrmApi.add(communitySlug, data).then((connection) => {
                if (connection) {
                    const event = new CustomEvent('addCrmConnection', {detail: connection});
                    document.dispatchEvent(event);
                    this.$emit('close');
                    this.$store.dispatch('closeModal');
                }
            }).catch(() => {
                // @ts-ignore
                this.errorMessages.push(CrmApi.getLastError().message);
            });
        },
    },
    created() {
        this.apiFields = Object.fromEntries(
            Object.entries(AUTH_TYPE_API_FIELDS).map(
                ([authType, fields]) => [authType, this.initializeApiFields(fields)],
            ),
        );

        const {connection} = this.$props.options;
        if (connection) {
            this.platform = connection.platform;
            this.name = connection.name;
            this.externalKey = connection.data.externalKey;

            /* eslint no-fallthrough: ["error", { "commentPattern": "no-break" }] */
            // noinspection FallThroughInSwitchStatementJS
            switch (parseInt(String(this.platform), 10)) {
                case PLATFORM_SALESFORCE:
                    this.apiPath = connection.data.apiPath;
                // no-break

                case PLATFORM_CM: {
                    this.authType = connection.data.authType;
                    this.connectionId = connection.id;
                    this.apiHost = connection.data.apiHost;
                    // @ts-ignore
                    this.apiFields[this.authType] = this.mapApiFields(
                        this.authType,
                        connection.data.fields,
                    );
                    break;
                }
                case PLATFORM_GOOGLE_SHEETS: {
                    this.connectionId = connection.id;
                    this.spreadsheetId = connection.data.spreadsheetId;
                    this.sheetName = connection.data.sheetName;
                    this.sheetRange = connection.data.sheetRange;
                    // @ts-ignore
                    this.sheetCredentials = JSON.stringify(connection.data.sheetCredentials);
                    break;
                }
                case PLATFORM_LOUWMAN_DATAROTONDE: {
                    this.authType = connection.data.authType;
                    this.connectionId = connection.id;
                    this.test = !(connection.data.production ?? false);
                    // @ts-ignore
                    this.apiFields[TYPE_API_KEY] = [
                        // @ts-ignore
                        this.mapApiField(
                            {
                                key: API_FIELD_API_KEY,
                                value: Object.keys(connection.data.headers)[0],
                            },
                        ),
                        // @ts-ignore
                        this.mapApiField(
                            {
                                key: API_FIELD_API_TOKEN,
                                value: Object.values(connection.data.headers)[0],
                            },
                        ),
                    ];
                }
            }
        }
        /* eslint-enable @typescript-eslint/ban-ts-comment, default-case, radix */
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';
    .modalWidth {
        width: 61%;
    }
    .input-width {
        width: 60%;
    }
    #sheet-credentials {
        resize: none;
        height: 174px;
    }
    .cred-modal {
        fieldset {
            margin-left: 30px;
        }
        &.error-message {
            h3 {
                padding: 0;
                margin: 0;

                font-weight: bold;
                font-size: 1.1em;
            }

            background-color: darken(red, 15%);
            color: white;

            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            margin: -2px;
            margin-bottom: 0;
            padding: 0.5rem 0.75rem;
        }
        select {
            height: 2.4em;
        }

        #multi-state {
            width: 15%;
        }
        .api-value {
            margin-left: 15px;
            width: 43.3%;
        }
        .api-button {
            color: white;
            border-radius: 6px;
        }
        .add-api-value {
            background-color: $abbi-green;
            margin-left: 50px;
        }
        .remove-api-value {
            background-color: $abbi-red;
            margin-left: 15px;
        }

        .submit-block {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1.2em;
            button {
                width: 20%;
                border-radius: 6px;
                background-color: $abbi-green;
            }
        }
    }
</style>
