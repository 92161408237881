import AbbiApi from '@/apis/abbi.api';

export default class UserApi extends AbbiApi {
    public static getAllUsers() {
        return UserApi.get(`${UserApi.url}/api/users`);
    }

    public static createNewUser(username: string, email: string, password: string) {
        return UserApi.put(`${AbbiApi.url}/api/users`, {username, email, password});
    }

    public static readUser(uuid: string) {
        return UserApi.get(`${UserApi.url}/api/users/${uuid}`);
    }

    public static updateUser(uuid: string, changes: {[key: string]: string | object}) {
        return UserApi.patch(`${UserApi.url}/api/users/${uuid}`, changes);
    }

    public static getDeleteUserCSRF(uuid: string) {
        return UserApi.get(`${UserApi.url}/api/users/${uuid}/delete`);
    }

    public static deleteUser(uuid: string, csrf: string) {
        return UserApi.delete(`${UserApi.url}/api/users/${uuid}/delete`, {csrf});
    }
}
