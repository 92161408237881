/* eslint @typescript-eslint/no-explicit-any: 0 */
import Vue, {Component} from 'vue';
import Vuex from 'vuex';
import createdPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import settings from '@/settings';

/* Load Models */
import User from '@/models/user.model';
import CommunityModel from '@/models/community.model';

const securityOpts = {
    encodingType: 'aes',
    encryptionSecret: 'abbi-secret',
    isCompression: true,
    encryptionNamespace: 'abbi-insights~CEP',
};
const localStorage = new SecureLS(securityOpts);

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        currentUser: null as User | null,
        loggedIn: false,

        communities: [] as CommunityModel[],
        activeCommunity: null as CommunityModel | null,

        /* MODAL STATE */
        modalTitle: null as string | null,
        modalOptions: null as Record<string, any> | null,
        modalEventHandlers: null as Record<string, Function> | null,
        modal: null as Component | null,
    },
    getters: {
        community: (state): CommunityModel | null => state.activeCommunity,
        // bla: (state): string|undefined => this.$route.params.communitySlug,
    },
    mutations: {
        setUser(state, user: User) {
            state.currentUser = user;
        },
        setLoggedIn(state, loggedIn: boolean) {
            state.loggedIn = loggedIn;
        },
        setModal(state, {
            title,
            component,
            options = null as Record<string, any> | null,
            eventHandlers = null as Record<string, Function> | null,
        }) {
            state.modalTitle = title;
            state.modal = component;
            state.modalOptions = options;
            state.modalEventHandlers = eventHandlers;
        },
        setCommunities(state, communities: CommunityModel[]) {
            state.communities = communities;
        },
        changeCommunity(state, community: CommunityModel) {
            state.activeCommunity = community;
        },
        addCommunity(state, community: CommunityModel) {
            state.communities.push(community);
        },
    },
    actions: {
        login({commit}, userEntity: User) {
            commit('setUser', userEntity);
            commit('setLoggedIn', true);
        },
        updateUser({commit}, userEntity: User) {
            commit('setUser', userEntity);
        },
        logout({commit}) {
            fetch(
                `${settings.apiBase}/api/logout`,
                {
                    method: 'GET',
                    credentials: 'include',
                    redirect: 'manual',
                },
            );
            commit('setUser', null);
            commit('setLoggedIn', false);
        },
        closeModal({commit}) {
            commit('setModal', {title: null, modal: null});
        },
    },
    modules: {},
    plugins: [
        createdPersistedState({
            paths: ['currentUser', 'loggedIn'],
            storage: {
                getItem: (key: string) => localStorage.get(key),
                setItem: (key: string, value) => localStorage.set(key, value),
                removeItem: (key: string) => localStorage.remove(key),
            },
        }),
    ],
    strict: process.env.NODE_ENV !== 'production',
});
