<template>
    <form class="edit-account" action="#" method="post" @click.stop @submit.prevent="handleSubmit">
        <h2 class="header">{{ $t('ACTION.USER.EDIT') }}</h2>
        <div class="edit-body">
            <div class="pure-g with-gutter">
                <div class="pure-u-1-2">
                    <AbbiTextInput is-small name="username" :placeholder="$t('USER.USERNAME')"
                                   v-model="user.username" />
                </div>
                <div class="pure-u-1-2">
                    <label>
                        <span>{{ $t('USER.ROLE') }}</span>
                        <select id="role" name="role">
                            <option value="ROLE_ADMIN" :selected="user.roles.includes('ROLE_ADMIN')">
                                {{ $t('ROLES.ADMIN') }}
                            </option>
                            <option value="ROLE_USER" :selected="user.roles.includes('ROLE_USER')">
                                {{ $t('ROLES.USER') }}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="mt-1 pure-g with-gutter">
                <div class="pure-u-1-2">
                    <AbbiTextInput is-small name="email" :placeholder="$t('USER.EMAIL')"
                                   v-model="user.email" />
                </div>
            </div>
            <hr />
            <div class="grid-2x2">
                <h3>{{ $t('GENERAL.COMMUNITIES') }}</h3>
                <div class="pure-g with-gutter" v-for="communityEntry in user.communityEntries"
                     v-bind:key="communityEntry.uuid">
                    <label class="pure-u-2-3">
                        <span>{{ communityEntry.community.name }}</span>
                        <select v-if="removeableCommunities.indexOf(communityEntry.community) >= 0"
                                :id="communityEntry.community.slug + '-role'"
                                :name="communityEntry.community.slug + '-role'">
                            <option value="ROLE_ADMIN" :selected="communityEntry.role === 'ROLE_ADMIN'">
                                {{ $t('ROLES.ADMIN') }}
                            </option>
                            <option value="ROLE_USER" :selected="communityEntry.role === 'ROLE_USER'">
                                {{ $t('ROLES.USER') }}
                            </option>
                        </select>
                    </label>
                    <AbbiButton v-if="removeableCommunities.indexOf(communityEntry.community) >= 0"
                                @click="removeCommunity(communityEntry.community.uuid)"
                                class="pure-u-1-3">
                        <FaIcon icon="times" />
                    </AbbiButton>
                    <span v-else class="pure-u-1-3">{{ $t('EDIT.STATE.TO_BE_REMOVED') }}</span>
                </div>
            </div>
            <hr />
            <div class="pure-g with-gutter">
                <label class="pure-u-1-2">
                    <select class="width-100 height-100" id="add-community" name="add-community">
                        <option
                            v-for="community in addableCommunities"
                            :key="community.uuid"
                            :value="community.uuid">
                            {{ community.name }}
                        </option>
                    </select>
                </label>
                <AbbiButton class="pure-u-1-2" grow @click="addCommunity">{{ $t('ACTION.USER.ADD_COMMUNITY') }}</AbbiButton>
            </div>
            <div class="grid-2x2 pt-1">
                <h3>{{ $t('EDIT.USER.COMMUNITIES_TO_ADD') }}</h3>
                <label v-for="community in relations.add" v-bind:key="community.uuid">
                    <span>{{ community.name }}</span>
                    <select :id="community.slug + '-role'"
                            :name="community.slug + '-role'">
                        <option value="ROLE_USER">
                            {{ $t('ROLES.USER') }}
                        </option>
                        <option value="ROLE_ADMIN">
                            {{ $t('ROLES.ADMIN') }}
                        </option>
                    </select>
                </label>
            </div>
            <div class="grid-2x2 pt-1">
                <h3>{{ $t('EDIT.USER.COMMUNITIES_TO_REMOVE') }}</h3>
                <div v-for="community in relations.remove" v-bind:key="community.uuid">
                    <span>{{ community.name }}</span>
                    <AbbiButton @click="revertRemoval(community)">
                        <FaIcon icon="times" />
                    </AbbiButton>
                </div>
            </div>
        </div>
        <div class="footer pure-g with-gutter">
            <AbbiButton class="pure-u-1-2" grow isSubmit="true">{{ $t('ACTION.USER.UPDATE') }}</AbbiButton>
            <AbbiButton class="pure-u-1-2" grow @click="close">{{ $t('ACTION.CANCEL') }}</AbbiButton>
        </div>
    </form>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import Vue from 'vue';
import UserModel, {UserCommunity} from '@/models/user.model';
import UserApi from '@/apis/user.api';
import CommunityModel from '@/models/community.model';
import CommunityApi from '@/apis/community.api';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';

export default Vue.extend({
    name: 'EditAccount',
    components: {AbbiButton, AbbiTextInput},
    props: ['user'],
    data() {
        return {
            communities: [] as CommunityModel[],
            relations: {
                add: [] as CommunityModel[],
                remove: [] as CommunityModel[],
                roleChange: [] as CommunityModel[],
            },
        };
    },
    computed: {
        addableCommunities() {
            const selected = this.user as UserModel;
            const entries = selected.communityEntries || [];
            const memberOf = entries.map((communityEntry) => {
                const {community} = communityEntry;
                return (community as CommunityModel).uuid;
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.communities.filter((community: CommunityModel) => memberOf.indexOf(community.uuid) < 0);
        },
        removeableCommunities() {
            const selected = this.user as UserModel;

            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            const entries = this.relations.remove.map((community: CommunityModel) => community.uuid);
            // @ts-ignore
            const memberOf = selected.communityEntries.map((communityEntry: UserCommunity) => communityEntry.community);
            // @ts-ignore
            return memberOf.filter((community: CommunityModel) => entries.indexOf(community.uuid) < 0);
            /* eslint-enable @typescript-eslint/ban-ts-comment */
        },
    },
    created() {
        CommunityApi.getCommunities()
            .then((responseData) => {
                if (responseData !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.communities = responseData as CommunityModel[];
                }
            });
    },
    methods: {
        addCommunity(): void {
            const communitySelector = document.getElementById('add-community') as HTMLSelectElement;
            const communityUuid = communitySelector.value;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const community = this.communities?.find((model: CommunityModel) => model.uuid === communityUuid);
            if (community !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.relations.add.push(community);
            }
        },
        removeCommunity(uuid: string): void {
            const communityUuid = uuid;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const community = this.communities?.find((model: CommunityModel) => model.uuid === communityUuid);
            if (community !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.relations.remove.push(community);
            }
        },
        revertRemoval(community: CommunityModel): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const idx = this.relations.remove.indexOf(community);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.relations.remove.splice(idx, 1);
        },
        handleSubmit({target}: Event): void {
            const selectedUser = this.user as UserModel;

            const formData = new FormData(target as HTMLFormElement);
            const usernameEntry = formData.get('username') as string;
            // const passwordEntry = formData.get('password') as string;
            const emailEntry = formData.get('email') as string;

            const roleEntry = formData.get('role') as string;

            const changeTargets: { [key: string]: string | UserCommunity[] } = {};
            if (usernameEntry !== selectedUser.username) {
                changeTargets.username = usernameEntry;
            }

            // if (passwordEntry !== '') {
            //     changeTargets.password = passwordEntry;
            // }

            if (emailEntry !== selectedUser.email) {
                changeTargets.email = emailEntry;
            }

            if (!selectedUser.roles.includes(roleEntry)) {
                changeTargets.roles = roleEntry;
            }

            const promise: Promise<any> = UserApi.updateUser(selectedUser.uuid as string, changeTargets)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        return responseData;
                    }
                    throw new Error('updateUser failed!');
                });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            for (let i = 0; i < this.relations.add.length; i += 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                promise.then(() => CommunityApi.addUserToCommunity(this.relations.add[i].uuid as string, selectedUser.uuid as string));
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            for (let i = 0; i < this.relations.remove.length; i += 1) {
                promise.then(() => CommunityApi.removeUserFromCommunityCSRF(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.relations.remove[i].uuid as string,
                        selectedUser.uuid as string,
                )
                    .then((csrfResponse) => CommunityApi.removeUserFromCommunity(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            this.relations.remove[i].uuid as string,
                            selectedUser.uuid as string,
                            csrfResponse.deleteToken,
                    )));
            }

            promise.then(() => UserApi.readUser(selectedUser.uuid))
                .then((responseData) => {
                    this.$emit('edit-made', responseData as UserModel);
                });
        },
        close() {
            this.$emit('close');
        },
    },
    watch: {
        user: {
            deep: true,
            handler() { // reset relation data when a different user is selected
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.relations = {
                    add: [],
                    remove: [],
                    roleChange: [],
                };
            },
        },
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

.edit-account {
    display: flex;
    flex-direction: column;
    box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.5);
    background: white;
    width: 30%;
    min-width: max-content;
    height: 100%;
    z-index: 2;

    .header {
        padding: 1em;
        margin: 0;

        color: white;
        background-color: $abbi-blue;
    }

    .edit-body {
        flex: 1;
        padding: 1em;
    }

    div[class^="pure-u"] > * {
        width: 100%;
    }

    .footer {
        padding: 1em;

        .cancel-button button {
            background-color: darken(red, 10%) !important;
        }
    }
}
</style>
