<template>
    <div id="question-types">
        <InstructionSet title="Show elements">
            <Instruction type='message' label='Message' :action="picked"/>
            <Instruction type='image' label='Image' :action="picked"/>
            <Instruction type='video' label='Video' :action="picked"/>
            <Instruction type='score' label='Score' :action="picked"/>
        </InstructionSet>
        <InstructionSet title="Questions">
            <InstructionPresentationSet title="choice">
                <Instruction type='choice' label='Single choice' :action="picked"/>
                <Instruction type='multiple' label='Multiple choice' :action="picked"/>
            </InstructionPresentationSet>
            <InstructionPresentationSet title="text">
                <Instruction type='open' label='Open answer' :action="picked"/>
                <Instruction type='email' label='Email' :action="picked"/>
            </InstructionPresentationSet>
            <InstructionPresentationSet title="number">
                <Instruction type='numeric' label='Numeric' :action="picked"/>
                <StarRatingInstruction :action="picked" />
                <Instruction type='currency' label='Currency' :action="picked"/>
                <Instruction type='nps' label='NPS' :action="picked"/>
            </InstructionPresentationSet>
            <InstructionPresentationSet>
                <Instruction type='emoji' label='Emoji' :action="picked"/>
                <Instruction type='file' label='File' :action="picked"/>
                <Instruction type='date' label='Date' :action="picked"/>
            </InstructionPresentationSet>
        </InstructionSet>
        <InstructionSet title="Control elements">
            <Instruction type='end' label='End chat' :action="picked"/>
            <Instruction type='send2api' label='Send to API' :action="picked"/>
            <Instruction type='crmAuth' label='CRM auth' :action="picked"/>
            <Instruction type='crmApi' label='CRM API Call' :action="picked"/>
            <Instruction type='send2con' label='Send2connector' :action="picked"/>
        </InstructionSet>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import {v4 as uuidv4} from 'uuid';
import InstructionModel from '@/models/instruction.model';
import MessageModel from '@/models/message.model';
import QuestionModel from '@/models/question.model';
import ActionModel from '@/models/action.model';
import {
    CRM_AUTH_TYPE_ANONYMOUS,
    CRM_AUTHENTICATED_TYPE_EMAIL,
    NOT_EMPTY_VALIDATION,
    PLATFORM_GOOGLE_SHEETS,
} from '@/helpers/constants';
import Instruction from '@/components/chat/Instruction.vue';
import InstructionSet from '@/components/chat/InstructionSet.vue';
import StarRatingInstruction from '@/components/chat/instructions/StarRatingInstruction.vue';
import InstructionPresentationSet from '@/components/chat/InstructionPresentationSet.vue';

export default vue.extend({
    name: 'InstructionSelection',
    components: {
        InstructionPresentationSet,
        StarRatingInstruction,
        InstructionSet,
        Instruction,
    },
    props: {
        instruction: [InstructionModel, Object],
    },
    methods: {
        picked(type: string) {
            const instruction = new InstructionModel();
            instruction.uuid = uuidv4();

            switch (type) {
                case 'email':
                case 'open': {
                    const question = new QuestionModel();
                    question.type = type;
                    question.options = {
                        minimum: 1,
                        maximum: 1024,
                    };
                    instruction.type = 'question';
                    instruction.question = question;

                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }

                case 'image': {
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    instruction.type = 'image';
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 3;
                    break;
                }

                case 'video': {
                    // https://stackoverflow.com/questions/7853904/how-to-detect-when-a-youtube-video-finishes-playing
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    instruction.type = 'video';
                    break;
                }

                case 'message': {
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    instruction.type = 'message';
                    break;
                }

                case 'date': {
                    const question = new QuestionModel();
                    question.type = type;
                    question.options = {};
                    instruction.type = 'question';
                    instruction.question = question;

                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }

                case 'nps': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.options = {
                        min: 1,
                        max: 10,
                    };
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }
                case 'numeric': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.uuid = uuidv4();
                    question.options = {stepSize: 1};
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }
                case 'starRating': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.uuid = uuidv4();
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];

                    break;
                }
                case 'currency': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.uuid = uuidv4();
                    question.options = {stepSize: 1};
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }

                case 'emoji': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.uuid = uuidv4();
                    question.options = {};
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }

                case 'file': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.uuid = uuidv4();
                    question.skipable = false;
                    question.options = {extensions: ['png', 'jpg', 'jpeg', 'gif', 'svg']};
                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];
                    break;
                }

                case 'multiple':
                case 'choice': {
                    // eslint-disable-next-line no-shadow
                    const question = new QuestionModel();
                    question.type = type;
                    question.options = {columns: 1, searchPattern: 'any'};
                    question.uuid = uuidv4();

                    if (type === 'multiple') question.options.minimum = 1;

                    instruction.type = 'question';
                    instruction.question = question;

                    // eslint-disable-next-line no-shadow
                    const msg = new MessageModel();
                    instruction.messages = [msg];

                    question.choices = [];
                    break;
                }

                case 'end': {
                    instruction.type = 'end';
                    const action = new ActionModel();
                    action.type = 'end';
                    action.params = {
                        endType: 'finished',
                        endAction: 'close',
                    };
                    instruction.action = action;
                    break;
                }

                case 'payment': {
                    const action = new ActionModel();
                    action.type = 'payment';
                    action.params = {environment: 'staging', autoRedirect: true, amount: ''};

                    const msg = new MessageModel();
                    msg.content = 'You\'ll now be redirected to our payment provider, in case you are not automatically redirected click this message.';
                    instruction.messages = [msg];

                    instruction.type = 'action';
                    instruction.action = action;
                    break;
                }
                case 'send2api': {
                    const action = new ActionModel();
                    action.type = 'send2api';
                    action.params = {
                        endpoint: '',
                        version: 2,
                        wizard: true,
                    };

                    instruction.type = 'action';
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 0;
                    instruction.draft = true;
                    instruction.action = action;
                    break;
                }

                case 'send2con': {
                    const action = new ActionModel();
                    action.type = 'send2con';
                    action.params = {
                        connector: null,
                        mapper: null,
                    };

                    instruction.type = 'action';
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 0;
                    instruction.action = action;
                    break;
                }

                case 'crmAuth': {
                    const action = new ActionModel();
                    action.type = 'crmAuth';
                    action.params = {
                        crmAuthenticatedType: CRM_AUTHENTICATED_TYPE_EMAIL,
                        crmAuthType: CRM_AUTH_TYPE_ANONYMOUS,
                        validation: NOT_EMPTY_VALIDATION,
                        platform: PLATFORM_GOOGLE_SHEETS,
                    };

                    const msg = new MessageModel();

                    instruction.type = 'action';
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 0;
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.can_revert = false;
                    instruction.action = action;
                    instruction.messages = [msg];
                    break;
                }

                case 'crmApi': {
                    const action = new ActionModel();
                    action.type = 'crmApi';
                    action.params = {
                        platform: PLATFORM_GOOGLE_SHEETS,
                        validation: NOT_EMPTY_VALIDATION,
                        column: null,
                        connection: null,
                    };

                    const msg = new MessageModel();

                    instruction.type = 'action';
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 0;
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.can_revert = false;
                    instruction.action = action;
                    instruction.messages = [msg];
                    break;
                }

                case 'score': {
                    const action = new ActionModel();
                    action.type = 'score';
                    action.params = null;

                    const msg = new MessageModel();
                    msg.content = 'You\'ve scored { SCORE } points';

                    instruction.type = 'action';
                    instruction.action = action;
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    instruction.typing_delay = 2.4;
                    instruction.messages = [msg];

                    break;
                }

                default:
                    throw new Error(`case for type: ${type} is not yet defined`);
            }

            if (instruction.type === 'question') {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                instruction.typing_delay = 1;
            }

            this.$emit('newInstruction', instruction);
        },
    },
});
</script>

<style lang="scss">
#question-types {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    display: flex;

    flex-wrap: wrap;
    justify-content: space-evenly;

    .option {
        background-color: white;

        height: 110px;
        width: 160px;

        margin: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: pointer;

        &.deprecated {
            opacity: 0.4;
        }

        .option-image {
            height: 60px;
            display: flex;
            align-items: center;
        }
    }
}
</style>
