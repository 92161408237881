const convertBlobToBase64 = (blob: Blob): Promise<string> => new Promise(
    (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    },
);

export default class ExportDecorator {
    public static UUID_LIST: string[] = [];

    public static uploadAssetToCommunity(
        matcher: RegExp,
        subject: string,
        replace = '"import://uploadAssetToCommunity(\'$1\')"',
    ): string {
        return subject.replace(matcher, replace);
    }

    public static async transformAssetsToBase64(
        matcher: RegExp,
        subject: string,
    ): Promise<string> {
        let transformed = subject;

        let m;
        /* eslint-disable */
        while (m = matcher.exec(subject)) {
            const url = m[1];
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
            const blob = await response.blob();
            const base64 = await convertBlobToBase64(blob);
            transformed = transformed.replace(url, `import://fromBase64('${base64}')`);
        }

        return transformed;
    }

    public static newUUid(subject: string): string {
        return subject.replaceAll(
            /"[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}"/g,
            (uuid, _) => {
                let index = this.UUID_LIST.indexOf(uuid);
                if (index === -1) {
                    this.UUID_LIST.push(uuid);
                    index = this.UUID_LIST.length - 1;
                }
                return `"import://newUuid(${index})"`;
            },
        );
    }
}
