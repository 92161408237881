<template>
    <v-app id="open-answer-configurator" class="dynamic">
        <v-alert
            prominent
            v-if="!validForm"
            type="error"
        >
            This configuration contains errors. Changes will not be saved. <br />
            Please fix them before continuing or moving away from this page.
        </v-alert>
        <v-form ref="form" v-model="validForm">
            <v-row>
                <v-col>
                    <p>Answer box size</p>
                    <v-btn-toggle
                        v-model="boxSize"
                        color="secondary"
                        title="Answer box size"
                        group
                        mandatory
                    >
                        <v-btn value="multiline">Multi-line</v-btn>
                        <v-btn value="singleline">Single-line</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                        filled
                        type="number"
                        :readonly="scriptLive"
                        min="1"
                        :max="maximumVal"
                        @change="changed"
                        label="Minimum length (characters)"
                        placeholder="Minimum length (characters)"
                        v-model.number="minimumVal"
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        filled
                        type="number"
                        :readonly="scriptLive"
                        :min="minimumVal"
                        @change="changed"
                        label="Maximum length (characters)"
                        placeholder="Maximum length (characters)"
                        v-model.number="maximumVal"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-app>
<!--    <div id="open-answer-configurator">-->
<!--        <div id="answer-box-size">-->
<!--            <b-input-group key='answerBoxSize' prepend="Answer box size">-->
<!--                <b-form-select class="form-control" v-model="boxSize" :options="answerBoxSizeOptions"/>-->
<!--            </b-input-group>-->
<!--        </div>-->
<!--        <div class="inputfield my-1">-->
<!--            <form>-->
<!--                <div class="form-row align-items-center">-->
<!--                    <b-input-group key='minLen' prepend="Minimum">-->
<!--                        <AbbiNumericInput id="minimum-length" :readonly="scriptLive" :min="0" is-small placeholder="minimum" v-model="minimumVal" @input="changed" />-->
<!--                    </b-input-group>-->
<!--                    <b-input-group key='maxLen' prepend="Maximum">-->
<!--                        <AbbiNumericInput id="maximum-length" :readonly="scriptLive" :max="1024" is-small placeholder="maximum" v-model="maximumVal" @input="changed" />-->
<!--                    </b-input-group>-->
<!--                </div>-->
<!--            </form>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script lang="ts">
import vue from 'vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';
import Toggle from '@/components/input/Toggle.vue';
import Configurator from '@/components/chat/configurators/Configurator.vue';

export default Configurator.extend({
    name: 'OpenAnswerConfigurator',
    components: {
        Toggle,
        AbbiNumericInput,
    },
    watch: {
        boxSize() {
            this.changed();
        },
    },
    methods: {
        changed() {
            this.$emit('change', {
                minimum: this.minimumVal,
                maximum: this.maximumVal,
                boxSize: this.boxSize,
            });
        },
    },
    computed: {
        answerBoxSizeOptions(): Array<{value: string, text: string}> {
            const sizes = {
                singleline: "Single-line",
                multiline: "Multi-line",
            };

            return Object.entries(sizes).map(([k, v]) => ({ value: k, text: v }));
        },
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data() {
        return {
            minimumVal: this.options?.minimum,
            maximumVal: this.options?.maximum,
            boxSize: this.options?.boxSize ?? 'multiline',
        };
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/grid';

@import "~bootstrap/scss/forms";

#open-answer-configurator {
    margin-top: 25px;
    max-width: 960px;

    .inputfield {
        display: flex;
        align-items: center;

        > .text {
            width: 240px;

            > * {
                margin: 0px;
            }
        }
    }

    #answer-box-size {
        display: flex;
        flex-direction: row;
    }
}
</style>
