<template>
    <div id="date-configurator">
        <div class="input-group my-1">
            <div class="inputfield">
                <div class="text">
                    <p><label for="earliest">Not before</label></p>
                </div>
                <input name="earliest" id="earliest" type="date" pattern="\d{4}-\d{2}-\d{2}" v-model="minimumVal" @input="changed">
            </div>
            <div class="inputfield">
                <div class="text">
                    <p><label for="latest">Not after</label></p>
                </div>
                <input name="latest" id="latest" type="date" pattern="\d{4}-\d{2}-\d{2}" v-model="maximumVal" @input="changed">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import {dateToDateString} from '@/helpers/utilities';

export default vue.extend({
    name: 'DateConfigurator',
    data() {
        return {
            initOptions: this.options || {},
            minimumVal: undefined as string|undefined,
            maximumVal: undefined as string|undefined,
        };
    },
    mounted() {
        if (this.options.maximum) this.maximumVal = dateToDateString(new Date(this.options.maximum));
        if (this.options.minimum) this.minimumVal = dateToDateString(new Date(this.options.minimum));
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    methods: {
        changed(): void {
            let earliest; let
                latest;
            if (this.minimumVal) earliest = this.minimumVal;
            if (this.maximumVal) latest = this.maximumVal;

            this.$emit('change', {
                minimum: earliest,
                maximum: latest,
            });
        },
    },
});
</script>

<style lang="scss">
#date-configurator {
    input, select {
        background-color: white;
        border: 1px solid black;
    }

    .input-group {
        margin: 0 auto;

        max-width: 960px;
        min-height: 40px;

        display: flex;
        justify-content: space-between;
    }

    .inputfield {
        display: flex;
        align-items: center;

        > .text {
            width: 150px;

            > * {
                margin: 0;
            }
        }
    }
}
</style>
