<script lang="ts">
import Configurator from '@/components/community/styling/Configurator.vue';
import StatModel from '@/models/stat.model';
import ScriptModel from '@/models/script.model';
import InstructionModel from '@/models/instruction.model';
import LiveStatsForm from '@/components/community/styling/live-stats/LiveStatsForm.vue';
import scenarios from '@/components/community/styling/live-stats/scenarios';

interface LiveStatisticsState {
    activeIdx: number;
    questionsOnly: boolean;
    stats: Record<string, StatModel>;
    script: ScriptModel;
}

export default Configurator.extend({
    data() {
        return {
            options: {
                scenario: Object.keys(scenarios)[0] as string,
            },
        };
    },
    computed: {
        form(): typeof LiveStatsForm {
            return LiveStatsForm;
        },
        activeIdx(): number {
            return 0;
        },
        questionsOnly(): boolean {
            return this.instructions.reduce(
                (carry: boolean, instruction: InstructionModel) => (
                    carry && instruction.type === 'question'
                ),
                true,
            );
        },
        instructions(): InstructionModel[] {
            return scenarios[this.options.scenario].instructions;
        },
        stats(): Record<string, StatModel> {
            return scenarios[this.options.scenario].stats;
        },
        state(): LiveStatisticsState {
            const {
                activeIdx,
                questionsOnly,
                instructions,
                stats,
            } = this;

            return {
                activeIdx,
                questionsOnly,
                script: {
                    uuid: '1',
                    name: 'Live stats configurator',
                    instructions,
                },
                stats,
            };
        },
    },
});
</script>
