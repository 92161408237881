<script lang="ts">
import {PropType} from 'vue';
import DataRangeInput from '@/components/community/styling/Input/DataRangeInput.vue';
import DataListInput from '@/components/community/styling/Input/DataListInput.vue';
import {Portal} from '@/components/community/styling/Configurator.vue';
import ColorInput from '@/components/community/styling/Input/ColorInput.vue';
import ImageInput from '@/components/community/styling/Input/ImageInput.vue';
import StylesheetHolder from '@/components/community/styling/form/StylesheetHolder.vue';
import ToggleInput from '@/components/community/styling/Input/ToggleInput.vue';
import FontUpload from '@/components/input/upload/FontUpload.vue';
import TextInput from '@/components/community/styling/Input/TextInput.vue';
import UrlInput from '@/components/input/UrlInput.vue';

interface ScenarioListener {
    (scenario: string): void;
}

export class ScenarioSwitcher {
    listeners: ScenarioListener[] = [];

    addListener(listener: ScenarioListener): void {
        this.listeners.push(listener);
    }

    removeListener(listener: ScenarioListener): void {
        this.listeners = this.listeners.filter(
            (current: ScenarioListener) => current !== listener,
        );
    }

    switch(scenario: string): void {
        this.listeners.forEach(
            (listener: ScenarioListener) => listener(scenario),
        );
    }
}

interface FormComponent {
    switchScenario(scenario: string): void;
    updateProperty(property: string, value: string): void;
}

export default StylesheetHolder.extend({
    props: {
        communitySlug: {
            type: String as PropType<string>,
            default: 'community',
        },
        portal: {
            type: String as PropType<Portal>,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        scenarioSwitcher: {
            type: Object as PropType<ScenarioSwitcher>,
            default: () => new ScenarioSwitcher(),
        },
    },
    provide() {
        const {
            portal,
            stylesheet,
            statsStylesheet,
            defaults,
            options,
            scenarioSwitcher,
            inheritor,
            updater,
        } = this;

        return {
            portal,
            stylesheet,
            statsStylesheet,
            defaults,
            options,
            scenarioSwitcher,
            inheritor,
            updater,
        };
    },
    data() {
        return {
            inputs: {
                color: ColorInput,
                image: ImageInput,
                dataRange: DataRangeInput,
                datalist: DataListInput,
                toggle: ToggleInput,
                text: TextInput,
                font: FontUpload,
                url: UrlInput,
            },
        };
    },
    created(): void {
        const self = this as unknown as FormComponent;

        this.scenarioSwitcher.addListener(self.switchScenario);
        this.updater.addListener(self.updateProperty);
    },
    destroyed(): void {
        const self = this as unknown as FormComponent;

        this.scenarioSwitcher.removeListener(self.switchScenario);
        this.updater.removeListener(self.updateProperty);
    },
    methods: {
        hasWidth(property: string): boolean {
            return !this.getValue(property).startsWith('0');
        },
        updateProperty(property: string, value: string): void {
            this.stylesheet[property] = value;
        },
        switchScenario(scenario: string): void {
            if (scenario) {
                this.options.scenario = scenario;
            }
        },
        openColorPicker($el: HTMLElement, property: string): void {
            this.$emit(
                'openColorPicker',
                property,
                this.stylesheet[property] ?? null,
                $el,
            );
        },
    },
});
</script>
