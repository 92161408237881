<template>
    <div class="m-auto">
        <BootstrapStylesheet />
        <AccountLogin />
    </div>
</template>

<script lang="ts">
import AccountLogin from '@/components/account/AccountLogin.vue';
import BootstrapStylesheet from '@/components/bootstrap/BootstrapStylesheet.vue';

export default {
    name: 'Login',
    components: {BootstrapStylesheet, AccountLogin},
};
</script>
