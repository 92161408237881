<template>
    <div id="instruction-editor" :key="instruction.uuid">
        <div class="d-flex flex-column min-h-100">
            <div class="header mb-3">
                <v-app id="header-inputs" class="dynamic">
                    <div class="flex-row justify-space-between">
                        <div class="flex-row flex-grow-1" v-if="instruction">
                            <InstructionNameControl v-if="withInstructionName" class="flex-shrink-1"
                                                    v-model="instruction.label"
                                                    :allInstructionNames="allInstructionNames"
                                                    :nextOrderNumber="instruction.order + 1"
                                                    :readonly="scriptLive"/>
                            <RoutingControl id="routing-control"
                                            class="flex-grow-1"
                                            v-model="instruction.condition"
                                            @focus="showConditionalInstructions = true; instructionsFocus = false;"
                                            @blur="showConditionalInstructions = false"
                                            :preventBlurEvent="instructionsFocus"
                                            :readonly="scriptLive"/>
                        </div>
                        <div class="flex-shrink-1 ml-auto">
                            <v-btn class="ml-2" v-if="!instruction.action"
                               :outlined="!instruction.can_revert"
                               color="secondary"
                               @click="instruction.can_revert = !instruction.can_revert"
                               :readonly="scriptLive"
                            >Revertable to</v-btn>
                            <v-btn class="ml-2" v-if="isOptional"
                                   :outlined="!instruction.question.skipable"
                                   color="secondary"
                                   @click="instruction.question.skipable = !instruction.question.skipable"
                                   :readonly="scriptLive"
                            >Optional</v-btn>
                        </div>
                    </div>
                    <ConditionInstructionControl :visible="showConditionalInstructions"
                                                 :locked="instructionsFocus"
                                                 @close="showConditionalInstructions = false"
                                                 @isInteracted="instructionsFocus = true"/>
                    <div class="flex-row">
                        <ReadingDelayControl v-if="withDelay" v-model="instruction" :readonly="scriptLive"/>
                    </div>
                </v-app>
            </div>
            <div class="body d-flex flex-column justify-content-start flex-1">
                <div id="editor-body-messages" class="setting-group" v-if="withMessages">
                    <h3 class="setting-label">{{ false ? $t('GENERAL.TRANSLATIONS') : 'Message' }}</h3>
                    <div class="setting-controls">
                        <div class="mt-05" v-for="message in instruction.messages" :key="message.uuid">
                            <h4 class="special-font locale-label hidden">{{ message.locale }}</h4>
                            <ejs-richtexteditor
                                :cssClass="cssClass"
                                v-model="message.content"
                                :toolbarSettings="toolbarSettings"
                                :pasteCleanupSettings="pasteCleanupSettings"
                                @change="contentChanged"
                                :fontSize="fontSize"
                                ref="editor"
                                :height="150"
                            />
                        </div>
                    </div>
                </div>
                <QuestionEditor v-if="instruction.question" :question="instruction.question" :script-live="scriptLive" :live-editable="liveEditable" />
                <EndConfigurator v-if="instruction.type === 'end'" :action="instruction.action" />
                <ActionConfigurator
                    v-if="instruction.action"
                    :action="instruction.action"
                    @undraft="instruction.draft = false"
                    :script="script"
                    :script-live="scriptLive"
                    :live-editable="liveEditable"
                />
                <ImageConfigurator v-if="instruction.type === 'image'"
                                   :instruction="instruction"
                                   :image="findUploadForInstruction(instruction)"
                                   @change="instruction = $event"
                                   @imgChange="addImgToUploads" />
                <VideoConfigurator v-if="instruction.type === 'video'" :instruction="instruction" @change="instruction = $event" />
                <div class="footer mt-1 mb-auto">
                    <AbbiButton is-blue @click="$emit('save')">{{ false ? $t('ACTION.INSTRUCTION.SAVE') : 'Back to selector' }}</AbbiButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import InstructionModel from '@/models/instruction.model';
import QuestionEditor from '@/components/chat/configurators/QuestionConfigurator.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import ImageConfigurator from '@/components/chat/configurators/ImageConfigurator.vue';
import ActionConfigurator from '@/components/chat/configurators/ActionConfigurator.vue';
import ScriptModel from '@/models/script.model';
import VideoConfigurator from '@/components/chat/configurators/VideoConfigurator.vue';
import EndConfigurator from '@/components/chat/configurators/EndConfigurator.vue';
import {
    Count,
    HtmlEditor,
    Image,
    Link,
    PasteCleanup,
    QuickToolbar,
    RichTextEditorPlugin,
    Toolbar,
} from '@syncfusion/ej2-vue-richtexteditor';
import {HUMAN_VISUAL_RESPONSE_TIME, MINIMAL_NUM_WORDS, READING_TIME_PER_WORD} from '@/helpers/constants';
import RoutingControl from '@/components/chat/controls/RoutingControl.vue';
import InstructionNameControl from '@/components/chat/controls/InstructionNameControl.vue';
import ReadingDelayControl from '@/components/chat/controls/ReadingDelayControl.vue';
import ConditionInstructionControl from '@/components/chat/controls/ConditionInstructionControl.vue';

Vue.use(RichTextEditorPlugin);

export default Vue.extend({
    name: 'InstructionEditor',
    components: {
        ConditionInstructionControl,
        ReadingDelayControl,
        InstructionNameControl,
        RoutingControl,
        AbbiButton,
        ActionConfigurator,
        EndConfigurator,
        ImageConfigurator,
        QuestionEditor,
        VideoConfigurator,
    },
    props: {
        scriptLive: Boolean,
        liveEditable: Boolean,
        instruction: [InstructionModel, Object],
        allInstructionNames: [Array],
        script: [ScriptModel, Object],
        uploads: [Array],
    },
    provide: {
        richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, PasteCleanup],
    },
    data() {
        return {
            showConditionalInstructions: false,
            instructionsFocus: false,
            delayDirty: false,
            msg: (this.instruction.messages && this.instruction.messages.length) ? this.instruction.messages[0].content : null,
            toolbarSettings: {
                items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                        'FontSize', 'LowerCase', 'UpperCase', '|',
                        'CreateLink', 'SourceCode', '|',
                        'Undo', 'Redo',
                ],
            },
            fontSize: {
                default: '16px',
                items: [
                    { text: '10px', value: '10px' },
                    { text: '12px', value: '12px' },
                    { text: '14px', value: '14px' },
                    { text: '16px', value: '16px' },
                    { text: '18px', value: '18px' },
                    { text: '20px', value: '20px' },
                    { text: '22px', value: '22px' },
                ],
            },
            pasteCleanupSettings: {
                prompt: false,
                plainText: true,
                keepFormat: false,
            },
            cssClass: 'editorContent',
        };
    },
    computed: {
        withInstructionName(): boolean {
            return ['question', 'score'].includes(this.instruction.type);
        },
        withDelay(): boolean {
            let result = true;
            result = result && ['message', 'video', 'image', 'score', 'end'].indexOf(this.instruction.type) >= 0;
            result = (result || this.instruction.messages?.length > 0) && this.instruction.type !== 'question';
            return result;
        },
        withMessages(): boolean {
            let result = true;
            result = result && ['message', 'action', 'question'].indexOf(this.instruction.type) >= 0;
            result = result && this.instruction.messages?.length > 0;
            return result;
        },
        isOptional(): boolean {
            return this.instruction.type === 'question'
                && this.instruction.question.type === 'file';
        },
    },
    methods: {
        addImgToUploads($e: Event) {
            this.$emit('addUpload', $e);
        },
        findUploadForInstruction(instr: InstructionModel) {
            if (!this.uploads || this.uploads.length === 0) return undefined;

            for (let i = 0; i < this.uploads.length; i++) {
                const store = Object.entries(this.uploads[i] as Record<string, File>)[0];
                const key = store[0] as string;

                if (key === instr.uuid) {
                    return store[1];
                }
            }

            return undefined;
        },
        conditionChanged(newCondition: string) {
            if (newCondition !== '') this.instruction.condition = newCondition;
            else this.instruction.condition = null;
        },
        delayChanged(delay: string) {
            this.delayDirty = delay !== '';
        },
        contentChanged($event: Event & string) {
            let valueData = $event;
            if (typeof $event === 'object' && $event !== null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                valueData = valueData.value;
            }
            if (!valueData) {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                this.instruction.typing_delay = (0).toPrecision(3);
                return;
            }

            if (!this.delayDirty && this.instruction.type !== 'question') {
                const words = valueData.replace(/<\/?[^>]+(>|$)/g, '')
                    .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
                    .trim()
                    .match(/[\w]{2,}/g);
                const numWords = Math.max(words?.length ?? 0, MINIMAL_NUM_WORDS);
                // eslint-disable-next-line @typescript-eslint/naming-convention
                this.instruction.typing_delay = (
                    HUMAN_VISUAL_RESPONSE_TIME
                    + (numWords * READING_TIME_PER_WORD)
                ).toPrecision(3);
            }
        },
    },
});
</script>

<style lang="scss">
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import 'src/styles/typography';

.v-application.dynamic {
    background-color: inherit !important;
    height: auto !important;
    width: auto !important;
    min-height: auto;
    max-height: auto;

    .v-application--wrap {
        min-height: auto !important;
    }
}

.editorContent .e-rte-content .e-content {
    font-family: $abbi-default-font;
}

#instruction-editor {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure this container grows properly if "flex" is applied */
    min-height: 0; /* Prevent improper height inheritance issues */
    position: relative; /* Ensures no issues with positioning */

    .setting-group {
        > .setting-label {
            display: block;
        }

        .setting-controls {
            display: block;
        }

        .setting-controls + .setting-controls {
            margin-top: 0.5em;
        }
    }

    #editor-header {
        display: flex;
        gap: 1em;

        > *:not([class*="flex"]) {
            flex: 1;
        }

        .types {
            display: flex;

            > * {
                flex: 1;
            }
        }

    }

    #xeditor-body {
        display: flex; /* Ensures it behaves like a flex container */
        flex-direction: column; /* Makes the children stack */
        flex-grow: 1; /* Overrides other growth issues */
        flex-shrink: 1; /* Ensures it shrinks if necessary */
        height: auto; /* Ensures it expands with contents */
        min-height: 0; /* Handles flexbox height overflow issues */
        overflow: visible; /* Allows growth if content exceeds container */
    }

    #editor-body {
        flex: 1;
        flex-direction: column;

        > * {
            margin-top: 1rem;
        }

        #editor-body-messages {
            .setting-control {
                display: flex;
                align-items: center;

                > :first-child {
                    min-width: 5rem;
                    text-align: center;
                }

                > :not(:first-child) {
                    flex: 1;
                }
            }
        }

    }

    #editor-routing-body {
        background-color: white;
        margin: -0.5rem;
        // margin-bottom: 0;
        padding: 0.5rem 0.5rem;

        .setting-label {
            pre {
                margin-top: 0;
                margin-bottom: 0;
            }

            p {
                margin-top: 0;

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            code {
                color: red;
            }

            .text-bold {
                font-weight: bold;
            }

            .flex-row {
                > * {
                    padding-right: 0.5em;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
</style>
