<template>
    <div class="field">
        <div class="field-inner">
            <label v-if="showLabel" :for="property" :title="title">
                <span v-html="label" />
                <FaIcon v-if="deprecated" class="text-warning" icon="triangle-exclamation" />
            </label>
            <div class="input" :style="{'flex-grow': inputComponentStyle }" @click="toggleScenario">
                <component
                    :is="inputComponent"
                    :id="property"
                    v-bind="options"
                    @toggle="toggleColorPicker"
                    @input="onInput"
                    @updateDependentProperty="onUpdateDependentProperty"
                    :value="value" />
            </div>
        </div>
        <Alert type="danger" v-if="!(property in defaults)">
            {{ $t('COMMUNITY.STYLING.FORM.FIELD.MESSAGE.MISSING_DEFAULT') }}
        </Alert>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Portal} from '@/components/community/styling/Configurator.vue';
import ColorInput from '@/components/community/styling/Input/ColorInput.vue';
import Input from '@/components/community/styling/Input/Input.vue';
import {ScenarioSwitcher} from '@/components/community/styling/Form.vue';
import Alert from '@/components/community/styling/Input/Alert.vue';
import {Toggle} from '@/components/community/styling/Input/ColorPickerContainer';
import StylesheetHolder from '@/components/community/styling/form/StylesheetHolder.vue';
import TextInput from '@/components/community/styling/Input/TextInput.vue';
import UrlInput from '@/components/input/UrlInput.vue';

export default StylesheetHolder.extend({
    components: {Alert},
    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },
        property: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        alias: {
            type: String,
            default: '',
        },
        deprecated: {
            type: Boolean,
            default: false,
        },
        input: {
            type: Function as PropType<typeof Input>,
        },
        portal: {
            type: String as PropType<Portal>,
        },
        colorPickerToggle: {
            type: Object as PropType<Toggle>,
        },
        scenarioSwitcher: {
            type: Object as PropType<ScenarioSwitcher>,
        },
        scenario: {
            type: String,
        },
    },
    inject: [
        'portal',
        'stylesheet',
        'defaults',
        'inheritor',
        'colorPickerToggle',
        'scenarioSwitcher',
        'scenario',
        'updater',
    ],
    computed: {
        inputComponent(): typeof Input {
            return this.input ?? ColorInput;
        },
        inputComponentStyle(): string {
            switch (this.input) {
                case TextInput:
                case UrlInput:
                    return '2';
                default:
                    return '0';
            }
        },
        label(): string {
            const portal = (this.portal ?? '').toUpperCase();
            const property = this.alias
                ? this.alias.toUpperCase()
                : this.property.toUpperCase();

            return this.$t(
                `COMMUNITY.STYLESHEET.CONFIGURATOR.${portal}.FIELD.${property}.LABEL`,
            ).toString();
        },
        value(): string {
            return this.getValue(this.property);
        },
        title(): string|boolean {
            const portal = (this.portal ?? '').toUpperCase();
            const property = this.alias
                ? this.alias.toUpperCase()
                : this.property.toUpperCase();
            const translationKey = `COMMUNITY.STYLESHEET.CONFIGURATOR.${portal}.FIELD.${property}.TITLE`;
            const title = this.$t(translationKey).toString();
            const defaultTitle = this.deprecated
                ? this.$t('COMMUNITY.STYLING.FORM.FIELD.MESSAGE.DEPRECATED').toString()
                : false; // This prevents empty title attributes from being added.

            return title === translationKey ? defaultTitle : title;
        },
    },
    methods: {
        onInput(value: string): void {
            this.updateProperty(this.property, value);
        },
        onUpdateDependentProperty(property: string, value: string): void {
            this.updateProperty(property, value);
        },
        toggleColorPicker(el: HTMLElement): void {
            this.colorPickerToggle.toggle(this.property, this.value, el);
        },
        toggleScenario(): void {
            if (this.scenario) {
                this.scenarioSwitcher.switch(this.scenario);
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.alert {
    margin-top: 1em;
}

.field-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > label {
        min-width: 120px;

        svg {
            margin-left: .5em;
        }
    }

    .input {
        padding-left: 1em;
        text-align: right;
        display: flex;
    }
}
</style>
