/* eslint @typescript-eslint/no-explicit-any: 0 */
import AbbiApi from '@/apis/abbi.api';

export default class CrmApi extends AbbiApi {
    public static listMappers() {
        return CrmApi.get(`${CrmApi.url}/api/crm/mappers`);
    }

    public static add(communitySlug: string, data: {[key: string]: any}) {
        return CrmApi.put(`${CrmApi.url}/api/crm/${communitySlug}/connection`, data);
    }

    public static edit(id: number, data: {[key: string]: any}) {
        return CrmApi.post(`${CrmApi.url}/api/crm/connection/${id}`, data);
    }

    public static remove(id: number) {
        return CrmApi.delete(`${CrmApi.url}/api/crm/connection/delete/${id}`, {});
    }

    public static list(communitySlug: string) {
        return CrmApi.get(`${CrmApi.url}/api/crm/${communitySlug}/connections`);
    }

    public static getAuthorizationLink(
        id: number,
        returnUrl: string = window.location.href,
    ) {
        return `${CrmApi.url}/api/crm/connection/authorize/${id}?returnUrl=${returnUrl}`;
    }

    public static getVerificationLink() {
        return `${CrmApi.url}/api/crm/connection/authorize/callback`;
    }

    public static saveDataPoint(scriptUuid: string, data: {[key: string]: any}) {
        return CrmApi.post(`${CrmApi.url}/api/datapoint/${scriptUuid}/save`, data);
    }

    public static showDataPoint(scriptUuid: string) {
        return CrmApi.get(`${CrmApi.url}/api/datapoint/${scriptUuid}/show`);
    }
}
