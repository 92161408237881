<template>
    <div
        class="progress-bar"
        :class="{'progress-bar-striped': striped, 'progress-bar-animated': animated}"
        :style="style">
        <slot>
            {{ this.renderedValue }}
        </slot>
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';

export enum Render {
    None,
    Value,
    Percentage
}

export default Vue.extend({
    name: 'ProgressBar',
    props: {
        render: {
            type: Number as PropType<Render>,
            default: Render.None,
        },
        animated: {
            type: Boolean,
            default: false,
        },
        striped: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        renderedValue(): string {
            let style: 'decimal' | 'percent' | 'currency' | undefined = undefined;
            let value = null;

            switch (this.render) {
                case Render.Percentage:
                    style = 'percent';
                    value = this.percentage / 100;
                    break;
                case Render.Value:
                    style = 'decimal';
                    value = this.value;
                    break;
                default:
                    break;
            }

            return value ? Intl.NumberFormat(
                this.$i18n.locale || 'nl-NL',
                { style },
            ).format(value) : '';
        },
        percentage(): number {
            return (this.value / this.max) * 100;
        },
        style(): string {
            const width = Math.max(
                0,
                Math.min(100, this.percentage),
            );

            return `width: ${width}%;`;
        },
    },
});
</script>
