<template>
    <div>
        <div class="mb-3">
            <AccountPassword />
        </div>
        <div class="mb-3">
            <AccountTwoFactorAuthentication />
        </div>
    </div>
</template>

<script lang="ts">
import AccountPassword from '@/components/account/AccountPassword.vue';
import AccountTwoFactorAuthentication from '@/components/account/AccountTwoFactorAuthentication.vue';

export default {
    name: 'AccountAuthentication',
    components: {
        AccountTwoFactorAuthentication,
        AccountPassword,
    },
};
</script>
