/* eslint-disable import/prefer-default-export */
import settings from '@/settings';

export function padInteger(num: number, minChars: number) {
    let numAsString = String(num);
    while (numAsString.length < minChars) {
        numAsString = `0${numAsString}`;
    }
    return numAsString;
}

export function dateToDateString(date: Date) {
    const year = padInteger(date.getUTCFullYear(), 2);
    const month = padInteger(date.getUTCMonth() + 1, 2);
    const day = padInteger(date.getUTCDate(), 2);

    return `${year}-${month}-${day}`
}

export function GetDateAsString(date: Date) {
    let str = '';
    str += `${padInteger(date.getDate(), 2)}-`;
    str += `${padInteger(date.getMonth() + 1, 2)}-`;
    str += `${date.getFullYear()} `;
    str += `${padInteger(date.getHours(), 2)}:`;
    str += padInteger(date.getMinutes(), 2);
    return str;
}

export function getShortCodeHost() {
    const base = new URL(settings.webBase);
    if (base.host === 'web.abbi-insights.com') return 'https://abbi.to/';
    if (settings.environment === 'staging') return `https://${settings.environment}.abbi.to/`;
    if (base.host.endsWith('local')) return `${base}s/`;
    return `${base}/s/`;
}

export function abbiToIFY(code: string) {
    const base = new URL(settings.webBase);

    if (base.host === 'web.abbi-insights.com') return `https://abbi.to/${code}`;
    if (settings.environment === 'staging') return `https://${settings.environment}.abbi.to/${code}`;

    return `${base}/s/${code}`;
}
