<template>
    <fieldset>
        <div class="presentations">
            <slot/>
        </div>
    </fieldset>
</template>

<script lang="ts">
export default {
    props: {
        title: String,
    },
};
</script>

<style scoped lang="scss">
fieldset {
    background-color: #00000020;
    border: 0;
    margin: 0;
    padding: 0;
    margin-right: 0.5rem;

    .presentations {
        display: grid;
        grid-template-rows: auto auto;
        grid-auto-flow: column;
        //grid-template-rows: 8em 8em;
        //grid-template-columns: 100px 100px;
    }
}
</style>
