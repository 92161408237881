import Vue, {PropType} from 'vue';
import ColorPicker, {Color} from './ColorPicker.vue';

export interface Listener {
    (property: string, value: string, el: HTMLElement): void;
}

export class Toggle {
    listeners: Listener[] = [];

    addListener(listener: Listener): void {
        this.listeners.push(listener);
    }

    removeListener(listener: Listener): void {
        this.listeners = this.listeners.filter(
            (current: Listener) => current !== listener,
        );
    }

    toggle(property: string, value: string, el: HTMLElement): void {
        this.listeners.forEach(
            (listener: Listener) => listener(property, value, el),
        );
    }
}

interface HTMLElementHolder {
    $el: HTMLElement;
}

export interface FocalPoint {
    property: string;
    value: string;
    $el: null | HTMLElement;
}

const defaultFocalPoint = {
    property: '',
    value: '',
    $el: null,
} as FocalPoint;

export default Vue.extend({
    components: {ColorPicker},
    props: {
        colorPickerRef: {
            type: String,
            default: 'colorPicker',
        },
        colorPickerToggle: {
            type: Object as PropType<Toggle>,
            default: () => new Toggle(),
        },
        pickerMargin: {
            type: Number,
            default: 6,
        },
    },
    provide() {
        return {
            colorPickerToggle: this.colorPickerToggle,
        };
    },
    data() {
        return {
            focalPoint: {...defaultFocalPoint} as FocalPoint,
        };
    },
    created(): void {
        this.colorPickerToggle.addListener(this.openColorPicker);
    },
    destroyed(): void {
        this.colorPickerToggle.removeListener(this.openColorPicker);
    },
    watch: {
        'focalPoint.value': {
            handler(color: string): void {
                const {property} = this.focalPoint;

                if (property) {
                    this.updateColorProperty(property, color);
                }
            },
        },
    },
    methods: {
        openColorPicker(property: string, value: string, $el: HTMLElement): void {
            this.focalPoint = {property, value, $el} as FocalPoint;
        },
        closeColorPicker(): void {
            this.focalPoint = {...defaultFocalPoint} as FocalPoint;
        },
        updateColor(color: Color): void {
            this.focalPoint.value = color.hex8;
        },
        updateColorProperty(property: string, color: string): void {
            throw new Error(
                `
                Please override the updateColorProperty method in your component.
                Attempting to update "${property}" to "${color}".
                `,
            );
        },
    },
    computed: {
        picker(): HTMLElement {
            const picker = this.$refs[this.colorPickerRef] as unknown as HTMLElementHolder;
            return picker.$el;
        },
        pickerHeight(): number {
            return this.picker.clientHeight;
        },
        pickerWidth(): number {
            return this.picker.clientWidth;
        },
        container(): HTMLElement {
            return this.picker.parentElement as HTMLElement;
        },
        containerHeight(): number {
            return this.container.scrollHeight;
        },
        containerWidth(): number {
            return this.container.scrollWidth;
        },
        colorPickerStyle(): object {
            const style = {
                visibility: 'hidden',
                top: '0',
                right: '0',
                height: 'auto',
            };
            const {$el} = this.focalPoint;

            if ($el) {
                const margin = this.pickerMargin;
                const {
                    containerHeight,
                    containerWidth,
                    pickerHeight,
                    pickerWidth,
                } = this;

                let offsetRight = containerWidth - $el.offsetLeft + margin;
                let offsetTop = $el.offsetTop + $el.clientHeight + margin;

                // Only add a horizontal offset when there is available space.
                if (offsetRight + pickerWidth > containerWidth) {
                    offsetRight = 0;
                }

                // If there is an offset to the right, position the picker relative
                // to the top of the element.
                if (offsetRight > 0) {
                    offsetTop -= $el.clientHeight + (margin * 2);
                }

                // Flip the vertical position if it would cause scrolling.
                if (offsetTop + pickerHeight + margin > containerHeight) {
                    offsetTop = $el.offsetTop - pickerHeight - margin + $el.clientHeight;
                }

                // Check if there is room to align from the left.
                if (
                    offsetRight === 0
                    && $el.offsetLeft + $el.clientWidth + pickerWidth + (margin * 2) < containerWidth
                ) {
                    offsetRight = containerWidth - (
                        $el.offsetLeft + $el.clientWidth + pickerWidth + (margin * 2)
                    );
                }

                style.visibility = 'visible';
                style.top = `${offsetTop}px`;
                style.right = `${offsetRight}px`;
            }

            return style;
        },
    },
});
