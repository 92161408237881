<template>
    <Card class="row" title="ACCOUNT.2FA.HEADING">
        <template #subtitle v-if="!saved">
            {{ $t('ACCOUNT.2FA.ENABLE.INTRODUCTION') }}
        </template>
        <template #subtitle v-else>
            <span class="text-success">
                {{ $t(successMessage) }}
            </span>
        </template>

        <div v-if="!saved" class="mt-3">
            <ModalToggle
                target="enable-2fa"
                class="w-100"
                @toggle="fetchSecret"
                label="ACCOUNT.2FA.ENABLE.MODAL.TOGGLE" />
            <Modal
                id="enable-2fa"
                :size="modalSize"
                :vertically-centered="true"
                label-submit="ACCOUNT.2FA.ENABLE.MODAL.SUBMIT.LABEL"
                :can-submit="canSubmit()"
                @close="resetForm"
                @submit="submitForm"
                :error-message="errorMessage"
                title="ACCOUNT.2FA.ENABLE.MODAL.TITLE">
                <div v-if="secret.key.length > 0">
                    <p>{{ $t('ACCOUNT.2FA.ENABLE.MODAL.INSTRUCTION') }}</p>
                    <i18n path="ACCOUNT.2FA.ENABLE.MODAL.RECOMMENDATION" tag="p">
                        <template #applications>
                                <span v-for="authenticator in authenticators" :key="authenticator.label">
                                    <a :href="authenticator.url" target="_blank">{{ authenticator.label }} <i class="bi-box-arrow-up-right"></i></a>,
                                </span>
                        </template>
                    </i18n>

                    <div class="d-flex">
                        <div class="flex-shrink-0 d-flex align-items-center align-middle">
                            <img class="mx-auto" alt="2FA secret" :src="secret.image" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <Card header="ACCOUNT.2FA.ENABLE.MODAL.CARD.HEADER">
                                <p>{{ $t('ACCOUNT.2FA.ENABLE.MODAL.CARD.INTRODUCTION') }}</p>
                                <table class="table table-striped table-sm">
                                    <tbody>
                                    <tr>
                                        <th class="w-25" scope="row">{{ $t('ACCOUNT.2FA.ENABLE.MODAL.CARD.ACCOUNT') }}:</th>
                                        <td>
                                            <pre class="mb-0"><code>{{ secret.account }}</code></pre>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{{ $t('ACCOUNT.2FA.ENABLE.MODAL.CARD.KEY') }}:</th>
                                        <td class="text-monospace">
                                                    <span v-for="(block, index) in secret.key.match(/.{1,4}/g)" :key="index" class="mr-2">
                                                        {{ block }}
                                                    </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{{ $t('ACCOUNT.2FA.ENABLE.MODAL.CARD.TIME_BASED') }}:</th>
                                        <td>
                                            <pre class="mb-0">{{ $t('ACCOUNT.2FA.ENABLE.MODAL.CARD.YES') }}</pre>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                    <form class="mt-3" @keyup.enter="submitForm">
                        <div class="mb-3 row">
                            <label for="currentPassword" class="col-sm-3 col-form-label">
                                {{ $t('ACCOUNT.FIELD.CURRENT_PASSWORD.LABEL') }}
                            </label>
                            <div class="col-sm-9">
                                <div class="input-group has-validation">
                                    <i class="input-group-text bi-key"></i>
                                    <input
                                        :type="visibility.currentPassword ? 'text' : 'password'"
                                        class="form-control"
                                        :class="{
                                                'is-invalid': violations.hasError('currentPassword'),
                                                'is-valid': isValid('currentPassword'),
                                            }"
                                        @input="mutateForm()"
                                        id="currentPassword"
                                        name="currentPassword"
                                        autocomplete="current-password"
                                        required
                                        aria-describedby="currentPasswordHelp"
                                        v-model="currentPassword" />
                                    <TogglePasswordVisibility :visible="visibility.currentPassword" @toggle="toggleVisibility('currentPassword')" />
                                    <ViolationList field="currentPassword" :violations="violations" />
                                </div>
                                <div id="currentPasswordHelp" class="form-text">
                                    {{ $t('ACCOUNT.2FA.ENABLE.MODAL.FIELD.CURRENT_PASSWORD.HELP') }}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="verificationCode" class="col-sm-3 col-form-label">
                                {{ $t('ACCOUNT.FIELD.VERIFICATION_CODE.LABEL') }}
                            </label>
                            <div class="col-sm-9">
                                <div class="input-group has-validation">
                                    <i class="input-group-text bi-qr-code"></i>
                                    <input
                                        :type="visibility.verificationCode ? 'text' : 'password'"
                                        class="form-control"
                                        :class="{
                                                'is-invalid': violations.hasError('verificationCode'),
                                                'is-valid': isValid('verificationCode'),
                                            }"
                                        @input="mutateForm()"
                                        id="verificationCode"
                                        name="verificationCode"
                                        autocomplete="one-time-code"
                                        required
                                        v-model="verificationCode" />
                                    <TogglePasswordVisibility :visible="visibility.verificationCode" @toggle="toggleVisibility('verificationCode')" />
                                    <ViolationList field="verificationCode" :violations="violations" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-else class="text-center">
                    <Spinner class="me-2" small />
                    {{ $t('ACCOUNT.2FA.ENABLE.MODAL.LOADING') }}
                </div>
            </Modal>
        </div>
    </Card>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue';
import Card from '@/components/bootstrap/Card.vue';
import TogglePasswordVisibility from '@/components/account/TogglePasswordVisibility.vue';
import ModalToggle from '@/components/bootstrap/ModalToggle.vue';
import Spinner from '@/components/bootstrap/Spinner.vue';
import ViolationList from '@/components/form/ViolationList.vue';
import Modal, {ModalSize} from '@/components/bootstrap/Modal.vue';
import ViolationListModel from '@/models/violation.list.model';
import {Authenticator, TwoFactorConfiguration} from '@/interfaces/two-factor.interface';
import authenticators from '@/data/account/2fa/authenticators';
import AccountApi from '@/apis/account.api';

interface Secret {
    key: string;
    image: string;
    account: string;
}

interface FieldVisibilities {
    currentPassword: boolean;
    verificationCode: boolean;
}

interface ComponentData {
    secret: Secret;
    visibility: FieldVisibilities;
    currentPassword: string;
    verificationCode: string;
    violations: ViolationListModel;
    enabled: boolean;
    errorMessage: string;
    saved: boolean;
    successMessage: string;
    authenticators: Array<Authenticator>;
}

export default Vue.extend({
    name: 'AccountEnableTwoFactorAuthentication',
    components: {
        Modal,
        ViolationList,
        Spinner,
        ModalToggle,
        TogglePasswordVisibility,
        Card,
    },
    props: {
        config: {
            type: Object as PropType<TwoFactorConfiguration>,
            required: true,
        },
    },
    data(): ComponentData {
        return {
            violations: new ViolationListModel(),
            currentPassword: '',
            verificationCode: '',
            visibility: {
                currentPassword: false,
                verificationCode: false,
            },
            secret: {
                key: '',
                image: '',
                account: '',
            },
            enabled: true,
            errorMessage: '',
            authenticators,
            saved: false,
            successMessage: 'ACCOUNT.2FA.ENABLE.MODAL.SUBMIT.SUCCESS',
        };
    },
    methods: {
        toggleVisibility(field: string): void {
            if (field === 'currentPassword') {
                this.visibility.currentPassword = !this.visibility.currentPassword;
            }

            if (field === 'verificationCode') {
                this.visibility.verificationCode = !this.visibility.verificationCode;
            }
        },
        canSubmit(): boolean {
            return this.enabled && ['currentPassword', 'verificationCode'].every(
                (field: string) => this.isValid(field),
            );
        },
        isValid(field: string): boolean {
            if (field === 'currentPassword') {
                return this.currentPassword.length > 0;
            }

            if (field === 'verificationCode') {
                const expression = new RegExp(
                    `^[0-9]{${this.config.totpDigits}}$`,
                );
                return expression.test(this.verificationCode);
            }

            return false;
        },
        mutateForm() {
            this.enabled = true;
            this.violations.reset();
            this.errorMessage = '';
        },
        resetSecret() {
            this.secret = {
                key: '',
                image: '',
                account: '',
            };
        },
        resetForm() {
            this.currentPassword = '';
            this.verificationCode = '';
            this.resetSecret();
            this.mutateForm();
        },
        fetchSecret() {
            AccountApi.totpGenerateSecret().then(
                (secret: Secret) => {
                    this.secret = secret;
                },
            );
        },
        submitForm() {
            if (!this.canSubmit()) {
                return;
            }

            this.enabled = false;
            this.violations.reset();
            this.errorMessage = '';

            AccountApi.totpConfirmSecret(this.currentPassword, this.verificationCode)
                .then((responseData) => {
                    this.enabled = true;

                    if (responseData) {
                        this.saved = true;
                        this.dispatchModalEvent('enable-2fa', 'close');
                        return;
                    }

                    AccountApi.getErrorData().then((errorData) => {
                        const violations = errorData.violations ?? [];

                        this.violations.apply(violations);

                        if (violations.length === 0) {
                            this.errorMessage = 'ACCOUNT.2FA.ENABLE.MODAL.SUBMIT.FAILURE';
                        }
                    });
                })
                .catch(() => {
                    this.enabled = true;
                    this.errorMessage = 'ACCOUNT.2FA.ENABLE.MODAL.SUBMIT.FAILURE';
                });
        },
        dispatchModalEvent(id: string, event: string) {
            const $modal = document.getElementById(id);

            if ($modal instanceof HTMLElement) {
                $modal.dispatchEvent(new Event(event));
            }
        },
    },
    computed: {
        modalSize(): ModalSize {
            return this.secret.key.length > 0
                ? ModalSize.L
                : ModalSize.None;
        },
    },
});
</script>
