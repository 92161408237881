<template>
    <div class="chat-component">
        <div v-for="errorMessage in errorMessages" class="error-message" :key="errorMessage.msg">
            <h3>{{ errorMessage.msg }}</h3>
        </div>
        <form class="add-chat-component" method="post" :action="action" @submit.prevent="createChat">
            <AbbiTextInput name="name" :placeholder="$t('FIELDS.TITLE')" />
            <AbbiButton class="submit-button" is-submit grow>{{ $t('ACTION.CHAT.CREATE') }}</AbbiButton>
        </form>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import ChatApi from '@/apis/chat.api';
import CommunityModel from '@/models/community.model';
import ErrorModel from '@/models/error.model';

export default Vue.extend({
    name: 'AddChatModal',
    components: {AbbiTextInput, AbbiButton},
    data() {
        return {
            action: `${window.location.protocol}//${window.location.hostname}:3000/api/users`,
            errorMessages: null as null | ErrorModel[],
        };
    },
    methods: {
        createChat({target}: Event) {
            const form = new FormData(target as HTMLFormElement);
            ChatApi.createNewChat((this.$store.getters.community as CommunityModel).slug, form.get('name') as string)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        const event = new CustomEvent('addChat', {detail: responseData});
                        document.dispatchEvent(event);
                        this.$emit('close');
                        this.$store.dispatch('closeModal');
                    }
                })
                .catch(() => {
                    ChatApi.getErrorData().then((errorData) => {
                        const newErrors = [] as ErrorModel[];
                        Object.entries(errorData).forEach((entry) => {
                            const messages = entry[1] as string[];
                            for (let i = 0; i < messages.length; i += 1) {
                                const errorMsg = new ErrorModel();
                                errorMsg.msg = this.$t(messages[i]);
                                newErrors.push(errorMsg);
                            }
                        });
                        this.errorMessages = newErrors;
                    });
                });
        },
    },
});
</script>

<style lang="scss">
.error-message {
    h3 {
        padding: 0;
        margin: 0;

        font-weight: bold;
        font-size: 1.1em;
    }

    background-color: darken(red, 15%);
    color: white;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    margin: -2px;
    margin-bottom: 0;
    padding: 0.5rem 0.75rem;
}

.add-chat-component {
    display: flex;
    flex-direction: column;

    padding: 0.75em 1em;

    .submit-button {
        margin-top: 0.5em;
    }
}
</style>
