import {Authenticator} from '@/interfaces/two-factor.interface';

export default new Array<Authenticator>(
    {
        label: '1Password',
        url: 'https://1password.com/',
    },
    {
        label: 'Google Authenticator',
        url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    },
);
