<template>
    <button
        class="btn"
        :class="buttonClass"
        type="button"
        @click="toggle"
        data-bs-toggle="modal"
        :data-bs-target="targetAttribute">
        <slot>{{ $t(label) }}</slot>
    </button>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Variant} from '@/components/bootstrap/variant';

export default Vue.extend({
    name: 'ModalToggle',
    props: {
        target: {
            type: String,
            required: true,
        },
        buttonColor: {
            type: String as PropType<Variant>,
            default: Variant.Primary,
        },
        label: {
            type: String,
            default: '',
        },
    },
    computed: {
        targetAttribute(): string {
            return `#${this.target}`;
        },
        buttonClass(): string {
            return `btn-${this.buttonColor}`;
        },
        $target(): HTMLElement | null {
            return document.getElementById(this.target);
        },
    },
    methods: {
        toggle() {
            this.$emit('toggle');
            if (this.$target) {
                this.$target.dispatchEvent(new Event('toggle'));
            }
        },
    },
});
</script>

<style>
    #app {
        .modal.show {
            display: block;
        }
    }
</style>
