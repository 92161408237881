<template>
    <div id="chat-page">
        <main class="margin-centered">
            <div class="page-header mb-1 flex-row">
                <div :class="{'chat-header-padding': !chats.length, 'chat-header': chats.length}">
                    <h2>{{ isActiveChats ? 'Active chats' : 'Archive chats' }}</h2>
                </div>
                <AbbiButton isBlue class="archive-button" v-if="isActiveChats" @click="getChats([STATUS_DELETED])">
                    Archive chats
                    <FaIcon icon="archive"/>
                </AbbiButton>
                <AbbiButton isBlue v-else @click="getChats([STATUS_LIVE, STATUS_PREPARATION, STATUS_CLOSED])">Active chats</AbbiButton>
                <AbbiButton @click="openAddChatModal">{{ $t('ACTION.CHAT.ADD') }}</AbbiButton>
            </div>
            <div class="chat-block my-1" v-for="script in chats" :key="script.uuid">
                <div class="chat-block-main flex-row">
                    <h2 class="script-name mr-3">
                        <AbbiLink :to="{
                            name: 'ChatDetails',
                            params: { communitySlug: community.slug, scriptUuid: script.uuid },
                        }">{{ script.name.length > 40 ? `${script.name.substring(0, 40)} ...` : script.name}}
                        </AbbiLink>
                        <sup class="script-uuid">{{ script.uuid }}</sup>
                    </h2>
                    <div class="actions flex-row ml-3">
                        <AbbiButton v-if="script.status === STATUS_PREPARATION" square @click="editChat(script)">
                            {{ $t('EDIT._self') }}
                            <FaIcon icon="edit"/>
                        </AbbiButton>
                        <AbbiLink
                            v-if="![STATUS_CLOSED, STATUS_DELETED].includes(script.status)"
                            is-button
                            square
                            title="go to chat"
                            is-extern
                            is-blue
                            :href="`${webBase}/${community.slug}/chat/${script.uuid}/anonymous`"
                        >
                            <FaIcon icon="comment-dots"/>
                        </AbbiLink>
                        <AbbiLink
                            v-if="script.status !== STATUS_DELETED"
                            is-button
                            square
                            title="livestats"
                            is-blue
                            is-extern
                            :href="`${livestatsBase}/${community.slug}/${script.uuid}`"
                        >
                            <FaIcon icon="chart-pie"/>
                        </AbbiLink>
                        <AbbiButton
                            v-if="script.status !== STATUS_DELETED"
                            is-button
                            square
                            title="copy"
                            is-blue
                            is-extern
                            @click.stop="copyScript(script.uuid)"
                        >
                            <FaIcon icon="copy"/>
                        </AbbiButton>
                        <AbbiButton square is-red @click.stop="promptRemove(script.uuid)">
                            <FaIcon icon="trash"/>
                        </AbbiButton>
                    </div>
                </div><br>
                <div class="chat-block-extra flex-row">
                    <div
                        class="badges"
                        :class="{selected: stateBeingChanged(script.uuid)}"
                        @click.stop="stateChangeKeeper = script.uuid"
                        v-on-clickaway="startScriptStateChange"
                    >
                        <span
                            v-if="!stateBeingChanged(script.uuid)"
                            class="badge"
                            :class="[`badge-${STATUSES[script.status]}`]"
                        >
                            {{ STATUSES[script.status] }}
                        </span>
                        <span
                            v-if="stateBeingChanged(script.uuid) && script.status !== STATUS_PREPARATION"
                            @click.stop.prevent="changeScriptState(script.uuid, STATUS_PREPARATION)"
                            class="badge badge-preparation"
                        >
                            preparation
                        </span>
                        <span
                            v-if="stateBeingChanged(script.uuid) && ![STATUS_LIVE, STATUS_DELETED].includes(script.status)"
                            @click.stop.prevent="changeScriptState(script.uuid, STATUS_LIVE)"
                            class="badge badge-live"
                        >
                            live
                        </span>
                        <span
                            v-if="stateBeingChanged(script.uuid) && ![STATUS_CLOSED, STATUS_DELETED].includes(script.status)"
                            @click.stop.prevent="changeScriptState(script.uuid, STATUS_CLOSED)"
                            class="badge badge-closed"
                        >
                            closed
                        </span>
                    </div>
                    <div class="dynamic-info">
                        <Toggle
                            class="ml-1"
                            v-if="script.status !== STATUS_DELETED"
                            v-model="script.isDeletionApproved"
                            @input="setDeleted(script)"
                            @click="confirmToggle"
                        >
                            Removal procedure
                        </Toggle>
                    </div>
                </div>
                <div class="dates">
                    <div
                        v-if="(script.status === STATUS_LIVE || script.status === STATUS_CLOSED) && script.liveDate"
                        class="chat-block-extra flex-row date-block"
                    >
                        <span>Live date:</span>
                        <span class="date-span date-span-1">{{ dateToDateString(new Date(script.liveDate)) }}</span>
                    </div>
                    <div
                        v-if="script.status === STATUS_CLOSED && script.closedDate"
                        class="chat-block-extra flex-row date-block"
                    >
                        <span>Closed date:</span>
                        <span class="date-span date-span-2">{{ dateToDateString(new Date(script.closedDate)) }}</span>
                    </div>
                    <div
                        v-if="script.status === STATUS_CLOSED && script.isDeletionApproved && script.deletedWarningDate"
                        class="chat-block-extra flex-row date-block"
                    >
                        <span>Removal warning:</span>
                        <span class="date-span date-span-3">{{ dateToDateString(new Date(script.deletedWarningDate)) }}</span>
                    </div>
                    <div
                        v-if="script.status === STATUS_CLOSED && script.isDeletionApproved && script.deletedDate"
                        class="chat-block-extra flex-row date-block"
                    >
                        <span>Deletion date:</span>
                        <span class="date-span date-span-4">{{ dateToDateString(new Date(script.deletedDate)) }}</span>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint no-alert: 0 */
import vue from 'vue';
import ScriptModel from '@/models/script.model';
import ChatApi from '@/apis/chat.api';
import AddChatModal from '@/components/modals/AddChatModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import CommunityModel from '@/models/community.model';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiLink from '@/components/interface/AbbiLink.vue';
import Toggle from '@/components/input/Toggle.vue';
import settings from '@/settings';
import {directive as onClickaway} from 'vue-clickaway';
import {
    STATUS_PREPARATION, STATUS_CLOSED, STATUS_LIVE, STATUSES, STATUS_DELETED,
} from '@/helpers/constants';
import {GetDateAsString} from '@/helpers/utilities';

export default vue.extend({
    name: 'ChatOverview',
    components: {
        AbbiButton, AbbiLink, Toggle,
    },
    directives: {onClickaway},
    data() {
        return {
            chats: [] as ScriptModel[],
            stateChangeKeeper: '',
            STATUS_PREPARATION,
            STATUS_CLOSED,
            STATUS_LIVE,
            STATUS_DELETED,
            STATUSES,
            isActiveChats: true,
        };
    },
    computed: {
        community() {
            return this.$store.getters.community;
        },
        webBase() {
            return settings.webBase;
        },
        livestatsBase() {
            return settings.statsBase;
        },
    },
    watch: {
        community: {
            deep: true,
            handler() {
                ChatApi.getAllChats((this.community as CommunityModel).slug, [STATUS_LIVE, STATUS_PREPARATION, STATUS_CLOSED])
                    .then((responseData) => {
                        if (responseData !== undefined) {
                            this.chats = Object.values<ScriptModel>(responseData);
                            this.isActiveChats = true;
                        }
                    });
            },
        },
    },
    methods: {
        startScriptStateChange(event: Event, uuid: string) {
            this.stateChangeKeeper = uuid;
        },
        dateToDateString(date: Date) {
            return GetDateAsString(date);
        },
        confirmToggle(event: Event, value: boolean) {
            if (value) {
                const confirmed = window.confirm(
                    'Are you sure you don\'t want to use the auto removal procedure?'
                        + ' This may not be in line with the GDPR policy. Continue?',
                );
                if (!confirmed) {
                    event.preventDefault();
                }
            }
        },
        setDeleted(script: any) {
            ChatApi.changeScriptDeletionState(script.uuid, script.isDeletionApproved)
                .then((response) => {
                    if (response) {
                        const chatScriptIdx = this.chats.findIndex((s: ScriptModel) => s.uuid === script.uuid);
                        this.chats[chatScriptIdx] = response;
                        this.$forceUpdate();
                    }
                });
        },
        getChats(statuses: any) {
            this.isActiveChats = !this.isActiveChats;
            ChatApi.getAllChats((this.community as CommunityModel).slug, statuses)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        this.chats = Object.values<ScriptModel>(responseData);
                    }
                });
        },
        stateBeingChanged(uuid: string) {
            return this.stateChangeKeeper === uuid;
        },
        copyScript(uuid: string) {
            const community = this.community as CommunityModel;
            ChatApi
                .makeCopy(community.slug, uuid)
                .then((response) => {
                    this.chats.push(response);
                });
        },
        changeScriptState(uuid: string, status: number) {
            if (this.stateChangeKeeper === '') return;
            this.stateChangeKeeper = '';
            const community = this.community as CommunityModel;

            if (!Object.keys(STATUSES).map(Number).includes(status)) {
                alert('Status doesn\'t exist!');
                return;
            }

            const chatScriptIdx = this.chats.findIndex((script: ScriptModel) => script.uuid === uuid);
            if (status === STATUS_PREPARATION) {
                const script = this.chats[chatScriptIdx];
                let msg = 'Changing the chat script might cause unexpected dataloss at this stage. Continue?';
                if (script.status === STATUS_DELETED) {
                    msg = 'Are you sure you want to reactivate this chat. This archived chat will be placed in the active chats.';
                }
                const confirmed = window.confirm(msg);
                if (!confirmed) return;
            }
            ChatApi.changeScriptState(community.slug, uuid, status)
                .then((response) => {
                    if (response) {
                        if (this.chats[chatScriptIdx].status === STATUS_DELETED && response.status === STATUS_PREPARATION) {
                            this.chats = this.chats.filter((chat) => chat.uuid !== uuid);
                            return;
                        }
                        this.chats[chatScriptIdx] = response;
                        this.$forceUpdate();
                    }
                });
        },
        processAddedChat($event: CustomEvent) {
            this.chats.push($event.detail as ScriptModel);
            this.$forceUpdate();
        },
        openAddChatModal() {
            this.$store.commit('setModal', {title: this.$t('ACTION.CHAT.ADD'), component: AddChatModal});
        },
        promptRemove(uuid: string) {
            ChatApi.getDeleteChatCSRF((this.community as CommunityModel).slug, uuid)
                .then((csrfResponseData) => {
                    if (csrfResponseData !== undefined) {
                        const csrf = csrfResponseData.deleteToken;
                        this.$store.commit('setModal', {
                            title: this.$t('ACTION.CONFIRMATION'),
                            component: ConfirmationModal,
                            options: {
                                message: this.$t('ACTION.CHAT.REMOVE_MESSAGE'),
                                actions: {
                                    confirm: {
                                        message: this.$t('ACTION.YES'),
                                        action: this.removeChat,
                                        argument: {uuid, csrf},
                                    },
                                    cancel: {
                                        message: this.$t('ACTION.NO'),
                                    },
                                },
                            },
                        });
                    }
                });
        },
        removeChat({uuid = '' as string, csrf = '' as string}) {
            ChatApi.deleteChat((this.community as CommunityModel).slug, uuid, csrf)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        this.chats = this.chats.filter((chat) => chat.uuid !== uuid);
                    }
                });
        },
        editChat({uuid = '' as string}) {
            this.$router.push({
                name: 'ChatEditor',
                params: {
                    communitySlug: this.community.slug,
                    scriptUuid: uuid,
                },
            });
        },
    },
    mounted() {
        document.addEventListener('addChat', this.processAddedChat as EventListener);
        if (this.community !== undefined && this.community !== null) {
            ChatApi.getAllChats((this.community as CommunityModel).slug, [STATUS_LIVE, STATUS_PREPARATION, STATUS_CLOSED])
                .then((responseData) => {
                    if (responseData !== undefined) {
                        this.chats = Object.values<ScriptModel>(responseData);
                    }
                });
        }
    },
    destroyed() {
        document.removeEventListener('addChat', this.processAddedChat as EventListener);
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';

    #chat-page {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        overflow-y: auto !important;

        main {
            padding: 1em;
            width: 50%;
            .page-header {
                text-align: right;
                justify-content: flex-end;
                > :nth-child(2) {
                    padding-right: 14px;
                }
                .chat-header {
                    margin-right: auto;
                    color: $abbi-blue;
                    align-self: center;
                }
                .chat-header-padding {
                    margin-right: auto;
                    color: $abbi-blue;
                    align-self: center;
                }
            }
            .chat-block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                min-height: 6rem;

                border-radius: 0.5em;
                padding: 1em;
                background-color: whitesmoke;

                box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

                .chat-block-main {
                    justify-content: space-between;
                    height: 2rem;
                    min-width: 512px;
                    .script-name {
                        position: relative;
                        display: flex;
                        align-items: center;

                        font-size: 1.25em;
                        // font-weight: light;

                        color: $abbi-blue;

                        .abbi-link a {
                            color: inherit;
                            text-decoration: none !important;

                            &:hover {
                                color: darken($abbi-blue, 10%);
                            }
                        }

                        .script-uuid {
                            position: absolute;
                            left: 0;
                            white-space: nowrap;

                            font-weight: normal;
                            font-size: 0.5em;
                        }
                    }

                    .actions {
                        flex: 1;
                        display: flex;

                        justify-content: flex-end;

                        > * {
                            // flex: 1;
                            margin-left: 0.5em;
                        }
                    }
                }
                .date-block {
                    color: black;
                    font-size: 12px;
                }

                .chat-block-extra {
                    justify-content: space-between;

                    .dynamic-info {
                        color: #00949e;
                        font-size: 12px;
                        padding-top: 4px;
                    }

                    .badge {
                        margin-right: 0.25rem;
                        display: inline-block;
                        padding: 0.25rem 0.5rem;

                        border-radius: 6px;

                        color: white;

                        cursor: pointer;

                        background-color: grey;

                        &.badge-preparation {
                            background-color: $abbi-blue;
                        }

                        &.badge-live {
                            background-color: $abbi-green;
                        }

                        font-size: 0.75em;
                    }
                }
            }
            .dates {
                > :nth-child(1) {
                    padding-top: 7px;
                }
                .date-span {
                    display: flex;
                    flex: 1;
                }
                .date-span-1 {
                    padding-left: 56px;
                }
                .date-span-2 {
                    padding-left: 40px;
                }
                .date-span-3 {
                    padding-left: 9px;
                }
                .date-span-4 {
                    padding-left: 32px;
                }
            }
        }
    }
</style>
