<template>
    <v-app id="numeric-configurator" class="dynamic">
        <v-alert
            prominent
            v-if="!validForm && showInvalidError"
            type="error"
        >
            This configuration contains errors. Changes will not be saved. <br />
            Please fix them before continuing or moving away from this page.
        </v-alert>
        <v-form ref="form" v-model="validForm">
            <v-row>
                <v-col>
                    <v-text-field
                        filled
                        type="number"
                        :readonly="scriptLive"
                        id="minimum-value"
                        label="Minimum value"
                        placeholder="Number"
                        min="0"
                        :max="maximumVal"
                        :rules="rules.stepSize"
                        @input="revalidate"
                        @change="changed"
                        @blur="inputBlurred"
                        v-model.number="minimumVal"
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        filled
                        type="number"
                        :readonly="scriptLive"
                        id="maximum-value"
                        label="Maximum value"
                        placeholder="Number"
                        :min="minimumVal"
                        :rules="rules.stepSize"
                        @change="changed"
                        @input="revalidate"
                        @blur="inputBlurred"
                        v-model.number="maximumVal"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-switch
                        class="px-2"
                        :label="$t('FIELDS.HAS_SLIDER')"
                        :readonly="scriptLive && !liveEditable"
                        @change="hasSliderChanged"
                        v-model="hasSlider"
                    />
                </v-col>
                <v-col>
<!--                    <v-slider-->
<!--                        v-if="!hasSlider"-->
<!--                        hint="Decimals"-->
<!--                        v-model="precision"-->
<!--                        min="0"-->
<!--                        max="2"-->
<!--                        step="1"-->
<!--                        ticks="always"-->
<!--                    >-->
<!--                        <template v-slot:prepend>-->
<!--                            <v-text-field-->
<!--                                v-model="precision"-->
<!--                                class="mt-0 pt-0"-->
<!--                                type="number"-->
<!--                            />-->
<!--                        </template>-->
<!--                    </v-slider>-->
                    <v-text-field
                        filled
                        v-if="!hasSlider"
                        type="number"
                        name="precision"
                        label="Decimals"
                        min="0"
                        :max="2"
                        :readonly="scriptLive"
                        v-model.number="precision"
                        @change="changed"
                        @blur="inputBlurred"
                        @input="revalidate"
                    />
                    <v-text-field
                        filled
                        v-else
                        :readonly="scriptLive"
                        type="number"
                        name="stepSize"
                        :rules="rules.stepSize"
                        min="0"
                        label="Step size"
                        v-model.number="stepSize"
                        @change="changed"
                        @blur="inputBlurred"
                        @input="revalidate"
                    />
                </v-col>
            </v-row>
            <v-row v-if="hasSlider">
                <v-col cols="12">
                    <v-switch
                        class="px-2"
                        :label="$t('FIELDS.HAS_LABEL')"
                        :readonly="scriptLive && !liveEditable"
                        v-model="hasLabel"
                        @change="changed"
                    />
                </v-col>
                <v-col v-if="hasLabel" cols="6">
                    <v-text-field
                        filled
                        placeholder="Least"
                        label="Minimum - Text label"
                        :rules="rules.lessThan25"
                        :readonly="scriptLive"
                        type="text"
                        :counter="25"
                        v-model="lowerBoundLabel"
                        @change="changed"
                        @blur="inputBlurred"
                        @input="revalidate"
                    />
                </v-col>
                <v-col v-if="hasLabel" cols="6">
                    <v-text-field
                        filled
                        placeholder="Most"
                        label="Maximum - Text label"
                        :rules="rules.lessThan25"
                        :readonly="scriptLive"
                        type="text"
                        :counter="25"
                        v-model="upperBoundLabel"
                        @change="changed"
                        @blur="inputBlurred"
                        @input="revalidate"
                    />
                </v-col>
            </v-row>

        </v-form>
<!--        <div id="label-warning" class="input-group" v-if="hasSlider && invalidStepSize">-->
<!--            (({{ this.maximumVal }} - {{ this.minimumVal }} ) % {{ stepSize }}) = {{ this.stepRemain }}, should be 0.-->
<!--        </div>-->
<!--        <div id="label-warning" class="input-group" v-if="hasSlider && hasLabel && (!lowerBoundLabel || !upperBoundLabel)">-->
<!--            Please enter both a minimum and maximum label.-->
<!--        </div>-->
    </v-app>
</template>

<script lang="ts">
import vue from 'vue';
import Toggle from '@/components/input/Toggle.vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Configurator from '@/components/chat/configurators/Configurator.vue';
import {ConfiguratorData} from '@/components/chat/configurators/Configurator.vue';

interface NumericConfiguratorData {
    initOptions: Object,
    stepRemain: number,
    hasSlider: boolean,
    hasLabel: boolean,
    minimumVal: number,
    maximumVal: number,
    precision: number,
    stepSize: number,
    inputKey: number,
    lowerBoundLabel: string,
    upperBoundLabel: string,
    rules: Object,
}

export default Configurator.extend({
    name: 'NumericConfigurator',
    components: {
        AbbiTextInput,
        Toggle,
        AbbiNumericInput,
        FontAwesomeIcon,
    },
    watch: {
    },
    methods: {
        hasSliderChanged(): void {
            this.revalidate();
            this.changed();
        },
        changed(): void {
            if (!this.validForm) return;

            this.$emit('change', {
                minimum: this.minimumVal,
                maximum: this.maximumVal,
                hasSlider: this.hasSlider,
                precision: this.precision,
                stepSize: this.stepSize,
                hasLabel: this.hasLabel,
                lowerBoundLabel: this.lowerBoundLabel,
                upperBoundLabel: this.upperBoundLabel,
            });
        },
        validateInput($event: InputEvent): void {
            const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            // eslint-disable-next-line
            // @ts-ignore
            if (!keysAllowed.includes($event.key)) {
                $event.preventDefault();
                $event.stopImmediatePropagation();
            }
        },
        validateStepSize(): boolean|string {
            const scale = 1000000; // Scaling factor to avoid float issues
            const scaledMin = Math.round(this.minimumVal * scale);
            const scaledMax = Math.round(this.maximumVal * scale);
            const scaledStepSize = Math.round(this.stepSize * scale);
            const scaledRange = scaledMax - scaledMin;
            const scaledRemain = (scaledRange % scaledStepSize) / scale;

            this.stepRemain = scaledRemain;

            if (scaledRemain !== 0) {
                return `((${this.maximumVal} - ${this.minimumVal}) % ${this.stepSize}) = ${this.stepRemain}, should be 0.`
            }

            return true;
        },
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data(): NumericConfiguratorData {
        return {
            initOptions: this.options || {},
            hasSlider: this.options?.hasSlider || false,
            minimumVal: this.options?.minimum,
            maximumVal: this.options?.maximum,
            precision: this.options?.precision || 0,
            stepSize: this.options?.stepSize || 1,
            inputKey: 0,
            hasLabel: this.options?.hasLabel || false,
            lowerBoundLabel: this.options?.lowerBoundLabel || '',
            upperBoundLabel: this.options?.upperBoundLabel || '',
            stepRemain: 0,
            rules: {
                stepSize: [
                    (v: string): boolean|string => {
                        return !(this as any).hasSlider || (this as any).validateStepSize();
                    },
                ],
                lessThan25: [
                    (v: string): boolean|string => {
                        return !(this as any).hasLabel || v.length <= 25 || 'must be less than or equal to 25 characters';
                    },
                ],
            },
        };
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

#label-warning {
    color: $abbi-red;
    margin: 2rem auto -1rem !important;
}

input[name='lowerBoundLabel'],input[name='upperBoundLabel'] {
    border-radius: 6px;
    border: 2px solid $abbi-green;
    padding: .5em;
    width: 25ch;
}

#numeric-configurator {

    margin-top: 25px;
    max-width: 960px;

    .step-toggle {
        align-self: center;
        max-height: 100px;
    }

    .input-group {
        margin: 0 auto;

        max-width: 960px;
        min-height: 40px;

        display: flex;
        justify-content: space-between;
    }

    .inputfield {
        display: flex;
        align-items: center;

        > .text {
            width: 150px;

            > * {
                margin: 0;
            }
        }
    }
}
</style>
