<template>
    <div :class="spinnerClasses" role="status">
        <span class="visually-hidden">{{ $t(label) }}</span>
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';

export enum SpinnerType {
    Border = 'border',
    Growing = 'grow',
}

export default Vue.extend({
    name: 'Spinner',
    props: {
        label: {
            type: String,
            default: 'SPINNER.LABEL',
        },
        type: {
            type: String as PropType<SpinnerType>,
            default: SpinnerType.Border,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        spinnerClasses(): Array<string> {
            const classes = [`spinner-${this.type}`];

            if (this.small) {
                classes.push(`spinner-${this.type}-sm`);
            }

            return classes;
        },
    },
});
</script>
