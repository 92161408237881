<template>
    <div class="abbi-datetime-picker">
        <!--suppress HtmlUnknownTag -->
        <v-date-picker locale="default" is-required :value="value" @input="dateChange" />
        <fieldset>
            <div class="time-picker vc-px-3 py-6px vc-border-gray-400">
                <label class="hours vc-text-gray-800">
                    <input class="naked" type="number" name="hours" min="0" max="23" :value="hours"
                           @change="numericInputChanged($event, 0, 23);timeChange($event, 'hours')" />
                </label>
                <span>:</span>
                <label class="minutes vc-text-gray-800">
                    <input class="naked" type="number" name="minutes" min="0" max="59" :value="minutes"
                           @change="numericInputChanged($event, 0, 59);timeChange($event, 'minutes')" />
                </label>
            </div>
        </fieldset>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'DatetimePicker',
    props: {
        value: [Object, Date],
    },
    methods: {
        dateChange($event: Date | null) {
            if ($event !== null) {
                const tmpDate = this.date;
                this.date = $event;
                this.date.setHours(tmpDate.getHours(), tmpDate.getMinutes(), 0, 0);
                this.$emit('input', this.date);
            }
        },
        timeChange($event: InputEvent, unit: string) {
            const val = Number(($event.target as HTMLInputElement).value);
            if (unit === 'hours') {
                this.date.setHours(val);
            } else if (unit === 'minutes') {
                this.date.setMinutes(val);
            }
            this.$emit('input', this.date);
        },
        numericInputChanged($e: InputEvent, min: number, max: number): void {
            const target = $e.target as HTMLInputElement;
            const value = Number(target.value);
            if (value > max) target.value = String(this.formatProperly(max));
            else if (value < min) target.value = String(this.formatProperly(min));
            else target.value = String(this.formatProperly(value));
        },
        formatProperly(num: number) {
            return num > 9 ? num : `0${num}`;
        },
    },
    computed: {
        hours() {
            // eslint-disable-next-line
                // @ts-ignore
            return this.formatProperly(this.date.getHours());
        },
        minutes() {
            // eslint-disable-next-line
                // @ts-ignore
            return this.formatProperly(this.date.getMinutes());
        },
    },
    data() {
        return {
            date: new Date(),
        };
    },
    mounted() {
        this.date = this.value;
    },
});
</script>

<style lang="scss">
.abbi-datetime-picker {
    display: flex;

    > * {
        flex: 1;
    }

    > span > input[type="input"] {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    fieldset {
        display: block;
        padding: 0;
        margin: 0;
        border: none;
    }

    .time-picker {
        display: flex;
        margin: 0;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        height: 100%;

        > label {
            display: block;
        }

        > span {
            display: block;
        }

        input[type="number"] {
            display: block;

            background-color: transparent;
            //noinspection CssInvalidPropertyValue
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }
        }

        .naked {
            outline: none;
            border: none;

            width: 1.5em;
        }

        .hours {
            input {
                text-align: end;
            }
        }

        .minutes {
            input {
                text-align: left;
            }
        }
    }
;
}
</style>
