<template>
    <div class="w-100">
        <FieldSet title="application">
            <Field property="app_background-color" />
        </FieldSet>

        <FieldSet title="Font" icon="font">
            <Field property="font_source_google"
                   :input="inputs.toggle"
                   :options="{on: '1', off: '0'}"/>
            <Field property="body_google_font-family"
                   v-if="googleFontsEnabled"
                   :input="inputs.datalist"
                   :options="googleFontOptions"/>
            <Field property="body_font-file"
                   v-if="!googleFontsEnabled"
                   :input="inputs.font"
                   :options="fontOptions"/>
            <Field property="body_font-weight"
                   v-if="googleFontsEnabled && this.fontWeights.length > 0"
                   :input="inputs.datalist"
                   :options="fontWeightsOptions"/>
            <Field property="body_font-size"
                   :input="inputs.dataRange"
                   :options="fontSizes"/>
        </FieldSet>

        <Alert
            type="warning"
            v-if="!displayHeader && !displaySideMenu"
            style="margin: 2em 0"
        >
            {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.APPLICATION.WARNING') }}
        </Alert>

        <FieldSet title="header" can-collapse :open="displayHeader">
            <template v-slot:toggle>
                <Field
                    property="header_display"
                    :show-label="false"
                    :input="inputs.toggle"
                    :options="{on: 'flex', off: 'none'}"
                />
            </template>
            <FieldSet>
                <Field property="header_hamburger_menu_color"/>
            </FieldSet>
            <FieldSet title="background" icon="image">
                <Field property="header_background-color" />
                <Field
                    property="header_background-size"
                    :input="inputs.toggle"
                    :options="{on: 'cover', off: '0'}"
                />
                <Field
                    property="header_background-image"
                    :input="inputs.image"
                    v-if="displayHeaderBackgroundImage"
                />
            </FieldSet>
            <FieldSet title="logo" icon="shapes" :open="displayHeaderLogo">
                <template v-slot:toggle>
                    <Field
                        property="header-logo_display"
                        :show-label="false"
                        :input="inputs.toggle"
                        :options="{on: 'flex', off: 'none'}"
                    />
                </template>
                <div v-if="displayHeaderLogo">
                    <Field
                        property="header-logo_content"
                        :options="headerLogoInputOptions"
                        :input="inputs.image"
                        v-if="displayHeaderLogo"
                    />
                    <div v-if="hasHeaderLogoSet">
                        <Field
                            property="header-expanded-logo_opacity"
                            :input="inputs.toggle"
                            :options="headerExpandedLogoOpacityOptions"
                            v-if="displayHeaderBackgroundImage && hasHeaderLogoSet"
                        />
                        <Alert
                            style="margin-top: 1em;"
                            type="warning"
                            v-if="displayHeaderBackgroundImage && displayExtendedHeaderLogo">
                            {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.HEADER.LOGO.OPACITY.WARNING') }}
                        </Alert>
                    </div>
                </div>
            </FieldSet>
            <FieldSet title="progress-bar" icon="bars-progress">
                <Field property="progress-bar_color" />
                <Field property="progress-bar_background-color" />
            </FieldSet>
        </FieldSet>
        <Alert
            type="warning"
            v-if="!displayHeader && displaySideMenu">
            {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.HEADER.WARNING') }}
        </Alert>

        <FieldSet title="sidemenu" can-collapse :open="displaySideMenu">
            <template v-slot:toggle>
                <Field
                    property="sidemenu_display"
                    :show-label="false"
                    :input="inputs.toggle"
                    :options="{on: 'flex', off: 'none'}"
                />
            </template>

            <Field property="sidemenu_background-color" />
            <Field property="sidemenu_color" />

            <FieldSet v-for="(item, idx) in sideMenuItems"
                      v-show="idx === 0 || displaySideMenuLinkButton(idx+1) || displaySideMenuLink(idx+1)"
                      :title="`sidemenu_link_${idx+1}`"
                      can-collapse :show-collapse="false" :open="displaySideMenuLink(idx+1)"
                      :key="`link_${idx+1}`">
                <template v-slot:toggle>
                    <Field :property="`sidemenu_link_${idx+1}-display`"
                           :input="inputs.toggle"
                           :show-label="false"
                           :options="{on: 'flex', off: 'none', disabled: displaySideMenuLink(idx+2)}"/>
                </template>
                <Field :property="`sidemenu_link_${idx+1}-content`"
                       :input="inputs.text"
                       :options="{placeholder: 'Example'}"/>
                <Field :property="`sidemenu_link_${idx+1}-url`"
                       :input="inputs.url"/>
            </FieldSet>
            <Alert
                type="warning"
                v-if="displayHeader && !displaySideMenu"
            >
                {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.SIDEMENU.WARNING') }}
            </Alert>
            <Alert
                type="danger"
                v-if="!displayHeader && displaySideMenu"
            >
                {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.SIDEMENU.ERROR') }}
            </Alert>
        </FieldSet>

        <FieldSet title="branding" icon="signature">
            <Field
                property="sidemenu_logo-display"
                v-if="displaySideMenu"
                :input="inputs.toggle"
                :options="{on: 'inline', off: 'none'}"
            />
            <Field
                property="sidemenu_copyright-display"
                v-if="displaySideMenu"
                :input="inputs.toggle"
                :options="{on: 'block', off: 'none'}"
            />
            <Field
                property="branding_message-display"
                :input="inputs.toggle"
                :options="{on: 'block', off: 'none'}"
            />
            <Alert
                style="margin-top: 1em;"
                type="warning"
                v-if="brandingHidden"
            >
                {{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.BRANDING.WARNING') }}
            </Alert>
        </FieldSet>
    </div>
</template>

<script lang="ts">
import ChatForm from '@/components/community/styling/chat/ChatForm.vue';
import FieldSet from '@/components/community/styling/form/FieldSet.vue';
import Field from '@/components/community/styling/form/Field.vue';
import Alert from '@/components/community/styling/Input/Alert.vue';
import { ImageInputProperties } from '@/components/community/styling/Input/ImageInput.vue';
import { DataListOptions, Option } from '@/components/community/styling/Input/DataListInput.vue';
import { DataRangeOptions } from '@/components/community/styling/Input/DataRangeInput.vue';
import FontService from '@/services/fonts.service';
import Toggle from '@/components/input/Toggle.vue';
import { FontUploadOptions } from '@/components/input/upload/FontUpload.vue';

export default ChatForm.extend({
    components: {
        Toggle,
        Alert,
        Field,
        FieldSet,
    },
    watch: {
        displayHeaderBackgroundImage(display: boolean): void {
            this.updateProperty('header_expanded', display ? '1' : '0');
        },
        async fontFamilyName(name: string): Promise<void> {
            this.getFontFamilyWeights(name);
            this.updateProperty('body_font-size', '1rem');
            this.updateProperty('body_font-weight', '400');
        },
        async fontFile(): Promise<void> {
            this.updateProperty('body_font-size', '1rem');
        },
        googleFontsEnabled(enabled: boolean): void {
            if (!enabled) {
                this.updateProperty('body_font-weight', '400');
            }
        },
        displaySideMenuLink1(enabled: boolean) {
            if (enabled) return;

            this.clearSideMenuLinkContents(1);
        },
        displaySideMenuLink2(enabled: boolean) {
            if (enabled) return;

            this.clearSideMenuLinkContents(2);
        },
        displaySideMenuLink3(enabled: boolean) {
            if (enabled) return;

            this.clearSideMenuLinkContents(3);
        },
        displaySideMenuLink4(enabled: boolean) {
            if (enabled) return;

            this.clearSideMenuLinkContents(4);
        },
    },
    data(): {
        fontFamilies: Record<string, string>;
        fontWeights: number[];
    } {
        return {
            fontFamilies: {},
            fontWeights: [],
        };
    },
    async created() {
        this.fontFamilies = await FontService.GetFonts();
        await this.getFontFamilyWeights(this.getValue('body_google_font-family'));
    },
    methods: {
        async getFontFamilyWeights(name: string) {
            this.fontWeights = await FontService.GetFontWeights(name);
        },
        clearSideMenuLinkContents(number: number): void {
            this.updateProperty(`sidemenu_link_${number}-content`, '');
            this.updateProperty(`sidemenu_link_${number}-url`, '');
        },
    },
    computed: {
        brandingHidden(): boolean {
            return (
                (
                    this.displaySideMenu && (
                        this.getValue('sidemenu_logo-display') === 'none'
                        || this.getValue('sidemenu_copyright-display') === 'none'
                    )
                )
                || this.getValue('branding_message-display') === 'none'
            );
        },
        displaySideMenu(): boolean {
            return this.stylesheet?.['sidemenu_display'] !== 'none';
        },
        displaySideMenuLink() {
            return (number: number) => this.stylesheet?.[`sidemenu_link_${number}-display`] === 'flex';
        },
        displaySideMenuLink1(): boolean {
            return this.displaySideMenuLink(1);
        },
        displaySideMenuLink2(): boolean {
            return this.displaySideMenuLink(2);
        },
        displaySideMenuLink3(): boolean {
            return this.displaySideMenuLink(3);
        },
        displaySideMenuLink4(): boolean {
            return this.displaySideMenuLink(4);
        },
        displaySideMenuLinkButton() {
            return (number: number) => {
                const content = this.stylesheet?.[`sidemenu_link_${number - 1}-content`];
                const url = this.stylesheet?.[`sidemenu_link_${number - 1}-url`];

                return content !== '' && url !== '';
            };
        },
        sideMenuItems() {
            const items = [];
            for (let i = 1; i <= 4; i++) {
                items.push({
                    content: this.stylesheet?.[`sidemenu_link_${i}-content`],
                    url: this.stylesheet?.[`sidemenu_link_${i}-url`],
                });
            }
            return items;
        },
        displayHeader(): boolean {
            return this.stylesheet?.['header_display'] !== 'none';
        },
        displayHeaderLogo(): boolean {
            return this.stylesheet?.['header-logo_display'] !== 'none';
        },
        googleFontsEnabled(): boolean {
            return this.stylesheet?.font_source_google === '1';
        },
        hasHeaderLogoSet(): boolean {
            return this.stylesheet?.['header-logo_content'] !== '';
        },
        displayExtendedHeaderLogo(): boolean {
            return this.stylesheet?.['header-expanded-logo_opacity'] === '1';
        },
        displayHeaderBackgroundImage(): boolean {
            return this.stylesheet?.['header_background-size'] !== '0';
        },
        headerLogoInputOptions(): ImageInputProperties {
            return ({
                acceptTypes: ['image/xml+svg', 'image/svg+xml', 'image/png', 'image/gif'],
                backgroundColor: this.getValue('header_background-color') ?? '#FFFFFF',
            });
        },
        headerExpandedLogoOpacityOptions(): any {
            return ({
                on: '1',
                off: '0',
                offLabel: this.$t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELD.HEADER-EXPANDED-LOGO_OPACITY.OFF'),
                onLabel: this.$t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELD.HEADER-EXPANDED-LOGO_OPACITY.ON'),
            });
        },
        fontFile(): string {
            return this.stylesheet?.['body_font-file'] ?? '';
        },
        fontFamilyName(): string|undefined {
            return this.stylesheet?.['body_google_font-family'];
        },
        googleFontOptions(): DataListOptions {
            if (Object.keys(this.fontFamilies).length === 0) {
                return ({options: []}) as DataListOptions;
            }

            return ({
                options: Object.entries(this.fontFamilies).map<Option>(([, value]) => ({ value, label: value })),
            }) as DataListOptions;
        },
        fontOptions(): FontUploadOptions {
            return {
                accepts: '.woff,.woff2,.ttf,.otf',
                currentFamilyName: this.getValue('body_font-family'),
            };
        },
        fontWeightsOptions(): DataListOptions {
            const fontWeightLabelMapping = new Map<number, string>([
                [100, 'Thin'],
                [200, 'Extra Light'],
                [300, 'Light'],
                [400, 'Regular'],
                [500, 'Medium'],
                [600, 'Semi Bold'],
                [700, 'Bold'],
                [800, 'Extra Bold'],
                [900, 'Black'],
                [950, 'Extra Black'],
            ]);

            return ({
                options: this.fontWeights.map<Option>((weight) => (
                    { value: weight, label: fontWeightLabelMapping.get(weight) }
                )),
            }) as DataListOptions;
        },
        fontSizes(): DataRangeOptions {
            return ({
                options: [
                    {value: 0.875, label: 'Small'},
                    {value: 1, label: 'Normal'},
                    {value: 1.125, label: 'Large'},
                ],
                unit: 'rem',
                decimals: 3,
                legend: true,
            }) as DataRangeOptions;
        },
    },
});
</script>
