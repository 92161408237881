<template>
    <div class="community-component-message">
        <div v-for="errorMessage in errorMessages" class="error-message" :key="errorMessage.msg">
            <h3>{{ errorMessage.msg }}</h3>
        </div>
        <form class="add-community-component" method="post" action="#" v-on:submit.prevent="createCommunity">
            <AbbiTextInput min="3" is-small name="name" :placeholder="$t('FIELDS.NAME')" />
            <AbbiTextInput min="3" class="mt-05" is-small name="slug" :placeholder="$t('FIELDS.SLUG')" />
            <AbbiButton class="submit-button" grow is-submit>
                {{
                    $t('ACTION.COMMUNITY.CREATE')
                        .charAt(0)
                        .toUpperCase() + $t('ACTION.COMMUNITY.CREATE')
                        .slice(1)
                }}
            </AbbiButton>
        </form>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CommunityApi from '@/apis/community.api';
import ErrorModel from '@/models/error.model';

export default Vue.extend({
    name: 'AddCommunityModal',
    components: {AbbiTextInput, AbbiButton},
    data() {
        return {
            csrfToken: '',
            errorMessages: null as null | ErrorModel[],
        };
    },
    methods: {
        createCommunity({target}: Event) {
            const form = new FormData(target as HTMLFormElement);
            CommunityApi.createCommunity(form.get('name') as string, form.get('slug') as string)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        const event = new CustomEvent('addCommunity', {detail: responseData});
                        document.dispatchEvent(event);
                        this.$emit('close');
                        this.$store.dispatch('closeModal');
                    }
                });
        },
    },
});
</script>

<style lang="scss">
.community-component-message {
    .error-message {
        h3 {
            padding: 0;
            margin: 0;

            font-weight: bold;
            font-size: 1.1em;
        }

        background-color: darken(red, 15%);
        color: white;

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        margin: -2px;
        margin-bottom: 0;
        padding: 0.5rem 0.75rem;
    }

    .add-community-component {
        display: flex;
        flex-direction: column;

        padding: 0.75em 1em;

        .submit-button {
            margin-top: 0.5em;
        }
    }
}
</style>
