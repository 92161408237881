var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"abbi-link",class:{square: _vm.square, button: _vm.isButton}},[(_vm.square)?_c('img',{attrs:{"alt":"squarizer","src":"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}):_vm._e(),(_vm.square)?_c('span',{staticClass:"vhidden no-wrap"},[_vm._t("default")],2):_vm._e(),(_vm.isExtern)?_c('a',{ref:"abbiLnk",class:{
            button: _vm.isButton,
            red: _vm.isRed,
            blue: _vm.isBlue
        },attrs:{"href":_vm.href,"target":"_blank"}},[_vm._t("default",[_c('span')])],2):(!_vm.isExtern && _vm.to !== undefined)?_c('router-link',{ref:"abbiLnk",class:{
            button: _vm.isButton,
            red: _vm.isRed,
            blue: _vm.isBlue
        },attrs:{"to":_vm.to}},[_vm._t("default",[_c('span')])],2):_c('div',{staticClass:"button",class:{blue: _vm.isBlue, 'no-radius': _vm.noRadius}},[_vm._t("default",[_c('span')])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }