<script lang="ts">
import Vue from 'vue';
import ListGroupItemInput from '@/components/form/ListGroupItemInput.vue';
import diff from 'deep-diff';
import OptionDefinition from '@/components/chat/configurators/action/Send2API/OptionDefinition';
import {TranslateResult} from 'vue-i18n';

interface SelectedRadioGroup {
    value: string;
    inputs: string[];
}

export default Vue.extend({
    name: 'StructureConfigurator',
    components: {ListGroupItemInput},
    props: ['value', 'optionsDefinitions'],
    watch: {
        computedOptions: {
            handler(newValue, oldValue): void {
                // Get changed properties in value
                const changed = diff(newValue, oldValue);

                this.$emit('input', Object.entries(newValue).filter(([, v]) => v).map(([k]) => k));
            },
            deep: true,
        },
        selectedRadio: {
            handler(groups: Record<string, SelectedRadioGroup>): void {
                const groupObjects = Object.entries(groups);
                groupObjects.forEach(([, g]) => {
                    g.inputs.forEach((i) => {
                        this.options[i] = (i === g.value);
                    });
                });
            },
            deep: true,
        },
        answerFormatKeyValueEnabled(enabled: boolean) {
            if (enabled) {
                console.log(Object.entries(this.options));
                const filteredOptions = Object.entries(this.options).filter(
                    ([k, v]) => typeof k === 'string' && k.startsWith('answer.options') && v === true,
                );
                console.log(filteredOptions);
                if (filteredOptions.length > 0) {
                    const answerOptionProperty = filteredOptions[0][0] ?? null;

                    if (answerOptionProperty) {
                       this.selectedRadio['answer.options'].value = answerOptionProperty;
                    }
                }
            }
        },
    },
    data() {
        return {
            options: {} as Record<string, boolean>,
            selectedRadio: {
                'answer.options': {
                    value: 'answer.options.text',
                    inputs: [
                        'answer.options.uuid',
                        'answer.options.text',
                        'answer.options.value',
                    ],
                },
            } as Record<string, SelectedRadioGroup>,
        };
    },
    computed: {
        computedOptions(): Record<string, boolean> {
            return {...this.options};
        },
        answerFormatKeyValueEnabled(): boolean {
            return this.options['answer.format.kv'];
        },
    },
    beforeMount() {
        this.options = this.arrayToObject(this.value);
    },
    methods: {
        arrayToObject(array: string[]): Record<string, boolean> {
            return array.reduce((acc: Record<string, boolean>, entry) => {
                acc[entry] = true;
                return acc;
            }, {});
        },
        resolveTranslation(key: string, optional = false): TranslateResult {
            const v = this.$t(key);

            if (!optional) return v;

            return v !== key ? v : '';
        },
        shouldBeDisabled(config: OptionDefinition, options: Record<string, boolean>): boolean {
            if (!config) return false;

            if (this.shouldBeRadio(config, options)) {
                return false;
            }
            const conditionsObject = config!.enabled?.conditions || {};
            const conditions = Object.entries(conditionsObject);

            if (conditions.length > 0) {
                if (conditions.some(([condition, values]) => {
                    switch (condition) {
                        case 'atLeastOneOfGroup':
                            if (values?.every((name) => !options[name])) {
                                return true;
                            }
                            break;
                        case 'onlyOneOf': {
                            const c = values?.filter((name) => options[name]).length || 0;

                            if (c === 0 || c > 1) {
                                return true;
                            }
                            break;
                        }
                        default:
                            return false; // Ensure a return value for other cases.
                    }

                    return false;
                })) {
                    return true;
                }
            }

            return false;
        },
        inputType(config: OptionDefinition, options: Record<string, boolean>): string {
            return this.shouldBeRadio(config, options) ? 'radio' : 'checkbox';
        },
        shouldBeRadio(config: OptionDefinition, options: Record<string, boolean>): boolean {
            return config?.radio?.whenTrue?.every((k) => (options[k])) || false;
        },
        radioGroupName(config: OptionDefinition, options: Record<string, boolean>): string {
            return config?.radio?.group || 'radio-input';
        },
    },
});
</script>

<template>
    <div class="configurator-fields mb-4" >
        <h3>{{ resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.TITLE') }}</h3>
        <p>{{ resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.TEXT') }}</p>
        <b-list-group v-for="(group, idx) in optionsDefinitions" class="user-select-none mb-4" :key="idx">
            <h4 v-if="group.title">{{ group.title }}
                <br v-if="group.subtitle" />
                <small v-if="group.subtitle" class="text-muted">{{group.subtitle}}</small>
            </h4>
            <p v-if="group.description">{{ group.description }}</p>
            <div v-for="[name, config] in Object.entries(group.items)" :key="`${name}`">
                <ListGroupItemInput
                    v-if="!shouldBeRadio(config, options)"
                    :name="name" v-model="options[name]"
                    :disabled="shouldBeDisabled(config, options)"
                    :group="shouldBeRadio(config, options) ? radioGroupName(config, options) : null"
                    type="checkbox"
                    :label="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.LABEL')"
                    :info-text="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.INFO', true)"
                    :example-text="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.EXAMPLE', true)"
                />
                <ListGroupItemInput
                    v-if="shouldBeRadio(config, options)"
                    :name="name"
                    v-model="selectedRadio[radioGroupName(config, options)].value"
                    :disabled="shouldBeDisabled(config, options)"
                    :group="radioGroupName(config, options)"
                    type="radio"
                    :label="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.LABEL')"
                    :info-text="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.INFO', true)"
                    :example-text="resolveTranslation('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.OPTION.'+name.toUpperCase()+'.EXAMPLE', true)"
                />
            </div>
        </b-list-group>
    </div>
</template>

<style scoped lang="scss">
@import 'src/styles/colors';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/utilities';

////@import '~bootstrap/scss/root';
////@import '~bootstrap/scss/reboot';
////@import '~bootstrap/scss/type';

@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/list-group';

@import "~bootstrap/scss/utilities/stretched-link";
//@import "~bootstrap/scss/utilities/color-bg";

//@import "~bootstrap/scss/grid";

.configurator-fields {
    //--line-height: 2.6em;
    //--border-radius: 6px;
    //--padding: calc(var(--border-radius) / 2);

    //display: table;
    //border: 2px solid rgba(0, 0, 0, .25);
    //background: #fff;
    //border-radius: var(--border-radius);
    //padding: 1rem;

    svg {
        color: white;
        height: 1em;
        width: 1em;
    }

    .abbi-row {
        display: table-row;
        line-height: var(--line-height);
        border-radius: var(--border-radius);

        .abbi-col {
            display: table-cell;
            padding: var(--padding) 0 var(--padding) 1em;

            &:last-of-type {
                padding-right: calc(.4em + var(--padding));
            }
        }

        label {
            display: inline-block;
            font-weight: 700;

            svg {
                margin-right: .5em;
            }

            &[title] {
                cursor: help;
            }
        }

        input {
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, .75);
            padding: 0 6px;
        }
    }

    .list-style-none {
        *, ul, li {
            list-style: none;
        }
    }

    .input-group {
        .input-group-prepend {
            //width: 20%;
            > .input-group-text {
                width: 100%;
            }
        }
    }

    h4 {
        font-size: 1rem;
        font-weight: bolder;
    }
}
</style>
