<script lang="ts">
import ChatConfigurator, {
    createAnswer,
    createMessage,
    createQuestion,
} from '@/components/community/styling/chat/ChatConfigurator.vue';
import ChatConversationForm from '@/components/community/styling/chat/conversation/ChatConversationForm.vue';

export default ChatConfigurator.extend({
    computed: {
        form(): typeof ChatConversationForm {
            return ChatConversationForm;
        },
        isTyping(): boolean {
            return true;
        },
        answers(): object[] {
            return [
                createAnswer(
                    '4',
                    'styling@abbi-insights.com',
                ),
            ];
        },
        instructions(): object[] {
            return [
                createMessage(
                    '1',
                    'The following messages are to help style the scripted messages.',
                ),
                createMessage(
                    '2',
                    'Here is a <a target="_blank" href="https://www.abbi-insights.com/">link to the Abbi-Insights website</a>.',
                ),
                createMessage(
                    '3',
                    'The following shows an example of <a class="custom-btn" target="_blank" href="https://www.abbi-insights.com/">a button</a>',
                ),
                createQuestion(
                    '4',
                    'email',
                    'What is your email address?',
                    'Provide a valid email address.',
                ),
                createMessage(
                    '5',
                    'Thank you for styling this chat!',
                ),
            ];
        },
    },
});
</script>
