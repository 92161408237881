<template>
    <span>
        <span :class="$style['label-left']" v-if="offLabel">{{ offLabel }}</span>
        <input
            :class="$style.component"
            type="checkbox"
            :disabled="disabled"
            :checked="checked"
            @click="toggle"
        />
        <span :class="$style['label-right']" v-if="onLabel">{{ onLabel }}</span>
    </span>
</template>

<script lang="ts">
import Input from '@/components/community/styling/Input/Input.vue';

export default Input.extend({
    props: {
        value: {
            type: String,
            required: true,
        },
        on: {
            type: String,
            default: '1',
        },
        off: {
            type: String,
            default: '0',
        },
        onLabel: {
            type: String,
            default: '',
        },
        offLabel: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        checked(): boolean {
            return this.value === this.on;
        },
    },
    methods: {
        toggle(): void {
            const {checked, on, off} = this;
            this.$emit('input', checked ? off : on);
        },
    },
});
</script>

<style lang="scss" module>
@import 'src/styles/colors';

.label-left, .label-right {
    --label-padding: 0.5rem;
}

.label-left {
    padding-right: var(--label-padding);
}

.label-right {
    padding-left: var(--label-padding);
}

.component {
    --size: 1.2em;
    width: calc(var(--size) * 2);
    height: 0;
    position: relative;
    top: calc(-1 * var(--size));
    margin: 3px 3px .8em;

    &:before, &:after {
        --offset: 3px;
        content: '';
        display: inline-block;
        position: absolute;
        height: var(--size);
        border-radius: calc(var(--size) / 2);
        border: none;
        cursor: pointer;
    }

    &:before {
        top: var(--offset);
        left: 0;
        width: 100%;
        box-shadow: rgba(0, 0, 0, .7) 0 0 4px -1px;
        background-color: rgba(255, 255, 255, .7);
    }

    &:after {
        --indicator: calc(var(--size) - calc(2 * var(--offset)));
        top: calc(2 * var(--offset));
        width: var(--indicator);
        height: var(--indicator);
        background-color: rgba(100, 100, 100, .9);

        transition-property: left, background-color;
        transition-duration: .2s;
    }

    &:checked {
        &:after {
            background-color: $abbi-blue;

            left: calc(100% - var(--indicator) - var(--offset));
        }
    }

    &:disabled {
        opacity: 0.4;
        &:before, &:after {
            cursor: not-allowed;
        }
    }

    &:not(:checked) {
        &:after {
            left: var(--offset);
        }
    }
}
</style>
