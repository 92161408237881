/* eslint @typescript-eslint/no-explicit-any: 0 */
import ChoiceModel from '@/models/choice.model';
import QuestionPresentationModel from '@/models/question-presentation.model';

export default class QuestionModel {
    uuid = null as string | null;

    type = 'empty';

    skipable = false as boolean;

    choices?: ChoiceModel[];

    options?: Record<string, any>;

    placeholder?: string;

    presentation?: QuestionPresentationModel;
}
