<template>
    <div id="home-page">
        <div class="top">
            <div class="search-input-container" >
                <AbbiTextInput placeholder="Filter community" v-model="communityFilter" class="search-input"
                               @keyup.enter="tryOpenCommunity" />
                <div v-if="filteredCommunities.length === 1 && communityFilter !== null">
                    Press the enter key to go to the <strong>{{filteredCommunities[0].name}}</strong> community.
                </div>
            </div>
            <AbbiButton class="add-button" @click="openAddCommunityModal">{{ $t('ACTION.COMMUNITY.ADD') }}</AbbiButton>
        </div>

        <div class="grid">
            <div class="community-link" v-for="community in filteredCommunities" :key="community.uuid">
                <button @click="go2Community(community)">{{ community.name }}</button>
            </div>

            <div v-if="filteredCommunities.length === 0 && communityFilter !== null">
                No communities match your filter.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import CommunityModel from '@/models/community.model';
import AddCommunityModal from '@/components/modals/AddCommunityModal.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';

export default vue.extend({
    name: 'Home',
    components: {AbbiTextInput, AbbiButton},
    data() {
        return {
            communityFilter: null as string | null,
        };
    },
    computed: {
        communities() {
            return this.$store.state.communities || [];
        },
        filteredCommunities(): CommunityModel[] {
            if (this.communityFilter === null) {
                return this.$store.state.communities;
            }

            return this.$store.state.communities.filter(
                (community: CommunityModel) => community.name.toLowerCase().includes(this.communityFilter!.toLowerCase()),
            );
        },
    },
    methods: {
        go2Community(community: CommunityModel) {
            this.$store.commit('changeCommunity', community);
            this.$router.push({
                name: 'ChatOverview',
                params: {communitySlug: community.slug},
            });
        },
        openAddCommunityModal() {
            this.$store.commit('setModal', {
                title: this.$t('ACTION.COMMUNITY.ADD'),
                component: AddCommunityModal,
            });
        },
        processAddedCommunity($event: CustomEvent) {
            this.$store.commit('addCommunity', $event.detail as CommunityModel);
            this.communities.push($event.detail as CommunityModel);
        },
        tryOpenCommunity($event: KeyboardEvent) {
            if (this.filteredCommunities.length === 1) {
                this.go2Community(this.filteredCommunities[0]);
            }
        },
    },
    created() {
        document.addEventListener('addCommunity', this.processAddedCommunity as EventListener);
    },
    destroyed() {
        document.removeEventListener('addCommunity', this.processAddedCommunity as EventListener);
    },
});
</script>

<style lang="scss">
#home-page {
    overflow-y: auto!important;

    > .grid {
        padding: 1rem;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(calc(2560px / 8), 1fr));
        place-items: center;

        > .community-link {
            margin: 1rem 0;
            width: 100%;
            height: 100%;

            > button {
                display: block;
                background-color: rgba(0, 0, 0, 0.05);
                border: none;
                cursor: pointer;
                width: 100%;
                max-width: calc(2560px / 8);
                height: 100%;
                outline: none;
                margin: 0 auto;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: normal;
        align-content: normal;

        > * {
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;
            margin: 1em;
        }
    }
}
</style>
