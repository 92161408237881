<template>
    <v-app id="star-rating-configurator" class="dynamic">
        <v-alert
            prominent
            v-if="!validForm"
            type="error"
        >
            This configuration contains errors. Changes will not be saved. <br />
            Please fix them before continuing or moving away from this page.
        </v-alert>
        <v-form ref="form" v-model="validForm">
            <v-row>
                <v-col>
                    <v-text-field
                        type="number"
                        readonly
                        disabled
                        label="Number of stars"
                        placeholder="Number of stars"
                        min="0"
                        max="10"
                        @change="changed"
                        :value="initOptions.max"
                        v-model.number="max"
                    />
                </v-col>
                <v-col>
                    <v-switch
                        class="px-2"
                        label="Allow half stars"
                        :readonly="scriptLive && !liveEditable"
                        @change="changed"
                        v-model="allowHalfStars"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-app>

<!--    <div class="input-group my-1">-->
<!--        <div class="inputfield">-->
<!--            <AbbiNumericInput-->
<!--                readonly-->
<!--                placeholder="Number of stars"-->
<!--                disabled="disabled"-->
<!--                is-small-->
<!--                :value="initOptions.max"-->
<!--                v-model="max"-->
<!--                @input="changed"-->
<!--            /><br />-->
<!--            <Toggle v-model="allowHalfStars" @input="changed">Allow half stars</Toggle>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script lang="ts">
import Configurator from '@/components/chat/configurators/Configurator.vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';
import Toggle from '@/components/input/Toggle.vue';

export default Configurator.extend({
    name: 'StarRatingConfigurator',
    components: {Toggle, AbbiNumericInput},
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    methods: {
        changed(): void {
            this.$emit('change', {
                max: 5,
                increment: this.allowHalfStars ? 0.5 : 1,
            });
        },
    },
    data() {
        return {
            validForm: true,
            initOptions: this.options || {},
            max: (this.options?.max ?? 5) as number,
            allowHalfStars: (this.options?.increment === 0.5) as boolean,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
