<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: "RoutingControl",
    props: {
        value: {
            type: String,
        },
        readonly: Boolean,
        preventBlurEvent: Boolean,
        prefix: String,
    },
    data() {
        return {
            condition: this.value as string|null,
        }
    },
    watch: {
        condition(condition: string) {
            if (condition === '') {
                this.condition = null;
            }
            this.$emit('input', condition);
        },
    },
    methods: {
        blurIfRequired(): void {
            if (!this.preventBlurEvent) this.$emit('blur');
        },
    },
})
</script>

<template>
    <div class="d-flex flex-row">
        <v-text-field
            class="monospaced mr-2"
            filled
            :readonly="readonly"
            :label="$t('FIELDS.CONDITION')"
            v-model="condition"
            @focus="$emit('focus')"
            @blur="blurIfRequired"
        />
<!--        <FaIcon v-if="!readonly" class="ml-2" role="button" icon="circle-info" @click="$emit('showInstructions')"/>-->
    </div>
</template>

<style lang="scss">
.v-input.monospaced input {
    font-family: monospace !important;
}
</style>
