<template>
    <v-text-field
        filled
        type="number"
        class="mr-2 flex-grow-0"
        :label="$t('FIELDS.DELAY')"
        v-model.number="value.typing_delay"
    />
    <!--  <div class="input-group mr-2">-->
    <!--    <div class="input-group-prepend">-->
    <!--      <span class="input-group-text">Reading Delay</span>-->
    <!--    </div>-->
    <!--    <input id="instruction-delay" class="form-control" name="reading-delay" type="number"-->
    <!--           step="0.05" min="0.25"-->
    <!--           :value="value.typing_delay"-->
    <!--           v-on:input="$emit('input', $event.target.value)"-->
    <!--           :placeholder="$t('FIELDS.DELAY')">-->
    <!--  </div>-->
</template>
<script lang="ts">
import InstructionModel from '@/models/instruction.model';

export default {
  name: 'ReadingDelayControl',
  props: {
      value: [Object, InstructionModel],
      readonly: Boolean,
  }
}
</script>
