<template>
    <div class="modal-backdrop" @click.self="close">
        <div class="modal" v-bind:style="modalStyle">
            <div class="modal-header">
                <h3 v-bind:style="modalHeaderStyle">{{ $store.state.modalTitle }}</h3>
                <AbbiButton is-red grow square @click.prevent="close">
                    <FaIcon icon="times" />
                </AbbiButton>
            </div>
            <div class="modal-content" @close="close">
                <slot @registerCloseHandler="closeCallback = $event"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

export default Vue.extend({
    name: 'Modal',
    components: {AbbiButton},
    methods: {
        close() {
            if (this.closeCallback) this.closeCallback();
            this.$store.dispatch('closeModal');
            this.$emit('close');
        },
    },
    data() {
        return {
            closeCallback: undefined as undefined | (() => void),
            modalStyle: this.$store.state.modalOptions.modalStyle,
            modalHeaderStyle: this.$store.state.modalOptions.modalHeaderStyle,
        };
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 666;
    opacity: 1 !important;

    background-color: rgba(0, 0, 0, 0.5) !important;

    .modal {
        cursor: default;
        background: white;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 1);
        display: flex;
        flex-direction: column;

        position: absolute;
        top: 50%;
        left: 50%;

        height: auto;
        width: auto;

        max-height: 95vh;

        transform: translate(-50%, -50%);
    }

    .modal-header {
        display: flex;
        padding: 0.25em;
        align-items: center;
        justify-content: space-between;

        :last-child {
            justify-self: flex-end;
        }

        h3 {
            padding: 0;
            margin: 0 1em;
            color: white;
        }

        background-color: $abbi-green;

        div.app-button > button {
            border-radius: 666px;

            margin: 0.25em;
            padding: 4px;

            width: 2em;
            height: 2em;

            align-items: center;
            justify-content: center;

            background-color: white;
            color: black;

            &:hover {
                background-color: rgba(255, 255, 255, 0.75);
            }

            &:active, &focus {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
    }

    .modal-content {
        overflow: auto;
    }
}
</style>
