<template>
    <div class="abbi-toggle">
        <label class="toggle" :class="{disabled, readonly}">
            <span><slot>missing label</slot></span>
            <input type="checkbox" :disabled="disabled || readonly" :name="name" :id="name" v-model="state" @click="clicked" @change="changed" />
            <span class="visualizer">
                <span class="stud"></span>
            </span>
        </label>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'Toggle',
    props: {
        disabled: Boolean,
        value: Boolean,
        name: String,
        label: String,
        readonly: Boolean,
    },
    data() {
        return {
            state: this.value,
        };
    },
    computed: {
        val(): boolean {
            return this.value;
        },
    },
    methods: {
        clicked(event: Event) {
            this.$emit('click', event, this.state);
        },
        changed() {
            this.$emit('input', this.state);
        },
    },
    watch: {
        val() {
            this.state = this.value;
        },
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

/* toggle element */
.abbi-toggle {
    display: inline-block;

    .toggle {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        height: 100%;
        max-height: 1rem;

        &.disabled, &.readonly {
            cursor: default;
        }

        input {
            display: none;
        }

        input:checked + .visualizer {
            background-color: $abbi-green;
            box-shadow: 0 0 2px 1px rgba(40, 40, 40, 0.75);

            .stud {
                transform: translateX(100%);
            }
        }

        input:disabled + .visualizer {
            box-shadow: 0 0 0 0 transparent;
            background-color: rgba(150, 150, 150, 1);

            .stud {
                background-color: rgba(192, 192, 192, 1);
            }
        }

        .visualizer {
            display: inline-flex;
            position: relative;
            box-shadow: 0 0 3px 2px rgba(100, 100, 100, 0.75);
            background-color: rgba(192, 192, 192, 1);

            border-radius: 666px;

            width: 2em;
            align-items: center;

            margin-left: 0.5em;
            padding: 0.25em;

            transition: background-color 0.1s linear, box-shadow 0.1s linear;

            .stud {
                display: block;
                padding-left: 50%;
                padding-top: 50%;
                background-color: $abbi-blue;

                transform: translateX(0);
                transition: background-color 0.05s ease-in-out, transform 0.05s ease-in-out;

                box-shadow: inset 0 0 2px 1px rgba(255, 255, 255, 0.25);

                border-radius: 666px;
            }
        }
    }
}
</style>
