// eslint-disable import/no-extraneous-dependencies

import Vue from 'vue';
// eslint-disable-next-line
// @ts-ignore
import VCalendar from 'v-calendar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FlashMessage from '@smartweb/vue-flash-message';
import settings from '@/settings';
import abbiAPI from '@/apis/abbi.api';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/icons';
import vSelect from 'vue-select';
import BootstrapVue, {VBPopover} from 'bootstrap-vue';
import { loader } from '@guolao/vue-monaco-editor';

import Vuetify from 'vuetify';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed icons

Vue.use(Vuetify);

loader.config({
    paths: {
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
    },
});

Vue.component('v-select', vSelect);
Vue.use(BootstrapVue);

abbiAPI.setBase(settings.apiBase);

Vue.config.productionTip = false;
Vue.use(FlashMessage);

Vue.use(VCalendar, {
    locales: {
        default: {
            firstDayOfWeek: 2,
            masks: {
                title: 'MMMM YYYY',
                weekdays: 'W',
                navMonths: 'MMM',
                input: ['DD-MM-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                dayPopover: 'WWW, MMM D, YYYY',
                data: ['DD-MM-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'],
            },
        },
    },
    datePicker: {
        popover: {
            visibility: 'focus',
        },
    },
});

const vuetifyConfig = new Vuetify({
    icons: {
        iconfont: 'faSvg',
    },
    theme: {
        themes: {
            light: {
                surface: '#000000',
                primary: '#A9C035',
                secondary: '#00949E',
                error: '#CC0000',
            },
            dark: {
                surface: '#FFFFFF',
                primary: '#A9C035',
                secondary: '#00949E',
                error: '#CC0000',
            },
        },
    },
});

new Vue({
    vuetify: vuetifyConfig,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
