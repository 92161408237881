<template>
    <div
        :title="$t(label || '')"
        :aria-label="$t(label || '')"
        :aria-valuenow="value"
        :aria-valuemin="min"
        class="progress"
        role="progressbar">
        <slot>
            <ProgressBar
                :render="render"
                :animated="animated"
                :striped="striped"
                :min="min"
                :max="max"
                :value="value" />
        </slot>
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import ProgressBar, {Render} from '@/components/bootstrap/ProgressBar.vue';

export default Vue.extend({
    name: 'Progress',
    components: {ProgressBar},
    props: {
        render: {
            type: Number as PropType<Render>,
            default: Render.None,
        },
        animated: {
            type: Boolean,
            default: false,
        },
        striped: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
    },
});
</script>
