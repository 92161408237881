<script lang="ts">
import vue from 'vue';

type VFormType = Vue & {
    validate: () => boolean;              // To validate the form
    resetValidation: () => void;         // To reset the validation state of the form
};

export interface ConfiguratorData {
    validForm: boolean,
    showInvalidError: boolean,
}

export default vue.extend({
    name: 'Configurator',
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange() {},
        changed(): void {
            if (!this.validForm) return;

            console.log('implemented $emit change event');
        },
        revalidate(): void {
            (this.$refs.form as VFormType).validate();
        },
        inputBlurred(): void {
            if (this.validForm) return;

            this.showInvalidError = true;
        },
    },
    watch: {
        validForm(isValid: boolean): void {
            if (!isValid) return;

            this.showInvalidError = false;

            this.changed();
        },
    },
    data() {
        return {
            showInvalidError: false,
            validForm: true,
        };
    },
    mounted() {
        this.onChange();
    },
});
</script>
