/* eslint max-classes-per-file: 0 */
import TranslationModel from '@/models/translation.model';

export default class ChoiceModel {
    uuid = null as string | null;

    value = 1;

    order!: number;

    single = false;

    fixed = false;

    score?: number = undefined;

    amount?: number = undefined;

    translation?: TranslationModel;
}

export class ChoiceModelExt {
    static GetMessageForLocale(self: ChoiceModel, locale: string, fallbackLocale: string): TranslationModel | null {
        if (self.translation == null) return null;
        let preferred = null;
        let fallback = null;
        const msg = self.translation as TranslationModel;
        if (msg.locale === locale) {
            preferred = msg;
        } else if (msg.locale === fallbackLocale) {
            fallback = msg;
        }

        return preferred || fallback;
    }
}
