import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import Login from '@/views/authentication/Login.vue';
import Logout from '@/views/authentication/Logout.vue';
import UserManagement from '@/views/management/UserManagement.vue';
import Admin from '@/views/Admin.vue';
import CommunityManagement from '@/views/management/CommunityManagement.vue';
import ChatOverview from '@/views/ChatOverview.vue';
import ChatEditor from '@/views/chats/ChatEditor.vue';
import Community from '@/views/Community.vue';
import Home from '@/views/Home.vue';
import CommunityOptions from '@/views/community/Options.vue';
import ChatDetails from '@/views/chats/ChatDetails.vue';
import Members from '@/views/community/Members.vue';
import PasswordReset from '@/views/authentication/PasswordReset.vue';
import ChangePassword from '@/views/authentication/ChangePassword.vue';
import Removal from '@/views/community/Removal.vue';
import RemovalManagement from '@/views/management/RemovalManagement.vue';
import AccountOverview from '@/views/account/AccountOverview.vue';
import Account from '@/views/Account.vue';
import AccountAuthentication from '@/views/account/AccountAuthentication.vue';
import store from '@/store';
import Stylesheet from '@/views/community/Stylesheet.vue';
import i18n from '@/i18n';

Vue.use(VueRouter);

declare module 'vue-router' {
    interface RouteMeta {
        requiresAuth: boolean;
    }
}

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'HomeLogin',
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/password-reset',
        name: 'ResetPassword',
        component: PasswordReset,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/change-password/:token',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            requiresAuth: false,
        },
    },
    // @see https://en.wikipedia.org/wiki/Well-known_URI
    {
        path: '/.well-known',
        name: 'Well-known URIs',
        children: [
            {
                path: 'change-password',
                redirect: {
                    name: 'AccountAuthentication',
                },
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/account',
        component: Account,
        children: [
            {
                path: '',
                name: 'AccountOverview',
                component: AccountOverview,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'authentication',
                name: 'AccountAuthentication',
                component: AccountAuthentication,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        children: [
            {
                path: 'user-management',
                name: 'UserManagement',
                component: UserManagement,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'community-management',
                name: 'CommunityManagement',
                component: CommunityManagement,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'removal-management',
                name: 'RemovalManagement',
                component: RemovalManagement,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/:communitySlug',
        name: 'Community',
        component: Community,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'stylesheet',
                name: 'CommunityStylesheet',
                component: Stylesheet,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'members',
                name: 'CommunityMembers',
                component: Members,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'options',
                name: 'CommunityOptions',
                component: CommunityOptions,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'removal-chats',
                name: 'Removal',
                component: Removal,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/chats/:communitySlug',
        name: 'ChatOverview',
        component: ChatOverview,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/chats/:communitySlug/:scriptUuid',
        name: 'ChatDetails',
        component: ChatDetails,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/chats/:communitySlug/:scriptUuid/edit',
        name: 'ChatEditor',
        component: ChatEditor,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to: Route, from: Route, next) => {
    if (store.state.loggedIn || to.meta?.requiresAuth === false) {
        document.title = `${i18n.t(`ROUTE.${to.name}`)} | Conversation Admin`;
        next();
    }
});

export default router;
