/* eslint @typescript-eslint/no-explicit-any: 0 */
import AbbiApi from '@/apis/abbi.api';
import ScriptModel from '@/models/script.model';

export default class ChatApi extends AbbiApi {
    public static getAllChats(communitySlug: string, statuses: any) {
        const statusesParams = encodeURIComponent(JSON.stringify(statuses));
        return ChatApi.get(`${ChatApi.url}/api/${communitySlug}/chat-scripts?statuses=${statusesParams}`);
    }

    public static createNewChat(communitySlug: string, name: string) {
        return ChatApi.put(`${ChatApi.url}/api/${communitySlug}/chat-scripts`, {name});
    }

    public static readChat(communitySlug: string, uuid: string) {
        return ChatApi.get(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}`);
    }

    public static updateChat(communitySlug: string, uuid: string, script: {[key: string]: ScriptModel|string[]}) {
        return ChatApi.patch(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}`, script);
    }

    public static getDeleteChatCSRF(communitySlug: string, uuid: string) {
        return ChatApi.get(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/delete`);
    }

    public static deleteChat(communitySlug: string, uuid: string, csrf: string) {
        return ChatApi.delete(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/delete`, {csrf});
    }

    public static changeScriptState(communitySlug: string, uuid: string, state: number) {
        return ChatApi.post(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/state`, {state});
    }

    public static changeScriptDeletionState(uuid: string, deletionState: boolean) {
        return ChatApi.post(`${ChatApi.url}/api/chat-scripts/${uuid}/deletion-state`, {deletionState});
    }

    public static getClearLiveStatsCSRF(communitySlug: string, uuid: string) {
        return ChatApi.get(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/stats/delete`);
    }

    public static clearLiveStats(communitySlug: string, uuid: string, csrf: string) {
        return ChatApi.delete(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/stats/delete`, {csrf});
    }

    public static getClearChatDataCSRF(communitySlug: string, uuid: string) {
        return ChatApi.get(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/data/delete`);
    }

    public static clearChatData(communitySlug: string, uuid: string, csrf: string) {
        return ChatApi.delete(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/data/delete`, {csrf});
    }

    public static makeCopy(communitySlug: string, uuid: string) {
        return ChatApi.post(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/copy`, {});
    }

    public static editRemoval(id: number, data: any) {
        return ChatApi.post(`${ChatApi.url}/api/chat-scripts/removal-edit/${id}`, data);
    }

    public static setConfig(communitySlug: string, uuid: string, data: any) {
        return ChatApi.post(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/config/set`, data);
    }

    public static requestFileUpload(communitySlug: string, uuid: string, isCheck: boolean) {
        const url = (!isCheck)
            ? `${ChatApi.url}/${communitySlug}/${uuid}/files/process`
            : `${ChatApi.url}/${communitySlug}/${uuid}/files/process?isCheck=1`;
        return ChatApi.get(url);
    }

    public static downloadFiles(uuid: string) {
        return ChatApi.get(`${ChatApi.url}/${uuid}/files/download`);
    }

    public static previewPayload(
        communitySlug: string,
        uuid: string,
        questions: string[]|null,
        options: string[],
        version = 2,
        conversationId: string|null = null,
    ) {
        const validatedConversationId = (
            conversationId
            && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(conversationId)
        ) ? conversationId : null;

        return ChatApi.post(`${ChatApi.url}/api/${communitySlug}/chat-scripts/${uuid}/preview/payload`,
            {
                questions,
                options,
                fake: validatedConversationId === null,
                version,
                conversationId: validatedConversationId,
            });
    }
}
