<template>
    <button
        :title="visible ? $t('ACCOUNT.PASSWORD.VISIBILITY.HIDE') : $t('ACCOUNT.PASSWORD.VISIBILITY.SHOW')"
        class="btn btn-primary"
        type="button"
        @click="$emit('toggle')">
        <i :class="{'bi-eye': !visible, 'bi-eye-slash': visible}"></i>
    </button>
</template>

<script lang="ts">
export default {
    name: 'TogglePasswordVisibility',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['toggle'],
};
</script>
