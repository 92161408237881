<template>
        <div class="input-group my-1 flex-column">
            <h4>Emoji</h4>
            <b-list-group class="emoji-container">
                <b-list-group-item>1 - <span style="margin-left: 3px;">😡 Very unsatisfied (angry)</span></b-list-group-item>
                <b-list-group-item>2 - 🙁 Unsatisfied (sad)</b-list-group-item>
                <b-list-group-item>3 - 😐 Neutral (ok)</b-list-group-item>
                <b-list-group-item>4 - 🙂 Satisfied (good)</b-list-group-item>
                <b-list-group-item>5 - 😊 Very satisfied (happy/excited)</b-list-group-item>
            </b-list-group>
        </div>
</template>

<script lang="ts">
import vue from 'vue';
import Toggle from '@/components/input/Toggle.vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';

export default vue.extend({
    name: 'EmojiConfigurator',
    components: {
        Toggle,
        AbbiNumericInput,
    },
    methods: {
        onChange() {
            this.$emit('change', {emoticonsCount: this.emoticonsCount});
        },
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    mounted() {
        this.$emit('change', {
            emoticonsCount: this.emoticonsCount,
        });
    },
    data() {
        return {
            emoticonsCount: this.options?.emoticonsCount || 5,
            emoticonsCountValues: [5],
        };
    },
});
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/utilities';

//@import '~bootstrap/scss/root';
//@import '~bootstrap/scss/reboot';
//@import '~bootstrap/scss/type';

@import '~bootstrap/scss/list-group';

.emoji-container{
    margin-top: 15px;
}
</style>
