<template>
    <div id="image-configurator">
        <ImageUpload
            identifier="image-upload"
            accepts=".jpg, .jpeg, .png,.svg, .bmp, .apng, .gif"
            @input="imageChanged"
            @change="processImageChange"
            :default-value="imgData"
        />
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import InstructionModel, {InstructionModelExt} from '@/models/instruction.model';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ImageUpload from '@/components/input/properties/ImageUpload.vue';

export default vue.extend({
    name: 'ImageConfigurator',
    components: {
        AbbiTextInput,
        ImageUpload,
    },
    props: {
        instruction: [Object, InstructionModel],
        image: [File],
    },
    computed: {
        filename() {
            if (this.image) {
                return this.image.name;
            }

            const path = this.imgData as string;
            if (path != null) {
                return path.substr(path.lastIndexOf('/'));
            }

            return undefined;
        },
    },
    methods: {
        imageChanged(img: ImageData) {
            this.imgData = img;
            this.handleChange();
        },
        processImageChange($e: Event) {
            const [file] = ($e.target as HTMLInputElement).files ?? [];
            this.file = file;
            if (this.file) {
                const fileObj = {} as { [key: string]: File };
                fileObj[`${this.instr.uuid}`] = this.file;
                this.$emit('imgChange', fileObj);
            }
        },
        handleChange() {
            const msg = InstructionModelExt.GetMessageForLocale(this.instr, 'en', 'en');
            if (msg !== null) {
                msg.content = '';
                if (this.imgData !== null) {
                    msg.content = '<img src="{imageSource}" alt="image" />';
                }
                if (this.text !== '') {
                    msg.content = `${msg.content}<span class="img-msg">${this.text}</span>`;
                }
            }
            this.$emit('change', this.instr);
        },
        getImageFromContent(content: string|undefined) {
            if (content !== undefined) {
                let haystack = content;
                haystack = haystack.replace('<img src="', '');
                const idx = haystack.indexOf('" alt="image" />');
                if (idx > -1) {
                    haystack = haystack.substr(0, idx);
                    return haystack.replace('{instrUuid}', this.instr.uuid);
                }
                return null;
            }

            return null;
        },
    },
    data() {
        return {
            instr: this.instruction as InstructionModel,
            imgData: null as null | ImageData | string,
            text: InstructionModelExt.GetMessageForLocale(this.instruction, 'en', 'en')
                ?.content.replace(/(<img.*?\/>)/g, '').replace(/<span.*?>/g, '').replace('</span>', ''),
            file: this.image as File | null,
        };
    },
    mounted() {
        if (this.file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.imgData = reader.result as string;
            });
            reader.readAsDataURL(this.file);
        } else {
            this.imgData = this.getImageFromContent(
                InstructionModelExt.GetMessageForLocale(this.instruction, 'en', 'en')?.content,
            ) as null | ImageData | string;
        }
    },
});
</script>

<style lang="scss">
#image-configurator {
    .img-preview {
        max-width: 100%;
        max-height: 720px;
    }
}
</style>
