export default class FontService {
    public static async GetFonts(): Promise<Record<string, string>> {
        /* eslint-disable quote-props */
        return {
            // <editor-fold defaultstate="collapsed" desc="Font List">
            'abeezee': 'ABeeZee',
            'abel': 'Abel',
            'abhaya-libre': 'Abhaya Libre',
            'aboreto': 'Aboreto',
            'abril-fatface': 'Abril Fatface',
            'abyssinica-sil': 'Abyssinica SIL',
            'aclonica': 'Aclonica',
            'acme': 'Acme',
            'actor': 'Actor',
            'adamina': 'Adamina',
            'adlam-display': 'ADLaM Display',
            'advent-pro': 'Advent Pro',
            'afacad': 'Afacad',
            'afacad-flux': 'Afacad Flux',
            'agbalumo': 'Agbalumo',
            'agdasima': 'Agdasima',
            'aguafina-script': 'Aguafina Script',
            'akatab': 'Akatab',
            'akaya-kanadaka': 'Akaya Kanadaka',
            'akaya-telivigala': 'Akaya Telivigala',
            'akronim': 'Akronim',
            'akshar': 'Akshar',
            'aladin': 'Aladin',
            'alata': 'Alata',
            'alatsi': 'Alatsi',
            'albert-sans': 'Albert Sans',
            'aldrich': 'Aldrich',
            'alef': 'Alef',
            'alegreya': 'Alegreya',
            'alegreya-sans': 'Alegreya Sans',
            'alegreya-sans-sc': 'Alegreya Sans SC',
            'alegreya-sc': 'Alegreya SC',
            'aleo': 'Aleo',
            'alex-brush': 'Alex Brush',
            'alexandria': 'Alexandria',
            'alfa-slab-one': 'Alfa Slab One',
            'alice': 'Alice',
            'alike': 'Alike',
            'alike-angular': 'Alike Angular',
            'alkalami': 'Alkalami',
            'alkatra': 'Alkatra',
            'allan': 'Allan',
            'allerta': 'Allerta',
            'allerta-stencil': 'Allerta Stencil',
            'allison': 'Allison',
            'allura': 'Allura',
            'almarai': 'Almarai',
            'almendra': 'Almendra',
            'almendra-display': 'Almendra Display',
            'almendra-sc': 'Almendra SC',
            'alumni-sans': 'Alumni Sans',
            'alumni-sans-collegiate-one': 'Alumni Sans Collegiate One',
            'alumni-sans-inline-one': 'Alumni Sans Inline One',
            'alumni-sans-pinstripe': 'Alumni Sans Pinstripe',
            'amarante': 'Amarante',
            'amaranth': 'Amaranth',
            'amatic-sc': 'Amatic SC',
            'amethysta': 'Amethysta',
            'amiko': 'Amiko',
            'amiri': 'Amiri',
            'amiri-quran': 'Amiri Quran',
            'amita': 'Amita',
            'anaheim': 'Anaheim',
            'andada-pro': 'Andada Pro',
            'andika': 'Andika',
            'anek-bangla': 'Anek Bangla',
            'anek-devanagari': 'Anek Devanagari',
            'anek-gujarati': 'Anek Gujarati',
            'anek-gurmukhi': 'Anek Gurmukhi',
            'anek-kannada': 'Anek Kannada',
            'anek-latin': 'Anek Latin',
            'anek-malayalam': 'Anek Malayalam',
            'anek-odia': 'Anek Odia',
            'anek-tamil': 'Anek Tamil',
            'anek-telugu': 'Anek Telugu',
            'angkor': 'Angkor',
            'annapurna-sil': 'Annapurna SIL',
            'annie-use-your-telescope': 'Annie Use Your Telescope',
            'anonymous-pro': 'Anonymous Pro',
            'anta': 'Anta',
            'antic': 'Antic',
            'antic-didone': 'Antic Didone',
            'antic-slab': 'Antic Slab',
            'anton': 'Anton',
            'anton-sc': 'Anton SC',
            'antonio': 'Antonio',
            'anuphan': 'Anuphan',
            'anybody': 'Anybody',
            'aoboshi-one': 'Aoboshi One',
            'ar-one-sans': 'AR One Sans',
            'arapey': 'Arapey',
            'arbutus': 'Arbutus',
            'arbutus-slab': 'Arbutus Slab',
            'architects-daughter': 'Architects Daughter',
            'archivo': 'Archivo',
            'archivo-black': 'Archivo Black',
            'archivo-narrow': 'Archivo Narrow',
            'are-you-serious': 'Are You Serious',
            'aref-ruqaa': 'Aref Ruqaa',
            'aref-ruqaa-ink': 'Aref Ruqaa Ink',
            'arima': 'Arima',
            'arima-madurai': 'Arima Madurai',
            'arimo': 'Arimo',
            'arizonia': 'Arizonia',
            'armata': 'Armata',
            'arsenal': 'Arsenal',
            'arsenal-sc': 'Arsenal SC',
            'artifika': 'Artifika',
            'arvo': 'Arvo',
            'arya': 'Arya',
            'asap': 'Asap',
            'asap-condensed': 'Asap Condensed',
            'asar': 'Asar',
            'asset': 'Asset',
            'assistant': 'Assistant',
            'astloch': 'Astloch',
            'asul': 'Asul',
            'athiti': 'Athiti',
            'atkinson-hyperlegible': 'Atkinson Hyperlegible',
            'atma': 'Atma',
            'atomic-age': 'Atomic Age',
            'aubrey': 'Aubrey',
            'audiowide': 'Audiowide',
            'autour-one': 'Autour One',
            'average': 'Average',
            'average-sans': 'Average Sans',
            'averia-gruesa-libre': 'Averia Gruesa Libre',
            'averia-libre': 'Averia Libre',
            'averia-sans-libre': 'Averia Sans Libre',
            'averia-serif-libre': 'Averia Serif Libre',
            'azeret-mono': 'Azeret Mono',
            'b612': 'B612',
            'b612-mono': 'B612 Mono',
            'babylonica': 'Babylonica',
            'bacasime-antique': 'Bacasime Antique',
            'bad-script': 'Bad Script',
            'bagel-fat-one': 'Bagel Fat One',
            'bahiana': 'Bahiana',
            'bahianita': 'Bahianita',
            'bai-jamjuree': 'Bai Jamjuree',
            'bakbak-one': 'Bakbak One',
            'ballet': 'Ballet',
            'baloo-2': 'Baloo 2',
            'baloo-bhai-2': 'Baloo Bhai 2',
            'baloo-bhaijaan-2': 'Baloo Bhaijaan 2',
            'baloo-bhaina-2': 'Baloo Bhaina 2',
            'baloo-chettan-2': 'Baloo Chettan 2',
            'baloo-da-2': 'Baloo Da 2',
            'baloo-paaji-2': 'Baloo Paaji 2',
            'baloo-tamma-2': 'Baloo Tamma 2',
            'baloo-tammudu-2': 'Baloo Tammudu 2',
            'baloo-thambi-2': 'Baloo Thambi 2',
            'balsamiq-sans': 'Balsamiq Sans',
            'balthazar': 'Balthazar',
            'bangers': 'Bangers',
            'barlow': 'Barlow',
            'barlow-condensed': 'Barlow Condensed',
            'barlow-semi-condensed': 'Barlow Semi Condensed',
            'barriecito': 'Barriecito',
            'barrio': 'Barrio',
            'basic': 'Basic',
            'baskervville': 'Baskervville',
            'baskervville-sc': 'Baskervville SC',
            'battambang': 'Battambang',
            'baumans': 'Baumans',
            'bayon': 'Bayon',
            'be-vietnam-pro': 'Be Vietnam Pro',
            'beau-rivage': 'Beau Rivage',
            'bebas-neue': 'Bebas Neue',
            'beiruti': 'Beiruti',
            'belanosima': 'Belanosima',
            'belgrano': 'Belgrano',
            'bellefair': 'Bellefair',
            'belleza': 'Belleza',
            'bellota': 'Bellota',
            'bellota-text': 'Bellota Text',
            'benchnine': 'BenchNine',
            'benne': 'Benne',
            'bentham': 'Bentham',
            'berkshire-swash': 'Berkshire Swash',
            'besley': 'Besley',
            'beth-ellen': 'Beth Ellen',
            'bevan': 'Bevan',
            'bhutuka-expanded-one': 'BhuTuka Expanded One',
            'big-shoulders-display': 'Big Shoulders Display',
            'big-shoulders-inline-display': 'Big Shoulders Inline Display',
            'big-shoulders-inline-text': 'Big Shoulders Inline Text',
            'big-shoulders-stencil-display': 'Big Shoulders Stencil Display',
            'big-shoulders-stencil-text': 'Big Shoulders Stencil Text',
            'big-shoulders-text': 'Big Shoulders Text',
            'bigelow-rules': 'Bigelow Rules',
            'bigshot-one': 'Bigshot One',
            'bilbo': 'Bilbo',
            'bilbo-swash-caps': 'Bilbo Swash Caps',
            'biorhyme': 'BioRhyme',
            'biorhyme-expanded': 'BioRhyme Expanded',
            'birthstone': 'Birthstone',
            'birthstone-bounce': 'Birthstone Bounce',
            'biryani': 'Biryani',
            'bitter': 'Bitter',
            'biz-udgothic': 'BIZ UDGothic',
            'biz-udmincho': 'BIZ UDMincho',
            'biz-udpgothic': 'BIZ UDPGothic',
            'biz-udpmincho': 'BIZ UDPMincho',
            'black-and-white-picture': 'Black And White Picture',
            'black-han-sans': 'Black Han Sans',
            'black-ops-one': 'Black Ops One',
            'blaka': 'Blaka',
            'blaka-hollow': 'Blaka Hollow',
            'blaka-ink': 'Blaka Ink',
            'blinker': 'Blinker',
            'bodoni-moda': 'Bodoni Moda',
            'bodoni-moda-sc': 'Bodoni Moda SC',
            'bokor': 'Bokor',
            'bona-nova': 'Bona Nova',
            'bona-nova-sc': 'Bona Nova SC',
            'bonbon': 'Bonbon',
            'bonheur-royale': 'Bonheur Royale',
            'boogaloo': 'Boogaloo',
            'borel': 'Borel',
            'bowlby-one': 'Bowlby One',
            'bowlby-one-sc': 'Bowlby One SC',
            'braah-one': 'Braah One',
            'brawler': 'Brawler',
            'bree-serif': 'Bree Serif',
            'bricolage-grotesque': 'Bricolage Grotesque',
            'briem-hand': 'Briem Hand',
            'bruno-ace': 'Bruno Ace',
            'bruno-ace-sc': 'Bruno Ace SC',
            'brygada-1918': 'Brygada 1918',
            'bubblegum-sans': 'Bubblegum Sans',
            'bubbler-one': 'Bubbler One',
            'buda': 'Buda',
            'buenard': 'Buenard',
            'bungee': 'Bungee',
            'bungee-hairline': 'Bungee Hairline',
            'bungee-inline': 'Bungee Inline',
            'bungee-outline': 'Bungee Outline',
            'bungee-shade': 'Bungee Shade',
            'bungee-spice': 'Bungee Spice',
            'bungee-tint': 'Bungee Tint',
            'butcherman': 'Butcherman',
            'butterfly-kids': 'Butterfly Kids',
            'cabin': 'Cabin',
            'cabin-condensed': 'Cabin Condensed',
            'cabin-sketch': 'Cabin Sketch',
            'cactus-classical-serif': 'Cactus Classical Serif',
            'caesar-dressing': 'Caesar Dressing',
            'cagliostro': 'Cagliostro',
            'cairo': 'Cairo',
            'cairo-play': 'Cairo Play',
            'caladea': 'Caladea',
            'calistoga': 'Calistoga',
            'calligraffitti': 'Calligraffitti',
            'cambay': 'Cambay',
            'cambo': 'Cambo',
            'candal': 'Candal',
            'cantarell': 'Cantarell',
            'cantata-one': 'Cantata One',
            'cantora-one': 'Cantora One',
            'caprasimo': 'Caprasimo',
            'capriola': 'Capriola',
            'caramel': 'Caramel',
            'carattere': 'Carattere',
            'cardo': 'Cardo',
            'carlito': 'Carlito',
            'carme': 'Carme',
            'carrois-gothic': 'Carrois Gothic',
            'carrois-gothic-sc': 'Carrois Gothic SC',
            'carter-one': 'Carter One',
            'castoro': 'Castoro',
            'castoro-titling': 'Castoro Titling',
            'catamaran': 'Catamaran',
            'caudex': 'Caudex',
            'caveat': 'Caveat',
            'caveat-brush': 'Caveat Brush',
            'cedarville-cursive': 'Cedarville Cursive',
            'ceviche-one': 'Ceviche One',
            'chakra-petch': 'Chakra Petch',
            'changa': 'Changa',
            'changa-one': 'Changa One',
            'chango': 'Chango',
            'charis-sil': 'Charis SIL',
            'charm': 'Charm',
            'charmonman': 'Charmonman',
            'chathura': 'Chathura',
            'chau-philomene-one': 'Chau Philomene One',
            'chela-one': 'Chela One',
            'chelsea-market': 'Chelsea Market',
            'chenla': 'Chenla',
            'cherish': 'Cherish',
            'cherry-bomb-one': 'Cherry Bomb One',
            'cherry-cream-soda': 'Cherry Cream Soda',
            'cherry-swash': 'Cherry Swash',
            'chewy': 'Chewy',
            'chicle': 'Chicle',
            'chilanka': 'Chilanka',
            'chivo': 'Chivo',
            'chivo-mono': 'Chivo Mono',
            'chocolate-classical-sans': 'Chocolate Classical Sans',
            'chokokutai': 'Chokokutai',
            'chonburi': 'Chonburi',
            'cinzel': 'Cinzel',
            'cinzel-decorative': 'Cinzel Decorative',
            'clicker-script': 'Clicker Script',
            'climate-crisis': 'Climate Crisis',
            'coda': 'Coda',
            'coda-caption': 'Coda Caption',
            'codystar': 'Codystar',
            'coiny': 'Coiny',
            'combo': 'Combo',
            'comfortaa': 'Comfortaa',
            'comforter': 'Comforter',
            'comforter-brush': 'Comforter Brush',
            'comic-neue': 'Comic Neue',
            'coming-soon': 'Coming Soon',
            'comme': 'Comme',
            'commissioner': 'Commissioner',
            'concert-one': 'Concert One',
            'condiment': 'Condiment',
            'content': 'Content',
            'contrail-one': 'Contrail One',
            'convergence': 'Convergence',
            'cookie': 'Cookie',
            'copse': 'Copse',
            'corben': 'Corben',
            'corinthia': 'Corinthia',
            'cormorant': 'Cormorant',
            'cormorant-garamond': 'Cormorant Garamond',
            'cormorant-infant': 'Cormorant Infant',
            'cormorant-sc': 'Cormorant SC',
            'cormorant-unicase': 'Cormorant Unicase',
            'cormorant-upright': 'Cormorant Upright',
            'courgette': 'Courgette',
            'courier-prime': 'Courier Prime',
            'cousine': 'Cousine',
            'coustard': 'Coustard',
            'covered-by-your-grace': 'Covered By Your Grace',
            'crafty-girls': 'Crafty Girls',
            'creepster': 'Creepster',
            'crete-round': 'Crete Round',
            'crimson-pro': 'Crimson Pro',
            'crimson-text': 'Crimson Text',
            'croissant-one': 'Croissant One',
            'crushed': 'Crushed',
            'cuprum': 'Cuprum',
            'cute-font': 'Cute Font',
            'cutive': 'Cutive',
            'cutive-mono': 'Cutive Mono',
            'dai-banna-sil': 'Dai Banna SIL',
            'damion': 'Damion',
            'dancing-script': 'Dancing Script',
            'danfo': 'Danfo',
            'dangrek': 'Dangrek',
            'darker-grotesque': 'Darker Grotesque',
            'darumadrop-one': 'Darumadrop One',
            'david-libre': 'David Libre',
            'dawning-of-a-new-day': 'Dawning of a New Day',
            'days-one': 'Days One',
            'dekko': 'Dekko',
            'dela-gothic-one': 'Dela Gothic One',
            'delicious-handrawn': 'Delicious Handrawn',
            'delius': 'Delius',
            'delius-swash-caps': 'Delius Swash Caps',
            'delius-unicase': 'Delius Unicase',
            'della-respira': 'Della Respira',
            'denk-one': 'Denk One',
            'devonshire': 'Devonshire',
            'dhurjati': 'Dhurjati',
            'didact-gothic': 'Didact Gothic',
            'diphylleia': 'Diphylleia',
            'diplomata': 'Diplomata',
            'diplomata-sc': 'Diplomata SC',
            'dm-mono': 'DM Mono',
            'dm-sans': 'DM Sans',
            'dm-serif-display': 'DM Serif Display',
            'dm-serif-text': 'DM Serif Text',
            'do-hyeon': 'Do Hyeon',
            'dokdo': 'Dokdo',
            'domine': 'Domine',
            'donegal-one': 'Donegal One',
            'dongle': 'Dongle',
            'doppio-one': 'Doppio One',
            'dorsa': 'Dorsa',
            'dosis': 'Dosis',
            'dotgothic16': 'DotGothic16',
            'dr-sugiyama': 'Dr Sugiyama',
            'duru-sans': 'Duru Sans',
            'dynalight': 'Dynalight',
            'dynapuff': 'DynaPuff',
            'eagle-lake': 'Eagle Lake',
            'east-sea-dokdo': 'East Sea Dokdo',
            'eater': 'Eater',
            'eb-garamond': 'EB Garamond',
            'economica': 'Economica',
            'eczar': 'Eczar',
            'edu-au-vic-wa-nt-dots': 'Edu AU VIC WA NT Dots',
            'edu-au-vic-wa-nt-guides': 'Edu AU VIC WA NT Guides',
            'edu-au-vic-wa-nt-hand': 'Edu AU VIC WA NT Hand',
            'edu-nsw-act-foundation': 'Edu NSW ACT Foundation',
            'edu-qld-beginner': 'Edu QLD Beginner',
            'edu-sa-beginner': 'Edu SA Beginner',
            'edu-tas-beginner': 'Edu TAS Beginner',
            'edu-vic-wa-nt-beginner': 'Edu VIC WA NT Beginner',
            'el-messiri': 'El Messiri',
            'electrolize': 'Electrolize',
            'elsie': 'Elsie',
            'elsie-swash-caps': 'Elsie Swash Caps',
            'emblema-one': 'Emblema One',
            'emilys-candy': 'Emilys Candy',
            'encode-sans': 'Encode Sans',
            'encode-sans-condensed': 'Encode Sans Condensed',
            'encode-sans-expanded': 'Encode Sans Expanded',
            'encode-sans-sc': 'Encode Sans SC',
            'encode-sans-semi-condensed': 'Encode Sans Semi Condensed',
            'encode-sans-semi-expanded': 'Encode Sans Semi Expanded',
            'engagement': 'Engagement',
            'englebert': 'Englebert',
            'enriqueta': 'Enriqueta',
            'ephesis': 'Ephesis',
            'epilogue': 'Epilogue',
            'erica-one': 'Erica One',
            'esteban': 'Esteban',
            'estonia': 'Estonia',
            'euphoria-script': 'Euphoria Script',
            'ewert': 'Ewert',
            'exo': 'Exo',
            'exo-2': 'Exo 2',
            'expletus-sans': 'Expletus Sans',
            'explora': 'Explora',
            'fahkwang': 'Fahkwang',
            'familjen-grotesk': 'Familjen Grotesk',
            'fanwood-text': 'Fanwood Text',
            'farro': 'Farro',
            'farsan': 'Farsan',
            'fascinate': 'Fascinate',
            'fascinate-inline': 'Fascinate Inline',
            'faster-one': 'Faster One',
            'fasthand': 'Fasthand',
            'fauna-one': 'Fauna One',
            'faustina': 'Faustina',
            'federant': 'Federant',
            'federo': 'Federo',
            'felipa': 'Felipa',
            'fenix': 'Fenix',
            'festive': 'Festive',
            'figtree': 'Figtree',
            'finger-paint': 'Finger Paint',
            'finlandica': 'Finlandica',
            'fira-code': 'Fira Code',
            'fira-mono': 'Fira Mono',
            'fira-sans': 'Fira Sans',
            'fira-sans-condensed': 'Fira Sans Condensed',
            'fira-sans-extra-condensed': 'Fira Sans Extra Condensed',
            'fjalla-one': 'Fjalla One',
            'fjord-one': 'Fjord One',
            'flamenco': 'Flamenco',
            'flavors': 'Flavors',
            'fleur-de-leah': 'Fleur De Leah',
            'flow-block': 'Flow Block',
            'flow-circular': 'Flow Circular',
            'flow-rounded': 'Flow Rounded',
            'foldit': 'Foldit',
            'fondamento': 'Fondamento',
            'fontdiner-swanky': 'Fontdiner Swanky',
            'forum': 'Forum',
            'fragment-mono': 'Fragment Mono',
            'francois-one': 'Francois One',
            'frank-ruhl-libre': 'Frank Ruhl Libre',
            'fraunces': 'Fraunces',
            'freckle-face': 'Freckle Face',
            'fredericka-the-great': 'Fredericka the Great',
            'fredoka': 'Fredoka',
            'fredoka-one': 'Fredoka One',
            'freehand': 'Freehand',
            'freeman': 'Freeman',
            'fresca': 'Fresca',
            'frijole': 'Frijole',
            'fruktur': 'Fruktur',
            'fugaz-one': 'Fugaz One',
            'fuggles': 'Fuggles',
            'fustat': 'Fustat',
            'fuzzy-bubbles': 'Fuzzy Bubbles',
            'ga-maamli': 'Ga Maamli',
            'gabarito': 'Gabarito',
            'gabriela': 'Gabriela',
            'gaegu': 'Gaegu',
            'gafata': 'Gafata',
            'gajraj-one': 'Gajraj One',
            'galada': 'Galada',
            'galdeano': 'Galdeano',
            'galindo': 'Galindo',
            'gamja-flower': 'Gamja Flower',
            'gantari': 'Gantari',
            'gasoek-one': 'Gasoek One',
            'gayathri': 'Gayathri',
            'gelasio': 'Gelasio',
            'gemunu-libre': 'Gemunu Libre',
            'genos': 'Genos',
            'gentium-book-basic': 'Gentium Book Basic',
            'gentium-book-plus': 'Gentium Book Plus',
            'gentium-plus': 'Gentium Plus',
            'geo': 'Geo',
            'geologica': 'Geologica',
            'georama': 'Georama',
            'geostar': 'Geostar',
            'geostar-fill': 'Geostar Fill',
            'germania-one': 'Germania One',
            'gfs-didot': 'GFS Didot',
            'gfs-neohellenic': 'GFS Neohellenic',
            'gideon-roman': 'Gideon Roman',
            'gidugu': 'Gidugu',
            'gilda-display': 'Gilda Display',
            'girassol': 'Girassol',
            'give-you-glory': 'Give You Glory',
            'glass-antiqua': 'Glass Antiqua',
            'glegoo': 'Glegoo',
            'gloock': 'Gloock',
            'gloria-hallelujah': 'Gloria Hallelujah',
            'glory': 'Glory',
            'gluten': 'Gluten',
            'goblin-one': 'Goblin One',
            'gochi-hand': 'Gochi Hand',
            'goldman': 'Goldman',
            'golos-text': 'Golos Text',
            'gorditas': 'Gorditas',
            'gothic-a1': 'Gothic A1',
            'gotu': 'Gotu',
            'goudy-bookletter-1911': 'Goudy Bookletter 1911',
            'gowun-batang': 'Gowun Batang',
            'gowun-dodum': 'Gowun Dodum',
            'graduate': 'Graduate',
            'grand-hotel': 'Grand Hotel',
            'grandiflora-one': 'Grandiflora One',
            'grandstander': 'Grandstander',
            'grape-nuts': 'Grape Nuts',
            'gravitas-one': 'Gravitas One',
            'great-vibes': 'Great Vibes',
            'grechen-fuemen': 'Grechen Fuemen',
            'grenze': 'Grenze',
            'grenze-gotisch': 'Grenze Gotisch',
            'grey-qo': 'Grey Qo',
            'griffy': 'Griffy',
            'gruppo': 'Gruppo',
            'gudea': 'Gudea',
            'gugi': 'Gugi',
            'gulzar': 'Gulzar',
            'gupter': 'Gupter',
            'gurajada': 'Gurajada',
            'gwendolyn': 'Gwendolyn',
            'habibi': 'Habibi',
            'hachi-maru-pop': 'Hachi Maru Pop',
            'hahmlet': 'Hahmlet',
            'halant': 'Halant',
            'hammersmith-one': 'Hammersmith One',
            'hanalei': 'Hanalei',
            'hanalei-fill': 'Hanalei Fill',
            'handjet': 'Handjet',
            'handlee': 'Handlee',
            'hanken-grotesk': 'Hanken Grotesk',
            'hanuman': 'Hanuman',
            'happy-monkey': 'Happy Monkey',
            'harmattan': 'Harmattan',
            'headland-one': 'Headland One',
            'hedvig-letters-sans': 'Hedvig Letters Sans',
            'hedvig-letters-serif': 'Hedvig Letters Serif',
            'heebo': 'Heebo',
            'henny-penny': 'Henny Penny',
            'hepta-slab': 'Hepta Slab',
            'herr-von-muellerhoff': 'Herr Von Muellerhoff',
            'hi-melody': 'Hi Melody',
            'hina-mincho': 'Hina Mincho',
            'hind': 'Hind',
            'hind-guntur': 'Hind Guntur',
            'hind-madurai': 'Hind Madurai',
            'hind-siliguri': 'Hind Siliguri',
            'hind-vadodara': 'Hind Vadodara',
            'holtwood-one-sc': 'Holtwood One SC',
            'homemade-apple': 'Homemade Apple',
            'homenaje': 'Homenaje',
            'honk': 'Honk',
            'hubballi': 'Hubballi',
            'hurricane': 'Hurricane',
            'ibarra-real-nova': 'Ibarra Real Nova',
            'ibm-plex-mono': 'IBM Plex Mono',
            'ibm-plex-sans': 'IBM Plex Sans',
            'ibm-plex-sans-arabic': 'IBM Plex Sans Arabic',
            'ibm-plex-sans-condensed': 'IBM Plex Sans Condensed',
            'ibm-plex-sans-devanagari': 'IBM Plex Sans Devanagari',
            'ibm-plex-sans-hebrew': 'IBM Plex Sans Hebrew',
            'ibm-plex-sans-jp': 'IBM Plex Sans JP',
            'ibm-plex-sans-kr': 'IBM Plex Sans KR',
            'ibm-plex-sans-thai': 'IBM Plex Sans Thai',
            'ibm-plex-sans-thai-looped': 'IBM Plex Sans Thai Looped',
            'ibm-plex-serif': 'IBM Plex Serif',
            'iceberg': 'Iceberg',
            'iceland': 'Iceland',
            'im-fell-double-pica': 'IM Fell Double Pica',
            'im-fell-double-pica-sc': 'IM Fell Double Pica SC',
            'im-fell-dw-pica': 'IM Fell DW Pica',
            'im-fell-dw-pica-sc': 'IM Fell DW Pica SC',
            'im-fell-english': 'IM Fell English',
            'im-fell-english-sc': 'IM Fell English SC',
            'im-fell-french-canon': 'IM Fell French Canon',
            'im-fell-french-canon-sc': 'IM Fell French Canon SC',
            'im-fell-great-primer': 'IM Fell Great Primer',
            'im-fell-great-primer-sc': 'IM Fell Great Primer SC',
            'imbue': 'Imbue',
            'imperial-script': 'Imperial Script',
            'imprima': 'Imprima',
            'inclusive-sans': 'Inclusive Sans',
            'inconsolata': 'Inconsolata',
            'inder': 'Inder',
            'indie-flower': 'Indie Flower',
            'ingrid-darling': 'Ingrid Darling',
            'inika': 'Inika',
            'inknut-antiqua': 'Inknut Antiqua',
            'inria-sans': 'Inria Sans',
            'inria-serif': 'Inria Serif',
            'inspiration': 'Inspiration',
            'instrument-sans': 'Instrument Sans',
            'instrument-serif': 'Instrument Serif',
            'inter': 'Inter',
            'inter-tight': 'Inter Tight',
            'irish-grover': 'Irish Grover',
            'island-moments': 'Island Moments',
            'istok-web': 'Istok Web',
            'italiana': 'Italiana',
            'italianno': 'Italianno',
            'itim': 'Itim',
            'jacquard-12': 'Jacquard 12',
            'jacquard-12-charted': 'Jacquard 12 Charted',
            'jacquard-24': 'Jacquard 24',
            'jacquard-24-charted': 'Jacquard 24 Charted',
            'jacquarda-bastarda-9': 'Jacquarda Bastarda 9',
            'jacquarda-bastarda-9-charted': 'Jacquarda Bastarda 9 Charted',
            'jacques-francois': 'Jacques Francois',
            'jacques-francois-shadow': 'Jacques Francois Shadow',
            'jaini': 'Jaini',
            'jaini-purva': 'Jaini Purva',
            'jaldi': 'Jaldi',
            'jaro': 'Jaro',
            'jersey-10': 'Jersey 10',
            'jersey-10-charted': 'Jersey 10 Charted',
            'jersey-15': 'Jersey 15',
            'jersey-15-charted': 'Jersey 15 Charted',
            'jersey-20': 'Jersey 20',
            'jersey-20-charted': 'Jersey 20 Charted',
            'jersey-25': 'Jersey 25',
            'jersey-25-charted': 'Jersey 25 Charted',
            'jetbrains-mono': 'JetBrains Mono',
            'jim-nightshade': 'Jim Nightshade',
            'joan': 'Joan',
            'jockey-one': 'Jockey One',
            'jolly-lodger': 'Jolly Lodger',
            'jomhuria': 'Jomhuria',
            'jomolhari': 'Jomolhari',
            'josefin-sans': 'Josefin Sans',
            'josefin-slab': 'Josefin Slab',
            'jost': 'Jost',
            'joti-one': 'Joti One',
            'jua': 'Jua',
            'judson': 'Judson',
            'julee': 'Julee',
            'julius-sans-one': 'Julius Sans One',
            'junge': 'Junge',
            'jura': 'Jura',
            'just-another-hand': 'Just Another Hand',
            'just-me-again-down-here': 'Just Me Again Down Here',
            'k2d': 'K2D',
            'kablammo': 'Kablammo',
            'kadwa': 'Kadwa',
            'kaisei-decol': 'Kaisei Decol',
            'kaisei-harunoumi': 'Kaisei HarunoUmi',
            'kaisei-opti': 'Kaisei Opti',
            'kaisei-tokumin': 'Kaisei Tokumin',
            'kalam': 'Kalam',
            'kalnia': 'Kalnia',
            'kalnia-glaze': 'Kalnia Glaze',
            'kameron': 'Kameron',
            'kanit': 'Kanit',
            'kantumruy': 'Kantumruy',
            'kantumruy-pro': 'Kantumruy Pro',
            'karantina': 'Karantina',
            'karla': 'Karla',
            'karma': 'Karma',
            'katibeh': 'Katibeh',
            'kaushan-script': 'Kaushan Script',
            'kavivanar': 'Kavivanar',
            'kavoon': 'Kavoon',
            'kay-pho-du': 'Kay Pho Du',
            'kdam-thmor-pro': 'Kdam Thmor Pro',
            'keania-one': 'Keania One',
            'kelly-slab': 'Kelly Slab',
            'kenia': 'Kenia',
            'khand': 'Khand',
            'khmer': 'Khmer',
            'khula': 'Khula',
            'kings': 'Kings',
            'kirang-haerang': 'Kirang Haerang',
            'kite-one': 'Kite One',
            'kiwi-maru': 'Kiwi Maru',
            'klee-one': 'Klee One',
            'knewave': 'Knewave',
            'kodchasan': 'Kodchasan',
            'kode-mono': 'Kode Mono',
            'koh-santepheap': 'Koh Santepheap',
            'koho': 'KoHo',
            'kolker-brush': 'Kolker Brush',
            'konkhmer-sleokchher': 'Konkhmer Sleokchher',
            'kosugi': 'Kosugi',
            'kosugi-maru': 'Kosugi Maru',
            'kotta-one': 'Kotta One',
            'koulen': 'Koulen',
            'kranky': 'Kranky',
            'kreon': 'Kreon',
            'kristi': 'Kristi',
            'krona-one': 'Krona One',
            'krub': 'Krub',
            'kufam': 'Kufam',
            'kulim-park': 'Kulim Park',
            'kumar-one': 'Kumar One',
            'kumar-one-outline': 'Kumar One Outline',
            'kumbh-sans': 'Kumbh Sans',
            'kurale': 'Kurale',
            'la-belle-aurore': 'La Belle Aurore',
            'labrada': 'Labrada',
            'lacquer': 'Lacquer',
            'laila': 'Laila',
            'lakki-reddy': 'Lakki Reddy',
            'lalezar': 'Lalezar',
            'lancelot': 'Lancelot',
            'langar': 'Langar',
            'lateef': 'Lateef',
            'lato': 'Lato',
            'lavishly-yours': 'Lavishly Yours',
            'league-gothic': 'League Gothic',
            'league-script': 'League Script',
            'league-spartan': 'League Spartan',
            'leckerli-one': 'Leckerli One',
            'ledger': 'Ledger',
            'lekton': 'Lekton',
            'lemon': 'Lemon',
            'lemonada': 'Lemonada',
            'lexend': 'Lexend',
            'lexend-deca': 'Lexend Deca',
            'lexend-exa': 'Lexend Exa',
            'lexend-giga': 'Lexend Giga',
            'lexend-mega': 'Lexend Mega',
            'lexend-peta': 'Lexend Peta',
            'lexend-tera': 'Lexend Tera',
            'lexend-zetta': 'Lexend Zetta',
            'libre-barcode-128': 'Libre Barcode 128',
            'libre-barcode-128-text': 'Libre Barcode 128 Text',
            'libre-barcode-39': 'Libre Barcode 39',
            'libre-barcode-39-extended': 'Libre Barcode 39 Extended',
            'libre-barcode-39-extended-text': 'Libre Barcode 39 Extended Text',
            'libre-barcode-39-text': 'Libre Barcode 39 Text',
            'libre-barcode-ean13-text': 'Libre Barcode EAN13 Text',
            'libre-baskerville': 'Libre Baskerville',
            'libre-bodoni': 'Libre Bodoni',
            'libre-caslon-display': 'Libre Caslon Display',
            'libre-caslon-text': 'Libre Caslon Text',
            'libre-franklin': 'Libre Franklin',
            'licorice': 'Licorice',
            'life-savers': 'Life Savers',
            'lilita-one': 'Lilita One',
            'lily-script-one': 'Lily Script One',
            'limelight': 'Limelight',
            'linden-hill': 'Linden Hill',
            'linefont': 'Linefont',
            'lisu-bosa': 'Lisu Bosa',
            'literata': 'Literata',
            'liu-jian-mao-cao': 'Liu Jian Mao Cao',
            'livvic': 'Livvic',
            'lobster': 'Lobster',
            'lobster-two': 'Lobster Two',
            'londrina-outline': 'Londrina Outline',
            'londrina-shadow': 'Londrina Shadow',
            'londrina-sketch': 'Londrina Sketch',
            'londrina-solid': 'Londrina Solid',
            'long-cang': 'Long Cang',
            'lora': 'Lora',
            'love-light': 'Love Light',
            'love-ya-like-a-sister': 'Love Ya Like A Sister',
            'loved-by-the-king': 'Loved by the King',
            'lovers-quarrel': 'Lovers Quarrel',
            'luckiest-guy': 'Luckiest Guy',
            'lugrasimo': 'Lugrasimo',
            'lumanosimo': 'Lumanosimo',
            'lunasima': 'Lunasima',
            'lusitana': 'Lusitana',
            'lustria': 'Lustria',
            'luxurious-roman': 'Luxurious Roman',
            'luxurious-script': 'Luxurious Script',
            'lxgw-wenkai-mono-tc': 'LXGW WenKai Mono TC',
            'lxgw-wenkai-tc': 'LXGW WenKai TC',
            'm-plus-1': 'M PLUS 1',
            'm-plus-1-code': 'M PLUS 1 Code',
            'm-plus-1p': 'M PLUS 1p',
            'm-plus-2': 'M PLUS 2',
            'm-plus-code-latin': 'M PLUS Code Latin',
            'm-plus-rounded-1c': 'M PLUS Rounded 1c',
            'ma-shan-zheng': 'Ma Shan Zheng',
            'macondo': 'Macondo',
            'macondo-swash-caps': 'Macondo Swash Caps',
            'mada': 'Mada',
            'madimi-one': 'Madimi One',
            'magra': 'Magra',
            'maiden-orange': 'Maiden Orange',
            'maitree': 'Maitree',
            'major-mono-display': 'Major Mono Display',
            'mako': 'Mako',
            'mali': 'Mali',
            'mallanna': 'Mallanna',
            'maname': 'Maname',
            'mandali': 'Mandali',
            'manjari': 'Manjari',
            'manrope': 'Manrope',
            'mansalva': 'Mansalva',
            'manuale': 'Manuale',
            'marcellus': 'Marcellus',
            'marcellus-sc': 'Marcellus SC',
            'marck-script': 'Marck Script',
            'margarine': 'Margarine',
            'marhey': 'Marhey',
            'markazi-text': 'Markazi Text',
            'marko-one': 'Marko One',
            'marmelad': 'Marmelad',
            'martel': 'Martel',
            'martel-sans': 'Martel Sans',
            'martian-mono': 'Martian Mono',
            'marvel': 'Marvel',
            'mate': 'Mate',
            'mate-sc': 'Mate SC',
            'matemasie': 'Matemasie',
            'material-icons': 'Material Icons',
            'material-icons-outlined': 'Material Icons Outlined',
            'material-icons-round': 'Material Icons Round',
            'material-icons-sharp': 'Material Icons Sharp',
            'material-icons-two-tone': 'Material Icons Two Tone',
            'material-symbols-outlined': 'Material Symbols Outlined',
            'material-symbols-rounded': 'Material Symbols Rounded',
            'material-symbols-sharp': 'Material Symbols Sharp',
            'maven-pro': 'Maven Pro',
            'mclaren': 'McLaren',
            'mea-culpa': 'Mea Culpa',
            'meddon': 'Meddon',
            'medievalsharp': 'MedievalSharp',
            'medula-one': 'Medula One',
            'meera-inimai': 'Meera Inimai',
            'megrim': 'Megrim',
            'meie-script': 'Meie Script',
            'meow-script': 'Meow Script',
            'merienda': 'Merienda',
            'merienda-one': 'Merienda One',
            'merriweather': 'Merriweather',
            'merriweather-sans': 'Merriweather Sans',
            'metal': 'Metal',
            'metal-mania': 'Metal Mania',
            'metamorphous': 'Metamorphous',
            'metrophobic': 'Metrophobic',
            'michroma': 'Michroma',
            'micro-5': 'Micro 5',
            'micro-5-charted': 'Micro 5 Charted',
            'milonga': 'Milonga',
            'miltonian': 'Miltonian',
            'miltonian-tattoo': 'Miltonian Tattoo',
            'mina': 'Mina',
            'mingzat': 'Mingzat',
            'miniver': 'Miniver',
            'miriam-libre': 'Miriam Libre',
            'mirza': 'Mirza',
            'miss-fajardose': 'Miss Fajardose',
            'mitr': 'Mitr',
            'mochiy-pop-one': 'Mochiy Pop One',
            'mochiy-pop-p-one': 'Mochiy Pop P One',
            'modak': 'Modak',
            'modern-antiqua': 'Modern Antiqua',
            'moderustic': 'Moderustic',
            'mogra': 'Mogra',
            'mohave': 'Mohave',
            'moirai-one': 'Moirai One',
            'molengo': 'Molengo',
            'molle': 'Molle',
            'monda': 'Monda',
            'monofett': 'Monofett',
            'monomaniac-one': 'Monomaniac One',
            'monoton': 'Monoton',
            'monsieur-la-doulaise': 'Monsieur La Doulaise',
            'montaga': 'Montaga',
            'montagu-slab': 'Montagu Slab',
            'montecarlo': 'MonteCarlo',
            'montez': 'Montez',
            'montserrat': 'Montserrat',
            'montserrat-alternates': 'Montserrat Alternates',
            'montserrat-subrayada': 'Montserrat Subrayada',
            'moo-lah-lah': 'Moo Lah Lah',
            'mooli': 'Mooli',
            'moon-dance': 'Moon Dance',
            'moul': 'Moul',
            'moulpali': 'Moulpali',
            'mountains-of-christmas': 'Mountains of Christmas',
            'mouse-memoirs': 'Mouse Memoirs',
            'mr-bedfort': 'Mr Bedfort',
            'mr-dafoe': 'Mr Dafoe',
            'mr-de-haviland': 'Mr De Haviland',
            'mrs-saint-delafield': 'Mrs Saint Delafield',
            'mrs-sheppards': 'Mrs Sheppards',
            'ms-madi': 'Ms Madi',
            'mukta': 'Mukta',
            'mukta-mahee': 'Mukta Mahee',
            'mukta-malar': 'Mukta Malar',
            'mukta-vaani': 'Mukta Vaani',
            'mulish': 'Mulish',
            'murecho': 'Murecho',
            'museomoderno': 'MuseoModerno',
            'my-soul': 'My Soul',
            'mynerve': 'Mynerve',
            'mystery-quest': 'Mystery Quest',
            'nabla': 'Nabla',
            'namdhinggo': 'Namdhinggo',
            'nanum-brush-script': 'Nanum Brush Script',
            'nanum-gothic': 'Nanum Gothic',
            'nanum-gothic-coding': 'Nanum Gothic Coding',
            'nanum-myeongjo': 'Nanum Myeongjo',
            'nanum-pen-script': 'Nanum Pen Script',
            'narnoor': 'Narnoor',
            'neonderthaw': 'Neonderthaw',
            'nerko-one': 'Nerko One',
            'neucha': 'Neucha',
            'neuton': 'Neuton',
            'new-amsterdam': 'New Amsterdam',
            'new-rocker': 'New Rocker',
            'new-tegomin': 'New Tegomin',
            'news-cycle': 'News Cycle',
            'newsreader': 'Newsreader',
            'niconne': 'Niconne',
            'niramit': 'Niramit',
            'nixie-one': 'Nixie One',
            'nobile': 'Nobile',
            'nokora': 'Nokora',
            'norican': 'Norican',
            'nosifer': 'Nosifer',
            'notable': 'Notable',
            'nothing-you-could-do': 'Nothing You Could Do',
            'noticia-text': 'Noticia Text',
            'noto-color-emoji': 'Noto Color Emoji',
            'noto-emoji': 'Noto Emoji',
            'noto-kufi-arabic': 'Noto Kufi Arabic',
            'noto-music': 'Noto Music',
            'noto-naskh-arabic': 'Noto Naskh Arabic',
            'noto-nastaliq-urdu': 'Noto Nastaliq Urdu',
            'noto-rashi-hebrew': 'Noto Rashi Hebrew',
            'noto-sans': 'Noto Sans',
            'noto-sans-adlam': 'Noto Sans Adlam',
            'noto-sans-adlam-unjoined': 'Noto Sans Adlam Unjoined',
            'noto-sans-anatolian-hieroglyphs': 'Noto Sans Anatolian Hieroglyphs',
            'noto-sans-arabic': 'Noto Sans Arabic',
            'noto-sans-armenian': 'Noto Sans Armenian',
            'noto-sans-avestan': 'Noto Sans Avestan',
            'noto-sans-balinese': 'Noto Sans Balinese',
            'noto-sans-bamum': 'Noto Sans Bamum',
            'noto-sans-bassa-vah': 'Noto Sans Bassa Vah',
            'noto-sans-batak': 'Noto Sans Batak',
            'noto-sans-bengali': 'Noto Sans Bengali',
            'noto-sans-bhaiksuki': 'Noto Sans Bhaiksuki',
            'noto-sans-brahmi': 'Noto Sans Brahmi',
            'noto-sans-buginese': 'Noto Sans Buginese',
            'noto-sans-buhid': 'Noto Sans Buhid',
            'noto-sans-canadian-aboriginal': 'Noto Sans Canadian Aboriginal',
            'noto-sans-carian': 'Noto Sans Carian',
            'noto-sans-caucasian-albanian': 'Noto Sans Caucasian Albanian',
            'noto-sans-chakma': 'Noto Sans Chakma',
            'noto-sans-cham': 'Noto Sans Cham',
            'noto-sans-cherokee': 'Noto Sans Cherokee',
            'noto-sans-chorasmian': 'Noto Sans Chorasmian',
            'noto-sans-coptic': 'Noto Sans Coptic',
            'noto-sans-cuneiform': 'Noto Sans Cuneiform',
            'noto-sans-cypriot': 'Noto Sans Cypriot',
            'noto-sans-cypro-minoan': 'Noto Sans Cypro Minoan',
            'noto-sans-deseret': 'Noto Sans Deseret',
            'noto-sans-devanagari': 'Noto Sans Devanagari',
            'noto-sans-display': 'Noto Sans Display',
            'noto-sans-duployan': 'Noto Sans Duployan',
            'noto-sans-egyptian-hieroglyphs': 'Noto Sans Egyptian Hieroglyphs',
            'noto-sans-elbasan': 'Noto Sans Elbasan',
            'noto-sans-elymaic': 'Noto Sans Elymaic',
            'noto-sans-ethiopic': 'Noto Sans Ethiopic',
            'noto-sans-georgian': 'Noto Sans Georgian',
            'noto-sans-glagolitic': 'Noto Sans Glagolitic',
            'noto-sans-gothic': 'Noto Sans Gothic',
            'noto-sans-grantha': 'Noto Sans Grantha',
            'noto-sans-gujarati': 'Noto Sans Gujarati',
            'noto-sans-gunjala-gondi': 'Noto Sans Gunjala Gondi',
            'noto-sans-gurmukhi': 'Noto Sans Gurmukhi',
            'noto-sans-hanifi-rohingya': 'Noto Sans Hanifi Rohingya',
            'noto-sans-hanunoo': 'Noto Sans Hanunoo',
            'noto-sans-hatran': 'Noto Sans Hatran',
            'noto-sans-hebrew': 'Noto Sans Hebrew',
            'noto-sans-hk': 'Noto Sans HK',
            'noto-sans-imperial-aramaic': 'Noto Sans Imperial Aramaic',
            'noto-sans-indic-siyaq-numbers': 'Noto Sans Indic Siyaq Numbers',
            'noto-sans-inscriptional-pahlavi': 'Noto Sans Inscriptional Pahlavi',
            'noto-sans-inscriptional-parthian': 'Noto Sans Inscriptional Parthian',
            'noto-sans-javanese': 'Noto Sans Javanese',
            'noto-sans-jp': 'Noto Sans JP',
            'noto-sans-kaithi': 'Noto Sans Kaithi',
            'noto-sans-kannada': 'Noto Sans Kannada',
            'noto-sans-kawi': 'Noto Sans Kawi',
            'noto-sans-kayah-li': 'Noto Sans Kayah Li',
            'noto-sans-kharoshthi': 'Noto Sans Kharoshthi',
            'noto-sans-khmer': 'Noto Sans Khmer',
            'noto-sans-khojki': 'Noto Sans Khojki',
            'noto-sans-khudawadi': 'Noto Sans Khudawadi',
            'noto-sans-kr': 'Noto Sans KR',
            'noto-sans-lao': 'Noto Sans Lao',
            'noto-sans-lao-looped': 'Noto Sans Lao Looped',
            'noto-sans-lepcha': 'Noto Sans Lepcha',
            'noto-sans-limbu': 'Noto Sans Limbu',
            'noto-sans-linear-a': 'Noto Sans Linear A',
            'noto-sans-linear-b': 'Noto Sans Linear B',
            'noto-sans-lisu': 'Noto Sans Lisu',
            'noto-sans-lycian': 'Noto Sans Lycian',
            'noto-sans-lydian': 'Noto Sans Lydian',
            'noto-sans-mahajani': 'Noto Sans Mahajani',
            'noto-sans-malayalam': 'Noto Sans Malayalam',
            'noto-sans-mandaic': 'Noto Sans Mandaic',
            'noto-sans-manichaean': 'Noto Sans Manichaean',
            'noto-sans-marchen': 'Noto Sans Marchen',
            'noto-sans-masaram-gondi': 'Noto Sans Masaram Gondi',
            'noto-sans-math': 'Noto Sans Math',
            'noto-sans-mayan-numerals': 'Noto Sans Mayan Numerals',
            'noto-sans-medefaidrin': 'Noto Sans Medefaidrin',
            'noto-sans-meetei-mayek': 'Noto Sans Meetei Mayek',
            'noto-sans-mende-kikakui': 'Noto Sans Mende Kikakui',
            'noto-sans-meroitic': 'Noto Sans Meroitic',
            'noto-sans-miao': 'Noto Sans Miao',
            'noto-sans-modi': 'Noto Sans Modi',
            'noto-sans-mongolian': 'Noto Sans Mongolian',
            'noto-sans-mono': 'Noto Sans Mono',
            'noto-sans-mro': 'Noto Sans Mro',
            'noto-sans-multani': 'Noto Sans Multani',
            'noto-sans-myanmar': 'Noto Sans Myanmar',
            'noto-sans-nabataean': 'Noto Sans Nabataean',
            'noto-sans-nag-mundari': 'Noto Sans Nag Mundari',
            'noto-sans-nandinagari': 'Noto Sans Nandinagari',
            'noto-sans-new-tai-lue': 'Noto Sans New Tai Lue',
            'noto-sans-newa': 'Noto Sans Newa',
            'noto-sans-nko': 'Noto Sans NKo',
            'noto-sans-nko-unjoined': 'Noto Sans NKo Unjoined',
            'noto-sans-nushu': 'Noto Sans Nushu',
            'noto-sans-ogham': 'Noto Sans Ogham',
            'noto-sans-ol-chiki': 'Noto Sans Ol Chiki',
            'noto-sans-old-hungarian': 'Noto Sans Old Hungarian',
            'noto-sans-old-italic': 'Noto Sans Old Italic',
            'noto-sans-old-north-arabian': 'Noto Sans Old North Arabian',
            'noto-sans-old-permic': 'Noto Sans Old Permic',
            'noto-sans-old-persian': 'Noto Sans Old Persian',
            'noto-sans-old-sogdian': 'Noto Sans Old Sogdian',
            'noto-sans-old-south-arabian': 'Noto Sans Old South Arabian',
            'noto-sans-old-turkic': 'Noto Sans Old Turkic',
            'noto-sans-oriya': 'Noto Sans Oriya',
            'noto-sans-osage': 'Noto Sans Osage',
            'noto-sans-osmanya': 'Noto Sans Osmanya',
            'noto-sans-pahawh-hmong': 'Noto Sans Pahawh Hmong',
            'noto-sans-palmyrene': 'Noto Sans Palmyrene',
            'noto-sans-pau-cin-hau': 'Noto Sans Pau Cin Hau',
            'noto-sans-phags-pa': 'Noto Sans Phags Pa',
            'noto-sans-phoenician': 'Noto Sans Phoenician',
            'noto-sans-psalter-pahlavi': 'Noto Sans Psalter Pahlavi',
            'noto-sans-rejang': 'Noto Sans Rejang',
            'noto-sans-runic': 'Noto Sans Runic',
            'noto-sans-samaritan': 'Noto Sans Samaritan',
            'noto-sans-saurashtra': 'Noto Sans Saurashtra',
            'noto-sans-sc': 'Noto Sans SC',
            'noto-sans-sharada': 'Noto Sans Sharada',
            'noto-sans-shavian': 'Noto Sans Shavian',
            'noto-sans-siddham': 'Noto Sans Siddham',
            'noto-sans-signwriting': 'Noto Sans SignWriting',
            'noto-sans-sinhala': 'Noto Sans Sinhala',
            'noto-sans-sogdian': 'Noto Sans Sogdian',
            'noto-sans-sora-sompeng': 'Noto Sans Sora Sompeng',
            'noto-sans-soyombo': 'Noto Sans Soyombo',
            'noto-sans-sundanese': 'Noto Sans Sundanese',
            'noto-sans-syloti-nagri': 'Noto Sans Syloti Nagri',
            'noto-sans-symbols': 'Noto Sans Symbols',
            'noto-sans-symbols-2': 'Noto Sans Symbols 2',
            'noto-sans-syriac': 'Noto Sans Syriac',
            'noto-sans-syriac-eastern': 'Noto Sans Syriac Eastern',
            'noto-sans-tagalog': 'Noto Sans Tagalog',
            'noto-sans-tagbanwa': 'Noto Sans Tagbanwa',
            'noto-sans-tai-le': 'Noto Sans Tai Le',
            'noto-sans-tai-tham': 'Noto Sans Tai Tham',
            'noto-sans-tai-viet': 'Noto Sans Tai Viet',
            'noto-sans-takri': 'Noto Sans Takri',
            'noto-sans-tamil': 'Noto Sans Tamil',
            'noto-sans-tamil-supplement': 'Noto Sans Tamil Supplement',
            'noto-sans-tangsa': 'Noto Sans Tangsa',
            'noto-sans-tc': 'Noto Sans TC',
            'noto-sans-telugu': 'Noto Sans Telugu',
            'noto-sans-thaana': 'Noto Sans Thaana',
            'noto-sans-thai': 'Noto Sans Thai',
            'noto-sans-thai-looped': 'Noto Sans Thai Looped',
            'noto-sans-tifinagh': 'Noto Sans Tifinagh',
            'noto-sans-tirhuta': 'Noto Sans Tirhuta',
            'noto-sans-ugaritic': 'Noto Sans Ugaritic',
            'noto-sans-vai': 'Noto Sans Vai',
            'noto-sans-vithkuqi': 'Noto Sans Vithkuqi',
            'noto-sans-wancho': 'Noto Sans Wancho',
            'noto-sans-warang-citi': 'Noto Sans Warang Citi',
            'noto-sans-yi': 'Noto Sans Yi',
            'noto-sans-zanabazar-square': 'Noto Sans Zanabazar Square',
            'noto-serif': 'Noto Serif',
            'noto-serif-ahom': 'Noto Serif Ahom',
            'noto-serif-armenian': 'Noto Serif Armenian',
            'noto-serif-balinese': 'Noto Serif Balinese',
            'noto-serif-bengali': 'Noto Serif Bengali',
            'noto-serif-devanagari': 'Noto Serif Devanagari',
            'noto-serif-display': 'Noto Serif Display',
            'noto-serif-dogra': 'Noto Serif Dogra',
            'noto-serif-ethiopic': 'Noto Serif Ethiopic',
            'noto-serif-georgian': 'Noto Serif Georgian',
            'noto-serif-grantha': 'Noto Serif Grantha',
            'noto-serif-gujarati': 'Noto Serif Gujarati',
            'noto-serif-gurmukhi': 'Noto Serif Gurmukhi',
            'noto-serif-hebrew': 'Noto Serif Hebrew',
            'noto-serif-hk': 'Noto Serif HK',
            'noto-serif-jp': 'Noto Serif JP',
            'noto-serif-kannada': 'Noto Serif Kannada',
            'noto-serif-khitan-small-script': 'Noto Serif Khitan Small Script',
            'noto-serif-khmer': 'Noto Serif Khmer',
            'noto-serif-khojki': 'Noto Serif Khojki',
            'noto-serif-kr': 'Noto Serif KR',
            'noto-serif-lao': 'Noto Serif Lao',
            'noto-serif-makasar': 'Noto Serif Makasar',
            'noto-serif-malayalam': 'Noto Serif Malayalam',
            'noto-serif-myanmar': 'Noto Serif Myanmar',
            'noto-serif-np-hmong': 'Noto Serif NP Hmong',
            'noto-serif-old-uyghur': 'Noto Serif Old Uyghur',
            'noto-serif-oriya': 'Noto Serif Oriya',
            'noto-serif-ottoman-siyaq': 'Noto Serif Ottoman Siyaq',
            'noto-serif-sc': 'Noto Serif SC',
            'noto-serif-sinhala': 'Noto Serif Sinhala',
            'noto-serif-tamil': 'Noto Serif Tamil',
            'noto-serif-tangut': 'Noto Serif Tangut',
            'noto-serif-tc': 'Noto Serif TC',
            'noto-serif-telugu': 'Noto Serif Telugu',
            'noto-serif-thai': 'Noto Serif Thai',
            'noto-serif-tibetan': 'Noto Serif Tibetan',
            'noto-serif-toto': 'Noto Serif Toto',
            'noto-serif-vithkuqi': 'Noto Serif Vithkuqi',
            'noto-serif-yezidi': 'Noto Serif Yezidi',
            'noto-traditional-nushu': 'Noto Traditional Nushu',
            'noto-znamenny-musical-notation': 'Noto Znamenny Musical Notation',
            'nova-cut': 'Nova Cut',
            'nova-flat': 'Nova Flat',
            'nova-mono': 'Nova Mono',
            'nova-oval': 'Nova Oval',
            'nova-round': 'Nova Round',
            'nova-script': 'Nova Script',
            'nova-slim': 'Nova Slim',
            'nova-square': 'Nova Square',
            'ntr': 'NTR',
            'numans': 'Numans',
            'nunito': 'Nunito',
            'nunito-sans': 'Nunito Sans',
            'nuosu-sil': 'Nuosu SIL',
            'odibee-sans': 'Odibee Sans',
            'odor-mean-chey': 'Odor Mean Chey',
            'offside': 'Offside',
            'oi': 'Oi',
            'ojuju': 'Ojuju',
            'old-standard-tt': 'Old Standard TT',
            'oldenburg': 'Oldenburg',
            'ole': 'Ole',
            'oleo-script': 'Oleo Script',
            'oleo-script-swash-caps': 'Oleo Script Swash Caps',
            'onest': 'Onest',
            'oooh-baby': 'Oooh Baby',
            'open-sans': 'Open Sans',
            'oranienbaum': 'Oranienbaum',
            'orbit': 'Orbit',
            'orbitron': 'Orbitron',
            'oregano': 'Oregano',
            'orelega-one': 'Orelega One',
            'orienta': 'Orienta',
            'original-surfer': 'Original Surfer',
            'oswald': 'Oswald',
            'outfit': 'Outfit',
            'over-the-rainbow': 'Over the Rainbow',
            'overlock': 'Overlock',
            'overlock-sc': 'Overlock SC',
            'overpass': 'Overpass',
            'overpass-mono': 'Overpass Mono',
            'ovo': 'Ovo',
            'oxanium': 'Oxanium',
            'oxygen': 'Oxygen',
            'oxygen-mono': 'Oxygen Mono',
            'pacifico': 'Pacifico',
            'padauk': 'Padauk',
            'padyakke-expanded-one': 'Padyakke Expanded One',
            'palanquin': 'Palanquin',
            'palanquin-dark': 'Palanquin Dark',
            'palette-mosaic': 'Palette Mosaic',
            'pangolin': 'Pangolin',
            'paprika': 'Paprika',
            'parisienne': 'Parisienne',
            'passero-one': 'Passero One',
            'passion-one': 'Passion One',
            'passions-conflict': 'Passions Conflict',
            'pathway-extreme': 'Pathway Extreme',
            'pathway-gothic-one': 'Pathway Gothic One',
            'patrick-hand': 'Patrick Hand',
            'patrick-hand-sc': 'Patrick Hand SC',
            'pattaya': 'Pattaya',
            'patua-one': 'Patua One',
            'pavanam': 'Pavanam',
            'paytone-one': 'Paytone One',
            'peddana': 'Peddana',
            'peralta': 'Peralta',
            'permanent-marker': 'Permanent Marker',
            'petemoss': 'Petemoss',
            'petit-formal-script': 'Petit Formal Script',
            'petrona': 'Petrona',
            'philosopher': 'Philosopher',
            'phudu': 'Phudu',
            'piazzolla': 'Piazzolla',
            'piedra': 'Piedra',
            'pinyon-script': 'Pinyon Script',
            'pirata-one': 'Pirata One',
            'pixelify-sans': 'Pixelify Sans',
            'plaster': 'Plaster',
            'platypi': 'Platypi',
            'play': 'Play',
            'playball': 'Playball',
            'playfair': 'Playfair',
            'playfair-display': 'Playfair Display',
            'playfair-display-sc': 'Playfair Display SC',
            'playpen-sans': 'Playpen Sans',
            'playwrite-ar': 'Playwrite AR',
            'playwrite-at': 'Playwrite AT',
            'playwrite-au-nsw': 'Playwrite AU NSW',
            'playwrite-au-qld': 'Playwrite AU QLD',
            'playwrite-au-sa': 'Playwrite AU SA',
            'playwrite-au-tas': 'Playwrite AU TAS',
            'playwrite-au-vic': 'Playwrite AU VIC',
            'playwrite-be-vlg': 'Playwrite BE VLG',
            'playwrite-be-wal': 'Playwrite BE WAL',
            'playwrite-br': 'Playwrite BR',
            'playwrite-ca': 'Playwrite CA',
            'playwrite-cl': 'Playwrite CL',
            'playwrite-co': 'Playwrite CO',
            'playwrite-cu': 'Playwrite CU',
            'playwrite-cz': 'Playwrite CZ',
            'playwrite-de-grund': 'Playwrite DE Grund',
            'playwrite-de-la': 'Playwrite DE LA',
            'playwrite-de-sas': 'Playwrite DE SAS',
            'playwrite-de-va': 'Playwrite DE VA',
            'playwrite-dk-loopet': 'Playwrite DK Loopet',
            'playwrite-dk-uloopet': 'Playwrite DK Uloopet',
            'playwrite-es': 'Playwrite ES',
            'playwrite-es-deco': 'Playwrite ES Deco',
            'playwrite-fr-moderne': 'Playwrite FR Moderne',
            'playwrite-fr-trad': 'Playwrite FR Trad',
            'playwrite-gb-j': 'Playwrite GB J',
            'playwrite-gb-s': 'Playwrite GB S',
            'playwrite-hr': 'Playwrite HR',
            'playwrite-hr-lijeva': 'Playwrite HR Lijeva',
            'playwrite-hu': 'Playwrite HU',
            'playwrite-id': 'Playwrite ID',
            'playwrite-ie': 'Playwrite IE',
            'playwrite-in': 'Playwrite IN',
            'playwrite-is': 'Playwrite IS',
            'playwrite-it-moderna': 'Playwrite IT Moderna',
            'playwrite-it-trad': 'Playwrite IT Trad',
            'playwrite-mx': 'Playwrite MX',
            'playwrite-ng-modern': 'Playwrite NG Modern',
            'playwrite-nl': 'Playwrite NL',
            'playwrite-no': 'Playwrite NO',
            'playwrite-nz': 'Playwrite NZ',
            'playwrite-pe': 'Playwrite PE',
            'playwrite-pl': 'Playwrite PL',
            'playwrite-pt': 'Playwrite PT',
            'playwrite-ro': 'Playwrite RO',
            'playwrite-sk': 'Playwrite SK',
            'playwrite-tz': 'Playwrite TZ',
            'playwrite-us-modern': 'Playwrite US Modern',
            'playwrite-us-trad': 'Playwrite US Trad',
            'playwrite-vn': 'Playwrite VN',
            'playwrite-za': 'Playwrite ZA',
            'plus-jakarta-sans': 'Plus Jakarta Sans',
            'podkova': 'Podkova',
            'poetsen-one': 'Poetsen One',
            'poiret-one': 'Poiret One',
            'poller-one': 'Poller One',
            'poltawski-nowy': 'Poltawski Nowy',
            'poly': 'Poly',
            'pompiere': 'Pompiere',
            'pontano-sans': 'Pontano Sans',
            'poor-story': 'Poor Story',
            'poppins': 'Poppins',
            'port-lligat-sans': 'Port Lligat Sans',
            'port-lligat-slab': 'Port Lligat Slab',
            'potta-one': 'Potta One',
            'pragati-narrow': 'Pragati Narrow',
            'praise': 'Praise',
            'prata': 'Prata',
            'preahvihear': 'Preahvihear',
            'press-start-2p': 'Press Start 2P',
            'pridi': 'Pridi',
            'princess-sofia': 'Princess Sofia',
            'prociono': 'Prociono',
            'prompt': 'Prompt',
            'prosto-one': 'Prosto One',
            'protest-guerrilla': 'Protest Guerrilla',
            'protest-revolution': 'Protest Revolution',
            'protest-riot': 'Protest Riot',
            'protest-strike': 'Protest Strike',
            'proza-libre': 'Proza Libre',
            'pt-mono': 'PT Mono',
            'pt-sans': 'PT Sans',
            'pt-sans-caption': 'PT Sans Caption',
            'pt-sans-narrow': 'PT Sans Narrow',
            'pt-serif': 'PT Serif',
            'pt-serif-caption': 'PT Serif Caption',
            'public-sans': 'Public Sans',
            'puppies-play': 'Puppies Play',
            'puritan': 'Puritan',
            'purple-purse': 'Purple Purse',
            'pushster': 'Pushster',
            'qahiri': 'Qahiri',
            'quando': 'Quando',
            'quantico': 'Quantico',
            'quattrocento': 'Quattrocento',
            'quattrocento-sans': 'Quattrocento Sans',
            'questrial': 'Questrial',
            'quicksand': 'Quicksand',
            'quintessential': 'Quintessential',
            'qwigley': 'Qwigley',
            'qwitcher-grypen': 'Qwitcher Grypen',
            'racing-sans-one': 'Racing Sans One',
            'radio-canada': 'Radio Canada',
            'radio-canada-big': 'Radio Canada Big',
            'radley': 'Radley',
            'rajdhani': 'Rajdhani',
            'rakkas': 'Rakkas',
            'raleway': 'Raleway',
            'raleway-dots': 'Raleway Dots',
            'ramabhadra': 'Ramabhadra',
            'ramaraja': 'Ramaraja',
            'rambla': 'Rambla',
            'rammetto-one': 'Rammetto One',
            'rampart-one': 'Rampart One',
            'ranchers': 'Ranchers',
            'rancho': 'Rancho',
            'ranga': 'Ranga',
            'rasa': 'Rasa',
            'rationale': 'Rationale',
            'ravi-prakash': 'Ravi Prakash',
            'readex-pro': 'Readex Pro',
            'recursive': 'Recursive',
            'red-hat-display': 'Red Hat Display',
            'red-hat-mono': 'Red Hat Mono',
            'red-hat-text': 'Red Hat Text',
            'red-rose': 'Red Rose',
            'redacted': 'Redacted',
            'redacted-script': 'Redacted Script',
            'reddit-mono': 'Reddit Mono',
            'reddit-sans': 'Reddit Sans',
            'reddit-sans-condensed': 'Reddit Sans Condensed',
            'redressed': 'Redressed',
            'reem-kufi': 'Reem Kufi',
            'reem-kufi-fun': 'Reem Kufi Fun',
            'reem-kufi-ink': 'Reem Kufi Ink',
            'reenie-beanie': 'Reenie Beanie',
            'reggae-one': 'Reggae One',
            'rem': 'REM',
            'rethink-sans': 'Rethink Sans',
            'revalia': 'Revalia',
            'rhodium-libre': 'Rhodium Libre',
            'ribeye': 'Ribeye',
            'ribeye-marrow': 'Ribeye Marrow',
            'righteous': 'Righteous',
            'risque': 'Risque',
            'road-rage': 'Road Rage',
            'roboto': 'Roboto',
            'roboto-condensed': 'Roboto Condensed',
            'roboto-flex': 'Roboto Flex',
            'roboto-mono': 'Roboto Mono',
            'roboto-serif': 'Roboto Serif',
            'roboto-slab': 'Roboto Slab',
            'rochester': 'Rochester',
            'rock-3d': 'Rock 3D',
            'rock-salt': 'Rock Salt',
            'rocknroll-one': 'RocknRoll One',
            'rokkitt': 'Rokkitt',
            'romanesco': 'Romanesco',
            'ropa-sans': 'Ropa Sans',
            'rosario': 'Rosario',
            'rosarivo': 'Rosarivo',
            'rouge-script': 'Rouge Script',
            'rowdies': 'Rowdies',
            'rozha-one': 'Rozha One',
            'rubik': 'Rubik',
            'rubik-80s-fade': 'Rubik 80s Fade',
            'rubik-beastly': 'Rubik Beastly',
            'rubik-broken-fax': 'Rubik Broken Fax',
            'rubik-bubbles': 'Rubik Bubbles',
            'rubik-burned': 'Rubik Burned',
            'rubik-dirt': 'Rubik Dirt',
            'rubik-distressed': 'Rubik Distressed',
            'rubik-doodle-shadow': 'Rubik Doodle Shadow',
            'rubik-doodle-triangles': 'Rubik Doodle Triangles',
            'rubik-gemstones': 'Rubik Gemstones',
            'rubik-glitch': 'Rubik Glitch',
            'rubik-glitch-pop': 'Rubik Glitch Pop',
            'rubik-iso': 'Rubik Iso',
            'rubik-lines': 'Rubik Lines',
            'rubik-maps': 'Rubik Maps',
            'rubik-marker-hatch': 'Rubik Marker Hatch',
            'rubik-maze': 'Rubik Maze',
            'rubik-microbe': 'Rubik Microbe',
            'rubik-mono-one': 'Rubik Mono One',
            'rubik-moonrocks': 'Rubik Moonrocks',
            'rubik-pixels': 'Rubik Pixels',
            'rubik-puddles': 'Rubik Puddles',
            'rubik-scribble': 'Rubik Scribble',
            'rubik-spray-paint': 'Rubik Spray Paint',
            'rubik-storm': 'Rubik Storm',
            'rubik-vinyl': 'Rubik Vinyl',
            'rubik-wet-paint': 'Rubik Wet Paint',
            'ruda': 'Ruda',
            'rufina': 'Rufina',
            'ruge-boogie': 'Ruge Boogie',
            'ruluko': 'Ruluko',
            'rum-raisin': 'Rum Raisin',
            'ruslan-display': 'Ruslan Display',
            'russo-one': 'Russo One',
            'ruthie': 'Ruthie',
            'ruwudu': 'Ruwudu',
            'rye': 'Rye',
            'sacramento': 'Sacramento',
            'sahitya': 'Sahitya',
            'sail': 'Sail',
            'saira': 'Saira',
            'saira-condensed': 'Saira Condensed',
            'saira-extra-condensed': 'Saira Extra Condensed',
            'saira-semi-condensed': 'Saira Semi Condensed',
            'saira-stencil-one': 'Saira Stencil One',
            'salsa': 'Salsa',
            'sanchez': 'Sanchez',
            'sancreek': 'Sancreek',
            'sankofa-display': 'Sankofa Display',
            'sansita': 'Sansita',
            'sansita-swashed': 'Sansita Swashed',
            'sarabun': 'Sarabun',
            'sarala': 'Sarala',
            'sarina': 'Sarina',
            'sarpanch': 'Sarpanch',
            'sassy-frass': 'Sassy Frass',
            'satisfy': 'Satisfy',
            'sawarabi-gothic': 'Sawarabi Gothic',
            'sawarabi-mincho': 'Sawarabi Mincho',
            'scada': 'Scada',
            'scheherazade-new': 'Scheherazade New',
            'schibsted-grotesk': 'Schibsted Grotesk',
            'schoolbell': 'Schoolbell',
            'scope-one': 'Scope One',
            'seaweed-script': 'Seaweed Script',
            'secular-one': 'Secular One',
            'sedan': 'Sedan',
            'sedan-sc': 'Sedan SC',
            'sedgwick-ave': 'Sedgwick Ave',
            'sedgwick-ave-display': 'Sedgwick Ave Display',
            'sen': 'Sen',
            'send-flowers': 'Send Flowers',
            'sevillana': 'Sevillana',
            'seymour-one': 'Seymour One',
            'shadows-into-light': 'Shadows Into Light',
            'shadows-into-light-two': 'Shadows Into Light Two',
            'shalimar': 'Shalimar',
            'shantell-sans': 'Shantell Sans',
            'shanti': 'Shanti',
            'share': 'Share',
            'share-tech': 'Share Tech',
            'share-tech-mono': 'Share Tech Mono',
            'shippori-antique': 'Shippori Antique',
            'shippori-antique-b1': 'Shippori Antique B1',
            'shippori-mincho': 'Shippori Mincho',
            'shippori-mincho-b1': 'Shippori Mincho B1',
            'shizuru': 'Shizuru',
            'shojumaru': 'Shojumaru',
            'short-stack': 'Short Stack',
            'shrikhand': 'Shrikhand',
            'siemreap': 'Siemreap',
            'sigmar': 'Sigmar',
            'sigmar-one': 'Sigmar One',
            'signika': 'Signika',
            'signika-negative': 'Signika Negative',
            'silkscreen': 'Silkscreen',
            'simonetta': 'Simonetta',
            'single-day': 'Single Day',
            'sintony': 'Sintony',
            'sirin-stencil': 'Sirin Stencil',
            'six-caps': 'Six Caps',
            'sixtyfour': 'Sixtyfour',
            'sixtyfour-convergence': 'Sixtyfour Convergence',
            'skranji': 'Skranji',
            'slabo-13px': 'Slabo 13px',
            'slabo-27px': 'Slabo 27px',
            'slackey': 'Slackey',
            'slackside-one': 'Slackside One',
            'smokum': 'Smokum',
            'smooch': 'Smooch',
            'smooch-sans': 'Smooch Sans',
            'smythe': 'Smythe',
            'sniglet': 'Sniglet',
            'snippet': 'Snippet',
            'snowburst-one': 'Snowburst One',
            'sofadi-one': 'Sofadi One',
            'sofia': 'Sofia',
            'sofia-sans': 'Sofia Sans',
            'sofia-sans-condensed': 'Sofia Sans Condensed',
            'sofia-sans-extra-condensed': 'Sofia Sans Extra Condensed',
            'sofia-sans-semi-condensed': 'Sofia Sans Semi Condensed',
            'solitreo': 'Solitreo',
            'solway': 'Solway',
            'sometype-mono': 'Sometype Mono',
            'song-myung': 'Song Myung',
            'sono': 'Sono',
            'sonsie-one': 'Sonsie One',
            'sora': 'Sora',
            'sorts-mill-goudy': 'Sorts Mill Goudy',
            'source-code-pro': 'Source Code Pro',
            'source-sans-3': 'Source Sans 3',
            'source-sans-pro': 'Source Sans Pro',
            'source-serif-4': 'Source Serif 4',
            'source-serif-pro': 'Source Serif Pro',
            'space-grotesk': 'Space Grotesk',
            'space-mono': 'Space Mono',
            'special-elite': 'Special Elite',
            'spectral': 'Spectral',
            'spectral-sc': 'Spectral SC',
            'spicy-rice': 'Spicy Rice',
            'spinnaker': 'Spinnaker',
            'spirax': 'Spirax',
            'splash': 'Splash',
            'spline-sans': 'Spline Sans',
            'spline-sans-mono': 'Spline Sans Mono',
            'squada-one': 'Squada One',
            'square-peg': 'Square Peg',
            'sree-krushnadevaraya': 'Sree Krushnadevaraya',
            'sriracha': 'Sriracha',
            'srisakdi': 'Srisakdi',
            'staatliches': 'Staatliches',
            'stalemate': 'Stalemate',
            'stalinist-one': 'Stalinist One',
            'stardos-stencil': 'Stardos Stencil',
            'stick': 'Stick',
            'stick-no-bills': 'Stick No Bills',
            'stint-ultra-condensed': 'Stint Ultra Condensed',
            'stint-ultra-expanded': 'Stint Ultra Expanded',
            'stix-two-text': 'STIX Two Text',
            'stoke': 'Stoke',
            'strait': 'Strait',
            'style-script': 'Style Script',
            'stylish': 'Stylish',
            'sue-ellen-francisco': 'Sue Ellen Francisco',
            'suez-one': 'Suez One',
            'sulphur-point': 'Sulphur Point',
            'sumana': 'Sumana',
            'sunflower': 'Sunflower',
            'sunshiney': 'Sunshiney',
            'supermercado-one': 'Supermercado One',
            'sura': 'Sura',
            'suranna': 'Suranna',
            'suravaram': 'Suravaram',
            'suse': 'SUSE',
            'suwannaphum': 'Suwannaphum',
            'swanky-and-moo-moo': 'Swanky and Moo Moo',
            'syncopate': 'Syncopate',
            'syne': 'Syne',
            'syne-mono': 'Syne Mono',
            'syne-tactile': 'Syne Tactile',
            'tac-one': 'Tac One',
            'tai-heritage-pro': 'Tai Heritage Pro',
            'tajawal': 'Tajawal',
            'tangerine': 'Tangerine',
            'tapestry': 'Tapestry',
            'taprom': 'Taprom',
            'tauri': 'Tauri',
            'taviraj': 'Taviraj',
            'teachers': 'Teachers',
            'teko': 'Teko',
            'tektur': 'Tektur',
            'telex': 'Telex',
            'tenali-ramakrishna': 'Tenali Ramakrishna',
            'tenor-sans': 'Tenor Sans',
            'text-me-one': 'Text Me One',
            'texturina': 'Texturina',
            'thasadith': 'Thasadith',
            'the-girl-next-door': 'The Girl Next Door',
            'the-nautigal': 'The Nautigal',
            'tienne': 'Tienne',
            'tillana': 'Tillana',
            'tilt-neon': 'Tilt Neon',
            'tilt-prism': 'Tilt Prism',
            'tilt-warp': 'Tilt Warp',
            'timmana': 'Timmana',
            'tinos': 'Tinos',
            'tiny5': 'Tiny5',
            'tiro-bangla': 'Tiro Bangla',
            'tiro-devanagari-hindi': 'Tiro Devanagari Hindi',
            'tiro-devanagari-marathi': 'Tiro Devanagari Marathi',
            'tiro-devanagari-sanskrit': 'Tiro Devanagari Sanskrit',
            'tiro-gurmukhi': 'Tiro Gurmukhi',
            'tiro-kannada': 'Tiro Kannada',
            'tiro-tamil': 'Tiro Tamil',
            'tiro-telugu': 'Tiro Telugu',
            'titan-one': 'Titan One',
            'titillium-web': 'Titillium Web',
            'tomorrow': 'Tomorrow',
            'tourney': 'Tourney',
            'trade-winds': 'Trade Winds',
            'train-one': 'Train One',
            'trirong': 'Trirong',
            'trispace': 'Trispace',
            'trocchi': 'Trocchi',
            'trochut': 'Trochut',
            'truculenta': 'Truculenta',
            'trykker': 'Trykker',
            'tsukimi-rounded': 'Tsukimi Rounded',
            'tulpen-one': 'Tulpen One',
            'turret-road': 'Turret Road',
            'twinkle-star': 'Twinkle Star',
            'ubuntu': 'Ubuntu',
            'ubuntu-condensed': 'Ubuntu Condensed',
            'ubuntu-mono': 'Ubuntu Mono',
            'ubuntu-sans': 'Ubuntu Sans',
            'ubuntu-sans-mono': 'Ubuntu Sans Mono',
            'uchen': 'Uchen',
            'ultra': 'Ultra',
            'unbounded': 'Unbounded',
            'uncial-antiqua': 'Uncial Antiqua',
            'underdog': 'Underdog',
            'unica-one': 'Unica One',
            'unifrakturcook': 'UnifrakturCook',
            'unifrakturmaguntia': 'UnifrakturMaguntia',
            'unkempt': 'Unkempt',
            'unlock': 'Unlock',
            'unna': 'Unna',
            'updock': 'Updock',
            'urbanist': 'Urbanist',
            'vampiro-one': 'Vampiro One',
            'varela': 'Varela',
            'varela-round': 'Varela Round',
            'varta': 'Varta',
            'vast-shadow': 'Vast Shadow',
            'vazirmatn': 'Vazirmatn',
            'vesper-libre': 'Vesper Libre',
            'viaoda-libre': 'Viaoda Libre',
            'vibes': 'Vibes',
            'vibur': 'Vibur',
            'victor-mono': 'Victor Mono',
            'vidaloka': 'Vidaloka',
            'viga': 'Viga',
            'vina-sans': 'Vina Sans',
            'voces': 'Voces',
            'volkhov': 'Volkhov',
            'vollkorn': 'Vollkorn',
            'vollkorn-sc': 'Vollkorn SC',
            'voltaire': 'Voltaire',
            'vt323': 'VT323',
            'vujahday-script': 'Vujahday Script',
            'waiting-for-the-sunrise': 'Waiting for the Sunrise',
            'wallpoet': 'Wallpoet',
            'walter-turncoat': 'Walter Turncoat',
            'warnes': 'Warnes',
            'water-brush': 'Water Brush',
            'waterfall': 'Waterfall',
            'wavefont': 'Wavefont',
            'wellfleet': 'Wellfleet',
            'wendy-one': 'Wendy One',
            'whisper': 'Whisper',
            'windsong': 'WindSong',
            'wire-one': 'Wire One',
            'wittgenstein': 'Wittgenstein',
            'wix-madefor-display': 'Wix Madefor Display',
            'wix-madefor-text': 'Wix Madefor Text',
            'work-sans': 'Work Sans',
            'workbench': 'Workbench',
            'xanh-mono': 'Xanh Mono',
            'yaldevi': 'Yaldevi',
            'yanone-kaffeesatz': 'Yanone Kaffeesatz',
            'yantramanav': 'Yantramanav',
            'yarndings-12': 'Yarndings 12',
            'yarndings-12-charted': 'Yarndings 12 Charted',
            'yarndings-20': 'Yarndings 20',
            'yarndings-20-charted': 'Yarndings 20 Charted',
            'yatra-one': 'Yatra One',
            'yellowtail': 'Yellowtail',
            'yeon-sung': 'Yeon Sung',
            'yeseva-one': 'Yeseva One',
            'yesteryear': 'Yesteryear',
            'yomogi': 'Yomogi',
            'young-serif': 'Young Serif',
            'yrsa': 'Yrsa',
            'ysabeau': 'Ysabeau',
            'ysabeau-infant': 'Ysabeau Infant',
            'ysabeau-office': 'Ysabeau Office',
            'ysabeau-sc': 'Ysabeau SC',
            'yuji-boku': 'Yuji Boku',
            'yuji-hentaigana-akari': 'Yuji Hentaigana Akari',
            'yuji-hentaigana-akebono': 'Yuji Hentaigana Akebono',
            'yuji-mai': 'Yuji Mai',
            'yuji-syuku': 'Yuji Syuku',
            'yusei-magic': 'Yusei Magic',
            'zain': 'Zain',
            'zcool-kuaile': 'ZCOOL KuaiLe',
            'zcool-qingke-huangyou': 'ZCOOL QingKe HuangYou',
            'zcool-xiaowei': 'ZCOOL XiaoWei',
            'zen-antique': 'Zen Antique',
            'zen-antique-soft': 'Zen Antique Soft',
            'zen-dots': 'Zen Dots',
            'zen-kaku-gothic-antique': 'Zen Kaku Gothic Antique',
            'zen-kaku-gothic-new': 'Zen Kaku Gothic New',
            'zen-kurenaido': 'Zen Kurenaido',
            'zen-loop': 'Zen Loop',
            'zen-maru-gothic': 'Zen Maru Gothic',
            'zen-old-mincho': 'Zen Old Mincho',
            'zen-tokyo-zoo': 'Zen Tokyo Zoo',
            'zeyada': 'Zeyada',
            'zhi-mang-xing': 'Zhi Mang Xing',
            'zilla-slab': 'Zilla Slab',
            'zilla-slab-highlight': 'Zilla Slab Highlight',
            // </editor-fold>
        };
    }

    public static async GetFontWeights(familyName: string): Promise<number[]> {
        const url = `https://api.fontsource.org/v1/fonts?family=${familyName}`;

        let weights = [400];

        try {
            const response = await fetch(url);
            if (response.ok) {
                const [{ weights: selectedWeights }] = await response.json();
                weights = selectedWeights;
            }
        } catch (error: any) {
            console.error(error.message);
        }

        return weights;
    }
}
