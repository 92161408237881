<template>
    <div id="community-management-page" @click="selectRow(undefined)">
        <main class="margin-centered">
            <table>
                <tr>
                    <th>{{$t('FIELDS.NAME')}}</th>
                    <th>{{$t('FIELDS.SLUG')}}</th>
                    <th>{{$t('FIELDS.UNIQUE_ID')}}</th>
                    <th>
                        <AbbiButton @click="openAddCommunityModal">{{ $t('ACTION.COMMUNITY.ADD') }}</AbbiButton>
                    </th>
                </tr>
                <tr v-for="community in communities" :key="community.uuid" :id="community.uuid"
                    class="selectable-row"
                    :class="{active: community === selectedCommunity}"
                    @click.stop="selectRow(community)">
                    <td>{{community.name}}</td>
                    <td>{{community.slug}}</td>
                    <td>{{community.uuid}}</td>
                    <td>
                        <AbbiButton is-red @click.stop="promptRemove(community.uuid)"><FaIcon icon="times" /></AbbiButton>
                    </td>
                </tr>
            </table>
            <EditCommunity v-if="selectedCommunity !== null" :community="selectedCommunity" class="right-side"
                           @close="selectRow(undefined)" @edit-made="processChangedCommunity" />
        </main>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import CommunityModel from '@/models/community.model';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import CommunityApi from '@/apis/community.api';
import AddCommunityModal from '@/components/modals/AddCommunityModal.vue';
import EditCommunity from '@/components/community/EditCommunity.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

export default Vue.extend({
    name: 'CommunityManagement',
    components: {EditCommunity, AbbiButton},
    data() {
        return {
            communities: [] as CommunityModel[],
            currentRow: null as HTMLElement | null,
            selectedCommunity: null as CommunityModel | null,
        };
    },
    methods: {
        openAddCommunityModal() {
            this.$store.commit('setModal', {
                title: this.$t('ACTION.COMMUNITY.ADD'),
                component: AddCommunityModal,
            });
        },
        processAddedCommunity($event: CustomEvent) {
            this.$store.commit('addCommunity', $event.detail as CommunityModel);
            this.communities.push($event.detail as CommunityModel);
        },
        processChangedCommunity(communityData: CommunityModel) {
            const idx = this.communities.findIndex((community) => community.uuid === communityData.uuid);
            this.communities[idx] = communityData;
            this.selectRow(undefined);
        },
        selectRow(communityObject: CommunityModel | undefined) {
            if (communityObject !== undefined) {
                this.currentRow = document.getElementById(communityObject.uuid as string);
                this.selectedCommunity = communityObject as CommunityModel;
            } else {
                this.currentRow = null;
                this.selectedCommunity = null;
            }
        },
        promptRemove(uuid: string) {
            CommunityApi.getDeleteCommunityCSRF(uuid)
                .then((csrfResponseData) => {
                    if (csrfResponseData !== undefined) {
                        const csrf = csrfResponseData.deleteToken as string;
                        this.$store.commit('setModal', {
                            title: this.$t('ACTION.CONFIRMATION'),
                            component: ConfirmationModal,
                            options: {
                                message: this.$t('ACTION.COMMUNITY.REMOVE_MESSAGE'),
                                actions: {
                                    confirm: {
                                        message: this.$t('ACTION.YES'),
                                        action: this.removeCommunity,
                                        argument: {uuid, csrf},
                                    },
                                    cancel: {
                                        message: this.$t('ACTION.NO'),
                                    },
                                },
                            },
                        });
                    }
                });
        },
        removeCommunity({uuid = '' as string, csrf = '' as string}) {
            CommunityApi.deleteCommunity(uuid, csrf)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        this.communities = this.communities.filter((community) => community.uuid !== uuid);
                    }
                });
        },
    },
    created() {
        document.addEventListener('addCommunity', this.processAddedCommunity as EventListener);
        // get all communities
        CommunityApi.getCommunities()
            .then((responseData) => {
                if (responseData !== undefined) {
                    this.communities = responseData;
                }
            });
    },
    destroyed() {
        document.removeEventListener('addCommunity', this.processAddedCommunity as EventListener);
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';

    #community-management-page {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        overflow: auto!important;

        main {
            padding: 1em;

            .community-action-button {
                width: min-content;
                margin-left: auto;
            }

            table {
                border-collapse: collapse;

                tr {
                    &.selectable-row {
                        cursor: pointer;

                        &:hover:not(.active) {
                            background-color: lighten($abbi-green, 20%);
                        }
                    }

                    > * {
                        padding: 0.25em;
                    }

                    &.active {
                        background-color: $abbi-blue;
                        color: white;

                        cursor: default;
                    }
                }
            }

            .right-side {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
</style>
