<template>
    <div class="abbi-text-input" :class="{small: isSmall, disabled, readonly}" :style="style">
        <div class="input-container">
            <div class="left">
            </div>
            <div class="middle">
                <label :for="name" class="label" v-if="placeholder || max || min">
                    <span v-if="placeholder">{{ placeholder }}</span>
                    <span class="ml-1" v-if="min && isActive">{{ min }} / {{ currentValue !== undefined ? currentValue.length : 0 }}</span>
                    <span class="ml-1" v-if="max && isActive">{{ (currentValue !== undefined ? currentValue : '').length }} / {{ max }}</span>
                </label>
            </div>
            <div class="right">
            </div>
        </div>
        <ejs-richtexteditor
            v-if="isRtf"
            :ref='name'
            v-model="currentValue"
            :pasteCleanupSettings="editor.pasteCleanupSettings"
            :name='name'
            :cssClass="editor.cssClass"
            :height="42"
            :width="800"
            :toolbarSettings="editor.toolbarData"
            :inlineMode="editor.inlineModeData"
            :placeholder="$t('FIELDS.ANSWER')"
        />
        <!--suppress HtmlFormInputWithoutLabel -->
        <input v-else :disabled="disabled" :readonly="readonly" autocomplete="off" ref="input" :type="isPassword ? 'password' : 'text'" :name="name"
               :id="name" v-model="currentValue" :max="max" :autofocus="hasAutofocus"
               @input="handleInput" @change="$emit('change', $event.target.value)"
               @keyup="$emit('keyup', $event)"
               @keypress="$emit('keypress', $event)" @focus="active" @blur="inactive" />
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import {
    RichTextEditorPlugin, Toolbar, Link, Image, HtmlEditor, QuickToolbar, PasteCleanup,
} from '@syncfusion/ej2-vue-richtexteditor';

vue.use(RichTextEditorPlugin);

export default vue.extend({
    name: 'AbbiTextInput',
    props: {
        placeholder: String,
        name: String,
        isSmall: Boolean,
        isRtf: Boolean,
        isPassword: Boolean,
        disabled: Boolean,
        value: String,
        min: String,
        max: String,
        readonly: Boolean,
        hasAutofocus: Boolean,
    },
    methods: {
        active() {
            this.$el.classList.add('active');
            this.isActive = true;
        },
        inactive() {
            if (this.currentValue === undefined || this.currentValue === '') {
                this.$el.classList.remove('active');
                this.isActive = false;
            }
        },
        activate() {
            if (this.currentValue !== '' && this.currentValue !== undefined) {
                this.$el.classList.add('active');
                this.isActive = true;
            } else {
                this.$el.classList.remove('active');
                this.isActive = false;

                this.color = undefined;
                if (this.max && (this.currentValue?.length || 0) > Number(this.max)) {
                    this.color = 'red';
                }
                if (this.min && (this.currentValue?.length || 0) < Number(this.min)) {
                    this.color = 'red';
                }
            }
        },
        handleInput($event: KeyboardEvent) {
            this.$emit('input', ($event.target as HTMLInputElement).value);

            this.color = undefined;

            if (!this.currentValue) {
                return;
            }

            if (this.max && this.currentValue.length > Number(this.max)) {
                this.color = 'red';
            }
            if (this.min && this.currentValue.length < Number(this.min)) {
                this.color = 'red';
            }
        },
    },
    computed: {
        val() {
            return this.value;
        },
        style(): object {
            return {
                '--abbi-text-input-border-color': this.color,
            };
        },
    },
    watch: {
        val(newValue: string) {
            this.currentValue = newValue;
            this.activate();
        },
    },
    data() {
        return {
            currentValue: this.value || undefined,
            color: undefined as undefined | string,
            isActive: false,
            editor: {
                toolbarData: {
                    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'SourceCode'],
                },
                inlineModeData: {
                    enable: true,
                    onSelection: true,
                },
                cssClass: 'choiceEditor',
                pasteCleanupSettings: {
                    prompt: false,
                    plainText: true,
                    keepFormat: false,
                },
            },
        };
    },
    provide: {
        richtexteditor: [Toolbar, Link, Image, HtmlEditor, QuickToolbar, PasteCleanup],
    },
    mounted() {
        this.activate();
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';
@import 'src/styles/typography';

$border-color: var(--abbi-text-input-border-color, $abbi-green);
$border-width-inactive: 1px;
$border-width-active: 2px;
$border-radius: 6px;

.choiceEditor  {
    border-radius: $border-radius !important;
    border: 0 !important;
    resize: none;
    white-space: nowrap;
    overflow: hidden;
    .e-rte-content{
        border-radius: $border-radius !important;
        font-family: $abbi-default-font;
        overflow: hidden !important;
        @media screen and (min-width: 992px){
            p {
                font-size: 16px !important;
            }
        }
    }
    .e-content {
        padding: 12px !important;
    }
}

.abbi-text-input {
    --abbi-color-red: $abbi-red;

    position: relative;

    // margin-top: 0.5em;

    border: $border-width-inactive solid grey;
    border-radius: $border-radius;

    background-color: white;

    &.active {
        background-color: transparent;
        border-color: transparent;
    }

    input {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        border-radius: $border-radius;
        padding: 1em;
        position: relative;
        z-index: 1;

        &:focus {
            outline: none;
        }
    }

    .input-container {
        display: flex;
        position: absolute;
        top: -$border-width-inactive;
        left: -$border-width-inactive;
        right: -$border-width-inactive;
        bottom: -$border-width-inactive;

        .left {
            width: 1em;
        }

        .middle {
            display: flex;
            align-items: center;

            .label {
                padding: 0 0.25em;
                font-size: 1.25em;
                color: grey;
                transform: translateY(0px);
                transition: transform 0.2s ease-out, color 0.1s ease-out, font-size 0.2s ease-out;
            }
        }

        .right {
            flex: 1;
        }
    }

    &.active .input-container {
        > div {
            background-color: white;

            > label {
                background-color: white;
            }
        }
    }

    &.active .input-container {
        .left {
            border-left: $border-width-active solid var(--active-border-color, $border-color);
            border-top: $border-width-active solid var(--active-border-color, $border-color);
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        .middle {
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            .label {
                font-size: 1em;
                color: var(--active-border-color, $border-color);
                transform: translateY(calc(-50% - 1em));
                transition: transform 0.1s ease-in, color 0.1s ease-in, font-size 0.1s ease-out;
            }
        }

        .right {
            flex: 1;
            border-right: $border-width-active solid var(--active-border-color, $border-color);
            border-top: $border-width-active solid var(--active-border-color, $border-color);
            border-bottom: $border-width-active solid var(--active-border-color, $border-color);

            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    &.small {
        .middle {
            font-size: 0.75em;
        }

        input {
            padding: 0.5em;
        }
    }

    &.disabled, &.readonly {
        background-color: lightgrey;

        .label, input {
            color: darkgrey !important;
        }

        .input-container {
            --active-border-color: lightgrey;
        }
    }
}
</style>
