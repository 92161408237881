<template>
    <div class="browser-preview">
        <button v-if="forcedScale || scale < 100" type="button" class="scale" @click.prevent="forcedScale = !forcedScale">
            <FaIcon icon="binoculars" />
            {{ scale }}%
        </button>

        <div class="device" :class="[device.type]" :style="deviceStyle">
            <div class="operating-system" :class="{hasTaskbar: device && device.os.taskbar}" :style="osStyle">
                <div class="browser"
                     :style="browserStyle"
                     :class="{fullscreen: device && device.browser.fullscreen}">
                    <div v-if="device && !device.browser.fullscreen">
                        <div class="window-bar">
                            <div class="window-tab">
                                <FaIcon :icon="icon" />
                                <span>{{ windowTitle }}</span>
                            </div>
                            <div class="separator"></div>
                            <div class="window-buttons">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div class="address-bar">
                            <span class="address">
                                <span class="scheme">{{addressBarUrl.protocol}}//</span>
                                <span class="credentials" v-if="addressBarUrl.username">
                                    <span class="username">{{addressBarUrl.username}}</span>
                                    <span class="password" v-if="addressBarUrl.password">:{{addressBarUrl.password}}</span>
                                    <span class="credentials-marker">@</span>
                                </span>
                                <span class="host">{{addressBarUrl.host}}</span>
                                <span class="path">{{addressBarUrl.pathname}}</span>
                                <span class="query">{{addressBarUrl.search}}</span>
                                <span class="hash">{{addressBarUrl.hash}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="viewport">
                        <iframe ref="viewport" :src="target" />
                    </div>
                </div>
                <div class="taskbar" v-if="device && device.os.taskbar && !device.browser.fullscreen">
                    <span class="menu-button">
                        <FaIcon icon="power-off" />
                    </span>
                    <span class="application" :title="windowTitle">
                        <FaIcon :icon="icon" />
                        <span>{{ windowTitle }}</span>
                    </span>
                    <span class="separator"></span>
                    <span class="clock">{{ clockTime }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import vue, {PropType} from 'vue';

export enum DeviceType {
    Mobile = 'mobile',
    Desktop = 'desktop',
}

export interface Viewport {
    width: number;
    height: number;
}

export interface BoundedViewport extends Viewport {
    minWidth: number;
    maxWidth: number;
    minHeight: number;
    maxHeight: number;
}

export interface Coordinates {
    x: number;
    y: number;
}

export interface Browser {
    fullscreen: boolean;
    offset: Coordinates;
}

export interface OperatingSystem {
    taskbar: boolean;
}

export interface Device {
    type: DeviceType;
    label: string;
    resolution: BoundedViewport;
    browser: Browser;
    os: OperatingSystem;
}

const taskbarHeight = 50;

export interface Message {
    type: string;
    payload: object;
    content: object;
}

export interface Listener {
    (message: Message): void;
}

export class Messenger {
    listeners: Listener[] = [];

    addListener(listener: Listener): void {
        this.listeners.push(listener);
    }

    removeListener(listener: Listener): void {
        this.listeners = this.listeners.filter(
            (current: Listener) => current !== listener,
        );
    }

    dispatch(message: Message): void {
        this.listeners.forEach(
            (listener: Listener) => listener(message),
        );
    }
}

export default vue.extend(
    {
        props: {
            addressBarUrl: {
                type: URL,
                required: true,
            },
            icon: {
                type: String,
                default: 'comment-dots',
            },
            target: {
                type: URL,
                required: true,
            },
            windowTitle: {
                type: String,
                required: true,
            },
            forcedScale: {
                type: Boolean,
                default: false,
            },
            device: {
                type: Object as PropType<Device>,
                required: true,
            },
            messenger: {
                type: Object as PropType<Messenger>,
                required: true,
            },
        },
        data() {
            return {
                time: new Date(),
                clockIntervalId: null as null | number,
                preview: {
                    width: 0,
                    height: 0,
                } as Viewport,
                resizeTimerId: null as null | number,
            };
        },
        mounted() {
            const viewport = this.$refs.viewport as HTMLIFrameElement;
            viewport.addEventListener('load', this.onReady);

            this.preview.width = this.$el.clientWidth;
            this.preview.height = this.$el.clientHeight;

            // Ensure the clock is updated.
            // The resolution of the clock is 1 minute.
            this.clockIntervalId = window.setInterval(
                () => {
                    this.time = new Date();
                },
                // Update 10x per minute.
                (60 * 1000) / 10,
            );

            window.addEventListener('resize', this.updatePreviewDimensions);
            this.messenger.addListener(this.dispatchMessage);
        },
        beforeUpdate() {
            const viewport = this.$refs.viewport as HTMLIFrameElement;
            viewport.addEventListener('load', this.onReady);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updatePreviewDimensions);
            this.messenger.removeListener(this.dispatchMessage);

            if (this.clockIntervalId) {
                window.clearInterval(this.clockIntervalId);
            }
        },
        methods: {
            onReady(): void {
                const viewport = this.$refs.viewport as HTMLIFrameElement;
                viewport.removeEventListener('load', this.onReady);
                this.$emit('ready');
            },
            dispatchMessage(message: Message): void {
                const viewport = this.$refs.viewport as HTMLIFrameElement;

                if (viewport && viewport.contentWindow) {
                    viewport.contentWindow.postMessage(
                        message,
                        this.target.origin,
                    );
                }
            },
            updatePreviewDimensions(): void {
                if (this.resizeTimerId) {
                    window.clearTimeout(this.resizeTimerId);
                    this.resizeTimerId = null;
                }

                this.resizeTimerId = window.setTimeout(
                    () => {
                        this.preview.width = this.$el.clientWidth;
                        this.preview.height = this.$el.clientHeight;
                    },
                    100,
                );
            },
        },
        computed: {
            clockTime(): string {
                return [this.time.getHours(), this.time.getMinutes()]
                    .map((component: number) => (`00${component}`.slice(-2)))
                    .join(':');
            },
            scale(): number {
                if (this.forcedScale) {
                    return 100;
                }

                const {viewport} = this;

                const widthScale = (
                    this.preview.width / viewport.width
                ) * 100;
                const heightScale = (
                    this.preview.height / viewport.height
                ) * 100;

                return Math.floor(
                    Math.min(
                        widthScale,
                        heightScale,
                        100,
                    ),
                );
            },
            offset(): Coordinates {
                const {offset, fullscreen} = this.device.browser;
                const {taskbar} = this.device.os;
                const adjustedOffset = {
                    x: offset.x,
                    y: offset.y,
                } as Coordinates;

                if (!fullscreen && taskbar) {
                    adjustedOffset.y += taskbarHeight / 2;
                }

                return adjustedOffset;
            },
            viewport(): Viewport {
                const {max} = Math;
                const {resolution} = this.device;
                const viewport = {
                    width: max(
                        resolution.width || this.preview.width,
                        resolution.minWidth ?? 0,
                    ),
                    height: max(
                        resolution.height || this.preview.height,
                        resolution.minHeight ?? 0,
                    ),
                } as Viewport;

                if (resolution.maxWidth && viewport.width > resolution.maxWidth) {
                    viewport.width = resolution.maxWidth;
                }

                if (resolution.maxHeight && viewport.height > resolution.maxHeight) {
                    viewport.height = resolution.maxHeight;
                }

                return viewport;
            },
            deviceStyle(): object {
                return {
                    '--scale': `${this.scale}%`,
                };
            },
            osStyle(): object {
                const {fullscreen} = this.device.browser;
                const {offset} = this;
                const {taskbar} = this.device.os;
                const taskbarOffset = taskbar ? taskbarHeight / 2 : 0;

                let padding = '0px';

                if (!fullscreen && (offset.x || offset.y)) {
                    padding = `${offset.y - (taskbarOffset)}px ${offset.x}px ${offset.y + (taskbarOffset)}px`;
                }

                return {padding};
            },
            browserStyle(): object {
                const {viewport} = this;
                const {fullscreen} = this.device.browser;
                const {offset} = this;
                const width = fullscreen
                    ? viewport.width
                    : viewport.width - (offset.x * 2);
                const height = fullscreen
                    ? viewport.height
                    : viewport.height - (offset.y * 2);

                return {
                    width: `${width}px`,
                    height: `${height}px`,
                };
            },
        },
    },
);
</script>

<style lang="scss" scoped>
@import 'src/styles/colors';

.browser-preview {
    --padding: 1em;

    background: lightgrey;
    height: 100%;
    box-shadow: inset gray 0 0 5px 0;
    padding: var(--padding);
    position: relative;
    overflow: hidden;

    .scale {
        background: hsl(0, 0, 90%);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        margin: var(--padding);
        padding: .5em;
        border-radius: .5em;
        box-shadow: gray 0 0 5px 0;
        outline: 1px solid hsl(0, 0, 40%);
        border: none;
        line-height: 1em;
        display: flex;
        gap: .5em;
    }

    .device {
        --scale: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%) scale(var(--scale));
        background: grey;
        border-radius: .5em;
        padding: 8px 5px 15px;

        .operating-system {
            --taskbarHeight: 0px;
            height: 100%;
            position: relative;
            background: radial-gradient(white 30%, $abbi-green 90%);

            &.hasTaskbar {
                --taskbarHeight: 50px;
            }

            .taskbar {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: var(--taskbarHeight);
                line-height: var(--taskbarHeight);
                background: lightgrey;
                display: flex;
                gap: 1em;
                padding: 0 .5em;
                border-top: 1px solid hsla(0, 0, 50%, 20%);
                pointer-events: none;
                user-select: none;

                .separator {
                    flex: 1;
                }

                .menu-button {
                    background-color: hsla(0deg, 0, 100%, .25);
                    padding: 0 1em;
                }

                .application {
                    background-color: hsla(0deg, 0, 100%, .65);
                    border: 1px solid hsla(0deg, 0, 100%, .45);
                    border-top: 0;
                    border-bottom: 0;
                    padding: 0 1em;
                    display: flex;
                    gap: .5em;
                    pointer-events: initial;

                    svg {
                        margin-top: calc((var(--taskbarHeight) / 2) - .5em);
                    }

                    span {
                        display: inline-block;
                        max-width: 8em;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .clock {
                    border: 2px inset lightgrey;
                    padding: .5em;
                    height: 2em;
                    line-height: .75em;
                    margin-top: .6em;
                }
            }

            .browser {
                --windowBarHeight: 1.6em;
                --addressBarHeight: 2.4em;
                background: lightgrey;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                box-shadow: hsla(0, 0, 30%, 40%) 0 0 10px 0;

                .window-bar {
                    height: var(--windowBarHeight);
                    padding: 2px .5em;
                    display: flex;
                    gap: 1em;
                    background: dimgrey;

                    .window-tab {
                        background: lightgrey;
                        height: 1.8em;
                        padding: .1em .5em;
                        border-radius: .2em .2em 0 0;
                        color: hsl(0deg, 0, 25%);
                        display: flex;
                        gap: .5em;

                        svg {
                            margin-top: .15em;
                        }
                    }

                    .separator {
                        flex: 1;
                    }

                    .window-buttons {
                        display: flex;
                        gap: 1em;
                        padding: .2em .5em;

                        div {
                            display: inline-block;
                            width: 1em;
                            height: 1em;
                            border-radius: 50%;
                            background: lightgrey;

                            &:last-child {
                                background: darkgrey;
                            }
                        }
                    }
                }

                .address-bar {
                    height: var(--addressBarHeight);
                    padding: 2px 2px 4px;
                    display: flex;

                    .address {
                        margin: .2em .5em;
                        flex: 1;
                        background: white;
                        border-radius: .2em;
                        height: 1.8em;
                        padding: .1em .5em;
                        color: grey;

                        .host {
                            color: black;
                        }
                    }
                }

                &:not(.fullscreen) {
                    .viewport {
                        border-radius: .5em .5em 0 0;
                        width: calc(100% - 2px);
                        margin: 0 1px 1px;
                        height: calc(
                            100%
                            - var(--windowBarHeight)
                            - var(--addressBarHeight)
                            - var(--taskbarHeight)
                        );
                    }
                }

                .viewport {
                    flex: 1;
                    background: white;
                    position: relative;

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
