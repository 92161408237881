<template>
    <div class="dropdown" :class="{ unfolded: open }" @keyup.enter="closeDropdown" v-on-clickaway="closeDropdown">
        <button class="dropdown-toggler" @click.stop="open = !open">{{ text }}</button>

        <div class="dropdown-window" :class="align">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {mixin as clickaway} from 'vue-clickaway';

export default Vue.extend({
    mixins: [clickaway],
    name: 'Dropdown',
    props: ['text', 'align'],
    data() {
        return {
            open: false,
        };
    },
    methods: {
        closeDropdown() {
            this.open = false;
        },
    },
});
</script>

<style lang="scss">
.dropdown {
    position: relative;

    .dropdown-toggler {
        cursor: pointer;
    }

    .dropdown-window {
        &.right {
            right: 0;
        }

        position: absolute;
        visibility: collapse;

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5), -2px 2px 5px 1px rgba(0, 0, 0, 0.5);
    }
}

div.dropdown.unfolded {
    button.dropdown-toggler {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .dropdown-window {
        visibility: visible;
        z-index: 1000;

        background-color: white;
    }
}
</style>
