import {createAnswer, createQuestion, Scenario} from '@/components/community/styling/chat/ChatConfigurator.vue';

const question = {
    instructions: [],
    answers: [],
    isTyping: false,
    validationError: null,
    valid: true,
    canGoBack: false,
};

const openAnswer = {
    ...question,
    instructions: [
        createQuestion(
            'open-1',
            'open',
            'Tell us a happy thought',
            'Put that frown upside down',
            {
                minimum: 20,
                maximum: 300,
                boxSize: 'multiline',
            },
        ),
    ],
} as Scenario;

const invalid = {
    ...question,
    instructions: [
        createQuestion(
            'invalid-1',
            'open',
            'Tell us a happy thought',
            'Put that frown upside down',
            {
                minimum: 20,
                maximum: 300,
            },
            false,
        ),
    ],
    validationError: 'ERROR.VALIDATION.TOO_SHORT',
    valid: false,
    canGoBack: false,
} as Scenario;

const actionsEnabled = {
    ...openAnswer,
    canGoBack: true,
} as Scenario;

const actionsDisabled = {
    ...actionsEnabled,
    instructions: [
        createQuestion(
            'disabled-1',
            'open',
            'Tell us a happy thought',
            'Put that frown upside down',
            {
                minimum: 20,
                maximum: 300,
            },
            false,
        ),
    ],
    valid: false,
} as Scenario;

const slider = {
    ...question,
    instructions: [
        createQuestion(
            'numeric-1',
            'numeric',
            'How would you rate this styling menu?',
            'Please do not be shy',
            {
                hasSlider: true,
                hasLabel: true,
                minimum: 5,
                maximum: 95,
                stepSize: 5,
                inputKey: 0,
                lowerBoundLabel: 'Very unhappy',
                upperBoundLabel: 'Very happy',
            },
        ),
    ],
} as Scenario;

const starRating = {
    ...question,
    instructions: [
        createQuestion(
            'star-rating-1',
            'starRating',
            'How happy are you with this control?',
            '',
            {
                initialValue: 4,
                increment: 1,
            },
        ),
        createQuestion(
            'star-rating-2',
            'starRating',
            'How easy was it to give a rating?',
            '',
            {
                initialValue: 4,
                increment: 1,
            },
        ),
    ],
    answers: [
        createAnswer(
            'star-rating-1',
            '4',
        ),
    ],
} as Scenario;

const choices = [
    {
        uuid: 'c2',
        fixed: false,
        single: false,
        value: 1,
        translations: {
            locale: 'en',
            content: 'Apple',
        },
    },
    {
        uuid: 'c3',
        fixed: false,
        single: false,
        value: 2,
        translations: {
            locale: 'en',
            content: 'Banana',
        },
    },
    {
        uuid: 'c4',
        fixed: false,
        single: false,
        value: 3,
        translations: {
            locale: 'en',
            content: 'Lemon',
        },
    },
];

const active = 'c2';

const choiceInstruction = createQuestion(
    'choice-1',
    'choice',
    'What is your favorite food?',
    'Please select a food',
    {
        dropdown: false,
        search: false,
        columns: 1,
        scores: false,
    },
) as {question: object};
Object.assign(choiceInstruction.question, {choices, active});

const choice = {
    ...question,
    instructions: [choiceInstruction],
} as Scenario;

const dropdownInstruction = createQuestion(
    'choice-2',
    'choice',
    'What is your favorite food?',
    'Please select a food',
    {
        dropdown: true,
        search: true,
        searchPattern: 'any',
        columns: 1,
        scores: false,
        minimum: 1,
        maximum: 3,
    },
) as {question: object};
Object.assign(dropdownInstruction.question, {choices, active});

const dropdown = {
    ...question,
    instructions: [dropdownInstruction],
} as Scenario;

export default {
    choice,
    dropdown,
    openAnswer,
    invalid,
    actionsEnabled,
    actionsDisabled,
    slider,
    starRating,
} as Record<string, Scenario>;
