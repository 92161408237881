<template>
    <div class="field" :class="componentClass">
        <Alert v-if="noMatchingShape" :type="alertType">{{noMatchingShapeLabel}}</Alert>
        <div :class="shapesClass">
            <ShapeInput
                v-for="(shape, index) in shapes"
                :key="index"
                :value="shape"
                :message="messageLabel"
                :reply="replyLabel"
                :is-current="isCurrentShape(shape)"
                :has-tail="tail && tail.length > 0"
                @input="selectShape(shape)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Field from '@/components/community/styling/form/Field.vue';
import {PropType} from 'vue';
import Shape from '@/components/community/styling/form/ShapeField/Shape';
import ShapeInput from '@/components/community/styling/Input/ShapeInput.vue';
import Alert, {AlertType} from '@/components/community/styling/Input/Alert.vue';

export default Field.extend({
    components: {ShapeInput, Alert},
    props: {
        property: {
            required: false,
        },
        border: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        reply: {
            type: String,
        },
        noMatchingShapeMessage: {
            type: String,
            default: 'no_matching_shape',
        },
        tail: {
            type: String,
        },
        padding: {
            type: String,
            required: true,
        },
        shapes: {
            type: Array as PropType<Shape[]>,
            required: true,
        },
    },
    data() {
        return {
            borderRadius: this.getValue(this.border),
            tailRadius: this.getValue(this.tail ?? this.border),
            paddingValue: this.getValue(this.padding),
        };
    },
    computed: {
        alertType(): AlertType {
            return AlertType.Danger;
        },
        noMatchingShapeLabel(): string {
            return this.getLabel(this.noMatchingShapeMessage.toUpperCase());
        },
        messageLabel(): string {
            return this.getLabel(this.message.toUpperCase());
        },
        replyLabel(): string {
            return this.reply
                ? this.getLabel(this.reply.toUpperCase())
                : '';
        },
        currentShape(): Shape {
            const {borderRadius, tailRadius, paddingValue} = this;
            return {borderRadius, tailRadius, padding: paddingValue};
        },
        noMatchingShape(): boolean {
            return !this.shapes.reduce(
                (carry: boolean, shape: Shape) => carry || this.isCurrentShape(shape),
                false,
            );
        },
        style(): {[key: string]: string} {
            const {$style} = this as unknown as {$style: {[key: string]: string}};
            return $style;
        },
        componentClass(): Array<string> {
            return [this.style.component];
        },
        shapesClass(): Array<string> {
            return [this.style.shapes];
        },
    },
    methods: {
        getLabel(key: string): string {
            const portal = (this.portal ?? '').toUpperCase();
            const property = this.alias
                ? this.alias.toUpperCase()
                : this.border.toUpperCase();

            return this.$t(
                `COMMUNITY.STYLESHEET.CONFIGURATOR.${portal}.FIELD.${property}.${key}`,
            ).toString();
        },
        isCurrentShape(shape: Shape): boolean {
            const {currentShape} = this;

            return (
                (shape.tailRadius ?? shape.borderRadius) === (currentShape.tailRadius ?? currentShape.borderRadius)
                && shape.borderRadius === currentShape.borderRadius
                && shape.padding === currentShape.padding
            );
        },
        selectShape(shape: Shape): void {
            const {borderRadius, tailRadius, padding} = shape;

            this.toggleScenario();
            this.updateProperty(this.border, borderRadius);
            this.updateProperty(this.padding, padding);
            this.borderRadius = borderRadius;
            this.paddingValue = padding;
            this.tailRadius = tailRadius ?? borderRadius;

            if (this.tail && tailRadius) {
                this.updateProperty(this.tail, tailRadius);
            }
        },
    },
});
</script>

<style lang="scss" module>
.component {
    margin-top: .5em;

    .shapes {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;

        > * {
            width: calc(50% - .5em);
        }
    }
}
</style>
