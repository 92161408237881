<template>
    <div class="invalid-feedback">
        <div v-for="error in getErrors()" :key="getKey(error)">
            {{ $t(error.message) }}
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Violation from '@/interfaces/violation.interface';

export default Vue.extend({
    name: 'ViolationList',
    props: ['field', 'violations'],
    methods: {
        getErrors(): Array<Violation> {
            return this.violations.getErrors(this.field);
        },
        getKey(error: Violation): string {
            return `${error.field}-${error.message}`;
        },
    },
});
</script>
