<template>
    <div id="community-options-page">
        <BootstrapStylesheet />
        <div class="container payment-config">
            <h2>Checkout</h2>
            <h3>Production credentials</h3>
            <div class="flex-row mt-05">
                <div class="flex-1 mr-05">
                    <AbbiTextInput
                        name="checkout-prod-username"
                        is-small
                        placeholder="username"
                        v-model="checkout.prod.username"
                    />
                    <AbbiTextInput
                        name="checkout-prod-client-id"
                        class="mt-05"
                        is-small
                        placeholder="client id"
                        v-model="checkout.prod.clientId"
                    />
                </div>
                <div class="flex-1">
                    <AbbiTextInput
                        name="checkout-prod-password"
                        is-small
                        placeholder="password"
                        v-model="checkout.prod.password"
                    />
                    <AbbiTextInput
                        name="checkout-prod-client-secret"
                        class="mt-05"
                        is-small
                        placeholder="client secret"
                        v-model="checkout.prod.clientSecret"
                    />
                </div>
            </div>
            <h3 class="mt-1">Test credentials</h3>
            <div class="flex-row mt-05">
                <div class="flex-1 mr-05">
                    <AbbiTextInput
                        name="checkout-test-username"
                        is-small
                        placeholder="username"
                        v-model="checkout.test.username"
                    />
                    <AbbiTextInput
                        name="checkout-test-client-id"
                        class="mt-05"
                        is-small
                        placeholder="client id"
                        v-model="checkout.test.clientId"
                    />
                </div>
                <div class="flex-1">
                    <AbbiTextInput
                        name="checkout-test-password"
                        is-small
                        placeholder="password"
                        v-model="checkout.test.password"
                    />
                    <AbbiTextInput
                        name="checkout-test-client-secret"
                        class="mt-05"
                        is-small
                        placeholder="client secret"
                        v-model="checkout.test.clientSecret"
                    />
                </div>
            </div>
            <AbbiButton @click="saveCheckoutCreds" class="mt-1">Save checkout credentials</AbbiButton>
        </div>
        <hr />
        <div class="container">
            <CrmConnections/>
        </div>
        <hr />
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint no-alert: 0 */
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CrmConnections from '@/views/community/options/CrmConnections.vue';
import CommunityApi from '@/apis/community.api';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import FileUpload from '@/components/input/FileUpload.vue';
import Alert from '@/components/community/styling/Input/Alert.vue';
import BootstrapStylesheet from '@/components/bootstrap/BootstrapStylesheet.vue';

export default vue.extend({
    name: 'CommunityOptions',
    components: {
        BootstrapStylesheet,
        Alert,
        FileUpload,
        AbbiButton,
        AbbiTextInput,
        CrmConnections,
    },
    data() {
        return {
            imageData: undefined as string | undefined,
            sideLogoData: undefined as string | undefined,
            file: undefined as File | undefined,
            sideMenu: [] as Record<string, string>[],
            fontCode: '' as string,
            cssFontFamRule: '' as string,
            fontFile: undefined as File | undefined,
            checkout: {
                test: {
                    username: '',
                    password: '',
                    clientId: '',
                    clientSecret: '',
                },
                prod: {
                    username: '',
                    password: '',
                    clientId: '',
                    clientSecret: '',
                },
            },
        };
    },
    methods: {
        saveCheckoutCreds() {
            const {community} = this.$store.getters;
            CommunityApi.saveOptionPair(community.uuid, 'checkout', this.checkout)
                .then((response) => {
                    if (response === 'success') {
                        alert('saved checkout credentials!');
                    }
                });
        },
        removeItem(idx: number) {
            this.$delete(this.sideMenu, idx);
        },
        updateInfo() {
            const community = this.$route.params.communitySlug;
            CommunityApi.getStyle(community)
                .then((response) => {
                    const communityStyle = response.community || {};
                    this.imageData = communityStyle.logo;
                    this.sideLogoData = communityStyle.sideLogo || communityStyle.logo;
                    this.cssFontFamRule = communityStyle['font-fam'] || '';
                    this.fontCode = communityStyle['font-url'] || '';

                    const web = response.web || {} as Record<string, string>;
                    Object.keys(web)
                        .forEach((key: string) => {
                            (this.$el as HTMLElement).style.setProperty(`--${key}`, web[key]);
                        });
                });

            CommunityApi.getSidemenu(community)
                .then((resp) => {
                    this.sideMenu = resp;
                });
        },
    },
    computed: {
        community() {
            return this.$route.params.communitySlug;
        },
    },
    watch: {
        community: {
            immediate: true,
            handler(selectedCommunity) {
                if (selectedCommunity) {
                    this.updateInfo();
                }
            },
        },
    },
});
</script>

<style lang="scss">
@import 'src/styles/utilities';
@import 'src/styles/colors';

#community-options-page {
    overflow: auto !important;
    padding: 1rem;

    hr {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    > .container {
        width: (100% / 3);
        min-width: 1024px;
        margin: 0 auto;
    }
}
</style>
