<template>
    <div class="btn-group" v-if="max > 0">
        <button :disabled="current < 1" type="button" @click.prevent="reset()" class="btn btn-primary">
            <FaIcon icon="backward-fast" />
            <span>{{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.APPLICATION.OPTION.RESET') }}</span>
        </button>
        <button :disabled="current >= max" type="button" @click.prevent="finish()" class="btn btn-primary">
            <span>{{ $t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.APPLICATION.OPTION.FINISH') }}</span>
            <FaIcon icon="forward-fast" />
        </button>
    </div>
</template>

<script lang="ts">
import Options from '@/components/community/styling/Options.vue';

export default Options.extend({
    computed: {
        current(): number {
            return this.options.current ?? 0;
        },
        max(): number {
            return this.options.max ?? this.current;
        },
    },
    methods: {
        reset(): void {
            this.options.current = 0;
        },
        finish(): void {
            this.options.current = this.max;
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/colors';

button {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: center;
}
</style>
