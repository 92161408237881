<template>
    <div id="export-dialogue">
        <div class="filter" v-if="options.filter.filtered">
            <div class="flex-row">
                <div class="date-selector">
                    <span>{{ $t('FIELDS.FROM') }}</span>
                    <DatetimePicker :value="options.from || null" />
                </div>
                <div class="vsplit"></div>
                <div class="date-selector">
                    <span>{{ $t('FIELDS.TO') }}</span>
                    <DatetimePicker :value="options.to || new Date()" />
                </div>
            </div>
        </div>
        <div class="formatting">
            <div class="flex-column">
                <div class="flex-row">
                    <Toggle
                        disabled
                        class="format-option"
                        name="only-questions"
                        v-model="format_onlyActionableInstructions"
                    >
                        Only export questions and actions
                    </Toggle>
                    <Toggle class="format-option" name="text-export" v-model="format_nonNumericExport">Non-numeric</Toggle>
                </div>
                <Toggle class="format-option" name="with-number" v-model="format_withNr">With Instruction Number</Toggle>
                <Toggle class="format-option" name="with-type" v-model="format_withType">With Type</Toggle>
                <div class="flex-row">
                    <Toggle class="format-option" name="with-message" v-model="format_withMessage">With Message</Toggle>
                    <Toggle class="format-option" name="use-short" v-model="format_useShortIfAvailable">Use Short Message if possible</Toggle>
                </div>
                <Toggle class="format-option" name="with-empty" v-model="format_withEmpty">With empty chats</Toggle>
            </div>
        </div>
        <div class="preview">
            <table>
                <tr class="header special-font no-wrap">
                    <th>{{ $t('GENERAL.UUID') }}</th>
                    <th>Status</th>
                    <th>Started at</th>
                    <th>Finished at</th>
                    <th v-for="key in metaIdxs" :key="key">{{ key }}</th>
                    <template v-for="instruction in instructionsToExport">
                        <template v-if="IsMultiple(instruction)">
                            <th v-for="(choice, idx) in instruction.question.choices" :key="`choice-${choice.uuid}`">
                                <span v-if="format_withNr || format_withType" class="number">
                                    <span v-if="format_withNr">{{ GetAppropriateNumberLabel(instruction) }}_a{{ idx + 1 }}</span>
                                    <span v-if="format_withType"> {{ GetType(instruction) }}</span>
                                </span>
                                <span v-if="(format_withNr || format_withType) & format_withMessage" class="splitter">: </span>
                                <span v-if="format_withMessage" class="message">{{ GetAppropriateMessageForChoice(choice) }}</span>
                            </th>
                        </template>
                        <template v-else>
                            <th :key="instruction.uuid">
                                <span v-if="format_withNr || format_withType" class="number">
                                    <span v-if="format_withNr">{{ GetAppropriateNumberLabel(instruction) }}</span>
                                    <span v-if="format_withType"> {{ GetType(instruction) }}</span>
                                </span>
                                <span v-if="(format_withNr || format_withType) & format_withMessage" class="splitter">: </span>
                                <span v-if="format_withMessage" class="message">{{ GetAppropriateMessage(instruction) }}</span>
                            </th>
                        </template>
                    </template>
                </tr>
                <tr v-for="conversation in data" :key="conversation.uuid">
                    <td class="no-wrap">{{ conversation.uuid }}</td>
                    <td class="no-wrap">{{ conversation.status }}</td>
                    <td class="no-wrap">{{ conversation.startedAt ? GetDateAsString(new Date(conversation.startedAt)) : '' }}</td>
                    <td class="no-wrap">{{ conversation.finishedAt ? GetDateAsString(new Date(conversation.finishedAt)) : '' }}</td>
                    <template v-for="key in metaIdxs">
                        <td class="no-wrap" :key="`${conversation.uuid}-${key}`">
                            {{ GetValueForKey(key, conversation.metadata) }}
                        </td>
                    </template>
                    <template v-for="instruction in instructionsToExport">
                        <!-- eslint-disable-next-line vue/valid-v-for -->
                        <template v-if="IsMultiple(instruction)">
                            <td v-for="(answer, idx) in getAnswerPossibilitiesForMultiple(instruction, conversation.answers)" :key="`choice-${idx}`">
                                {{ answer }}
                            </td>
                        </template>
                        <template v-else>
                            <td :key="GetAnswerForInstruction(instruction, conversation.answers, conversation.actions)[0]">
                                {{ GetAnswerForInstruction(instruction, conversation.answers, conversation.actions)[1] }}
                            </td>
                        </template>

                    </template>
                </tr>
            </table>
        </div>
        <div class="progress-bar mx-1 mb-1" :style="{'--progress': `${(exportData.length / rowCount) * 100}%`}">
            <div><span>{{ exportData.length }}</span> of <span> {{ rowCount }} records</span></div>
        </div>
        <div class="flex-row px-05 pb-05">
            <AbbiButton class="flex-3 mr-05" grow @click="performExport">{{ $t('ACTION.EXPORT._self') }}</AbbiButton>
            <AbbiButton class="flex-1" grow is-blue @click="exportSpssSyntax">{{ $t('ACTION.EXPORT._self') }} syntax</AbbiButton>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import vue from 'vue';
import ExportApi, {ExportOptions} from '@/apis/export.api';
import AbbiButton from '@/components/input/AbbiButton.vue';
import DatetimePicker from '@/components/input/DatetimePicker.vue';
import ScriptModel from '@/models/script.model';
import InstructionModel, {InstructionModelExt} from '@/models/instruction.model';
import ConversationModel from '@/models/conversation.model';
import ChoiceModel, {ChoiceModelExt} from '@/models/choice.model';
import Toggle from '@/components/input/Toggle.vue';
import {GetDateAsString, padInteger} from '@/helpers/utilities';
import striptags from 'striptags';

export default vue.extend({
    name: 'ExportModal',
    components: {
        AbbiButton, DatetimePicker, Toggle,
    },
    props: {
        options: {
            type: Object,
            default() {
                return {
                    rows: Number,
                    script: [Object, ScriptModel],
                    filter: ExportOptions,
                    format: {
                        type: Object,
                        default() {
                            return {};
                        },
                    },
                };
            },
        },
    },
    data() {
        return {
            communitySlug: '' as string,
            scriptUuid: '' as string,
            data: [] as ConversationModel[],
            exportData: [] as ConversationModel[],
            metaIdxs: [] as string[],
            exportableTable: [] as string[][],

            rowCount: 0,

            /* eslint-disable @typescript-eslint/naming-convention */
            format_withNr: true,
            format_withMessage: true,
            format_useShortIfAvailable: true,

            format_withType: false,

            format_onlyActionableInstructions: true,
            format_nonNumericExport: false,

            format_withEmpty: false,
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },
    computed: {
        instructionsToExport() {
            if (this.format_onlyActionableInstructions) {
                return this.options.script.instructions.filter((inst: InstructionModel) => ['question', 'action'].indexOf(inst.type) !== -1);
            }

            return this.options.script.instructions;
        },
    },
    methods: {
        IsMultiple(instruction: InstructionModel) {
            return instruction.question?.type === 'multiple';
        },
        getAnswerPossibilitiesForMultiple(instruction: InstructionModel, answers: { [key: string]: any }) {
            if (instruction?.question?.choices === undefined || instruction.question.type !== 'multiple') {
                return [];
            }

            const possibilities = {} as Record<string, number | string>;
            const answersToThis = answers[instruction.uuid as unknown as string];
            instruction.question.choices.forEach((choice: ChoiceModel) => {
                if (answersToThis === undefined) {
                    possibilities[choice.uuid as unknown as string] = '';
                    return;
                }

                possibilities[choice.uuid as unknown as string] = Number(answersToThis[choice.uuid as unknown as string] || false);
            });
            return possibilities;
        },
        GetDateAsString(date: Date) {
            return GetDateAsString(date);
        },
        GetAppropriateNumberLabel(instr: InstructionModel): string {
            return `Q${String(instr.order + 1)}`;
        },
        GetType(instr: InstructionModel): string {
            const char = instr.type.slice(0, 1);
            return `[${char.toUpperCase()}]`;
        },
        GetAppropriateMessage(instr: InstructionModel): string {
            if (this.format_useShortIfAvailable && instr.label != null) {
                return instr.label;
            }
            const msg = InstructionModelExt.GetMessageForLocale(instr, 'en', 'en')?.content || '';
            return msg.replace(/<\/?[^>]+(>|$)/g, '');
        },
        GetAppropriateMessageForChoice(choice: ChoiceModel): string {
            return ChoiceModelExt.GetMessageForLocale(choice, 'en', 'en')?.content || '';
        },
        GetAnswerForInstruction(instr: InstructionModel, answers: { [key: string]: any }, actions: { [key: string]: any }, escape = false): string[] {
            const uuid = instr.uuid as string;

            if (instr.question) {
                const questionType = instr.question.type;

                // eslint-disable-next-line
                    // @ts-ignore
                let initialAnswer = answers[uuid];
                if (questionType === 'open' && initialAnswer !== undefined && escape) {
                    let answer = initialAnswer as string;
                    answer = answer
                        .replaceAll('"', '""')
                        .replaceAll(/(\r\n|\n|\r)/gm, '');
                    return [uuid, `"${answer}"`];
                }
                if (questionType === 'emoji' && initialAnswer !== undefined && this.format_nonNumericExport) {
                    const emojiMap = {
                        1: 'Very unsatisfied',
                        2: 'Unsatisfied',
                        3: 'Neutral',
                        4: 'Satisfied',
                        5: 'Very satisfied',
                    };
                    // eslint-disable-next-line
                    // @ts-ignore
                    return [uuid, emojiMap[initialAnswer]];
                }
                if (questionType === 'choice' && this.format_nonNumericExport && instr.question.choices) {
                    const choice = instr.question.choices.find((choiceObj: ChoiceModel) => choiceObj.value === initialAnswer);

                    if (choice !== undefined) {
                        initialAnswer = this.GetAppropriateMessageForChoice(choice);
                    } else if (initialAnswer !== undefined) {
                        initialAnswer = `${initialAnswer}: #N/A`;
                    }

                    if (initialAnswer) {
                        initialAnswer = striptags(initialAnswer);
                        initialAnswer = initialAnswer.replace(/"/g, '""');
                        initialAnswer = `"${initialAnswer}"`;
                    }
                }
                if (initialAnswer === undefined) initialAnswer = '';
                return [uuid, initialAnswer];
            }
            if (instr.action) {
                const result = actions[uuid];
                if (result === undefined) return [uuid, ''];
                return [uuid, result];
            }

            return [uuid, ''];
        },
        GetValueForKey(key: string, metadata: { [key: string]: string | number }, escape = false): string {
            const initialVal = metadata[key];
            if (escape && initialVal !== undefined) {
                return String(initialVal)
                    .replaceAll('"', '""');
            }
            return String(initialVal || '');
        },
        performExport(): void {
            this.exportData = this.data as ConversationModel[];
            this.getNextRows(this.exportData.length);

            const header = ['conversationUuid', 'status', 'started', 'finished'];
            for (let i = 0; i < this.metaIdxs.length; i++) {
                header.push(`"${String(this.metaIdxs[i])
                    .replaceAll('"', '""')}"`);
            }

            const replaceNewLineChars = ((someString: string, replacementString = '') => { // defaults to just removing
                const LF = '\u{000a}'; // Line Feed (\n)
                const VT = '\u{000b}'; // Vertical Tab
                const FF = '\u{000c}'; // Form Feed
                const CR = '\u{000d}'; // Carriage Return (\r)
                const CRLF = `${CR}${LF}`; // (\r\n)
                const NEL = '\u{0085}'; // Next Line
                const LS = '\u{2028}'; // Line Separator
                const PS = '\u{2029}'; // Paragraph Separator
                const lineTerminators = [LF, VT, FF, CR, CRLF, NEL, LS, PS]; // all Unicode `lineTerminators`
                let finalString = someString.normalize('NFD'); // better safe than sorry? Or is it?
                // eslint-disable-next-line no-restricted-syntax
                for (const lineTerminator of lineTerminators) {
                    if (finalString.includes(lineTerminator)) { // check if the string contains the current `lineTerminator`
                        const regex = new RegExp(lineTerminator.normalize('NFD'), 'gu'); // create the `regex` for the current `lineTerminator`
                        finalString = finalString.replace(regex, replacementString); // perform the replacement
                    }
                }
                return finalString.normalize('NFC'); // return the `finalString` (without any Unicode `lineTerminators`)
            });

            let needsEscaping = false;
            const exportInstructions = this.instructionsToExport;
            for (let i = 0; i < exportInstructions.length; i++) {
                if (exportInstructions[i]?.question?.type === 'multiple') {
                    for (let j = 0; j < exportInstructions[i].question.choices.length; j++) {
                        let line = '';
                        if (this.format_withNr) {
                            line += `${String(this.GetAppropriateNumberLabel(exportInstructions[i]))}_a${j + 1}`;
                        }
                        if (this.format_withType) {
                            if (this.format_withNr) line += ' ';
                            line += String(this.GetType(exportInstructions[i]));
                        }
                        if ((this.format_withNr || this.format_withType) && this.format_withMessage) {
                            line += ': ';
                        }
                        if (this.format_withMessage) {
                            needsEscaping = true;
                            line += this.GetAppropriateMessageForChoice(exportInstructions[i].question.choices[j]);
                        }
                        line = replaceNewLineChars(line, ' ');
                        line = striptags(line);
                        if (needsEscaping) header.push(`"${line.replaceAll('"', '""')}"`);
                        else header.push(line);
                    }
                } else {
                    let line = '';
                    if (this.format_withNr) {
                        line += String(this.GetAppropriateNumberLabel(exportInstructions[i]));
                    }
                    if (this.format_withType) {
                        if (this.format_withNr) line += ' ';
                        line += String(this.GetType(exportInstructions[i]));
                    }
                    if ((this.format_withNr || this.format_withType) && this.format_withMessage) {
                        line += ': ';
                    }
                    if (this.format_withMessage) {
                        needsEscaping = true;
                        line += this.GetAppropriateMessage(exportInstructions[i]);
                    }
                    line = replaceNewLineChars(line, ' ');
                    line = striptags(line);
                    if (needsEscaping) header.push(`"${line.replaceAll('"', '""')}"`);
                    else header.push(line);
                }
            }

            this.exportableTable = [header];

            this.processRows();
        },
        processRows(startIndex = 0): void {
            let currentIndex = startIndex;
            while (currentIndex < this.rowCount) {
                if (currentIndex < this.exportData.length) {
                    const conv = this.exportData[currentIndex];
                    const line = [
                        conv.uuid,
                        conv.status,
                        conv.startedAt ? this.GetDateAsString(new Date(conv.startedAt)) : '',
                        conv.finishedAt ? this.GetDateAsString(new Date(conv.finishedAt)) : '',
                    ];

                    for (let j = 0; j < this.metaIdxs.length; j++) {
                        line.push(`"${this.GetValueForKey(this.metaIdxs[j], conv.metadata, true)}"`);
                    }
                    for (let j = 0; j < this.instructionsToExport.length; j++) {
                        const instr = this.instructionsToExport[j];
                        if (instr.question?.type === 'multiple') {
                            const choices = this.getAnswerPossibilitiesForMultiple(instr, conv.answers);
                            Object.values(choices)
                                .forEach((answer) => {
                                    line.push(String(answer));
                                });
                        } else {
                            line.push(this.GetAnswerForInstruction(instr, conv.answers, conv.actions, true)[1]);
                        }
                    }
                    this.exportableTable.push(line);
                    currentIndex++;
                } else {
                    setTimeout(this.processRows, 100, currentIndex);
                    return;
                }
            }

            this.saveExport();
        },
        saveExport(): void {
            let csvData = '';
            this.exportableTable.forEach((row: string[]) => {
                csvData += `${row.join(',')}\n`;
            });
            const csvBytes = new TextEncoder().encode(csvData);
            const exportBlob = new Blob([csvBytes], {type: 'text/csv;charset=utf-8'});

            const date = new Date();
            let datePrefix = '';
            datePrefix += `${date.getFullYear()}`;
            datePrefix += `${padInteger(date.getMonth() + 1, 2)}`;
            datePrefix += `${padInteger(date.getDate(), 2)}`;

            const link = document.createElement('a');
            link.setAttribute('href', URL.createObjectURL(exportBlob));
            link.setAttribute('download', `${datePrefix}_${this.communitySlug}_${this.options.script.name}_export.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        exportSpssSyntax(): void {
            let syntaxDataUri = 'data:text/plain;charset=utf-8';
            let mrSets = '** Define all of the relevant multiple response sets.';
            let answerLabels = '** Creating answer labels.';
            let syntax = 'Encoding: UTF-8.\n\n** Creating questions labels.\n VARIABLE LABELS\nUserId "Respondent ID"';
            const instructions = this.instructionsToExport || [];
            for (let i = 0; i < instructions.length; i++) {
                const instr = instructions[i];
                let variableName = '';
                let msg = '';
                if (instr.question && instr.question.type === 'multiple') {
                    const {choices} = instr.question;
                    for (let j = 0; j < choices.length; j++) {
                        if (this.format_withNr) {
                            variableName += `${String(this.GetAppropriateNumberLabel(instr))}_a${j + 1}`;
                        }
                        if (this.format_withType) {
                            if (this.format_withNr) variableName += ' ';
                            variableName += String(this.GetType(instr));
                        }
                        msg = `${ChoiceModelExt.GetMessageForLocale(choices[j], 'en', 'en')?.content}`;
                        syntax += `\n${variableName} "${variableName}: ${msg.replaceAll('"', '""')}"`;
                        variableName = '';
                    }
                }

                if (this.format_withNr) {
                    variableName += String(this.GetAppropriateNumberLabel(instr));
                }
                if (this.format_withType) {
                    if (this.format_withNr) variableName += ' ';
                    variableName += String(this.GetType(instr));
                }
                msg = this.GetAppropriateMessage(instr);

                if (!(instr.question && instr.question.type === 'multiple')) {
                    syntax += `\n${variableName} "${variableName}: ${msg.replaceAll('"', '""')}"`;
                }

                if (i === instructions.length - 1) {
                    syntax += '.\nExecute.';
                }

                if (instr.question) {
                    if (instr.question.type === 'choice') {
                        const {choices} = instr.question;
                        answerLabels += `\nVALUE LABELS ${variableName}`;
                        for (let j = 0; j < choices.length; j++) {
                            answerLabels += `\n${choices[j].value} "${ChoiceModelExt.GetMessageForLocale(choices[j], 'en', 'en')?.content}"`;
                        }
                        answerLabels += '.\nExecute.\n';
                    } else if (instr.question.type === 'multiple') {
                        const {choices} = instr.question;
                        mrSets += '\nMRSETS';
                        mrSets += `\n  /MDGROUP NAME=$${variableName} LABEL="${variableName}: ${msg}"`;
                        mrSets += '\n    CATEGORYLABELS=VARLABELS VARIABLES=';
                        for (let j = 0; j < choices.length; j++) {
                            mrSets += `${this.GetAppropriateNumberLabel(instr)}_a${j + 1}`;
                            if (j !== choices.length - 1) mrSets += ' ';
                        }
                        mrSets += `\n  VALUE=1\n  /DISPLAY NAME=[$${variableName}].\n`;
                    }
                } else if (instr.initialAction) {
                    if (instr.initialAction.type === 'score') { /* nothing special */ } else {
                        const states = ['finished', 'pending', 'expired'];
                        answerLabels += `\nVALUE LABELS ${variableName}`;
                        for (let j = 0; j < states.length; j++) {
                            answerLabels += `\n${j} "${states[j]}"`;
                        }
                        answerLabels += '.\nExecute.\n';
                    }
                }
            }

            syntaxDataUri += `,${syntax}\n\n${answerLabels}\n\n${mrSets}`;
            const encodedUri = encodeURI(syntaxDataUri);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${this.communitySlug}_${this.options.script.name}_syntax.sps`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        getNextRows(lastRow: number): void {
            if (lastRow < this.rowCount) {
                const options = new ExportOptions();
                options.limit = 1250;
                options.offset = lastRow;
                options.empties = this.format_withEmpty;
                ExportApi.getExportData(this.communitySlug, this.scriptUuid, options)
                    .then(this.handleRowResponse);
            }
        },
        handleRowResponse(data: ConversationModel[]): void {
            this.exportData = this.exportData.concat(data);
            this.getNextRows(this.exportData.length);
        },
    },
    watch: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        format_withEmpty: {
            deep: true,
            handler(toggle: boolean) {
                const {communitySlug, scriptUuid} = this.$route.params;
                this.communitySlug = communitySlug;
                this.scriptUuid = scriptUuid;

                ExportApi.getMetakeys(communitySlug, scriptUuid)
                    .then((data) => {
                        this.metaIdxs = data as string[];
                    });

                let filter = new ExportOptions();
                if (this.options.filter !== null) {
                    filter = JSON.parse(JSON.stringify(this.options.filter));
                }
                filter.empties = toggle;

                if (scriptUuid) {
                    ExportApi.getConversationCount(communitySlug, scriptUuid, !filter.empties)
                        .then((data) => {
                            this.exportData = [];
                            this.rowCount = data as number;
                        });
                }
                ExportApi.getExportData(communitySlug, scriptUuid, filter)
                    .then((data) => {
                        this.data = data as ConversationModel[];
                        this.exportData = this.data as ConversationModel[];
                    });
            },
        },
    },
    created() {
        const {communitySlug, scriptUuid} = this.$route.params;
        this.communitySlug = communitySlug;
        this.scriptUuid = scriptUuid;

        this.rowCount = this.options.rows;

        ExportApi.getMetakeys(communitySlug, scriptUuid)
            .then((data) => {
                this.metaIdxs = data as string[];
            });

        ExportApi.getExportData(communitySlug, scriptUuid, this.options.filter)
            .then((data) => {
                this.data = data as ConversationModel[];
                this.exportData = this.data as ConversationModel[];
            });
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

#export-dialogue {
    .filter {
        .date-selector {
            display: flex;
            align-items: center;

            > * {
                flex: 1;
            }

            > *:first-child {
                flex: 0;
                min-width: 15%;
            }
        }
    }

    .formatting {
        .format-option {
            margin: 0.5rem;
        }
    }

    .preview {
        overflow-x: auto;
        max-width: 75vw;
        width: 50vw;
        min-width: 960px;

        min-height: 256px;
        max-height: 30vh;

        margin: 1rem 1rem 0;
        padding: 0.5rem;
        box-shadow: inset 0 0 0.4rem 0.1rem black;

        table {
            min-width: 100%;
            min-height: 100%;
            border-collapse: collapse;

            tr {
                border-bottom: 1px solid transparentize($abbi-blue, 0.5);

                &:last-child {
                    border-bottom: none;
                }

                &.header {
                    border-bottom: 1px solid $abbi-blue;
                }
            }

            th, td {
                min-width: max-content;

                position: relative;
                padding: 0.25rem 0.5rem;

                border-right: 1px solid $abbi-blue;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .progress-bar {
        display: grid;
        place-items: center;
        background: linear-gradient(90deg, $abbi-blue var(--progress, 50%), transparent var(--progress, 50%));

        > div {
            padding: 0.25em 0.5em;
            background-color: transparentize(white, 0.5);
            min-width: 24ch;
            text-align: center;
        }
    }
}
</style>
