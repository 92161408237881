<template>
    <div>
        <link
            rel="preload"
            as="style"
            type="text/css"
            :href="href"
            :integrity="integrity"
            crossorigin="anonymous" />
        <link
            rel="stylesheet"
            type="text/css"
            :href="href"
            :integrity="integrity"
            crossorigin="anonymous"
            :disabled="!enabled" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export interface ComponentData {
    enabled: boolean;
    href: string;
    integrity: string;
}

export default Vue.extend({
    name: 'BootstrapStylesheet',
    data(): ComponentData {
        return {
            enabled: false,
            href: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css',
            integrity: 'sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9',
        };
    },
    beforeMount() {
        this.enabled = true;
    },
    beforeDestroy() {
        this.enabled = false;
    },
});
</script>

<style lang="scss">
@import 'src/styles/bootstrap';
</style>
