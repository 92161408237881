<template>
    <div class="abbi-button" :class="{square}">
        <img v-if="square" alt="squarizer"
             src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
        <span v-if="square" class="vhidden no-wrap"><slot></slot></span>
        <button
            class="no-wrap"
            :class="{red: isRed, blue: isBlue, 'fill-parent': grow, 'black-text': darkText, 'no-radius': noRadius}"
            :disabled="disabled"
            ref="abbiBtn"
            :type="type"
            :title="title"
            @click="onclick"
        >
            <span><slot></slot></span>
        </button>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'AbbiButton',
    props: {
        type: String,
        color: String,
        darkText: Boolean,
        disabled: Boolean,
        isRed: Boolean,
        isBlue: Boolean,
        grow: Boolean,
        square: Boolean,
        noRadius: Boolean,
        title: String,
    },
    methods: {
        onclick(event: MouseEvent) {
            this.$emit('click', event);
        },
    },
    mounted() {
        if (this.color && this.color !== '') {
            const myBtn = this.$refs.abbiBtn as HTMLElement;
            myBtn.style.setProperty('background-color', this.color);
        }
    },
});
</script>

<style lang='scss'>
@import 'src/styles/colors';

.abbi-button {
    button, .vhidden {
        position: relative;

        display: inline-block;
        overflow: hidden;

        height: 100%;

        padding: 0.5em;

        border: none;
        color: white;

        outline: none;
        border-radius: 6px;

        cursor: pointer;
        background-color: $abbi-green;

        &.no-radius {
            border-radius: 0;
        }

        &.black-text {
            color: darkgrey;
        }

        &.fill-parent {
            width: 100%;
        }

        &[disabled] {
            cursor: default;
            color: rgb(96, 96, 96);

            &::before {
                display: block;
                content: '';
                background-color: rgb(154, 154, 154);
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        > span {
            position: relative;
            z-index: 2;
        }

        &:active:not([disabled]), &:hover:not([disabled]) {
            &::before {
                display: block;
                content: '';
                background-color: rgba(0, 0, 0, 0.2);
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &:active:not([disabled])::before {
            background-color: rgba(0, 0, 0, 0.5);
        }

        &.red {
            background-color: $abbi-red !important;
        }
        &.blue {
            background-color: $abbi-blue !important;
        }
    }

    .vhidden {
        display: block;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: collapse;
    }

    &.square {
        position: relative;
        height: 100%!important;

        > img {
            display: block;
            visibility: hidden;
            height: 100%;
            width: 100%;
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            width: 100%;

            padding: 0 0.5em;
        }
    }
}
</style>
