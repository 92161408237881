<template>
    <v-app class="dynamic">
        <v-row>
            <v-col>
                <v-switch v-model="fileCameraCapability" :disabled="!fileLibraryCapability"
                          label="Image Capture Button"
                ></v-switch>
                <div v-if="fileCameraCapability" class="btn-upload">
                    <div class="icons">
                        <FaIcon class="icon" icon="camera"></FaIcon>
                    </div>
                    <input
                        maxlength="30"
                        ref="cameraTextInput"
                        @focusout="fileUploadCameraInputText = !fileUploadCameraInputText"
                        v-show="fileUploadCameraInputText"
                        class="text"
                        v-model="fileUploadCameraText"
                        @change="onChange"
                        type="text"
                    />
                    <span @click="focusCameraTextInput" v-show="!fileUploadCameraInputText"
                          :class="{ text: true, placeholder: fileUploadCameraText == '' }">{{ fileUploadCameraText || 'Camera' }}</span>
                </div>
            </v-col>
            <v-col>
                <v-switch v-model="fileLibraryCapability" :disabled="!fileCameraCapability"
                          label="Image Upload Button"
                ></v-switch>
                <div v-if="fileLibraryCapability" class="btn-upload">
                    <div class="icons">
                        <FaIcon class="icon" icon="image"></FaIcon>
                    </div>
                    <input
                        maxlength="30"
                        ref="libraryTextInput"
                        @focusout="fileUploadLibraryInputText = !fileUploadLibraryInputText"
                        v-show="fileUploadLibraryInputText"
                        class="text"
                        v-model="fileUploadLibraryText"
                        @change="onChange"
                        type="text"
                    />
                    <span @click="focusLibraryTextInput" v-show="!fileUploadLibraryInputText"
                          :class="{ text: true, placeholder: fileUploadLibraryText == '' }">{{ fileUploadLibraryText || 'Gallerij'  }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3>File extensions</h3>
                <v-btn-toggle
                    v-model="extensions"
                    @change="onChange"
                    multiple
                    color="secondary"
                    group
                >
                    <v-btn value="png">PNG</v-btn>
                    <v-btn value="jpg">JPG</v-btn>
                    <v-btn value="jpeg">JPEG</v-btn>
                    <v-btn value="gif">GIF</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
    </v-app>
</template>

<script lang="ts">
import Toggle from '@/components/input/Toggle.vue';
import Input from '@/components/community/styling/Input/Input.vue';
import Configurator from '@/components/chat/configurators/Configurator.vue';

export default Configurator.extend({
    name: 'FileConfigurator',
    components: {
        Input,
        Toggle,
    },
    methods: {
        sanitizeValue(value: string) {
            if (/^\s*$/.test(value)) {
                return '';
            }

            return value;
        },
        onChange() {
            const data = {
                extensions: this.extensions,
                fileUploadCameraText: this.fileUploadCameraText,
                fileUploadLibraryText: this.fileUploadLibraryText,
                fileCameraCapability: this.fileCameraCapability,
                fileLibraryCapability: this.fileLibraryCapability,
            };
            this.$emit('change', data);
        },
        focusCameraTextInput() {
            this.fileUploadCameraInputText = !this.fileUploadCameraInputText;
            this.$nextTick(() => {
                const textInput = this.$refs.cameraTextInput as HTMLInputElement;
                if (textInput) {
                    textInput.focus();
                }
            });
        },
        focusLibraryTextInput(event: FocusEvent) {
            this.fileUploadLibraryInputText = !this.fileUploadLibraryInputText;
            this.$nextTick(() => {
                const textInput = this.$refs.libraryTextInput as HTMLInputElement;
                if (textInput) {
                    textInput.focus();
                }
            });
        },
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    watch: {
        fileUploadCameraText(value: string) {
            this.fileUploadCameraText = this.sanitizeValue(value);
        },
        fileUploadLibraryText(value: string) {
            this.fileUploadLibraryText = this.sanitizeValue(value);
        },
    },
    data() {
        return {
            extensions: this.options?.extensions,
            fileUploadCameraText: this.options?.fileUploadCameraText ?? this.options?.fileUploadText ?? 'Camera',
            fileUploadLibraryText: this.options?.fileUploadLibraryText ?? this.options?.fileUploadText ?? 'Gallerij',
            fileUploadCameraInputText: false,
            fileUploadLibraryInputText: false,
            fileCameraCapability: this.options?.fileCameraCapability ?? true,
            fileLibraryCapability: this.options?.fileLibraryCapability ?? true,
        };
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

#app.v-application.dynamic {
    height: auto !important;
    width: auto !important;
    min-height: auto;
    max-height: auto;

    .v-application--wrap {
        min-height: auto !important;
    }
}

.button-style-config {
    display: flex;

    div {
        flex: 1;
    }
}

.btn-upload {
    width: fit-content;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border: 2px solid gray;
    background-color: white;
    border-radius: 0.25rem;
    padding: 0 3rem;
}

.text {
    &.placeholder {
        opacity: 0.5;
    }
    border: none;
    outline: none;
}

.icon {
    margin-right: 5px;
}

</style>
