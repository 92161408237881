<template>
    <div class="edit-removal">
        <form class="pure-form pure-form-aligned" v-on:submit.prevent="editRemoval">
            <fieldset>
                <div class="pure-control-group">
                    <label for="aligned-script-name">Chat</label>
                    <span id="aligned-script-name">{{ script.name.length > 24 ? `${script.name.substring(0, 24)} ...` : script.name}}</span>
                </div>
                <Toggle class="ml-1" v-model="script.removalProcedure" @click="confirmToggle">Removal procedure</Toggle>
                <div class="pure-control-group">
                    <label for="aligned-removal-warning">Removal warning</label>
                    <input type="date" :min="currentDate" v-model="script.removalWarningDate" id="aligned-removal-warning" />
                </div>
                <div class="pure-control-group">
                    <label for="aligned-deletion-date">Deletion date</label>
                    <input type="date" :min="currentDate" v-model="script.deletedDate" id="aligned-deletion-date" />
                </div>
                <AbbiButton class="submit-button" :grow="true" is-submit="true">Submit</AbbiButton>
            </fieldset>
        </form>
    </div>
</template>

<script lang="ts">
/* eslint no-alert: 0 */
import Vue from 'vue';
import Toggle from '@/components/input/Toggle.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import ChatApi from '@/apis/chat.api';

export default Vue.extend({
    name: 'EditRemovalModal',
    components: {Toggle, AbbiButton},
    props: {
        options: Object,
    },
    data() {
        return {
            script: { ...this.options.script},
        };
    },
    computed: {
        currentDate() {
            return new Date().toISOString().split('T')[0];
        },
    },
    methods: {
        confirmToggle(event: Event, value: boolean) {
            if (value) {
                const confirmed = window.confirm(
                    'Are you sure you don\'t want to use the auto removal procedure? '
                        + 'This may not be in line with the GDPR policy. Continue?',
                );
                if (!confirmed) {
                    event.preventDefault();
                }
            }
        },
        editRemoval() {
            ChatApi.editRemoval(this.script.id, this.script).then((responseData) => {
                if (responseData === 'success') {
                    const event = new CustomEvent('changeRemovalItem', {detail: this.script});
                    document.dispatchEvent(event);
                    this.$emit('close');
                    this.$store.dispatch('closeModal');
                }
            });
        },
    },
});
</script>

<style lang="scss">
    .abbi-toggle {
        padding-bottom: 7px;
        .visualizer {
            margin-left: 16px !important;
        }
    }
.edit-removal {
    .pure-form {
        .submit-button {
            width: 48%;
            margin-left: 120px;
            padding-top: 9px;
        }
    }
    #aligned-script-name {
        position: absolute;
        bottom: 196px;
    }
}

.edit-removal-component {
    display: flex;
    flex-direction: column;

    padding: 0.75em 1em;

    .submit-button {
        margin-top: 0.5em;
    }
}
</style>
