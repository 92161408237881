<template>
    <div class="pie">
        <div v-for="(value, slice) in slices"
             :key="`slice-${slice}`"
             class="slice"
             :ref="slice"
             @click="toggleScenario"
             :class="[`slice-${slice}`]"
        >
            <ColorInput
                :value="value"
                @toggle="toggle(slice, value)"
                class="slice-input"
            />
        </div>
    </div>
</template>

<script lang="ts">
import ColorInput from '@/components/community/styling/Input/ColorInput.vue';
import Field from './Field.vue';

export default Field.extend({
    components: {ColorInput},
    props: {
        property: {
            required: false,
        },
        slice1: {
            type: String,
            required: true,
        },
        slice2: {
            type: String,
            required: true,
        },
        slice3: {
            type: String,
            required: true,
        },
        slice4: {
            type: String,
            required: true,
        },
    },
    inject: ['colorPickerToggle', 'stylesheet', 'defaults'],
    computed: {
        slices(): Record<string, string> {
            // Emulate a clockwise rotation.
            return {
                [this.slice4]: this.getValue(this.slice4),
                [this.slice1]: this.getValue(this.slice1),
                [this.slice3]: this.getValue(this.slice3),
                [this.slice2]: this.getValue(this.slice2),
            };
        },
    },
    methods: {
        toggle(slice: string, value: string): void {
            const $el = this.$refs[slice] as unknown as HTMLElement[];
            this.colorPickerToggle.toggle(slice, value, $el[0]);
        },
    },
});
</script>

<style lang="scss" scoped>
.pie {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 1em 0 0;

    .slice {
        --slice-size: 7em;
        width: 100%;
        flex: 50%;

        &:nth-child(odd) {
            text-align: right;
        }

        .slice-input {
            width: var(--slice-size);
            height: var(--slice-size);
            margin: 0;
        }

        &:nth-child(1) {
            .slice-input {
                border-radius: 100% 0 0 0;
            }
        }

        &:nth-child(2) {
            .slice-input {
                border-radius: 0 100% 0 0;
            }
        }

        &:nth-child(3) {
            .slice-input {
                border-radius: 0 0 0 100%;
            }
        }

        &:nth-child(4) {
            .slice-input {
                border-radius: 0 0 100% 0;
            }
        }
    }
}
</style>
