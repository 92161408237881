export enum Breakpoint {
    None,
    S = 'sm',
    M = 'md',
    L = 'lg',
    XL = 'xl',
    XXL = 'xxl',
}

export default {
    breakpoint: Breakpoint,
};
