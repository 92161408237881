interface ExtensionResolver {
    (subtype: string): string;
}

interface Resolvers {
    [key: string]: ExtensionResolver;
}

const resolvers: Resolvers = {
    image: (subtype: string) => {
        const extensions: Record<string, string> = {
            'svg+xml': 'svg',
        };

        return extensions[subtype] ?? subtype;
    },
    font: (subtype: string) => subtype,
    application: (subtype: string) => {
        const extensions: Record<string, string> = {
            'font-woff': 'woff',
        };

        return extensions[subtype] ?? subtype;
    },
};

function getExtensionResolver(category: string): ExtensionResolver {
    const resolver = resolvers[category];

    if (!resolver) {
        throw new Error(
            `File extension resolution for "${category}" types is not supported.`,
        );
    }

    return resolver;
}

export default function getExtensionFromMimeType(type: string): string {
    const [category, subtype] = type.split('/');
    const resolver = getExtensionResolver(category);
    return resolver(subtype);
}
