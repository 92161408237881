<template>
    <header>
        <div id="logo-area">
            <router-link to="/home">
                <img src="@/assets/images/logo.svg" alt="Abbi logo image" />
                <span class="version-group special-font">
                    <span class="">Abbi Insights</span>
                    <span class="version">{{ version }}</span>
                </span>
            </router-link>
        </div>
        <nav id="independent-nav" class="special-font" v-if="$store.state.loggedIn">
            <!-- <router-link to="/dashboard"></router-link> -->
            <router-link to="/admin">{{ $t('ROUTER.ADMIN') }}</router-link>
            <router-link
                v-if="activeCommunity !== null"
                :to="{
                    name: 'Community',
                    params:{ communitySlug: activeCommunity.slug },
                }">{{ $t('ROUTER.COMMUNITY._self') }}
            </router-link>
            <router-link
                v-if="activeCommunity !== null"
                :to="{
                    name: 'ChatOverview',
                    params: { communitySlug: activeCommunity.slug },
                }">{{ $t('ROUTER.CHATS') }}
            </router-link>
        </nav>
        <nav id="profile-nav" class="special-font" v-if="!$store.state.currentUser !== undefined">
            <div v-if="$store.state.loggedIn">
                <!--<router-link to="/user-management">{{$t('ROUTER.USER_MANAGEMENT')}}</router-link>-->
                <Dropdown id="community-selector" class="keep-case flex-item" align="right"
                          :text="activeCommunity !== null ? activeCommunity.name : '('+$t('MESSAGE.SELECT_COMMUNITY')+')'">
                    <div class="community">
                        <AbbiButton grow @click="switchCommunity(null)">{{ $t('MESSAGE.SELECT_COMMUNITY') }}</AbbiButton>
                    </div>
                    <AbbiTextInput placeholder="Filter community" v-model="communityFilter" hasAutofocus ref="filter" class="search-input" @keyup.enter="tryOpenCommunity"/>
                    <div class="community" v-for="community in filteredCommunities" :key="community.uuid">
                        <AbbiButton grow @click="switchCommunity(community)">{{ community.name }}</AbbiButton>
                    </div>
                    <div v-if="filteredCommunities.length === 1 && communityFilter !== null">
                        Press the enter key to go to the <strong>{{filteredCommunities[0].name}}</strong> community.
                    </div>
                    <div v-if="filteredCommunities.length === 0 && communityFilter !== null">
                        No communities match your filter.
                    </div>
                </Dropdown>
                <Dropdown id="account-dropdown" class="keep-case flex-item" :text="$store.state.currentUser.username">
                    <div>
                        <router-link to="/account">
                            <i class="bi-person-circle"></i>
                            {{ $t('ROUTER.ACCOUNT.OVERVIEW') }}
                        </router-link>
                    </div>
                    <div>
                        <router-link class="text-danger" to="/logout">
                            <i class="bi-box-arrow-right"></i>
                            {{ $t('ROUTER.LOGOUT') }}
                        </router-link>
                    </div>
                </Dropdown>
            </div>
            <div v-else>
                <!-- <span id="community-selector">Comm sel</span>
                <span id="account-dropdown" class="dropdown">Account</span> -->
                <router-link to="/login">{{ $t('ROUTER.LOGIN') }}</router-link>
                <a href="https://www.abbi-insights.com" target="_blank">abbi website</a>
            </div>
        </nav>
    </header>
</template>

<script lang="ts">
import Vue from 'vue';
import Dropdown from '@/components/interface/Dropdown.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CommunityApi from '@/apis/community.api';
import CommunityModel from '@/models/community.model';

import settings from '@/settings';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';

export default Vue.extend({
    name: 'Header',
    components: {AbbiTextInput, Dropdown, AbbiButton},
    computed: {
        loggedIn() {
            return this.$store.state.loggedIn;
        },
        activeCommunity() {
            return this.$store.getters.community;
        },
        version(): string {
            return settings.version;
        },
        filteredCommunities(): CommunityModel[] {
            if (this.communityFilter === null) {
                return this.$store.state.communities;
            }

            return this.$store.state.communities.filter(
                (community: CommunityModel) => community.name.toLowerCase().includes(this.communityFilter!.toLowerCase()),
            );
        },
    },
    watch: {
        loggedIn(newVal: boolean) {
            if (newVal) {
                CommunityApi.getCommunities()
                    .then((responseData) => {
                        if (responseData != null) {
                            this.$store.commit('setCommunities', responseData);
                        }
                    });
            }
        },
    },
    created() {
        if (this.$store.state.loggedIn) {
            CommunityApi.getCommunities()
                .then((responseData: CommunityModel[]) => {
                    if (responseData !== null) {
                        this.$store.commit('setCommunities', responseData);

                        const {currentRoute} = this.$router;
                        if ('communitySlug' in currentRoute.params) {
                            const results = responseData.filter(
                                (model: CommunityModel) => model.slug === currentRoute.params.communitySlug,
                            );

                            if (results.length > 0) {
                                this.$store.commit('changeCommunity', results[0]);
                            } else {
                                // TODO: Not allowed
                            }
                        }
                    }
                });
        }
    },
    data() {
        return {
            communityFilter: null as string | null,
        };
    },
    methods: {
        switchCommunity(newCommunity: CommunityModel) {
            this.$store.commit('changeCommunity', newCommunity);
            const {currentRoute} = this.$router;
            if ('communitySlug' in currentRoute.params) {
                if (newCommunity !== null && currentRoute.params.communitySlug !== newCommunity.slug) {
                    let routePath = this.$router.currentRoute.name as string;

                    if (routePath.indexOf('Chat') >= 0) {
                        routePath = 'ChatOverview';
                    }

                    this.$router.push({name: routePath as string, params: {communitySlug: newCommunity.slug}})
                        .catch(() => { /**/
                        });
                } else if (newCommunity === null) {
                    this.$router.push('/home');
                }
            }
        },
        tryOpenCommunity() {
            if (this.filteredCommunities.length === 1) {
                this.switchCommunity(this.filteredCommunities[0]);
                this.communityFilter = null;
            }
        },
    },
});
</script>

<style lang="scss">
@import 'src/styles/typography';
@import 'src/styles/colors';

header {
    background-color: $abbi-green;
    display: flex;

    > * {
        flex: 0;
    }

    > :first-child, :last-child {
        flex: 1;
    }
}

input.search-input {
    width: 100%;
    padding: 0.5em;
    border: none;
    color: white;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: hsl(70, 57%, 48%);
}

#logo-area {
    > a {
        padding: 8px;
        display: flex;
        align-items: center;

        color: white;
        font-size: 1.25em;
        text-decoration: none;
        font-weight: 700;

        width: max-content;

        > img {
            display: block;
            margin-right: 0.5rem;
            height: 60px;
        }
    }

    .version-group {
        position: relative;

        > .version {
            position: absolute;
            top: -1em;
            left: 0;
            font-size: 0.75em;
        }
    }
}

#independent-nav {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;

    > a {
        text-transform: capitalize;

        position: relative;
        overflow: hidden;
        display: flex;
        flex: 0;
        min-width: max-content;

        margin: 0 0.5em;
        padding: 0.5em;

        text-decoration: none;
        color: white;
        border-radius: 6px;

        font-size: 1.1em;

        background-color: transparent;

        transition: background-color 0.075s ease-out;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }

        &:active {
            background: rgba(0, 0, 0, 0.1);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0.125em;
            background-color: transparent;

            transition: background-color 0.1s ease-out;
        }

        &.router-link-active {
            &:after {
                background-color: white;
            }

            &:hover, &:active {
                background: inherit;
            }
        }

        &.router-link-exact-active {
            cursor: default;
        }
    }
}

#profile-nav > div {
    padding: 8px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    height: 100%;

    .flex-item {
        flex: 0;
    }

    > a, > button {
        display: flex;
        flex: 0;

        min-width: max-content;

        margin: 0 0.5em;
        padding: 0.5em;

        border: none;
        text-decoration: none;
        color: white;
        font-size: 1.1em;
        font-weight: 500;

        border-radius: 6px;

        background-color: rgba(255, 255, 255, 0.2);
        transition: background-color 0.075s ease-out;

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            // box-shadow: 0 2px 3px 0 lighten(black, 25%);
        }

        &:focus {
            background-color: rgba(0, 0, 0, 0.1);
            outline: none;
        }

        &.router-link-active {
            background-color: transparent;
            cursor: default;
        }

        &:not([target="_blank"]):not(.keep-case) {
            text-transform: capitalize;
        }
    }

    .dropdown-toggler {
        display: flex;
        flex: 0;

        min-width: max-content;

        margin: 0 0.5em;
        padding: 0.5em;

        border: none;
        text-decoration: none;
        color: white;
        font-size: 1.1em;
        font-weight: 500;

        border-radius: 6px;

        background-color: rgba(255, 255, 255, 0.2);
        transition: background-color 0.075s ease-out, border-radius 0.1s ease-out;

        outline: none;

        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }

    .unfolded .dropdown-toggler:active, .unfolded .dropdown-toggler:focus {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .dropdown-window {
        margin: 0.5em;

        max-height: 85vh;
        overflow-y: auto;

        > * {
            margin: 0.5rem;
        }
    }
}
</style>
