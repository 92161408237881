<template>
    <div id="crm-auth-configurator">
        <div class="setting-control mt-05 pure-u-1-2">
            <AbbiTextInput
                v-model="action.params.placeholder"
                is-small
                type="text"
                max="1024"
                name="placeholder"
                :placeholder="$t('FIELDS.PLACEHOLDER')"
            />
        </div>
        <div>
            <div class="text">
                <p>Authentication Entry Point</p>
            </div>
            <select v-model="action.params.crmAuthType" class="auth-type pure-input-1-2">
                <option v-for="(value, key) in crmAuthTypes" v-bind:value="key" v-bind:key="key">
                    {{ value }}
                </option>
            </select>
        </div>
        <div v-if="action.params.crmAuthType == CRM_AUTH_TYPE_AUTHENTICATED" class="authenticated">
            <br>
            <div id="editor-body-messages" class="setting-group">
                <h3 class="setting-label">Email body</h3>
                <div class="setting-controls">
                    <div class="mt-05">
                        <h4 class="special-font locale-label hidden">en</h4>
                        <ejs-richtexteditor
                            :cssClass="cssClass"
                            v-model="action.params.emailBody"
                            :toolbarSettings="toolbarSettings"
                            :fontSize="fontSize"
                            ref="editor"
                            :height="400"
                        />
                    </div>
                </div>
            </div><br>
            <div class="setting-control mt-05 setting-control-width pure-u-1-2">
                <AbbiTextInput v-model="action.params.emailSubject" is-small type="text" max="1024" placeholder="Email subject" />
            </div>
            <div class="flex-row">
                <div class="flex-1 mr-3">
                    <div class="text">
                        <p>Choose connection</p>
                    </div>
                    <select v-model="action.params.connection" class="auth-type pure-input-1-2 pure-u-1-2">
                        <option v-for="({id, name}) in connections" :value="id" :key="id">
                            {{ name}}
                        </option>
                    </select>
                </div>
                <div class="flex-1">
                    <div class="text">
                        <p>Code is sent via</p>
                    </div>
                    <select v-model="action.params.crmAuthenticatedType" class="auth-type pure-input-1-2 pure-u-1-2">
                        <option v-for="(value, key) in crmAuthenticatedTypes" v-bind:value="key" v-bind:key="key">
                            {{ value }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="flex-row">
                <div class="flex-1 mr-3">
                    <div class="text">
                        <p>Email Sender</p>
                    </div>
                    <AbbiTextInput v-model="action.params.emailSender" name="emailSender" isSmall placeholder="Email address" />
                </div>
                <div class="flex-1">
                    <div class="text">
                        <p>Selector Field Name</p>
                    </div>
                    <AbbiTextInput v-model="action.params.selector" name="selector" isSmall placeholder="Selector" />
                </div>
            </div>
        </div>
        <div v-else-if="action.params.crmAuthType == CRM_AUTH_TYPE_PREAUTHENTICATED" class="pre-authenticated">
            <div class="flex-row">
                <div class="flex-1">
                    <div class="text">
                        <p>Choose connection</p>
                    </div>
                    <select v-model="action.params.connection" class="auth-type pure-input-1-2 pure-u-1-2">
                        <option v-for="({id, name}) in connections" :value="id" :key="id">
                            {{ name }}
                        </option>
                    </select>
                </div>
                <div class="flex-1" style="margin-top: 4%;">
                    <AbbiTextInput
                        class="pure-u-1-2"
                        v-model="action.params.valueSelector"
                        name="selector"
                        isSmall
                        placeholder="Query parameter"
                    />
                </div>
            </div>
        </div>
        <div v-else class="anonymous">
            <br>
            <div class="flex-row">
                <div class="flex-1">
                    <div class="text">
                        <p>Choose connection</p>
                    </div>
                    <select v-model="action.params.connection" class="auth-type pure-input-1-2 pure-u-1-2">
                        <option v-for="({id, name}) in connections" :value="id" :key="id">
                            {{ name}}
                        </option>
                    </select>
                </div>
                <div class="flex-1" style="margin-top: 4%;">
                    <AbbiTextInput
                        class="pure-u-1-2"
                        v-model="action.params.valueSelector"
                        name="selector"
                        isSmall
                        placeholder="Selector"
                    />
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-1">
                    <div class="text">
                        <p>Choose validation</p>
                    </div>
                    <select v-model="action.params.validation" class="auth-type pure-input-1-2 pure-u-1-4">
                        <option v-for="(value, key) in validationConstraints" :value="key" :key="key">
                            {{ value }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import vue from 'vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ActionModel from '@/models/action.model';
import ScriptModel from '@/models/script.model';
import {
    CRM_AUTHENTICATED_TYPES as crmAuthenticatedTypes,
    CRM_AUTH_TYPES as crmAuthTypes,
    CRM_AUTH_TYPE_AUTHENTICATED,
    VALIDATION_CONSTRAINTS as validationConstraints,
    CRM_AUTH_TYPE_PREAUTHENTICATED,
} from '@/helpers/constants';
import CrmApi from '@/apis/crm.api';
import {
    RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, PasteCleanup,
} from '@syncfusion/ej2-vue-richtexteditor';

vue.use(RichTextEditorPlugin);

export default vue.extend({
    name: 'CrmAuthConfigurator',
    components: {AbbiTextInput},
    props: {
        action: [Object, ActionModel],
        script: [Object, ScriptModel],
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    provide: {
        richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, PasteCleanup],
    },
    data() {
        return {
            crmAuthTypes,
            CRM_AUTH_TYPE_AUTHENTICATED,
            CRM_AUTH_TYPE_PREAUTHENTICATED,
            crmAuthenticatedTypes,
            validationConstraints,
            connections: [],
            toolbarSettings: {
                items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                        'FontSize', 'LowerCase', 'UpperCase', '|',
                        'CreateLink', 'SourceCode', '|',
                        'Undo', 'Redo',
                ],
            },
            fontSize: {
                default: '16px',
                items: [
                    { text: '10px', value: '10px' },
                    { text: '12px', value: '12px' },
                    { text: '14px', value: '14px' },
                    { text: '16px', value: '16px' },
                    { text: '18px', value: '18px' },
                    { text: '20px', value: '20px' },
                    { text: '22px', value: '22px' },
                ],
            },
            cssClass: 'editorContent',
        };
    },
    created() {
        const {communitySlug, scriptUuid} = this.$route.params;
        CrmApi.list(communitySlug).then((responseData) => {
            if (!responseData.length) {
                this.flashMessage.error({
                    message: this.$i18n.t('ERROR.CRM_CONNECTION'),
                    icon: '/img/icons/error.svg',
                    time: 3500,
                });
                throw new Error(this.$i18n.t('ERROR.CRM_CONNECTION'));
            }
            this.connections = responseData.map(({id, name}) => ({id, name}));
            if (!this.$options.propsData.initialAction.params.connection) {
                this.$options.propsData.initialAction.params.connection = this.connections[0].id;
            }
        }).then(() => {
            CrmApi.showDataPoint(scriptUuid).then((data) => {
                if (!data) {
                    this.flashMessage.error({
                        message: this.$i18n.t('ERROR.DATA_POINTS'),
                        icon: '/img/icons/error.svg',
                        time: 3500,
                    });
                }
            });
        });
    },
});
</script>

<style lang="scss">
    #crm-auth-configurator{
        .auth-type {
            height: 32px;
        }

        select {
            background-color: white;
            border-style: solid;
            appearance: auto;
            cursor: pointer;
        }
    }
</style>
