import averages from './averages';
import Scenario from './Scenario';
import barCharts from './barCharts';
import pieCharts from './pieCharts';

export default {
    barCharts,
    pieCharts,
    averages,
} as Record<string, Scenario>;
