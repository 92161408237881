// eslint-disable-next-line max-classes-per-file
import MessageModel from '@/models/message.model';
import QuestionModel from '@/models/question.model';
import ActionModel from '@/models/action.model';

import {v4 as uuid4v} from 'uuid';

export default class InstructionModel {
    uuid = uuid4v() as string;

    typing_delay = 0;

    order = 0;

    type = 'empty';

    condition = null as string | null;

    label = null as string | null;

    can_revert = true;

    draft = false;

    messages = null as MessageModel[] | null;

    question = null as QuestionModel | null;

    action = null as ActionModel | null;
}

export class InstructionModelExt {
    static GetMessageForLocale(self: InstructionModel, locale: string, fallbackLocale: string): MessageModel | null {
        if (self.messages == null) return null;
        let preferred = null;
        let fallback = null;
        for (let i = 0; i < self.messages.length; i += 1) {
            const msg = self.messages[i] as MessageModel;
            if (msg.locale === locale) {
                preferred = msg;
                break;
            } else if (msg.locale === fallbackLocale) {
                fallback = msg;
            }
        }

        return preferred || fallback;
    }
}
