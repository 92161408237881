<template>
    <div class="card">
        <slot name="header">
            <div class="card-header" v-if="header">
                {{ $t(header) }}
            </div>
        </slot>

        <slot name="topImage">
            <img
                v-if="topImage"
                class="card-img-top"
                :src="topImage.src"
                :alt="$t(topImage.alt)" />
        </slot>

        <div class="card-body">
            <h2 class="card-title">
                <slot name="title">
                    <span v-if="title">{{ $t(title) }}</span>
                </slot>
            </h2>

            <p class="card-subtitle">
                <slot name="subtitle">
                    <span v-if="subtitle">{{ $t(subtitle) }}</span>
                </slot>
            </p>

            <div class="card-text">
                <slot>
                    <p v-if="text">{{ $t(text) }}</p>
                </slot>
            </div>
        </div>

        <slot name="footer">
            <div class="card-footer" v-if="footer">
                {{ $t(footer) }}
            </div>
        </slot>

        <slot name="bottomImage">
            <img
                v-if="bottomImage"
                class="card-img-bottom"
                :src="bottomImage.src"
                :alt="$t(bottomImage.alt)" />
        </slot>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import Vue from 'vue';

interface Image {
    src: string;
    alt: string;
}

interface ImageConstructor {
    new(value?: any): Image;
    readonly prototype: Image;
}

declare const Image: ImageConstructor;

export default Vue.extend({
    name: 'Card',
    props: {
        header: {
            type: String,
        },
        topImage: {
            type: Image,
        },
        title: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        text: {
            type: String,
        },
        footer: {
            type: String,
        },
        bottomImage: {
            type: Image,
        },
    },
});
</script>
