import {Stylesheet} from '@/components/community/styling/CommunityStyling';

export interface InheritanceCallback {
    (property: string, stylesheet: Stylesheet): string;
}

export default class StylesheetInheritor {
    private callback: InheritanceCallback;

    constructor() {
        this.callback = (): string => '';
    }

    setCallback(callback: InheritanceCallback) {
        this.callback = callback;
    }

    calculateInheritance(property: string, stylesheet: Stylesheet): string {
        return this.callback(property, stylesheet);
    }
}
