var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"row",attrs:{"title":"ACCOUNT.2FA.HEADING"},scopedSlots:_vm._u([(!_vm.saved)?{key:"subtitle",fn:function(){return [_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT.2FA.DISABLE.INTRODUCTION'))+" ")])]},proxy:true}:{key:"subtitle",fn:function(){return [_c('div',{staticClass:"alert alert-warning mb-0 mt-3",attrs:{"role":"alert"}},[_c('i',{staticClass:"bi-exclamation-triangle-fill"}),_vm._v(" "+_vm._s(_vm.$t(_vm.successMessage))+" ")])]},proxy:true}],null,true)},[(!_vm.saved)?_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('div',{staticClass:"mb-3 row"},[_c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"currentPassword"}},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT.FIELD.CURRENT_PASSWORD.LABEL'))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"input-group has-validation"},[_c('i',{staticClass:"input-group-text bi-key"}),((_vm.currentPasswordVisible ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.violations.hasError('currentPassword'),
                                'is-valid': _vm.currentPassword.length > 0,
                            },attrs:{"id":"currentPassword","name":"currentPassword","autocomplete":"current-password","required":"","aria-describedby":"currentPasswordHelp","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentPassword)?_vm._i(_vm.currentPassword,null)>-1:(_vm.currentPassword)},on:{"input":function($event){return _vm.mutateForm()},"change":function($event){var $$a=_vm.currentPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentPassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentPassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentPassword=$$c}}}}):((_vm.currentPasswordVisible ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.violations.hasError('currentPassword'),
                                'is-valid': _vm.currentPassword.length > 0,
                            },attrs:{"id":"currentPassword","name":"currentPassword","autocomplete":"current-password","required":"","aria-describedby":"currentPasswordHelp","type":"radio"},domProps:{"checked":_vm._q(_vm.currentPassword,null)},on:{"input":function($event){return _vm.mutateForm()},"change":function($event){_vm.currentPassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.violations.hasError('currentPassword'),
                                'is-valid': _vm.currentPassword.length > 0,
                            },attrs:{"id":"currentPassword","name":"currentPassword","autocomplete":"current-password","required":"","aria-describedby":"currentPasswordHelp","type":_vm.currentPasswordVisible ? 'text' : 'password'},domProps:{"value":(_vm.currentPassword)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.currentPassword=$event.target.value},function($event){return _vm.mutateForm()}]}}),_c('TogglePasswordVisibility',{attrs:{"visible":_vm.currentPasswordVisible},on:{"toggle":function($event){_vm.currentPasswordVisible = !_vm.currentPasswordVisible}}}),_c('ViolationList',{attrs:{"field":"currentPassword","violations":_vm.violations}})],1),_c('div',{staticClass:"form-text",attrs:{"id":"currentPasswordHelp"}},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT.2FA.DISABLE.FIELD.CURRENT_PASSWORD.HELP'))+" ")])])]),(_vm.currentPassword.length > 0)?_c('div',{staticClass:"mt-4 input-group has-validation"},[_c('button',{staticClass:"btn btn-lg btn-danger w-100",class:{'is-valid': _vm.saved, 'is-invalid': _vm.errorMessage.length > 0},attrs:{"type":"submit","disabled":!_vm.enabled}},[(_vm.enabled)?_c('span',[_c('i',{staticClass:"bi-exclamation-diamond-fill"}),_vm._v(" "+_vm._s(_vm.$t('ACCOUNT.2FA.DISABLE.SUBMIT.LABEL'))+" ")]):_c('span',{staticClass:"spinner-border text-light",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v(" "+_vm._s(_vm.$t('ACCOUNT.FIELD.SUBMIT.ACTION'))+" ")])])]),(_vm.errorMessage)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorMessage))+" ")]):_vm._e()]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }