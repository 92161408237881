<template>
    <form class="edit-community" action="#" method="post" @click.stop @submit.prevent="handleSubmit">
        <h2 class="header">{{$t('ACTION.COMMUNITY.EDIT')}}</h2>
        <div class="edit-body">
            <AbbiTextInput class="display-block width-100" name="name" :placeholder="$t('FIELDS.NAME')" :value="community.name" />
            <AbbiTextInput disabled class="display-block width-100 mt-1" name="slug" :placeholder="$t('FIELDS.SLUG')" :value="community.slug" />
        </div>
        <div class="footer pure-g with-gutter">
            <AbbiButton class="pure-u-1-2" grow is-submit>{{ $t('ACTION.COMMUNITY.UPDATE') }}</AbbiButton>
            <AbbiButton class="pure-u-1-2" grow is-red @click="close">{{ $t('ACTION.CANCEL') }}</AbbiButton>
        </div>
    </form>
</template>

<script lang="ts">
import Vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import CommunityModel from '@/models/community.model';
import CommunityApi from '@/apis/community.api';

export default Vue.extend({
    name: 'EditCommunity',
    components: {AbbiButton, AbbiTextInput},
    props: ['community'],
    methods: {
        handleSubmit({target}: Event) {
            const selectedCommunity = this.community as CommunityModel;

            const formData = new FormData(target as HTMLFormElement);
            const nameEntry = formData.get('name') as string;

            const changeTargets: { [key: string]: string | object } = {};
            if (nameEntry !== selectedCommunity.name) {
                changeTargets.name = nameEntry;
            }

            CommunityApi.updateCommunity(selectedCommunity.uuid as string, changeTargets)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        selectedCommunity.name = nameEntry;
                        this.$emit('edit-made', responseData);
                    }
                });
        },
        close() {
            this.$emit('close');
        },
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';

    .edit-community {
        display: flex;
        flex-direction: column;
        box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.5);
        background: white;
        width: 20%;
        min-width: max-content;
        height: 100%;
        z-index: 2;

        .header {
            padding: 1em;
            margin: 0;

            color: white;
            background-color: $abbi-blue;
        }

        .edit-body {
            flex: 1;
            padding: 1em;
        }

        div[class^="pure-u"] > * {
            width: 100%;
        }

        .footer {
            padding: 1em;
        }
    }
</style>
