import QuestionModel from '@/models/question.model';
import InstructionModel from '@/models/instruction.model';

export function createInstruction(
    uuid: string,
    description: string,
    question: QuestionModel,
): InstructionModel {
    return {
        uuid,
        type: 'question',
        translations: [
            {
                locale: 'en',
                content: description,
            },
        ],
        question,
    } as unknown as InstructionModel;
}

export default {createInstruction};
