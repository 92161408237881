import CommunityApi from '@/apis/community.api';
import CommunityService from '@/services/community.service';
import {CommunityStyling, Stylesheet} from '@/components/community/styling/CommunityStyling';
import getExtensionFromMimeType from '@/helpers/mime';

interface StylesheetKey {
    scope: string;
    property: string;
}

export default class StylesheetService {
    public static getStyle(communitySlug: string): Promise<CommunityStyling> {
        return CommunityApi.getStyle(communitySlug);
    }

    public static async save(communitySlug: string, styling: CommunityStyling): Promise<string> {
        const {web, stats} = styling;

        // Process dependencies before saving the style changes.
        await Promise.allSettled(
            [
                this.processDependencies(web),
                this.processDependencies(stats),
            ],
        );

        return CommunityApi.createUpdateStyle(
            communitySlug,
            {
                web: this.splitStylesheet(web),
                stats: this.splitStylesheet(stats),
            },
        );
    }

    /**
     * This method is used to support legacy data formatting.
     * Can be omitted once the API supports a simpler data structure.
     */
    private static splitStylesheet(stylesheet: Stylesheet): Stylesheet[] {
        return Object.keys(stylesheet).reduce(
            (carry: Stylesheet[], property: string) => [
                ...carry,
                {[property]: stylesheet[property]},
            ],
            [],
        );
    }

    private static processDependencies(
        stylesheet: Stylesheet,
    ): Promise<PromiseSettledResult<never|null>[]> {
        const promises: Promise<never|null>[] = [];

        Object.keys(stylesheet).forEach((key: string) => {
            const {property} = this.parseStylesheetKey(key);

            const value = stylesheet[key];

            const uploadableProperties = ['background-image', 'content'];

            if (uploadableProperties.includes(property)
                && value.startsWith('data:')
                // Don't bother converting the data URI when it fits inside a
                // database record as-is.
                && value.length > 255
            ) {
                promises.push(
                    this.uploadImage(
                        stylesheet,
                        key,
                        this.dataURItoFile(key, value),
                    ),
                );
            }

            if (['font-file'].includes(property)
                && value.startsWith('data:')) {
                promises.push(
                    this.uploadFont(
                        stylesheet,
                        key,
                        this.dataURItoFile('font', value),
                    ),
                );
            }
        });

        return Promise.allSettled(promises);
    }

    private static parseStylesheetKey(key: string): StylesheetKey {
        const keyMatch = /^(?<scope>[\w\d-]+)_(?<property>[\w\d-]+)$/.exec(key);

        if (!keyMatch || !keyMatch.groups) {
            throw new Error(
                `The key "${key}" does not follow the convention 
                of "scope_property" with [a-z-] as valid characters.`,
            );
        }

        return keyMatch.groups as unknown as StylesheetKey;
    }

    private static uploadImage(
        stylesheet: Stylesheet,
        key: string,
        image: File,
    ): Promise<never|null> {
        const formData = new FormData();
        formData.append(key, image);

        return CommunityApi.uploadImage(
            CommunityService.GetCurrentCommunityUuid(),
            formData,
        ).then((patch) => {
            if (patch) {
                Object.assign(stylesheet, patch);
            }

            return null;
        });
    }

    private static uploadFont(
        stylesheet: Stylesheet,
        key: string,
        file: File,
    ): Promise<never|null> {
        const formData = new FormData();
        formData.append('font', file);

        return CommunityApi.uploadFont(
            CommunityService.GetCurrentCommunityUuid(),
            formData,
        ).then((uri) => {
            const patch = { [key]: uri };

            if (patch) {
                Object.assign(stylesheet, patch);
            }

            return null;
        });
    }

    private static dataURItoFile(name: string, dataURI: string): File {
        const [optionsURI, data] = dataURI.split(',');
        const [, options] = optionsURI.split(':');
        const [type, encoding] = options.split(';');
        const extension = getExtensionFromMimeType(type);
        const byteString = atob(data);

        if (encoding !== 'base64') {
            throw new Error(`Unknown encoding: "${encoding}"`);
        }

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ab], `${name}.${extension}`, {type});
    }
}
