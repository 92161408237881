<template>
    <div id="removal-page">
        <main class="margin-centered">
            <table class="pure-table pure-table-bordered">
                <thead>
                    <tr>
                        <th>Community</th>
                        <th>Chat</th>
                        <th>Closed date</th>
                        <th>Removal warning</th>
                        <th>Deletion date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="script in scripts" class="selectable-row" :key="script.id" :id="script.id">
                        <td>{{ script.communityName }}</td>
                        <td>
                            <div v-if="script.name.length > 50" class="tooltip">
                                {{ script.name.substring(0, 50) }} ...
                                <span class="tooltiptext">{{ script.name }}</span>
                            </div>
                            <div v-else>{{ script.name }}</div>
                        </td>
                        <td>{{ script.closedDate }}</td>
                        <td>{{ script.removalWarningDate }}</td>
                        <td>{{ script.deletedDate }}</td>
                        <td>
                            <AbbiButton  @click="openEditRemovalModal(script)">
                                <FaIcon icon="edit" /> Edit
                            </AbbiButton>
                        </td>
                </tr>
                </tbody>
            </table>
        </main>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CommunityApi from '@/apis/community.api';
import EditRemovalModal from '@/components/modals/EditRemovalModal.vue';

export default vue.extend({
    name: 'Removal',
    components: {AbbiButton},
    data() {
        return {
            scripts: [],
        };
    },
    created() {
        const {communitySlug} = this.$route.params;
        CommunityApi.getRemovalChats(communitySlug)
            .then((responseData) => {
                this.scripts = responseData;
            });
        document.addEventListener('changeRemovalItem', this.changeRemovalItem as EventListener);
    },
    destroyed() {
        document.removeEventListener('changeRemovalItem', this.changeRemovalItem as EventListener);
    },
    methods: {
        changeRemovalItem($event: CustomEvent) {
            const script = $event.detail;

            if (!script.removalProcedure) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.scripts = this.scripts.filter((sc) => sc.id !== script.id);
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const idx = this.scripts.findIndex((s) => s.id === script.id);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.scripts[idx] = script;
            this.$forceUpdate();
        },
        openEditRemovalModal(script: any) {
            this.$store.commit('setModal', {
                title: 'Edit removal',
                component: EditRemovalModal,
                options: {
                    script,
                    modalStyle: {
                        width: '455px',
                    },
                    modalHeaderStyle: {
                        marginLeft: '168px !important',
                    },
                },

            });
        },
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';

    #removal-page {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        overflow: hidden;

        main {
            padding: 1em;

            table {
                width: 1115px;
                border-collapse: collapse;

                tr {
                    &.selectable-row {
                        cursor: pointer;

                        &:hover {
                            background-color: lighten($abbi-green, 20%);
                        }
                    }

                    > * {
                        padding: 0.25em;
                    }
                }
            }

            .right-side {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: auto;
        background-color: white;
        color: black;
        text-align: center;
        border-radius: 6px;
        padding: 5px;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>
