<template>
    <div class="overview" :class="{large: $route.name === overviewRoute}">
        <div v-if="$route.name === overviewRoute" class="nav-box">
            <div class="items">
                <router-link
                    v-for="link in overviewLinks"
                    :key="link.to.name"
                    :to="link.to"
                    class="cell"
                >
                    <FaIcon v-if="link.icon" :icon="link.icon" />
                    {{ $t(link.label) }}
                </router-link>
            </div>
        </div>
        <nav class="sub-nav" v-else>
            <router-link
                v-for="link in links"
                :key="link.to.name"
                :to="link.to"
                :class="{deprecated: link.deprecated}"
            >
                <FaIcon v-if="link.icon" :icon="link.icon" />
                {{ $t(link.label) }}
            </router-link>
        </nav>
    </div>
</template>

<script lang="ts">
import vue, {PropType} from 'vue';

export interface NavLink {
    to: {
        name: string;
    };
    label: string;
    icon: string;
    deprecated: boolean;
}

export default vue.extend({
    props: {
        overviewRoute: {
            type: String,
            required: true,
        },
        links: {
            type: Array as PropType<NavLink[]>,
            required: true,
        },
    },
    computed: {
        overviewLinks(): NavLink[] {
            return this.links.filter(
                (link: NavLink) => !link.deprecated,
            );
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../../styles/nav-box';

.overview {
    @include nav-box-container;

    &.large {
        height: calc(100vh - 76px);
    }
}
</style>
