<template>
    <div id="members-page">
        <main class="margin-centered">
            <div class="page-header flex-row mb-1">
                <h2 class="flex-1">Members overview</h2>
                <AbbiButton @click="exportMembers">Export members</AbbiButton>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CommunityApi from '@/apis/community.api';

export default vue.extend({
    name: 'Members',
    components: {AbbiButton},
    data() {
        return {
            amount: 0,
        };
    },
    methods: {
        GetDateAsString(date: Date) {
            let str = '';
            str += `${date.getDate()}-`;
            str += `${date.getMonth()}-`;
            str += `${date.getFullYear()} `;
            str += `${date.getHours()}:`;
            str += date.getMinutes();
            return str;
        },
        exportMembers() {
            const {slug, uuid} = this.$store.getters.community;

            CommunityApi
                .exportMembers(uuid)
                .then((response: Record<string, string>[]|undefined) => {
                    if (!response) {
                        return;
                    }

                    let csvDataUri = 'data:text/csv;charset=utf-8,';
                    csvDataUri += 'uuid,type,external_id,email,phone,birthdate,firstname,lastname\n';
                    response.forEach((respondent) => {
                        const serialised = [];
                        serialised.push(respondent.uuid || '');
                        serialised.push(respondent.type || '');
                        serialised.push(respondent.external_id || '');
                        serialised.push(respondent.email || '');
                        serialised.push(respondent.phone || '');

                        const dob = respondent.dateofbirth;
                        let dateofbirth = '';
                        if (dob) {
                            dateofbirth = this.GetDateAsString(new Date(dob));
                        }

                        serialised.push(dateofbirth);
                        serialised.push(respondent.firstname || '');
                        serialised.push(respondent.lastname || '');
                        csvDataUri += serialised.join(',');
                        csvDataUri += '\n';
                    });
                    const encodedUri = encodeURI(csvDataUri);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', `${slug}_member_export.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        },
    },
});
</script>

<style lang="scss">
#members-page {
    padding: 1rem;
    main {
        max-width: 960px;
    }
}
</style>
