<template>
    <div class="image-input">
        <label>
            <span class="preview" :style="{backgroundColor}">
                <img v-if="preview" :src="preview" alt="" />
                <FaIcon v-else icon="image" />
            </span>
            <input :id="id" ref="file" type="file" :accept="accept" @change="onChange" />
        </label>

        <Alert
            v-if="maxExceeded"
            can-close
            @close="maxExceeded = false"
            type="danger">
            <span v-html="$t('COMMUNITY.STYLING.INPUT.IMAGE.VALIDATION.MAX_EXCEEDED', {maxSizeInMegaBytes})" />
        </Alert>
        <Alert
            v-else-if="warn"
            can-close
            @close="warn = false"
            type="warning"
        >
            <span v-html="$t('COMMUNITY.STYLING.INPUT.IMAGE.VALIDATION.WARN_SIZE', {warnSizeInMegaBytes})" />
        </Alert>

        <Alert
            v-if="previewTooLarge"
            can-close
            @close="previewTooLarge = false"
            type="danger"
        >
            <span v-html="$t('COMMUNITY.STYLING.INPUT.IMAGE.VALIDATION.PREVIEW_TOO_LARGE')" />
        </Alert>

        <Alert
            v-if="imageCouldNotLoad"
            can-close
            @close="imageCouldNotLoad = false"
            type="danger"
        >
            <span v-html="$t('COMMUNITY.STYLING.INPUT.IMAGE.VALIDATION.IMAGE_COULD_NOT_LOAD')" />
        </Alert>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Alert from './Alert.vue';
import Input from './Input.vue';

export interface ImageInputProperties {
    acceptTypes?: string[];
    backgroundColor?: string;
}

export default Input.extend({
    components: {Alert},
    props: {
        id: {
            type: String,
            required: true,
        },
        acceptTypes: {
            type: Array as PropType<string[]>,
            default: () => ['image/jpeg', 'image/png'],
        },
        maximumSize: {
            type: Number,
            default: 1024 * 1024 * 10,
        },
        warningSize: {
            type: Number,
            default: 1024 * 1024 * 1.1,
        },
        backgroundColor: {
            type: String,
            default: 'transparent',
        },
    },
    watch: {
        value(url: string): void {
            this.preview = url;
        },
    },
    computed: {
        accept(): string {
            return this.acceptTypes.join(', ');
        },
        maxSizeInMegaBytes(): number {
            return this.maximumSize / 1024 / 1024;
        },
        warnSizeInMegaBytes(): number {
            return this.warningSize / 1024 / 1024;
        },
    },
    data() {
        return {
            preview: this.value,
            maxExceeded: false,
            warn: false,
            previewTooLarge: false,
            imageCouldNotLoad: false,
        };
    },
    methods: {
        onChange(event: Event): void {
            this.preview = '';
            this.maxExceeded = false;
            this.warn = false;
            this.previewTooLarge = false;
            this.imageCouldNotLoad = false;

            const target = event.target as HTMLInputElement;
            const [image] = target.files as FileList;

            if (!image) {
                return;
            }

            if (image.size === 0) {
                this.imageCouldNotLoad = true;
                return;
            }

            if (image.size > this.maximumSize) {
                this.maxExceeded = true;
                return;
            }

            if (image.size > this.warningSize) {
                this.warn = true;
            }

            const reader = new FileReader();
            reader.addEventListener('load', (load: ProgressEvent) => {
                const loadTarget = load.target as unknown as {result: string};
                const dataURI = loadTarget.result;
                const {style} = document.documentElement;
                const property = '--preview';

                // Test that the preview can be shown by the portal previews.
                style.setProperty(property, '');
                style.setProperty(property, `url(${dataURI})`);

                this.$nextTick(() => {
                    if (style.getPropertyValue(property).length < 5) {
                        this.previewTooLarge = true;
                        // Revert the preview to the previous state.
                        this.preview = this.value;
                        return;
                    }

                    this.$emit('input', dataURI);
                });
            });
            reader.readAsDataURL(image);
        },
    },
});
</script>

<style lang="scss" scoped>
.image-input {
    .alert {
        margin-top: .5em;
        text-align: left;
    }

    label {
        border-radius: .5em;
        padding: .5em;
        box-shadow: rgba(0, 0, 0, .7) 0 0 4px -1px;
        margin: .5em 3px;
        cursor: pointer;
        text-align: center;
        width: calc(100% - 6px);
        background: rgba(100, 100, 100, .05);

        input[type=file] {
            display: none;
        }

        &:hover {
            background: rgba(200, 200, 200, .25);

            .preview {
                svg {
                    color: rgba(100, 100, 100, .5);
                }
            }
        }

        .preview {
            display: inline-block;
            height: 5em;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            img {
                max-height: 100%;
                max-width: 20em;
                width: 100%;
                height: 100%;
            }

            svg {
                height: 2em;
                width: auto;
                margin: 1.5em;
                color: rgba(100, 100, 100, .25);
            }
        }
    }
}
</style>
