<template>
    <div class="w-100">
        <FieldSet title="choices" scenario="choice" icon="square-check">
            <FieldSet title="choices-inactive" hide-scenario scenario="choice">
                <Field property="choices_background-color" />
                <Field property="choices_color" />
            </FieldSet>

            <FieldSet title="choices-active" hide-scenario scenario="choice">
                <Field property="choices_active-background-color" />
                <Field property="choices_active-color" />
            </FieldSet>

            <FieldSet title="choices_shape" hide-scenario scenario="choice" icon="shapes" can-collapse>
                <ShapeField
                    :shapes="choiceShapes"
                    padding="choices_padding"
                    message="message"
                    border="choices_border-radius"
                />

                <FieldSet title="choices_outline" hide-scenario scenario="choice">
                    <Field
                        property="choices_border-width"
                        :input="inputs.dataRange"
                        :options="choicesBorderWidthOptions"
                    />
                    <Field
                        v-show="hasWidth('choices_border-width')"
                        property="choices_inactive-border-color"
                    />
                    <Field
                        v-show="hasWidth('choices_border-width')"
                        property="choices_active-border-color"
                    />
                </FieldSet>
            </FieldSet>

            <FieldSet title="dropdown" scenario="dropdown" can-collapse>
                <p v-html="$t('COMMUNITY.STYLESHEET.CONFIGURATOR.CHAT.FIELDSET.DROPDOWN.WARNING')" />
                <Field property="input-control_background-color" alias="dropdown_background-color" deprecated />
                <Field property="input-control_color" alias="dropdown_color" deprecated />
                <Field property="input-control_placeholder-color" alias="dropdown_placeholder-color" deprecated />
            </FieldSet>
        </FieldSet>

        <FieldSet title="input" scenario="openAnswer" icon="keyboard">
            <Field property="input-control_background-color" />
            <Field property="input-control_color" />
            <Field property="input-control_placeholder-color" />

            <FieldSet title="input_shape" can-collapse icon="shapes" hide-scenario scenario="openAnswer">
                <ShapeField
                    :shapes="inputShapes"
                    padding="input-control_padding"
                    message="message"
                    border="input-control_border-radius"
                />

                <FieldSet title="input_outline" hide-scenario scenario="openAnswer">
                    <Field
                        property="input-control_border-width"
                        :input="inputs.dataRange"
                        :options="inputBorderWidthOptions"
                    />
                    <Field
                        v-show="hasWidth('input-control_border-width')"
                        property="input-control_border-color"
                    />
                </FieldSet>

                <FieldSet title="input_shadow" hide-scenario scenario="openAnswer">
                    <Field property="input-control_box-shadow-enabled" :input="inputs.toggle" />
                </FieldSet>
            </FieldSet>
        </FieldSet>

        <FieldSet title="slider" scenario="slider" icon="sliders">
            <Field property="slider_background-color-right" />
            <Field property="slider_background-color-left" />
            <Field property="slider_color-min-max" />
        </FieldSet>

        <FieldSet title="star_rating" scenario="starRating" icon="star-half-stroke">
            <FieldSet title="star_rating.inactive">
                <Field property="star_rating_inactive-color" />
            </FieldSet>

            <FieldSet title="star_rating.active">
                <Field property="star_rating_active-color" />
            </FieldSet>

            <FieldSet title="star_rating_border">
                <Field
                    property="star_rating_border-width"
                    :input="inputs.dataRange"
                    :options="this.starRatingBorderWidthOptions"
                />
                <Field property="star_rating_border-color" v-if="displayHeaderStarRatingBorderColor" />
            </FieldSet>
        </FieldSet>

        <FieldSet title="actions" icon="paper-plane">
            <FieldSet title="actions-enabled" scenario="actionsEnabled">
                <Field property="action-button_background-color" />
                <Field property="action-button_color" />
            </FieldSet>

            <FieldSet title="actions-disabled" scenario="actionsDisabled">
                <Field property="action-button_disabled-background-color" />
                <Field property="action-button_disabled-color" />
            </FieldSet>
        </FieldSet>

        <FieldSet title="validation" scenario="invalid" icon="triangle-exclamation">
            <Field property="validation-error_color" />
        </FieldSet>
    </div>
</template>

<script lang="ts">
import ChatForm from '@/components/community/styling/chat/ChatForm.vue';
import FieldSet from '@/components/community/styling/form/FieldSet.vue';
import Field from '@/components/community/styling/form/Field.vue';
import ShapeField from '@/components/community/styling/form/ShapeField.vue';
import Shape from '@/components/community/styling/form/ShapeField/Shape';
import { DataRangeOptions } from '@/components/community/styling/Input/DataRangeInput.vue';

export default ChatForm.extend({
    components: {ShapeField, Field, FieldSet},
    props: {

    },
    data() {
        const borderWidthOptions = {
            options: [
                {value: 0, label: 'No'},
                {value: 1},
                {value: 2},
                {value: 3},
            ],
            unit: 'px',
            legend: true,
        } as DataRangeOptions;

        const starRatingBorderWidthOptions = {
            options: [
                {value: 0, label: 'No'},
                {value: 1},
                {value: 2},
            ],
            unit: 'px',
            decimals: 0,
            legend: true,
        } as DataRangeOptions;

        return {
            choiceShapes: [
                {borderRadius: '.75em', padding: '.75em'},
                {borderRadius: '1.75em', padding: '.75em'},
            ] as Shape[],
            inputShapes: [
                {borderRadius: '.75em', padding: '.75em'},
                {borderRadius: '1.75em', padding: '1em'},
            ] as Shape[],
            choicesBorderWidthOptions: borderWidthOptions,
            inputBorderWidthOptions: borderWidthOptions,
            starRatingBorderWidthOptions,
        };
    },
    computed: {
        displayHeaderStarRatingBorderColor(): boolean {
            return this.stylesheet?.['star_rating_border-width'] !== '0px';
        },
    },
});
</script>
