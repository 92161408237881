<template>
    <div v-if="config.system.totpEnabled || config.user.totpEnabled">
        <AccountDisableTwoFactorAuthentication v-if="config.user.totpEnabled" />
        <AccountEnableTwoFactorAuthentication v-else :config="config.user" />
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import {TwoFactorConfiguration} from '@/interfaces/two-factor.interface';
import AccountApi from '@/apis/account.api';
import AccountEnableTwoFactorAuthentication from '@/components/account/AccountEnableTwoFactorAuthentication.vue';
import AccountDisableTwoFactorAuthentication from '@/components/account/AccountDisableTwoFactorAuthentication.vue';

interface Config {
    system: TwoFactorConfiguration;
    user: TwoFactorConfiguration;
}

interface Secret {
    key: string;
    image: string;
    account: string;
}

interface ComponentData {
    config: Config;
}

export default Vue.extend({
    name: 'AccountTwoFactorAuthentication',
    components: {
        AccountDisableTwoFactorAuthentication,
        AccountEnableTwoFactorAuthentication,
    },
    beforeMount() {
        AccountApi.twoFactorConfiguration().then(
            (config: Config) => {
                this.config = config;
            },
        );
    },
    data(): ComponentData {
        return {
            config: {
                system: {
                    totpEnabled: false,
                    backupCodesEnabled: false,
                    totpDigits: 100,
                },
                user: {
                    totpEnabled: false,
                    backupCodesEnabled: false,
                    totpDigits: 100,
                },
            },
        };
    },
});
</script>
