<template>
    <Card
        class="row"
        title="ACCOUNT.PASSWORD.HEADING">
        <template #subtitle v-if="!saved">
            {{ $t('ACCOUNT.PASSWORD.INTRODUCTION') }}
        </template>
        <template #subtitle v-else>
            <span class="text-success">
                {{ $t('ACCOUNT.PASSWORD.SUBMIT.SUCCESS') }}
            </span>
        </template>

        <form class="mt-3" @submit.prevent="submitForm()" v-if="!saved">
            <input
                v-if="resetToken !== undefined"
                name="resetToken"
                :value="resetToken"
                type="hidden" />
            <div v-else class="mb-3 row">
                <label for="oldPassword" class="col-sm-3 col-form-label">
                    {{ $t('ACCOUNT.FIELD.OLD_PASSWORD.LABEL') }}
                </label>
                <div class="col-sm-9">
                    <div class="input-group has-validation">
                        <i class="input-group-text bi-key"></i>
                        <input
                            :type="visibility.oldPassword ? 'text' : 'password'"
                            class="form-control"
                            :class="{
                                    'is-invalid': violations.hasError('oldPassword'),
                                    'is-valid': isValid('oldPassword'),
                                }"
                            @input="mutateForm()"
                            id="oldPassword"
                            name="oldPassword"
                            autocomplete="current-password"
                            required
                            aria-describedby="oldPasswordHelp"
                            v-model="oldPassword" />
                        <TogglePasswordVisibility :visible="visibility.oldPassword" @toggle="toggleVisibility('oldPassword')" />
                        <ViolationList field="oldPassword" :violations="violations" />
                    </div>
                    <div id="oldPasswordHelp" class="form-text">
                        {{ $t('ACCOUNT.FIELD.OLD_PASSWORD.HELP') }}
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <label for="newPassword" class="col-sm-3 col-form-label">
                    {{ $t('ACCOUNT.FIELD.NEW_PASSWORD.LABEL') }}
                </label>
                <div class="col-sm-9">
                    <div class="input-group has-validation">
                        <i class="input-group-text bi-key"></i>
                        <input
                            :type="visibility.newPassword ? 'text' : 'password'"
                            class="form-control"
                            :class="{
                                    'is-invalid': violations.hasError('newPassword'),
                                    'is-valid': isValid('newPassword'),
                                }"
                            @input="mutateForm()"
                            id="newPassword"
                            name="newPassword"
                            autocomplete="new-password"
                            :data-passwordrules="getPasswordRules()"
                            required
                            aria-describedby="newPasswordHelp"
                            v-model="newPassword" />
                        <TogglePasswordVisibility :visible="visibility.newPassword" @toggle="toggleVisibility('newPassword')" />
                        <ViolationList field="newPassword" :violations="violations" />
                    </div>
                    <div id="newPasswordHelp" class="form-text">
                        {{ $t('ACCOUNT.FIELD.NEW_PASSWORD.HELP') }}
                    </div>
                </div>
            </div>

            <div class="mb-3 row" v-if="newPassword.length > 0 && getPasswordStrength() < 100">
                <div class="col-sm-3"></div>

                <div class="col-sm-9">
                    <Progress
                        class="mb-3"
                        label="ACCOUNT.PASSWORD.STRENGTH.LABEL"
                        :value="getPasswordStrength()" />
                    <ul class="list-group">
                        <li class="list-group-item" v-if="newPassword.length < constraints.minimumLength">
                            {{ $t('ACCOUNT.PASSWORD.CONSTRAINTS.MINIMUM_LENGTH', {minimumLength: constraints.minimumLength}) }}
                        </li>
                        <li class="list-group-item"
                            v-for="component in unmetComponents"
                            :key="component.name">
                            {{ $t(
                            'ACCOUNT.PASSWORD.CONSTRAINTS.COMPONENT_TEMPLATE',
                            {
                                componentConstraint: $tc(
                                    component.name,
                                    component.minimumOccurrences,
                                    component,
                                )
                            }
                        ) }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mb-3 row" v-if="!saved">
                <label for="confirmPassword" class="col-sm-3 col-form-label">
                    {{ $t('ACCOUNT.FIELD.CONFIRM_PASSWORD.LABEL') }}
                </label>
                <div class="col-sm-9">
                    <div class="input-group has-validation">
                        <i class="input-group-text bi-key"></i>
                        <input
                            :type="visibility.newPassword ? 'text' : 'password'"
                            class="form-control"
                            :class="{
                                    'is-invalid': violations.hasError('confirmPassword'),
                                    'is-valid': isValid('confirmPassword'),
                                }"
                            @input="mutateForm()"
                            id="confirmPassword"
                            name="confirmPassword"
                            autocomplete="new-password"
                            required
                            aria-describedby="confirmPasswordHelp"
                            v-model="confirmPassword" />
                        <TogglePasswordVisibility :visible="visibility.newPassword" @toggle="toggleVisibility('newPassword')" />
                        <ViolationList field="confirmPassword" :violations="violations" />
                    </div>
                    <div id="confirmPasswordHelp" class="form-text">
                        {{ $t('ACCOUNT.FIELD.CONFIRM_PASSWORD.HELP') }}
                    </div>
                </div>
            </div>

            <div class="mt-4 input-group has-validation">
                <button
                    type="submit"
                    v-if="(
                            oldPassword.length > 0
                            || newPassword.length > 0
                            || confirmPassword.length > 0
                        )"
                    :disabled="!canSubmit()"
                    class="btn btn-lg btn-primary w-100"
                    :class="{'is-valid': saved, 'is-invalid': errorMessage.length > 0}">
                    <span v-if="enabled">
                        {{ $t('ACCOUNT.PASSWORD.SUBMIT.LABEL') }}
                    </span>
                    <span v-else class="spinner-border text-light" role="status">
                        <span class="visually-hidden">
                            {{ $t('ACCOUNT.FIELD.SUBMIT.ACTION') }}
                        </span>
                    </span>
                </button>
                <div v-if="errorMessage" class="invalid-feedback">
                    {{ $t(errorMessage) }}
                </div>
            </div>
        </form>
    </Card>
</template>

<script lang="ts">
import Vue from 'vue';
import TogglePasswordVisibility from '@/components/account/TogglePasswordVisibility.vue';
import AccountApi from '@/apis/account.api';
import ViolationList from '@/components/form/ViolationList.vue';
import ViolationListModel from '@/models/violation.list.model';
import {PasswordComponent, PasswordConstraints} from '@/interfaces/password.interface';
import Card from '@/components/bootstrap/Card.vue';
import Progress from '@/components/bootstrap/Progress.vue';

interface FieldVisibilities {
    oldPassword: boolean;
    newPassword: boolean;
}

interface ComponentData {
    constraints: PasswordConstraints;
    visibility: FieldVisibilities;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    enabled: boolean;
    violations: ViolationListModel;
    saved: boolean;
    errorMessage: string;
}

export default Vue.extend({
    name: 'AccountPassword',
    components: {
        Progress,
        Card,
        ViolationList,
        TogglePasswordVisibility,
    },
    props: ['resetToken'],
    beforeMount() {
        AccountApi.passwordConstraints(this.resetToken as string).then(
            (constraints: PasswordConstraints) => {
                this.constraints = constraints;
            },
        );
    },
    computed: {
        unmetComponents(): Array<PasswordComponent> {
            return this.constraints.components.filter(
                (component: PasswordComponent) => this.getComponentStrength(component) < 1,
            );
        },
    },
    data(): ComponentData {
        return {
            constraints: {
                minimumLength: 1,
                components: [],
            },
            visibility: {
                oldPassword: false,
                newPassword: false,
            },
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            enabled: false,
            violations: new ViolationListModel(),
            saved: false,
            errorMessage: '',
        };
    },
    methods: {
        toggleVisibility(field: string): void {
            if (field === 'oldPassword') {
                this.visibility.oldPassword = !this.visibility.oldPassword;
                return;
            }

            this.visibility.newPassword = !this.visibility.newPassword;
        },
        canSubmit(): boolean {
            return ['oldPassword', 'newPassword', 'confirmPassword'].reduce(
                (carry: boolean, current: string) => carry && this.isValid(current),
                this.enabled,
            );
        },
        getComponentStrength(component: PasswordComponent): number {
            const characters = component.characters.replace(
                /[.*+?^${}()|[\]\\]/g,
                '\\$&',
            );
            const expression = new RegExp(`[${characters}]`);
            return Math.min(
                (
                    (this.newPassword.match(expression)?.length || 0)
                    / component.minimumOccurrences
                ),
                1,
            );
        },
        getPasswordStrength(): number {
            const componentPercentage = 100 / (this.constraints.components.length + 1);
            let strength = (
                Math.min((this.newPassword.length / this.constraints.minimumLength), 1)
                * componentPercentage
            );

            this.constraints.components.forEach(
                (component: PasswordComponent) => {
                    strength += (
                        this.getComponentStrength(component)
                        * componentPercentage
                    );
                },
            );

            return strength;
        },
        // @see https://developer.1password.com/docs/web/compatible-website-design/#provide-password-requirements
        getPasswordRules(): string {
            const rules = [
                `minlength: ${this.constraints.minimumLength};`,
            ];

            this.constraints.components.forEach(
                (component: PasswordComponent) => {
                    for (let i = 0; i < component.minimumOccurrences; i++) {
                        rules.push(`required: [${component.characters}];`);
                    }
                },
            );

            return rules.join(' ');
        },
        isValid(field: string): boolean {
            if (this.saved) {
                return true;
            }

            if (this.violations.hasError(field)) {
                return false;
            }

            if (field === 'oldPassword'
                && (this.oldPassword.length > 0 || this.resetToken !== undefined)
            ) {
                return true;
            }

            if (field === 'confirmPassword'
                && this.confirmPassword.length > 0
                && this.confirmPassword === this.newPassword
            ) {
                return this.isValid('newPassword');
            }

            if (field === 'newPassword') {
                return (
                    this.getPasswordStrength() === 100
                    && this.newPassword !== this.oldPassword
                );
            }

            return false;
        },
        mutateForm() {
            this.enabled = true;
            this.violations.reset();
            this.saved = false;
            this.errorMessage = '';
        },
        submitForm() {
            this.enabled = false;
            this.violations.reset();
            this.errorMessage = '';

            const promise = this.resetToken === undefined
                ? AccountApi.updatePassword(
                    this.oldPassword,
                    this.newPassword,
                    this.confirmPassword,
                )
                : AccountApi.resetPassword(
                    this.resetToken,
                    this.newPassword,
                    this.confirmPassword,
                );

            promise.then(
                (userData) => {
                    this.enabled = true;

                    if (userData) {
                        this.$emit('success');
                        this.saved = true;
                        return;
                    }

                    AccountApi.getErrorData().then((errorData) => {
                        const violations = errorData.violations ?? [];

                        this.violations.apply(violations);

                        if (violations.length === 0) {
                            this.errorMessage = 'ACCOUNT.PASSWORD.SUBMIT.FAILURE';
                        }
                    });
                },
            );
        },
    },
});
</script>
