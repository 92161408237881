<template>
    <div class="add-user">
        <div v-for="errorMessage in errorMessages" class="error-message" :key="errorMessage.msg">
            <h3>{{ errorMessage.msg }}</h3>
        </div>
        <form class="add-user-component" method="post" :action="action" v-on:submit.prevent="createUser">
            <AbbiTextInput is-small name="username" :placeholder="$t('USER.USERNAME')" />
            <AbbiTextInput class="mt-05" is-small name="email" type="email" :placeholder="$t('USER.EMAIL')" />
            <AbbiTextInput class="mt-05" is-small name="password" isPassword :placeholder="$t('USER.PASSWORD')" />
            <AbbiButton class="submit-button" :grow="true" is-submit="true">
                {{ $t('ACTION.USER.CREATE').charAt(0).toUpperCase() + $t('ACTION.USER.CREATE').slice(1) }}
            </AbbiButton>
        </form>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import UserApi from '@/apis/user.api';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ErrorModel from '@/models/error.model';

export default Vue.extend({
    name: 'AddUserModal',
    components: {AbbiButton, AbbiTextInput},
    data() {
        return {
            action: `${window.location.protocol}//${window.location.hostname}:3000/api/users`,
            errorMessages: null as null | ErrorModel[],
        };
    },
    methods: {
        createUser({target}: Event) {
            const form = new FormData(target as HTMLFormElement);
            UserApi.createNewUser(form.get('username') as string, form.get('email') as string, form.get('password') as string)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        const event = new CustomEvent('addUser', {detail: responseData});
                        document.dispatchEvent(event);
                        this.$emit('close');
                        this.$store.dispatch('closeModal');
                    }
                })
                .catch(() => {
                    UserApi.getErrorData().then((errorData) => {
                        const newErrors = [] as ErrorModel[];
                        Object.entries(errorData).forEach((entry) => {
                            const messages = entry[1] as string[]; // idx 0 has the field name, idx 1 has the value(s)
                            for (let i = 0; i < messages.length; i += 1) {
                                const errorMsg = new ErrorModel();
                                errorMsg.msg = this.$t(messages[i]);
                                newErrors.push(errorMsg);
                            }
                        });
                        this.errorMessages = newErrors;
                    });
                });
        },
    },
});
</script>

<style lang="scss">
.add-user {
    &.error-message {
        h3 {
            padding: 0;
            margin: 0;

            font-weight: bold;
            font-size: 1.1em;
        }

        background-color: darken(red, 15%);
        color: white;

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        margin: -2px;
        margin-bottom: 0;
        padding: 0.5rem 0.75rem;
    }
}

.add-user-component {
    display: flex;
    flex-direction: column;

    padding: 0.75em 1em;

    .submit-button {
        margin-top: 0.5em;
    }
}
</style>
