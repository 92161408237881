<template>
    <div id="user-management-page" @click="selectRow(undefined)">
        <main class="margin-centered">
            <table>
                <tr>
                    <th>{{$t('USER.USERNAME')}}</th>
                    <th>{{$t('USER.EMAIL')}}</th>
                    <th>{{$t('USER.UNIQUE_ID')}}</th>
                    <th>
                        <AbbiButton @click="openAddUserModal" >{{ $t('ACTION.USER.ADD') }}</AbbiButton>
                    </th>
                </tr>
                <tr v-for="user in users" :key="user.uuid" :id="user.uuid"
                    class="selectable-row"
                    :class="{active: user === selectedUser}"
                    @click.stop="selectRow(user)">
                    <td>{{user.username}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.uuid}}</td>
                    <td>
                        <div v-if="user.uuid !== $store.state.currentUser.uuid">
                            <AbbiButton is-red @click.stop="promptRemove(user.uuid)"><FaIcon icon="trash" /></AbbiButton>
                        </div>
                    </td>
                </tr>
            </table>
            <EditAccount v-if="selectedUser !== null" :user="selectedUser" class="right-side"
                         @close="selectRow(undefined)" @edit-made="processChangedUser" />
        </main>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import UserModel from '@/models/user.model';
import AddUserModal from '@/components/modals/AddUserModal.vue';
import UserApi from '@/apis/user.api';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import EditAccount from '@/components/account/EditAccount.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

export default Vue.extend({
    name: 'UserManagement',
    components: {EditAccount, AbbiButton},
    data() {
        return {
            users: [] as UserModel[],
            currentRow: null as HTMLElement | null,
            selectedUser: null as UserModel | null,
        };
    },
    methods: {
        openAddUserModal() {
            this.$store.commit('setModal', {title: this.$t('ACTION.USER.ADD'), component: AddUserModal});
        },
        processAddedUser($event: CustomEvent) {
            this.users.push($event.detail as UserModel);
        },
        processChangedUser(userData: UserModel) {
            const idx = this.users.findIndex((user) => user.uuid === userData.uuid);
            this.users[idx] = userData;
            this.selectRow(undefined);
        },
        selectRow(userObject: UserModel | undefined) {
            if (userObject !== undefined) {
                this.currentRow = document.getElementById(userObject.uuid as string);
                this.selectedUser = userObject as UserModel;
            } else {
                this.currentRow = null;
                this.selectedUser = null;
            }
        },
        promptRemove(uuid: string) {
            UserApi.getDeleteUserCSRF(uuid)
                .then((csrfResponseData) => {
                    if (csrfResponseData !== undefined) {
                        const csrf = csrfResponseData.deleteToken as string;
                        this.$store.commit('setModal', {
                            title: this.$t('ACTION.CONFIRMATION'),
                            component: ConfirmationModal,
                            options: {
                                message: this.$t('ACTION.USER.REMOVE_MESSAGE'),
                                actions: {
                                    confirm: {
                                        message: this.$t('ACTION.YES'),
                                        action: this.removeUser,
                                        argument: {uuid, csrf},
                                    },
                                    cancel: {
                                        message: this.$t('ACTION.NO'),
                                    },
                                },
                            },
                        });
                    }
                });
        },
        removeUser({uuid = '' as string, csrf = '' as string}) {
            UserApi.deleteUser(uuid, csrf)
                .then((responseData) => {
                    if (responseData !== undefined) {
                        this.users = this.users.filter((user) => user.uuid !== uuid);
                    }
                });
        },
    },
    created() {
        document.addEventListener('addUser', this.processAddedUser as EventListener);
        // get all users
        UserApi.getAllUsers()
            .then((responseData) => {
                if (responseData !== undefined) {
                    this.users = responseData;
                }
            });
    },
    destroyed() {
        document.removeEventListener('addUser', this.processAddedUser as EventListener);
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';

    #user-management-page {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        overflow: hidden;

        main {
            padding: 1em;

            table {
                border-collapse: collapse;

                tr {
                    &.selectable-row {
                        cursor: pointer;

                        &:hover {
                            background-color: lighten($abbi-green, 20%);
                        }
                    }

                    > * {
                        padding: 0.25em;
                    }
                }
            }

            .right-side {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
</style>
