<script lang="ts">
import ChatConfigurator, {createMessage} from '@/components/community/styling/chat/ChatConfigurator.vue';
import ChatSettingsForm from '@/components/community/styling/chat/application/ChatSettingsForm.vue';
import ChatForm from '@/components/community/styling/chat/ChatForm.vue';

let intervalId = null as null | number;
let timeoutId = null as null | number;

const interval = 10000;
const timeout = 2000;

const instructions = [
    createMessage(
        '1',
        `
        Once upon a time in the quaint village of Eldoria, nestled between
        rolling hills and lush greenery, lived a curious young girl named Amelia.
        With sparkling blue eyes and a mop of unruly golden curls, she spent
        her days exploring the enchanting forests that bordered the village.
        One sunny afternoon, while wandering deep into the woods, Amelia
        stumbled upon an ancient, mysterious door covered in intricate carvings.
        Intrigued, she turned the ornate handle, and to her surprise, the door
        creaked open to reveal a hidden world beyond.`,
    ),
    createMessage(
        '2',
        `
        As Amelia stepped through the threshold, she found herself in a magical
        realm filled with vibrant colors and strange creatures. Giant butterflies
        fluttered around, emitting a soft glow, and talking trees whispered
        ancient secrets. A mischievous pixie named Spark joined Amelia on her
        adventure, guiding her through the whimsical landscapes. Together,
        they embarked on a quest to unlock the mysteries of this fantastical
        realm and restore balance to the magical forces that governed it.`,
    ),
    createMessage(
        '3',
        `
        The duo encountered challenges and puzzles that tested their wit and
        courage. Along the way, they formed unlikely alliances with mystical
        beings and made new friends, each with a unique story to share. Amelia
        discovered her own latent magical abilities, allowing her to harness the
        power of the elements. As she honed her skills, the bond between her and
        Spark grew stronger, and their friendship became a beacon of hope in the
        magical realm.`,
    ),
    createMessage(
        '4',
        `
        In the end, after overcoming trials and tribulations, Amelia and Spark
        discovered the key to restoring balance to the magical realm. With a
        selfless act of kindness, they unleashed a wave of positive energy that
        swept through the land, bringing harmony and joy to every corner. As
        Amelia stepped back through the mysterious door, she carried the lessons
        of her magical journey back to Eldoria, forever changed and grateful for
        the extraordinary adventure that had unfolded in the hidden realm beyond
        the door.`,
    ),
    createMessage(
        '5',
        'The end.',
    ),
];

export default ChatConfigurator.extend({
    computed: {
        current(): number {
            return this.options.current;
        },
        max(): number {
            return this.options.max;
        },
        instructions(): object[] {
            return instructions.slice(0, this.current);
        },
        currentInstruction(): null|object {
            return this.instructions[this.current - 1] ?? null;
        },
        completed(): number {
            return this.current / instructions.length;
        },
        isTyping(): boolean {
            return this.completed < 1;
        },
        form(): typeof ChatForm {
            return ChatSettingsForm;
        },
    },
    data() {
        return {
            options: {
                current: 0,
                max: instructions.length,
            },
        };
    },
    watch: {
        current(newCurrent: number): void {
            if (newCurrent >= instructions.length) {
                this.clearInterval();
                return;
            }

            if (newCurrent === 0 && this.max > 0 && timeoutId === null) {
                this.setupTimeout();
                return;
            }

            if (newCurrent < this.max && intervalId === null) {
                this.setupInterval();
            }
        },
    },
    methods: {
        setupInterval(): void {
            this.clearTimeout();
            this.clearInterval();

            intervalId = window.setInterval(
                () => {
                    this.options.current++;
                },
                interval,
            );
        },
        clearInterval(): void {
            if (intervalId) {
                window.clearInterval(intervalId);
            }
            intervalId = null;
        },
        setupTimeout(): void {
            this.clearTimeout();
            this.clearInterval();

            timeoutId = window.setTimeout(
                () => {
                    this.options.current = 1;
                    this.setupInterval();
                },
                timeout,
            );
        },
        clearTimeout(): void {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
            timeoutId = null;
        },
    },
    created(): void {
        this.setupTimeout();
    },
    destroyed(): void {
        this.clearTimeout();
        this.clearInterval();
    },
});
</script>
