import Vue from 'vue';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCommentDots,
    faPlusCircle,
    faMinusCircle,
    faTimes,
    faTrash,
    faPollH,
    faEdit,
    faEllipsisV,
    faInfoCircle,
    faPlay,
    faDoorClosed,
    faUser,
    faBan,
    faCopy,
    faArrowsAltV,
    faArrowDown,
    faArrowUp,
    faChartBar,
    faChartLine,
    faChartPie,
    faArchive,
    faCodeFork,
    faCog,
    faKey,
    faCloud,
    faProjectDiagram,
    faLayerGroup,
    faPlus,
    faMinus,
    faPowerOff,
    faBinoculars,
    faQuestion,
    faDesktop,
    faMobile,
    faFont,
    faBolt,
    faFloppyDisk,
    faDroplet,
    faPalette,
    faUsersViewfinder,
    faGears,
    faTrashCan,
    faCheck,
    faCube,
    faComments,
    faPenToSquare,
    faWandMagicSparkles,
    faArrowRight,
    faBackwardFast,
    faForwardFast,
    faEye,
    faImage,
    faEllipsis,
    faMessage,
    faLink,
    faSquare,
    faTriangleExclamation,
    faPaperPlane,
    faSquareCheck,
    faKeyboard,
    faSliders,
    faCaretDown,
    faCaretRight,
    faPeopleRoof,
    faShapes,
    faTags,
    faServer,
    faSignature,
    faEuroSign,
    faCamera,
    faFlagCheckered,
    faArrowRightFromBracket,
    faSignsPost,
    faHand,
    faBarsProgress,
    faStarHalfStroke,
    faFileExport, faFileImport,
    faRectangleList,
    faLaptopCode,
    faHatWizard,
    faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';

library.add(
    faCommentDots,
    faPlusCircle,
    faMinusCircle,
    faTimes,
    faTrash,
    faEdit,
    faPollH,
    faEllipsisV,
    faInfoCircle,
    faPlay,
    faDoorClosed,
    faUser,
    faBan,
    faCopy,
    faArrowsAltV,
    faArrowDown,
    faArrowUp,
    faChartBar,
    faChartLine,
    faChartPie,
    faArchive,
    faCodeFork,
    faCog,
    faKey,
    faCloud,
    faProjectDiagram,
    faLayerGroup,
    faPlus,
    faMinus,
    faPowerOff,
    faBinoculars,
    faQuestion,
    faDesktop,
    faMobile,
    faFont,
    faBolt,
    faFloppyDisk,
    faDroplet,
    faPalette,
    faUsersViewfinder,
    faGears,
    faTrashCan,
    faCheck,
    faCube,
    faComments,
    faPenToSquare,
    faWandMagicSparkles,
    faArrowRight,
    faBackwardFast,
    faForwardFast,
    faEye,
    faImage,
    faEllipsis,
    faMessage,
    faLink,
    faSquare,
    faTriangleExclamation,
    faPaperPlane,
    faSquareCheck,
    faKeyboard,
    faSliders,
    faCaretDown,
    faCaretRight,
    faPeopleRoof,
    faShapes,
    faTags,
    faServer,
    faSignature,
    faEuroSign,
    faCamera,
    faFlagCheckered,
    faArrowRightFromBracket,
    faSignsPost,
    faHand,
    faBarsProgress,
    faStarHalfStroke,
    faFileImport, faFileExport,
    faRectangleList,
    faLaptopCode,
    faHatWizard,
    faChevronLeft,
);

Vue.component('FaIcon', FontAwesomeIcon);
Vue.component('FaLayers', FontAwesomeLayers);
