<template>
    <div class="configurator-wrapper">
        <form @submit.prevent v-if="form" class="w-100">
            <component
                :is="form"
                :portal="portal"
                :options="options"
                :stylesheet="stylesheet"
                :statsStylesheet="statsStylesheet"
                :defaults="defaults"
                :inheritor="inheritor"
                :updater="updater"
                @openColorPicker="openColorPicker"
            />
            <ColorPicker
                :ref="colorPickerRef"
                :style="colorPickerStyle"
                :value="focalPoint.value"
                @close="closeColorPicker"
                @input="updateColor"
            />
        </form>
        <div class="options" v-if="optionsSelector">
            <component :options="options" :is="optionsSelector" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import StylesheetInheritor from '@/components/community/styling/StylesheetInheritor';
import {Stylesheet} from '@/components/community/styling/CommunityStyling';
import StylesheetUpdater from '@/components/community/styling/StylesheetUpdater';
import Form from './Form.vue';
import Options from './Options.vue';
import ColorPickerContainer from './Input/ColorPickerContainer';

export enum Portal {
    Chat = 'chat',
    Statistics = 'stats',
    Settings = 'stats',
}

export default Vue.extend({
    mixins: [ColorPickerContainer],
    props: {
        stylesheet: {
            type: Object as PropType<Stylesheet>,
            required: true,
        },
        statsStylesheet: {
            type: Object as PropType<Stylesheet>,
            required: true,
        },
        defaults: {
            type: Object as PropType<Stylesheet>,
            required: true,
        },
        portal: {
            type: String as PropType<Portal>,
            required: true,
        },
        inheritor: {
            type: Object as PropType<StylesheetInheritor>,
            required: true,
        },
        updater: {
            type: Object as PropType<StylesheetUpdater>,
            default: () => new StylesheetUpdater(),
        },
    },
    mounted(): void {
        this.resetPreview();
    },
    data() {
        return {
            options: {},
        };
    },
    computed: {
        state(): object {
            return {};
        },
        form(): typeof Form | null {
            return null;
        },
        optionsSelector(): typeof Options | null {
            return null;
        },
    },
    watch: {
        state(newState: object): void {
            this.$emit('stateChange', newState);
        },
    },
    methods: {
        updateColorProperty(property: string, color: string): void {
            if (property) {
                this.stylesheet[property] = color;
            }
        },
        resetPreview(): void {
            this.$emit('stateChange', this.state);
        },
    },
});
</script>

<style lang="scss">
.configurator-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1em;
    position: relative;

    > form {
        flex: 1;
        position: relative;
        overflow-y: auto;

        label[for] {
            cursor: pointer;
        }

        label[title] {
            cursor: help;
        }

        > *:not(.color-picker-wrapper) {
            position: absolute;
            height: 100%;
            padding: 0 1em;

            &:nth-child(1) {
                padding-top: 1em;
            }
        }
    }

    > .options {
        text-align: center;
        padding: 0 1em 1em;
    }
}
</style>
