<template>
    <div class="option" @click="action(type)">
        <div class="option-image">
            <FaIcon v-if="icon" :icon="icon" />
            <img v-else :src="imagePath" :alt="`image-${type}`">
        </div>
        <div class="option-text">
            <span>{{ label }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'Instruction',
    props: {
        type: {
            type: String,
            default: 'untyped',
        },
        label: {
            type: String,
            default: 'empty',
        },
        icon: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: 'http://via.placeholder.com/150x50',
        },
        options: Object,
        action: Function,
    },
    computed: {
        imagePath(): string|null {
            const imageFileName = this.image !== 'http://via.placeholder.com/150x50' ? this.image : this.type;
            return `/img/icons/instructions/${imageFileName}.png`;
        },
    },
});
</script>

<style scoped lang="scss">

.option {
    padding: 1em;

    .option-image {
        width: 100%;
        background-color: transparent;

        img, svg {
            aspect-ratio: auto;
            padding: 8px;
            margin: auto;
        }

        svg {
            height: 36px;
        }

        img {
            height: 48px;
        }
    }
}
</style>
