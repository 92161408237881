<script lang="ts">
import Form from '@/components/community/styling/Form.vue';
import {Stylesheet} from '@/components/community/styling/CommunityStyling';
import {InheritanceCallback} from '@/components/community/styling/StylesheetInheritor';

interface Inheritor {
    (stylesheet: Stylesheet): string;
}

function inheritValue(parent: string): Inheritor {
    return (stylesheet: Stylesheet): string => stylesheet[parent] ?? '';
}

/* eslint-disable @typescript-eslint/naming-convention */
const propertyMap = {
    'choices_active-background-color': inheritValue('user-message_background-color'),
    'choices_active-color': inheritValue('user-message_color'),
    'choices_border-radius': inheritValue('message_border-radius'),
    'slider_background-color-left': inheritValue('progress-bar_color'),
    'slider_background-color-right': inheritValue('progress-bar_background-color'),
    'input-control_padding': inheritValue('message_padding'),
    'input-control_border-radius': inheritValue('message_border-radius'),
} as Record<string, Inheritor>;

export default Form.extend({
    methods: {
        inheritProperty(property: string, stylesheet: Stylesheet): string {
            const inheritor = propertyMap[property] ?? (() => (''));
            return inheritor(stylesheet);
        },
    },
    created(): void {
        const self = this as unknown as {
            inheritProperty: InheritanceCallback;
        };
        this.inheritor.setCallback(self.inheritProperty);
    },
});
</script>
