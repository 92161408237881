<template>
    <div class="data-point-modal">
        <Loader v-if="loading" />
        <div v-for="(dataPoint, index) in dataPoints" :key="index">
            <div class="pure-form">
                <fieldset>
                    <select v-model="dataPoint.selectedConnection">
                        <option v-for="({id, name}) in connections" :value="id" :key="id">
                            {{ name}}
                        </option>
                    </select>
                    <input v-model="dataPoint.valueSource" class="value-source" type="text" placeholder="Value Source (e.g profiles.column.email)" />
                    <input v-model="dataPoint.dataSelector" class="data-selector" type="text" placeholder="Selector (e.g crm.cdp.email)" />
                    <button v-on:click.prevent="addDataPoint" class="pure-button add-data-point"><FaIcon icon="plus-circle" /></button>
                    <button v-on:click.prevent="removeDataPoint(index)" class="pure-button remove-data-point"><FaIcon icon="trash" /></button>
                </fieldset>
            </div>
        </div>
        <div class="submit-dara-modal ta-center">
            <div class="mt-1">
                <AbbiButton @click="onSubmit" grow class="pure-u-1-5">Save</AbbiButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import Loader from '@/views/Loader.vue';

import CrmApi from '@/apis/crm.api';

export default vue.extend({
    name: 'DataPointModal',
    components: {AbbiButton, AbbiTextInput, Loader},
    props: {
        options: Object,
    },
    data() {
        return {
            dataPoints: [],
            connections: [],
            errorMessages: [],
            loading: false,
        };
    },
    methods: {
        onSubmit() {
            const {scriptUuid} = this.$route.params;
            CrmApi.saveDataPoint(scriptUuid, this.dataPoints.filter((d) => d.valueSource !== null)).then((data) => {
                if (data === 'ok') {
                    this.$emit('close');
                    this.$store.dispatch('closeModal');
                }
            });
        },
        addDataPoint() {
            this.dataPoints.push({
                selectedConnection: this.connections[0].id,
                valueSource: null,
                dataSelector: null,
            });
        },
        removeDataPoint(index) {
            this.dataPoints = this.dataPoints.filter((d, i) => i !== index);
        },
    },
    created() {
        this.loading = true;
        const {communitySlug, scriptUuid} = this.$route.params;
        CrmApi.list(communitySlug).then((responseData) => {
            if (!responseData.length) {
                this.flashMessage.error({
                    message: this.$i18n.t('ERROR.CRM_CONNECTION'),
                    icon: '/img/icons/error.svg',
                    time: 1500,
                });
                this.loading = false;
                this.$emit('close');
                this.$store.dispatch('closeModal');
                return;
            }
            this.connections = responseData.map(({id, name}) => ({id, name}));
        }).then(() => {
            CrmApi.showDataPoint(scriptUuid).then((data) => {
                if (!data) {
                    this.dataPoints.push({
                        selectedConnection: this.connections[0].id,
                        valueSource: null,
                        dataSelector: null,
                    });
                    this.loading = false;
                    return;
                }
                this.dataPoints = data.fields;
                this.loading = false;
            });
        });
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';
    .data-point-modal {
        select {
            margin-left: 120px;
        }
        .value-source {
            margin-left: 9px;
            width: 27%;
        }
        .data-selector {
            margin-left: 9px;
            width: 27%;
        }
        .add-data-point {
            background-color: $abbi-green;
            margin-left: 50px;
            border-radius: 6px;
            color: white;
        }

        .remove-data-point {
            background-color: $abbi-red;
            border-radius: 6px;
            margin-left: 13px;
            color: white;
        }

        .submit-dara-modal {
            padding-bottom: 12px;
        }
    }
</style>
