import CommunityApi from '@/apis/community.api';
import {v4 as uuidv4} from 'uuid';
import getExtensionFromMimeType from '@/helpers/mime';

export default class ImportDecorator {
    public static UUID_LIST: string[] = [];

    private static FILE_INCREMENT_NUMBER = 0;

    /* eslint-disable no-useless-escape, max-len */
    public static DECORATOR_UPLOAD_ASSET_TO_COMMUNITY_REGEX = /"import:\/\/uploadAssetToCommunity\('(https?:\/\/(.*?(cdn.cloudbear.nl|.*localhost|.*abbi.dev))(\/.*?))'\)"/g;
    public static DECORATOR_UPLOAD_DATA_URI_ASSET_TO_COMMUNITY_REGEX = new RegExp('import:\\\/\\\/fromBase64\\(\'(data:[\\w\/+]*;base64,[A-Za-z0-9+\/=]*)\'\\)', 'g');

    public static async uploadDataURIAssetsToCommunity(subject: string, communityUuid: string): Promise<string> {
        let transformed = subject;

        let m;
        /* eslint-disable */
        while (m = this.DECORATOR_UPLOAD_DATA_URI_ASSET_TO_COMMUNITY_REGEX.exec(subject)) {
            const [full, data] = m;

            const filename = `file${this.getNewFileIncrementNumber()}`;

            // convert base64 data to form
            const file = this.dataURItoFile(filename, data);

            const formData = new FormData();
            formData.append(filename, file); // Default to 'image.jpg' if no filename can be derived
            const uploadResult = await CommunityApi.uploadImage(communityUuid, formData);

            transformed = transformed.replace(full, uploadResult[filename]);
        }

        return transformed;
    }

    public static newUUid(subject: string): string {
         return subject.replaceAll(
            /import:\/\/newUuid\((\d+)\)/gm,
            (_, index) => {
                let uuid: string|undefined = this.UUID_LIST[index];
                if (uuid === undefined) {
                    uuid = uuidv4();
                    this.UUID_LIST[index] = uuid;

                    return uuid;
                }
                return uuid;
            }
        );
    }

    private static dataURItoFile(name: string, dataURI: string): File {
        const [optionsURI, data] = dataURI.split(',');
        const [, options] = optionsURI.split(':');
        const [type, encoding] = options.split(';');
        const extension = getExtensionFromMimeType(type);
        const byteString = atob(data);

        if (encoding !== 'base64') {
            throw new Error(`Unknown encoding: "${encoding}"`);
        }

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ab], `${name}.${extension}`, {type});
    }

    private static getNewFileIncrementNumber(): number {
        return ++this.FILE_INCREMENT_NUMBER;
    }
}
