<template>
    <Card v-if="requireVerificationCode" :style="cardStyle">
        <template #title>
            <div class="text-center">
                <i class="bi-shield-lock me-2"></i><br />
                <p>{{ $t('TOTP.TITLE') }}</p>
            </div>
        </template>

        <template #subtitle>
            <p class="text-center">
                {{ $t('TOTP.SUBTITLE') }}
            </p>
        </template>

        <form @submit.prevent="submitTotpForm" class="mt-5">
            <div class="mb-3 row">
                <label for="verificationCode" class="form-label">
                    {{ $t('TOTP.FIELD.VERIFICATION_CODE.LABEL') }}
                </label>
                <div class="input-group input-group-lg has-validation">
                    <i class="input-group-text bi-qr-code"></i>
                    <input
                        :type="verificationCodeVisible ? 'text' : 'password'"
                        class="form-control"
                        :class="{
                            'is-invalid': violations.hasError('verificationCode'),
                        }"
                        @input="mutateForm()"
                        id="verificationCode"
                        name="verificationCode"
                        autofocus
                        :placeholder="$t('TOTP.FIELD.VERIFICATION_CODE.PLACEHOLDER')"
                        autocomplete="one-time-code"
                        required
                        v-model="verificationCode" />
                    <TogglePasswordVisibility
                        :visible="verificationCodeVisible"
                        @toggle="verificationCodeVisible = !verificationCodeVisible" />
                    <ViolationList field="verificationCode" :violations="violations" />
                </div>
            </div>

            <div class="mt-4 input-group has-validation">
                <button
                    type="submit"
                    :disabled="!enabled"
                    class="btn btn-lg btn-primary w-100"
                    :class="{'is-invalid': errorMessage.length > 0}">
                    <span v-if="enabled">
                        {{ $t('TOTP.SUBMIT.LABEL') }}
                    </span>
                    <span v-else class="spinner-border text-light" style="height: 1em; width: 1em;" role="status">
                        <span class="visually-hidden">
                            {{ $t('TOTP.SUBMIT.ACTION') }}
                        </span>
                    </span>
                </button>
                <div v-if="errorMessage" class="invalid-feedback">
                    {{ $t(errorMessage) }}
                </div>
            </div>
        </form>
    </Card>
    <Card v-else :style="cardStyle">
        <template #title>
            <div class="text-center">
                <i class="bi-person-fill-check me-2"></i><br />
                <p>{{ $t('LOGIN.TITLE') }}</p>
            </div>
        </template>

        <template #subtitle>
            <p class="text-center">
                {{ $t('LOGIN.SUBTITLE') }}
            </p>
        </template>

        <form @submit.prevent="submitLoginForm" class="mt-5">
            <div class="mb-3">
                <label for="username" class="form-label">
                    {{ $t('LOGIN.FIELD.USERNAME.LABEL') }}
                </label>
                <div class="input-group input-group-lg has-validation">
                    <i class="input-group-text bi-person-circle"></i>
                    <input
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': violations.hasError('username'),
                        }"
                        @input="mutateForm"
                        id="username"
                        name="username"
                        autofocus
                        autocomplete="username"
                        :placeholder="$t('LOGIN.FIELD.USERNAME.PLACEHOLDER')"
                        required
                        v-model="username">
                    <ViolationList field="username" :violations="violations" />
                </div>
            </div>

            <div class="mb-3">
                <label for="password" class="form-label">
                    {{ $t('LOGIN.FIELD.PASSWORD.LABEL') }}
                </label>
                <div class="input-group input-group-lg has-validation">
                    <i class="input-group-text bi-key"></i>
                    <input
                        :type="passwordVisible ? 'text' : 'password'"
                        class="form-control"
                        :class="{
                            'is-invalid': violations.hasError('password'),
                        }"
                        @input="mutateForm"
                        id="password"
                        name="password"
                        autocomplete="current-password"
                        :placeholder="$t('LOGIN.FIELD.PASSWORD.PLACEHOLDER')"
                        required
                        aria-describedby="passwordHelp"
                        v-model="password" />
                    <TogglePasswordVisibility
                        :visible="passwordVisible"
                        @toggle="passwordVisible = !passwordVisible" />
                    <ViolationList field="password" :violations="violations" />
                </div>
                <div id="passwordHelp" class="form-text">
                    <router-link :to="{name: 'ResetPassword'}">
                        {{ $t('LOGIN.ACTION.REQUEST_PASSWORD_RESET') }}
                    </router-link>
                </div>
            </div>

            <div class="mt-4 input-group has-validation">
                <button
                    type="submit"
                    :disabled="!enabled"
                    class="btn btn-lg btn-primary w-100"
                    :class="{'is-invalid': errorMessage.length > 0}">
                    <span v-if="enabled">
                        {{ $t('LOGIN.SUBMIT.LABEL') }}
                    </span>
                    <span v-else class="spinner-border text-light" style="height: 1em; width: 1em;" role="status">
                        <span class="visually-hidden">
                            {{ $t('LOGIN.SUBMIT.ACTION') }}
                        </span>
                    </span>
                </button>
                <div v-if="errorMessage" class="invalid-feedback">
                    {{ $t(errorMessage) }}
                </div>
            </div>
        </form>
    </Card>
</template>
<script lang="ts">
import Vue from 'vue';
import Card from '@/components/bootstrap/Card.vue';
import ViolationList from '@/components/form/ViolationList.vue';
import TogglePasswordVisibility from '@/components/account/TogglePasswordVisibility.vue';
import ViolationListModel from '@/models/violation.list.model';
import AccountApi from '@/apis/account.api';

interface ComponentData {
    passwordVisible: boolean;
    password: string;
    username: string;
    violations: ViolationListModel;
    errorMessage: string;
    enabled: boolean;
    requireVerificationCode: boolean;
    verificationCodeVisible: boolean;
    verificationCode: string;
}

interface CsrfCallback {
    (csrfToken: string): void;
}

const cardWidth = 460;

export default Vue.extend({
    name: 'AccountLogin',
    components: {TogglePasswordVisibility, ViolationList, Card},
    data(): ComponentData {
        return {
            errorMessage: '',
            password: '',
            passwordVisible: false,
            username: '',
            violations: new ViolationListModel(),
            enabled: true,
            requireVerificationCode: false,
            verificationCodeVisible: false,
            verificationCode: '',
        };
    },
    computed: {
        cardStyle(): string {
            return `width: ${cardWidth}px;`;
        },
    },
    methods: {
        mutateForm() {
            this.errorMessage = '';
            this.violations.reset();
            this.enabled = true;
        },
        submitTotpForm() {
            this.mutateForm();

            if (this.verificationCode.length < 1) {
                return;
            }

            this.enabled = false;

            AccountApi.totpConfirmAuthentication(this.verificationCode)
                .catch(() => {
                    this.totpFailure();
                })
                .then((response) => {
                    this.enabled = true;

                    if (response === undefined) {
                        AccountApi.getErrorData().then((errorData) => {
                            const violations = errorData.violations ?? [];
                            this.violations.apply(violations);
                            this.verificationCode = '';

                            if (violations.length === 0) {
                                this.totpFailure();
                            }
                        });
                        return;
                    }

                    this.finalizeAuthentication();
                });
        },
        totpFailure() {
            this.enabled = true;
            this.errorMessage = 'TOTP.SUBMIT.FAILURE';
            this.verificationCode = '';
        },
        submitLoginForm() {
            this.mutateForm();

            if (this.username.length < 1 || this.password.length < 1) {
                return;
            }

            this.enabled = false;

            this.withCsrfToken(
                (csrfToken: string) => {
                    AccountApi.login(this.username, this.password, csrfToken)
                        .catch(() => {
                            this.loginFailure();
                        })
                        .then((response) => {
                            this.enabled = true;

                            if (response === undefined) {
                                AccountApi.getErrorData().then((errorData) => {
                                    const violations = errorData.violations ?? [];

                                    this.violations.apply(violations);

                                    if (violations.length === 0) {
                                        this.loginFailure();
                                    }
                                });
                                return;
                            }

                            if (response.two_factor_complete === false) {
                                this.requireVerificationCode = true;
                                window.setTimeout(
                                    () => {
                                        const element = document.getElementById('verificationCode');

                                        if (element instanceof HTMLInputElement) {
                                            element.focus();
                                        }
                                    },
                                    1,
                                );
                                return;
                            }

                            this.finalizeAuthentication();
                        });
                },
            );
        },
        loginFailure() {
            this.enabled = true;
            this.errorMessage = 'LOGIN.SUBMIT.FAILURE';
        },
        finalizeAuthentication() {
            AccountApi.getCurrentUser()
                .catch(() => {
                    this.loginFailure();
                })
                .then((currentUser) => {
                    if (currentUser?.email !== undefined) {
                        this.$store.dispatch('login', currentUser);
                        this.$router.push('/home');
                        return;
                    }

                    this.loginFailure();
                });
        },
        withCsrfToken(callback: CsrfCallback) {
            const csrf = AccountApi.getLoginCSRF();
            csrf.catch(() => {
                this.loginFailure();
            });
            csrf.then((response) => {
                this.enabled = true;

                if (response?.loginToken === undefined) {
                    this.loginFailure();
                    return;
                }

                callback(response.loginToken);
            });
        },
    },
});
</script>
