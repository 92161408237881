<template>
    <input
        type="url"
        :placeholder="placeholder"
        :value="url"
        @input="onInput($event.target.value)"
        @change="onChange($event.target.value)"
    />
</template>

<script lang="ts">
import TextInput from '@/components/community/styling/Input/TextInput.vue';

export default TextInput.extend({
    props: {
        value: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'https://example.com',
        },
        defaultProtocol: {
            type: String,
            default: 'https',
        },
    },
    methods: {
        onInput(url: string): void {
            this.url = url;
            this.$emit('input', url);
        },
        onChange(url: string): void {
            this.url = url;
            this.$emit('change', url);
        },
    },
    data() {
        return {
            url: this.value,
        };
    },
    watch: {
        url(value: string): void {
            if (value.length < 1) {
                return;
            }

            let parsed = value;
            const {defaultProtocol} = this;

            try {
                const url = new URL(parsed);
                if (url.protocol.length === 0) {
                    url.protocol = defaultProtocol;
                    parsed = url.toString();
                }
            } catch (e) {
                if (parsed.includes('.')) {
                    const cleaned = /^[:/]*(?<url>.+)/.exec(parsed);

                    if (cleaned) {
                        parsed = `${defaultProtocol}://${cleaned.groups?.url}`;
                    }
                }
            }

            this.url = parsed;
        },
    },
});
</script>
