<template>
    <div id="payment-configurator">
        <div class="flex-row">
            <Toggle
                class="flex-1"
                :readonly="scriptLive && !liveEditable"
                :value="action.params.environment === 'production'"
                @input="environmentChanged"
            >
                Production environment
            </Toggle>
            <label>
                Amount instruction
                <select :disabled="scriptLive" v-model="action.params.amount">
                    <option value>Please select amount instruction</option>
                    <option
                        v-for="instruction in selectables"
                        :key="instruction.uuid"
                        :value="instruction.question.uuid"
                    >
                        {{ getInstructionInString(instruction) }}
                    </option>
                </select>
            </label>
        </div>
        <div class="flex-row">
            <Toggle
                class="flex-1"
                :readonly="scriptLive"
                :value="action.params.autoRedirect"
            >
                Redirect to Payment automatically
            </Toggle>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import Toggle from '@/components/input/Toggle.vue';
import ActionModel from '@/models/action.model';
import ScriptModel from '@/models/script.model';
import InstructionModel, {InstructionModelExt} from '@/models/instruction.model';

export default vue.extend({
    name: 'PaymentConfigurator',
    components: {Toggle},
    props: {
        action: [Object, ActionModel],
        script: [Object, ScriptModel],
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    computed: {
        selectables() {
            return this.script.instructions.filter(
                (instr: InstructionModel) => ['choice', 'numeric', 'currency'].includes(
                    instr.question?.type || 'never',
                ),
            );
        },
    },
    methods: {
        getInstructionInString(instruction: InstructionModel) {
            return `${instruction.order + 1}: ${InstructionModelExt.GetMessageForLocale(instruction, 'en', 'en')?.content}`;
        },
        environmentChanged($event: boolean) {
            this.action.params.environment = $event ? 'production' : 'staging';
        },
    },
});
</script>

<style lang="scss">
#payment-configurator {
    max-width: 960px;
    margin: 0 auto;
}
</style>
