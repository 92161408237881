<template>
    <div class="m-auto">
        <BootstrapStylesheet />
        <AccountPassword :reset-token="resetToken" @success="successHandler()" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AccountPassword from '@/components/account/AccountPassword.vue';
import BootstrapStylesheet from '@/components/bootstrap/BootstrapStylesheet.vue';

interface ComponentData {
    resetToken: string;
}

export default Vue.extend({
    name: 'AccountResetPassword',
    components: {BootstrapStylesheet, AccountPassword},
    data(): ComponentData {
        return {
            resetToken: this.$router.currentRoute.params.token,
        };
    },
    methods: {
        successHandler() {
            window.setTimeout(
                () => {
                    this.$router.push('/login');
                },
                2000,
            );
        },
    },
    created() {
        // get current user instead of relying on frontend state
        if (this.$store.state.loggedIn) {
            this.$router.push('/home');
        }
    },
});
</script>
