<template>
    <label>
        <div class="preview" :style="{ 'font-family': `'${familyName||'Quicksand Variable'}` }">{{ familyName || 'Click to upload' }}</div>
        <input type="file" :id="identifier" :name="identifier" :accept="accepts" @change="onChange" />
    </label>
</template>

<script lang="ts">
import FileUpload from '@/components/input/FileUpload.vue';

/* eslint-disable-next-line */
const fontkit = require('fontkit');

export interface FontUploadOptions {
    accepts: string;
    currentFamilyName: string;
}

export default FileUpload.extend({
    name: 'FontUpload',
    props: {
        currentFamilyName: {
            type: String,
            default: '',
        },
    },
    watch: {
        preview(newValue: string) {
            this.familyName = newValue;
        },
        familyName(newValue: string) {
            this.$emit('updateDependentProperty', 'body_font-family', newValue);
        },
    },
    created() {
        if (this.currentFamilyName !== '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS2304
            const face = new FontFace(this.currentFamilyName, `url(${this.value})`);
            face.load()
                .then((loadedFont: FontFace) => {
                    document.fonts.add(loadedFont);
                });
        }
    },
    data() {
        return {
            familyName: this.currentFamilyName ?? '',
            preview: this.value.split('/').pop() ?? '',
        };
    },
    methods: {
        async onChange(event: Event) {
            const target = event.target as HTMLInputElement;
            const [font] = target.files as FileList;

            const { familyName, fileExtension } = await this.parseFontFile(font);

            this.preview = familyName;

            const fontMime = this.resolveMimeFromExtension(fileExtension);

            const reader = new FileReader();
            reader.addEventListener('load', (load: ProgressEvent) => {
                const loadTarget = load.target as unknown as {result: string};

                const dataURI = loadTarget.result.replace(
                    'application/octet-stream',
                    fontMime,
                );

                this.$nextTick(() => {
                    this.$emit('input', dataURI);
                });
            });
            reader.readAsDataURL(font);
        },
        resolveMimeFromExtension(ext: string): string {
            const extensionToMimeTypeMapping: Record<string, string> = {
                woff: 'font/woff',
                woff2: 'font/woff2',
                ttf: 'font/ttf',
                otf: 'font/otf',
            };

            return extensionToMimeTypeMapping[ext] ?? 'font/*';
        },
        getFileNameAndExtension(fileName: string): { name: string; extension: string } {
            const regex = /^(.*?)(?:\.([^.]+))?$/;
            const match = fileName.match(regex);
            return {
                name: match ? match[1] : '',
                extension: match ? match[2] || '' : '',
            };
        },
        async parseFontFile(file: File) {
            const arrayBuffer = await this.readFileAsArrayBuffer(file);

            const font = fontkit.create(new Uint8Array(arrayBuffer));

            const { name: fileName, extension: fileExtension } = this.getFileNameAndExtension(file.name);

            const parsedFamilyName = font.familyName;

            const name = (parsedFamilyName?.length > 2 ? parsedFamilyName : fileName) ?? fileName;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS2304
            const face = new FontFace(name, arrayBuffer);
            face.load()
                .then((loadedFont: FontFace) => {
                    document.fonts.add(loadedFont);
                });

            return {
                familyName: name,
                subfamilyName: font.subfamilyName,
                type: font.type,
                fileExtension,
            };
        },
        async readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
            return new Promise<ArrayBuffer>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });
        },
    },
});
</script>

<style scoped lang="scss">

label {
    border-radius: .5em;
    padding: .5em;
    box-shadow: rgba(0, 0, 0, .7) 0 0 4px -1px;
    margin: .5em 3px;
    cursor: pointer;
    text-align: center;
    width: calc(100% - 6px);
    background: white;
}

.preview {
    //text-decoration: underline;
    //color: #0d5aa7;
}
input {
    display: none;
}
</style>
