<template>
    <div class="image-upload" :class="{inline, grow, button: true}" @click="$refs.fileInput.click()">
        <span><slot></slot></span>
        <input ref="fileInput" type="file" :id="identifier" :name="identifier" :accept="accepts" @change="process" />
        <span v-if="!preview || noPreview" class="filename">{{ filename === '' ? '' : filename }}</span>
        <span>Click to </span><span v-if="noPreview || preview">(re)</span><span>upload</span>
        <div class="label" v-if="!preview || noPreview">
            <FaIcon v-if="!preview || noPreview" icon="image" />
        </div>
        <img v-if="preview && !noPreview" class="image-preview" :src="preview" alt="preview" />
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint no-alert: 0 */
import vue from 'vue';

const MAX_FILE_SIZE_MB = 2;

export default vue.extend({
    name: 'ImageUpload',
    props: {
        identifier: String,
        accepts: String,
        inline: Boolean,
        grow: Boolean,
        noPreview: Boolean,
        defaultValue: [Object, String, File],
        initialFile: File,
    },
    methods: {
        process($e: Event) {
            const [image] = ($e.target as HTMLInputElement).files ?? [];
            const reader = new FileReader();

            if (image.size > 1024 * 1024 * MAX_FILE_SIZE_MB) {
                window.alert(`image is too big, maximum size is ${MAX_FILE_SIZE_MB}MB`);
                return;
            }

            reader.readAsDataURL(image);
            reader.onload = ($loadEvent: ProgressEvent) => {
                this.imageData = ($loadEvent.target as any)?.result;

                this.$emit('change', $e);
                this.$emit('input', this.preview);
            };
        },
    },
    watch: {
        defaultValue(value: string) {
            if (this.imageData === null && this.filename === '') {
                this.imageData = value;
                this.filename = value;
            }
        },
    },
    mounted() {
        const reader = new FileReader();

        if (this.initialFile) {
            reader.readAsDataURL(this.initialFile);
        } else if (this.defaultValue instanceof File) {
            reader.readAsDataURL(this.defaultValue);
        } else {
            return;
        }

        reader.onload = ($loadEvent: ProgressEvent) => {
            this.preview = ($loadEvent.target as any)?.result;
        };
    },
    data() {
        const initFile = this.initialFile ? this.initialFile : null;
        const defVal = this.defaultValue ? this.defaultValue : null;

        return {
            warn: false,
            imageData: (initFile || defVal) as ImageData | string | null,
            filename: (this.initialFile?.name ?? this.defaultValue) || '' as string,
        };
    },
    computed: {
        preview(): ImageData | string | null {
            return this.imageData;
        },
    },
});
</script>

<style lang="scss" scoped>
.image-upload {
    max-width: 50%;

    input {
        display: none;
    }

    &.grow {
        width: 100%;
    }

    .label {
        svg {
            margin-left: 0.5em;
        }
    }

    &.button {
        display: inline-block;
        padding: 0.25em;
        border: 1px solid rgba(0, 0, 0, 0.5);
        background-color: var(--background-color, rgba(0, 0, 0, 0.25));

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    &.inline {
        display: inline-block;

        > .image-preview {
            flex: 1 1 calc(100% / 3);
            max-width: calc(100% / 3);
        }
    }

    cursor: pointer;
    display: inline-flex;

    .label-text {
        margin: 0 -0.5em;
        padding: 0.5em;

        display: inline-flex;
        flex-direction: column;

        > * {
            padding: 0 0.5em;
        }

        .warn {
            color: red;
            font-weight: bold;
        }
    }

    input {
        display: none;
    }

    img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}
</style>
