<template>
    <div class="color-picker-wrapper">
        <div class="btn-toolbar justify-content-end">
            <div class="btn-group btn-group-sm">
                <button type="button" class="btn" @click.prevent="close">
                    <FaIcon icon="close" />
                </button>
            </div>
        </div>
        <component
            :is="picker"
            :value="value"
            @input="updateColor" />
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {
    Compact,
    Material,
    Slider,
    Swatches,
    Photoshop,
    Sketch,
    Chrome,
} from 'vue-color';

export type Picker = Compact | Material | Slider | Swatches | Photoshop | Sketch | Chrome;
const DefaultPicker = Chrome;

export interface Color {
    hex8: string;
}

export default Vue.extend({
    props: {
        value: {
            type: String,
            required: true,
        },
        picker: {
            type: Object as PropType<Picker>,
            default: () => DefaultPicker,
        },
    },
    methods: {
        updateColor(color: Color): void {
            this.$emit('input', color);
        },
        close(): void {
            this.$emit('close');
        },
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

.color-picker-wrapper {
    --background-color: whitesmoke;

    position: absolute;
    z-index: 999;
    background: var(--background-color);
    overflow: hidden;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, .2) 0 0 5px 0;
    margin: 5px;

    .vc-chrome-body {
        background: var(--background-color);

        .vc-chrome-color-wrap {
            box-shadow: rgba(0, 0, 0, .8) 0 0 5px -2px;
            margin: 3px 14px 3px 0;
            border-radius: 50%;
            width: 30px;
        }
    }

    .btn-toolbar {
        button {
            svg {
                pointer-events: none;
            }
        }
    }
}
</style>
