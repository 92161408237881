import QuestionModel from '@/models/question.model';
import StatModel from '@/models/stat.model';
import {createInstruction} from './helpers';
import Scenario from './Scenario';

function createAverageInstruction(uuid: string, description: string) {
    return createInstruction(
        uuid,
        description,
        {
            uuid: '5f45b5c7-b29c-417d-bc60-44f2517d93fc',
            type: 'numeric',
            options: {
                charttype: 'average',
            },
            choices: [],
        } as unknown as QuestionModel,
    );
}

const instructions = [
    createAverageInstruction(
        '1',
        'How would you rate this styling preview?',
    ),
    createAverageInstruction(
        '2',
        'How well can you read this question?',
    ),
    createAverageInstruction(
        '3',
        'How well are answers aligned?',
    ),
];

const stats = {
    1: {
        instruction: '1',
        selected: 156,
        answered: 156,
        statistics: {
            average: 9.6,
        },
    },
    2: {
        instruction: '2',
        selected: 107,
        answered: 107,
        statistics: {
            average: 8.3,
        },
    },
    3: {
        instruction: '3',
        selected: 78,
        answered: 78,
        statistics: {
            average: 10,
        },
    },
} as Record<string, StatModel>;

export default {
    instructions,
    stats,
} as Scenario;
