import moment from 'moment/moment';
import InstructionModel from '@/models/instruction.model';
import ExportDecorator from '@/services/io/exportDecorator';
import ImportDecorator from '@/services/io/importDecorator';
import crypto from 'crypto';
import CommunityService from '@/services/community.service';

export enum IOStatus {
    STARTED = 'started',
    COMPLETE = 'complete',
    ERROR = 'error',
}

export default class ScriptIOService {
    public static FILE_VERSION = 2;
    public static CRYPT_SALT = '@bbi2024script1';

    static async importFromFile(progressCallback: (status: IOStatus) => void): Promise<InstructionModel[] | null> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.json';
            input.onchange = async () => {
                try {
                    const files = Array.from(input.files ?? []);
                    if (files.length !== 1) {
                        alert('Please select exactly one file.');
                        resolve(null);
                        return;
                    }
                    const data = await files[0].text();
                    const importedData = JSON.parse(data);

                    // Basic validation of the imported data
                    if (importedData.version !== this.FILE_VERSION) {
                        alert(`We only support importing from version ${this.FILE_VERSION} of the style file.`);
                        resolve(null);
                        return;
                    }
                    const checksum = this.calculatePayloadChecksum(importedData.payload);
                    if (checksum !== importedData.checksum) {
                        alert('The checksum of the imported file does not match the checksum of the file we have on file.');
                        resolve(null);
                        return;
                    }

                    progressCallback(IOStatus.STARTED);
                    try {
                        const processedData = this.processPostImport(importedData.payload, CommunityService.GetCurrentCommunityUuid());
                        progressCallback(IOStatus.COMPLETE);
                        resolve(processedData);
                    } catch (e) {
                        progressCallback(IOStatus.ERROR);
                    }
                } catch (error) {
                    console.error('Error importing file:', error);
                    reject(error);
                }
            };
            input.click();
        });
    }

    static async exportToFile(
        payload: InstructionModel[],
        identifier: string,
    ): Promise<void> {
        const localPayload = await this.transformPreExport(payload);

        const data = {
            version: this.FILE_VERSION,
            payload: localPayload,
            checksum: this.calculatePayloadChecksum(localPayload),
        };

        const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const timestamp = moment().format('YYYYMMDD');
        link.setAttribute('download', `${timestamp}.v${this.FILE_VERSION}.${identifier}.json`);
        document.body.appendChild(link);
        link.click();
    }

    private static async transformPreExport(payload: InstructionModel[]): Promise<InstructionModel[]> {
        /* eslint-disable max-len, no-useless-escape */
        let decorated = JSON.stringify(payload);

        const regex = new RegExp('(?<fullUrl>(?<protocol>https?):\\\/\\\/(?<domain>localhost:\\d*|[\\w\/\\-.]*?cdn\\.cloudbear\\.nl|[\\w\/\\-.]*?abbi-insights\\.local)+(?<path>\\\/[\\w\/\\-.]*?))\\\\"', 'g');

        decorated = ExportDecorator.newUUid(decorated);
        decorated = await ExportDecorator.transformAssetsToBase64(regex, decorated);

        return JSON.parse(decorated);
    }

    private static async processPostImport(payload: InstructionModel[], communityUuid: string): Promise<InstructionModel[]> {
        let decorated = JSON.stringify(payload);

        decorated = ImportDecorator.newUUid(decorated);
        decorated = await ImportDecorator.uploadDataURIAssetsToCommunity(decorated, communityUuid);

        return JSON.parse(decorated);
    }

    private static calculatePayloadChecksum(payload: InstructionModel[]): string {
        return crypto.createHash('sha256').update(JSON.stringify(payload) + this.CRYPT_SALT).digest('hex').toUpperCase();
    }
}
