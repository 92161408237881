<template>
    <div class="alert" :class="alertClass" role="alert">
        <button v-if="canClose" type="button" class="btn-close float-end" @click="close()" />
        <slot />
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';

export enum AlertType {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark',
}

export default Vue.extend({
    props: {
        canClose: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String as PropType<AlertType>,
            required: false,
        },
    },
    computed: {
        alertClass(): string[] {
            return this.type ? [`alert-${this.type}`] : [];
        },
    },
    methods: {
        close(): void {
            this.$emit('close');
        },
    },
});
</script>

<style lang="scss" scoped>
.alert {
    .btn-close {
        margin: 0 0 .5em .5em;
    }
}
</style>
