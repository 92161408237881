<template>
    <div class="fieldset">
        <div class="title" v-if="heading || scenario" :class="{collapsible: canCollapse}">
            <h4 v-if="heading" @click="collapse = !collapse">
                <FaIcon v-if="canCollapse && showCollapse" :icon="collapse ? 'caret-down' : 'caret-right'" />
                <FaIcon v-if="icon" :style="iconStyle" :icon="icon" />
                <span v-html="heading" />
                <small v-if="description" class="description" v-html="description" />
            </h4>
            <slot name="toggle">
                <button
                    v-if="!hideScenario && scenario && options.scenario"
                    @click.prevent="options.scenario = scenario"
                    class="btn"
                    :disabled="scenario === options.scenario"
                >
                    <FaIcon icon="eye" />
                </button>
            </slot>
        </div>
        <slot v-if="!canCollapse || collapse" />
    </div>
</template>

<script lang="ts">
import Vue, {PropType} from 'vue';
import {Portal} from '@/components/community/styling/Configurator.vue';

interface Options {
    scenario: string;
}

export default Vue.extend({
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
        },
        iconStyle: {
            type: [Object, String],
            default: '',
        },
        scenario: {
            type: String,
        },
        hideScenario: {
            type: Boolean,
            default: false,
        },
        canCollapse: {
            type: Boolean,
            default: false,
        },
        showCollapse: {
            type: Boolean,
            default: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object as PropType<Options>,
        },
        portal: {
            type: String as PropType<Portal>,
        },
    },
    data() {
        return {
            collapse: this.open,
        };
    },
    inject: ['options', 'portal'],
    provide() {
        const provide = {} as {[key: string]: string};

        if ('scenario' in this) {
            provide.scenario = this.scenario;
        }

        return provide;
    },
    watch: {
        open(open: boolean): void {
            this.collapse = open;
        },
    },
    computed: {
        heading(): string {
            const portal = (this.portal as string).toUpperCase();
            const title = (this.title ?? '').toUpperCase();

            return title
                ? this.$t(
                    `COMMUNITY.STYLESHEET.CONFIGURATOR.${portal}.FIELDSET.${title}.LABEL`,
                ).toString()
                : '';
        },
        description(): string {
            const portal = (this.portal as string).toUpperCase();
            const title = (this.title ?? '').toUpperCase();
            const translationKey = `COMMUNITY.STYLESHEET.CONFIGURATOR.${portal}.FIELDSET.${title}.DESCRIPTION`;
            const description = this.$t(translationKey).toString();

            return description === translationKey ? '' : description;
        },
    },
});
</script>

<style lang="scss" scoped>
.fieldset + .fieldset {
    margin-top: 1.8em;
}

.fieldset {
    background: linear-gradient(to right, rgba(100,100,100,0.05), transparent);
    padding: 1em;
    padding-right: 0;
    border-radius: 0.5em;

    &:last-child {
        padding-bottom: 1.8em;
    }

    .title {
        display: flex;
        justify-content: space-between;

        &.collapsible {
            h4 {
                cursor: pointer;
            }
        }
    }

    h4 {
        font-size: 1.5em;

        svg {
            margin-right: .5em;
        }

        small {
            margin-left: .5em;
            font-size: 0.6em;
            color: rgba(100, 100, 100, 0.7);
        }
    }

    p {
        color: rgba(100, 100, 100, .95);
        font-size: .95em;
    }

    .field + .fieldset, p + .fieldset, .fieldset + .fieldset {
        margin-top: 1em;
    }

    .fieldset {
        margin-left: 1em;
        padding-bottom: 0.5em;

        h4 {
            font-size: 1.2em;
        }

        button {
            font-size: 0.8em;
        }

        .field + .fieldset, p + .fieldset, .fieldset + .fieldset {
            margin-top: 0.8em;
        }

        .fieldset {
            h4 {
                font-size: 1em;
            }

            button {
                font-size: 0.66em;
            }

            .field + .fieldset, p + .fieldset, .fieldset + .fieldset {
                margin-top: 0.66em;
            }
        }
    }
}
</style>
