<template>
    <div class="crm-container">
        <h2>Connected CRMs</h2><br>
        <table class="crm-table" v-if="connections.length > 0">
            <tr class="crm-table-header">
                <th style="width: 24%">Platform</th>
                <th>Name</th>
                <th colspan="3" style="width: 10%"></th>
            </tr>
            <tr v-for="connection in connections" :key="connection.id" :id="connection.id">
                <td :class="getStatusClass(connection)">
                    <FaIcon icon="cloud" style="margin-right: .3em;" />
                    {{ PLATFORMS[connection.platform] }}
                </td>
                <td>
                    {{ connection.name }}
                    <div v-if="connectionRequiresAuthorization(connection) && !isConnectionAuthorized(connection)">
                        <strong class="warning">
                            Warning! This connection is currently not authorized!
                        </strong>
                    </div>
                </td>
                <td>
                    <AbbiButton
                        v-if="connectionRequiresAuthorization(connection)"
                        title="This logs in the user that allows the application to perform API requests."
                        is-blue
                        @click="authorizeConnection(connection)">
                        <FaIcon icon="key" />
                        Authorize
                    </AbbiButton>
                </td>
                <td>
                    <AbbiButton  @click="editConnection(connection)">
                        <FaIcon icon="edit" />
                        Edit
                    </AbbiButton>
                </td>
                <td>
                    <AbbiButton is-red  @click="removeConnection(connection.id)">
                        <FaIcon icon="trash" />
                        Delete
                    </AbbiButton>
                </td>
            </tr>
        </table>

        <AbbiButton is-blue @click="addConnection">
            + Add connection
        </AbbiButton>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
/* eslint @typescript-eslint/no-explicit-any: 0 */
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import CrmConnectionModal from '@/components/modals/CrmConnectionModal.vue';
import CrmApi from '@/apis/crm.api';
import {
    AUTH_TYPES_REQUIRING_AUTHORIZATION,
    PLATFORMS,
    TYPE_OAUTH_2_REFRESH_TOKEN,
} from '@/helpers/constants';

export default vue.extend({
    name: 'CrmConnections',
    components: {AbbiButton, CrmConnectionModal},
    data() {
        return {
            PLATFORMS,
            AUTH_TYPES_REQUIRING_AUTHORIZATION,
            connections: [],
        };
    },
    methods: {
        getStatusClass(connection: any): string {
            if (!this.connectionRequiresAuthorization(connection)) {
                return 'neutral';
            }

            return this.isConnectionAuthorized(connection)
                ? 'authorized'
                : 'unauthorized';
        },
        connectionRequiresAuthorization(connection: any): boolean {
            return AUTH_TYPES_REQUIRING_AUTHORIZATION.includes(Number(connection.data.authType));
        },
        isConnectionAuthorized(connection: any): boolean {
            if (Number(connection.data.authType) === TYPE_OAUTH_2_REFRESH_TOKEN) {
                try {
                    const {clientId, clientSecret, scope} = connection.data.fields;
                    const accessTokenKey = `_accessToken:${clientId}:${clientSecret}:${scope}`;

                    return Number(
                        connection.data[accessTokenKey].access_token.length ?? 0,
                    ) > 0;
                } catch (error) {
                    return false;
                }
            }

            return false;
        },
        addConnection() {
            this.$store.commit('setModal', {
                title: 'Add connection',
                component: CrmConnectionModal,
                options: {
                    modalStyle: {
                        width: '55%',
                    },
                    modalHeaderStyle: {
                        marginLeft: '43% !important',
                    },
                },
            });
        },
        removeConnection(id: number) {
            /* eslint-disable-next-line */
            const confirmed = window.confirm('Are you sure?');
            if (!confirmed) {
                return;
            }
            CrmApi.remove(id).then((status) => {
                if (status === 'ok') {
                    this.connections = this.connections.filter((c) => c.id !== id);
                }
            });
        },
        authorizeConnection(connection: any) {
            window.location.href = CrmApi.getAuthorizationLink(
                Number(connection.id),
            );
        },
        editConnection(connection: any) {
            this.$store.commit('setModal', {
                title: 'Edit connection',
                component: CrmConnectionModal,
                options: {
                    connection,
                    modalStyle: {
                        width: '55%',
                    },
                    modalHeaderStyle: {
                        marginLeft: '43% !important',
                    },
                },
            });
        },
        addCrmConnection($event: CustomEvent) {
            const connection = $event.detail;
            // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // // @ts-ignore
            const idx = this.connections.findIndex((c) => c.id === connection.id);
            if (idx === -1) {
                this.connections.push(connection);
                this.$forceUpdate();
                return;
            }
            this.connections[idx] = connection;
            this.$forceUpdate();
        },
    },
    destroyed() {
        document.removeEventListener('addCrmConnection', this.addCrmConnection as EventListener);
    },
    created() {
        const {communitySlug} = this.$route.params;
        CrmApi.list(communitySlug).then((responseData) => {
            this.connections = responseData;
        });
        document.addEventListener('addCrmConnection', this.addCrmConnection as EventListener);
    },
});
</script>

<style lang="scss">
    .crm-container {
        width: (100% / 3);
        min-width: 1024px;
        margin: 0 auto;
        .crm-table {
            width: 100%;
            margin-bottom: 1em;
            .crm-table-header {
                text-align: left;
            }

            td.neutral {
                color: #0d5aa7;
            }

            td.authorized {
                color: #1c7430;
            }

            td.unauthorized {
                color: red;
            }
        }
    }

    .warning {
        color: red;
    }
</style>
