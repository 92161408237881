<template>
    <div class="w-100">
        <div class="options">
            <FieldSet title="Import/Export Styles">
                <div class="btn btn-secondary" @click="importStyle">
                    <FaIcon icon="file-import" /> Import
                </div>
                <div class="btn btn-secondary" @click="exportStyle">
                    <FaIcon icon="file-export" /> Export
                </div>
            </FieldSet>
        </div>
    </div>
</template>

<script lang="ts">
import ChatForm from '@/components/community/styling/chat/ChatForm.vue';
import FieldSet from '@/components/community/styling/form/FieldSet.vue';

import StyleIOService from '@/services/io/style.io.service';
import CommunityService from '@/services/community.service';

export default ChatForm.extend({
    components: {
        FieldSet,
    },
    methods: {
        async exportStyle() {
            const payload = {
                web: this.stylesheet,
                stats: this.statsStylesheet,
            };
            await StyleIOService.exportToFile(payload, `style.${this.$route.params.communitySlug}`);
        },
        async importStyle() {
            const styleData = await StyleIOService.importFromFile(
                CommunityService.GetCurrentCommunityUuid(),
            );

            if (styleData === null) {
                window.alert('Could not import style');

                return;
            }

            Object.entries(styleData?.web).forEach(([key, value]) => {
                this.stylesheet[key] = value as string;
            });

            Object.entries(styleData?.stats).forEach(([key, value]) => {
                this.statsStylesheet[key] = value as string;
            });

            const firstTab: HTMLAnchorElement|null = document.querySelector('.styling-configurator .nav li:first-child a');
            if (firstTab !== null) {
                firstTab.click();
            }
        },
    },
});
</script>

<style scoped>
    .options div.btn {
        margin-right: 1rem;
    }
</style>
