<template>
    <div class="w-100">
        <FieldSet title="header">
            <Field property="header_background-color" />
        </FieldSet>

        <FieldSet title="body">
            <Field property="body_background-color" />
            <Field property="body_color" />
        </FieldSet>

        <FieldSet title="bar-chart" scenario="barCharts">
            <Field property="bar_background-color" />
        </FieldSet>

        <FieldSet title="pie-chart" scenario="pieCharts">
            <PieField
                slice1="pie1_background-color"
                slice2="pie2_background-color"
                slice3="pie3_background-color"
                slice4="pie4_background-color"
            />
        </FieldSet>

        <FieldSet title="navigation" scenario="averages">
            <Field property="button_background-color" />
            <Field property="button_color" />
        </FieldSet>
    </div>
</template>

<script lang="ts">
import Form from '@/components/community/styling/Form.vue';
import Field from '@/components/community/styling/form/Field.vue';
import FieldSet from '@/components/community/styling/form/FieldSet.vue';
import PieField from '@/components/community/styling/form/PieField.vue';

export default Form.extend({
    components: {PieField, FieldSet, Field},
});
</script>
