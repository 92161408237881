/* eslint @typescript-eslint/no-explicit-any: 0 */
import AbbiApi from '@/apis/abbi.api';

export default class CommunityApi extends AbbiApi {
    public static getCommunities() {
        return CommunityApi.get(`${CommunityApi.url}/api/communities`);
    }

    public static createCommunity(name: string, slug: string) {
        return CommunityApi.put(`${CommunityApi.url}/api/communities`, {name, slug});
    }

    public static readCommunity(uuid: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${uuid}`);
    }

    public static readCommunityFromSlug(slug: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${slug}`);
    }

    public static updateCommunity(uuid: string, changes: { [key: string]: string | object }) {
        return CommunityApi.patch(`${CommunityApi.url}/api/communities/${uuid}`, changes);
    }

    public static getDeleteCommunityCSRF(uuid: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${uuid}/delete`);
    }

    public static deleteCommunity(uuid: string, csrf: string) {
        return CommunityApi.delete(`${CommunityApi.url}/api/communities/${uuid}/delete`, {csrf});
    }

    public static addUserToCommunity(communityUuid: string, userUuid: string) {
        return CommunityApi.post(`${CommunityApi.url}/api/communities/${communityUuid}/add/user/${userUuid}`, {});
    }

    public static removeUserFromCommunityCSRF(communityUuid: string, userUuid: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${communityUuid}/add/user/${userUuid}/delete`);
    }

    public static removeUserFromCommunity(communityUuid: string, userUuid: string, csrf: string) {
        return CommunityApi.post(`${CommunityApi.url}/api/communities/${communityUuid}/add/user/${userUuid}/delete`, {csrf});
    }

    public static uploadImage(communityUuid: string, image: FormData): Promise<{[key: string]: string}> {
        return CommunityApi.performFileUpload(`${CommunityApi.url}/api/communities/${communityUuid}/upload/image`, image);
    }

    public static createUpdateStyle(communityUuid: string, requestBody: Record<string, {[key: string]: string}[]>) {
        return CommunityApi.post(`${CommunityApi.url}/api/communities/${communityUuid}/style`, requestBody);
    }

    public static getStyle(communitySlug: string) {
        return CommunityApi.get(`${CommunityApi.url}/${communitySlug}/style`);
    }

    public static uploadLogo(communityUuid: string, image: FormData): Promise<string> {
        return CommunityApi.performFileUpload(`${CommunityApi.url}/api/communities/${communityUuid}/logo`, image);
    }

    public static uploadFont(communityUuid: string, font: FormData): Promise<string> {
        return CommunityApi.performFileUpload(`${CommunityApi.url}/api/communities/${communityUuid}/font`, font);
    }

    public static updateSidemenu(communityUuid: string, requestBody: Record<string, string>[]) {
        return CommunityApi.patch(`${CommunityApi.url}/api/communities/${communityUuid}/sidemenu`, requestBody);
    }

    public static getSidemenu(communitySlug: string) {
        return CommunityApi.get(`${CommunityApi.url}/${communitySlug}/sidemenu`);
    }

    public static saveOptionPair(communityUuid: string, optionId: string, requestBody: Record<string, any>) {
        return CommunityApi.post(`${CommunityApi.url}/api/communities/${communityUuid}/options/${optionId}`, requestBody);
    }

    public static exportMembers(communityUuid: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${communityUuid}/members`);
    }

    public static getRemovalChats(slug: string) {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/${slug}/chats/removal`);
    }

    public static getRemovalAllChats() {
        return CommunityApi.get(`${CommunityApi.url}/api/communities/chats/removal`);
    }
}
