<template>
    <v-text-field filled class="mr-2"
        :label="`${$t('FIELDS.NAME')} / ${$t('FIELDS.SHORT_LABEL')}`"
        v-model="instructionName"
        @change="instructionNameChanged"
        @keydown="sanitizeKeydown"
        :prefix="`q${nextOrderNumber}`"
        :rules="rules.uniqueInstructionName"
    />
</template>

<script lang="ts">

// Constant for regex pattern
import vue from 'vue';

const INPUT_REGEX = /^[a-zA-Z0-9_]$/;

export default vue.extend({
    name: 'InstructionNameControl',
    props: {
        value: String,
        nextOrderNumber: Number,
        allInstructionNames: Array<string>,
    },
    mounted() {
        this.instructionName = this.value;
        this.usedInstructionNames = this.allInstructionNames?.filter((n) => n !== this.value) ?? [];
    },
    methods: {
        sanitizeKeydown(event: KeyboardEvent): void {
            if (this.isInputAllowed(event)) {
                return;
            } else {
                event.preventDefault();
                if (this.isSpace(event)) {
                    this.instructionName += '_';
                }
            }
        },
        isInputAllowed(event: KeyboardEvent): boolean {
            if (event.code.startsWith('Arrow') || event.code === 'Tab' || event.code === 'Backspace' || event.code === 'Delete') {
                return true;
            }

            return INPUT_REGEX.test(event.key);
        },
        isSpace(event: KeyboardEvent): boolean {
            return event.code === 'Space';
        },
        instructionNameChanged(name: string): void {
            if (!this.isNameUnique(name)) return;

            this.$emit('input', name);
        },
        isNameUnique(value: string): boolean {
            const count = this.usedInstructionNames?.filter((n) => n === value).length;

            return count < 1;
        },
    },
    data() {
        return {
            instructionName: null as string|null,
            usedInstructionNames: [] as string[],
            rules: {
                uniqueInstructionName: [
                    (value: string): boolean|string => {
                        return !(this as any).isNameUnique(value) ? 'Name must be unique' : true;
                    },
                ],
            },
        }
    }
});
</script>
