<template>
    <div id="question-editor" class="mt-2">
        <div id="question-editor-header">
            <div v-if="['email', 'open', 'numeric'].includes(question.type) || (question.options && question.options.dropdown)"
                 class="setting-controls">
                <div class="setting-control mt-05 setting-control-width">
                    <v-app id="v-island" class="dynamic">
                        <v-text-field
                            filled
                            :label="$t('FIELDS.PLACEHOLDER')"
                            v-model="question.placeholder"
                            type="text"
                            max="1024"
                            :placeholder="$t('FIELDS.PLACEHOLDER')"/>
                    </v-app>
                </div>
            </div>
        </div>

        <ChoiceConfigurator v-if="question.type === 'choice' || question.type === 'multiple'"
                            :multiple="question.type === 'multiple'"
                            :choices="question.choices" @change="question.choices = $event"
                            :options="question.options" @optionsChanged="changedOptions"
                            :script-live="scriptLive" :live-editable="liveEditable"
        />
        <DateConfigurator v-if="question.type === 'date'" :options="question.options" @change="changedOptions"
                          :script-live="scriptLive" :live-editable="liveEditable" />
        <NumericConfigurator v-if="question.type === 'numeric'" :options="question.options" @change="changedOptions"
                             :script-live="scriptLive" :live-editable="liveEditable" />
        <CurrencyConfigurator v-if="question.type === 'currency'" :options="question.options" @change="changedOptions"
                             :script-live="scriptLive" :live-editable="liveEditable" />
        <FileConfigurator v-if="question.type === 'file'" :options="question.options" @change="changedOptions"
                              :script-live="scriptLive" :live-editable="liveEditable" />
        <EmojiConfigurator v-if="question.type === 'emoji'" :options="question.options" @change="changedOptions"
                           :script-live="scriptLive" :live-editable="liveEditable" />
        <OpenAnswerConfigurator v-if="question.type === 'open'" :options="question.options" @change="changedOptions"
                                :script-live="scriptLive" :live-editable="liveEditable" />
        <StarRatingConfigurator v-if="question.type === 'starRating'" :options="question.options" @change="changedOptions"
                                :script-live="scriptLive" :live-editable="liveEditable" />

        <div id="chart-options">
            <v-app id="v-app-dynamic" class="dynamic">
                <ChartTypeSelectionControl v-if="selectChartOptions.length > 1" :options="selectChartOptions" v-model="selectedChartOption"/>
            </v-app>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import vue from 'vue';
import QuestionModel from '@/models/question.model';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ChoiceConfigurator from '@/components/chat/configurators/question/ChoiceConfigurator.vue';
import NumericConfigurator from '@/components/chat/configurators/question/NumericConfigurator.vue';
import CurrencyConfigurator from '@/components/chat/configurators/question/CurrencyConfigurator.vue';
import EmojiConfigurator from '@/components/chat/configurators/question/EmojiConfigurator.vue';
import FileConfigurator from '@/components/chat/configurators/question/FileConfigurator.vue';
import OpenAnswerConfigurator from '@/components/chat/configurators/question/OpenAnswerConfigurator.vue';
import ImageConfigurator from '@/components/chat/configurators/ImageConfigurator.vue';
import DateConfigurator from '@/components/chat/configurators/question/DateConfigurator.vue';
import StarRatingConfigurator from '@/components/chat/configurators/question/StarRatingConfigurator.vue';
import ChartTypeSelectionControl from '@/components/chat/controls/ChartTypeSelectionControl.vue';

export default vue.extend({
    name: 'QuestionConfigurator',
    components: {
        ChartTypeSelectionControl,
        DateConfigurator,
        ChoiceConfigurator,
        NumericConfigurator,
        CurrencyConfigurator,
        EmojiConfigurator,
        OpenAnswerConfigurator,
        ImageConfigurator,
        AbbiTextInput,
        FileConfigurator,
        StarRatingConfigurator,
    },
    props: {
        question: [QuestionModel, Object],
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data() {
        return ({
            selectedChartOption: this.question?.options?.charttype ?? 'null',
        })
    },
    computed: {
        selectChartOptions(): Array<{value: string, text: string}> {
            const mergedOptions = Object.entries({
                null: 'FIELDS.CHARTTYPE.NONE',
                ...this.chartTypes,
            });

            return mergedOptions.map(([k, v]) => ({ value: k, text: this.$t(v).toString() }));
        },
        chartTypes(): Record<string, string> {
            const validTypes = {} as { [key: string]: string };
            switch (this.question.type) {
                case 'starRating':
                case 'numeric': {
                    validTypes.average = 'Average'; // 'FIELDS.CHARTTYPE.AVERAGE';
                    break;
                }

                case 'choice':
                case 'multiple': {
                    validTypes.barchart = 'FIELDS.CHARTTYPE.BARCHART';
                    validTypes.piechart = 'FIELDS.CHARTTYPE.PIECHART';
                    break;
                }
                case 'emoji': {
                    validTypes.barchart = 'FIELDS.CHARTTYPE.BARCHART';
                    break;
                }
                case 'file': {
                    validTypes.piechart = 'FIELDS.CHARTTYPE.PIECHART';
                    break;
                }
                case 'nps': {
                    validTypes.npschart = 'Net Promoter Score';
                    break;
                }
                case 'currency': {
                    validTypes.average = 'Average'; // 'FIELDS.CHARTTYPE.AVERAGE';
                    break;
                }
                default:
                    break;
            }

            return validTypes;
        },
        selectedQuestion() {
            return this.question;
        },
    },
    watch: {
        selectedQuestion() {
            this.$forceUpdate();
        },
        selectedChartOption(option: string) {
            if (this.question.options === undefined) {
                this.question.options = {};
            }

            const chartType = option !== 'null' ? option : null;

            this.question.options.charttype = chartType ?? undefined;
        },
    },
    methods: {
        isSkippable(): boolean {
            return this.question?.type === 'file';
        },
        changedOptions($e: Record<string, any>) {
            if (this.question.options === undefined) {
                this.question.options = $e;
                return;
            }
            this.question.options = Object.assign(this.question.options, $e);
            this.$forceUpdate();
        },
    },
});
</script>

<style lang="scss">
//::v-deep {
    @import 'src/styles/colors';

    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    @import '~bootstrap/scss/utilities';

    @import '~bootstrap/scss/input-group';

    @import "~bootstrap/scss/utilities/stretched-link";
//}

#question-editor {
    #question-editor-header {
        display: flex;
        flex-direction: column;
        > * {
            flex: 1;
        }

        #chart-options {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
        }

        #question-type-control {
            display: flex;
        }
    }
}
</style>
