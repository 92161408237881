<template>
    <div class="password-reset-component">
        <div v-if="errorMessage != null" class="error-message">
            <h3>{{ errorMessage.msg }}</h3>
            <p>{{ errorMessage.cause }}</p>
        </div>
        <div v-if="successMessage != null" class="success-message">
            <h3>{{ successMessage }}</h3>
        </div>
        <div class="ta-center mt-1">
            <h2 class="text-center">Forgot Password?</h2>
            <p>You can reset your password here.</p>
        </div>
        <form method="post" @submit.prevent="reset">
            <div class="ta-center">
                <AbbiTextInput class="pure-u-1-2" name="email" isSmall :placeholder="$t('USER.EMAIL')"/>
                <div class="mt-1">
                    <AbbiButton grow class="pure-u-1-2">{{ $t('LOGIN.RESET') }}</AbbiButton>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AccountApi from '@/apis/account.api';
import ErrorModel from '@/models/error.model';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

export default Vue.extend({
    name: 'PasswordResetComponent',
    components: {AbbiTextInput, AbbiButton},
    data() {
        return {
            errorMessage: null as null | ErrorModel,
            successMessage: null,
        };
    },
    methods: {
        reset(e: Event) {
            e.preventDefault();
            this.errorMessage = null;

            const form = new FormData(e.target as HTMLFormElement);
            AccountApi.passwordReset(form.get('email') as string)
                .then((data) => {
                    if (data !== undefined) {
                        this.successMessage = data;
                        window.setTimeout(() => {
                            this.$router.push('/login');
                        }, 2000);
                    } else {
                        AccountApi.getErrorData().then((errorData) => {
                            this.errorMessage = Object.assign(
                                new ErrorModel(),
                                {
                                    msg: Object.values(errorData).flat()[0],
                                },
                            );
                        });
                    }
                })
                .catch(() => {
                    AccountApi.getErrorData().then((errorData) => {
                        const {message, cause} = errorData;
                        this.errorMessage = Object.assign(
                            new ErrorModel(),
                            {
                                msg: message,
                                cause,
                            },
                        );
                    });
                });
        },
    },
    created() {
        // get current user instead of relying on frontend state
        if (this.$store.state.loggedIn) {
            this.$router.push('/home');
        }
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';
@import 'src/styles/utilities';

.password-reset-component {
    border: 2px solid transparentize($abbi-green, 0.75);
    border-radius: 6px;
    background: white;
    // overflow: hidden;
    > form {
        margin: 0.5rem 0.75rem;
    }

    > div.error-message {
        h3, p {
            padding: 0;
            margin: 0;

            font-size: 0.9em;
        }

        h3 {
            font-weight: bold;
            font-size: 1.1em;
        }

        background-color: darken(red, 15%);
        color: white;

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        margin: -2px;
        margin-bottom: 0;
        padding: 0.5rem 0.75rem;
    }
    > div.success-message {
        h3, p {
            padding: 0;
            margin: 0;

            font-size: 0.9em;
        }

        h3 {
            font-weight: bold;
            font-size: 1.1em;
        }

        background-color: #A8BF36;
        color: white;

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        margin: -2px;
        margin-bottom: 0;
        padding: 0.5rem 0.75rem;
    }

    .abbi-text-input {
        width: 60% !important;
    }
    .abbi-button {
        width: 60% !important;
    }
}
</style>
