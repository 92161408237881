<template>
    <div class="abbi-link" :class="{square, button: isButton}">
        <img v-if="square" alt="squarizer"
             src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
        <span v-if="square" class="vhidden no-wrap"><slot></slot></span>
        <a v-if="isExtern"
           :href="href"
           target="_blank"
           :class="{
                button: isButton,
                red: isRed,
                blue: isBlue
            }"
           ref="abbiLnk"
        >
            <slot><span></span></slot>
        </a>
        <router-link
            v-else-if="!isExtern && to !== undefined"
            :to="to"
            :class="{
                button: isButton,
                red: isRed,
                blue: isBlue
            }"
            ref="abbiLnk"
        >
            <slot><span></span></slot>
        </router-link>
        <div v-else class="button" :class="{blue: isBlue, 'no-radius': noRadius}"><slot><span></span></slot></div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'AbbiLink',
    props: {
        href: String,
        to: Object,
        isExtern: Boolean,
        isButton: Boolean,
        isRed: Boolean,
        isBlue: Boolean,
        noRadius: Boolean,
        bgColor: String,
        square: Boolean,
    },
    mounted() {
        /* if (this.isButton === true) {
                const myLnk = this.$refs.abbiLnk as HTMLElement;
                myLnk.style.setProperty('background-color', this.bgColor);
            } */
    },
});
</script>

<style lang='scss'>
@import 'src/styles/colors';

.abbi-link {
    .button, .vhidden {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0.5em;
        text-decoration: none;
        color: white;
        text-align: center;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        background-color: $abbi-green;

        &.blue {
            background-color: $abbi-blue !important;
        }

        &.no-radius {
            border-radius: 0!important;
        }

        &:hover {
            position: relative;

            > span {
                z-index: 2;
            }

            &:before, &:active::before {
                display: block;
                content: '';
                background-color: rgba(0, 0, 0, 0.2);
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:active::before {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    &.square {
        position: relative;
        height: 100%;

        .vhidden {
            display: block;
            /*height: 0;
            padding-top: 0;
            padding-bottom: 0;*/
            visibility: hidden;
        }

        > img {
            display: block;
            visibility: hidden;
            height: 100%;
        }

        a.button {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            width: 100%;
        }
    }
}
</style>
