<script lang="ts">
import Vue, {PropType} from 'vue';
import {Stylesheet} from '@/components/community/styling/CommunityStyling';
import StylesheetInheritor from '@/components/community/styling/StylesheetInheritor';
import StylesheetUpdater from '@/components/community/styling/StylesheetUpdater';

export default Vue.extend({
    props: {
        stylesheet: {
            type: Object as PropType<Stylesheet>,
        },
        statsStylesheet: {
            type: Object as PropType<Stylesheet>,
        },
        defaults: {
            type: Object as PropType<Stylesheet>,
        },
        inheritor: {
            type: Object as PropType<StylesheetInheritor>,
        },
        updater: {
            type: Object as PropType<StylesheetUpdater>,
        },
    },
    methods: {
        getValue(property: string): string {
            const value = this.stylesheet[property] ?? '';

            if (value.length) {
                return value;
            }

            const inheritedValue = this.inheritor.calculateInheritance(
                property,
                this.stylesheet,
            );

            if (inheritedValue.length) {
                return inheritedValue;
            }

            return this.defaults[property] ?? '';
        },
        updateProperty(property: string, value: string): void {
            this.updater.update(property, value);
        },
    },
});
</script>
