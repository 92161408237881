<template>
    <div>
        <div class="configurator-fields">
            <div class="row">
                <div class="col">
                    <label for="connector">
                        <FaIcon icon="cloud" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.CONNECTOR.LABEL') }}
                    </label>
                </div>
                <div class="col">
                    <select name="connector" id="connector" v-model="action.params.connector">
                        <option v-for="{id, name} in connectors" :key="id" :value="id">
                            {{ name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label for="mapper">
                        <FaIcon icon="project-diagram" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.MAPPER.LABEL') }}
                    </label>
                </div>
                <div class="col">
                    <select name="mapper" id="mapper" v-model="action.params.mapper">
                        <option v-for="mapper in mappers" :key="mapper" :value="mapper">
                            {{ $t(`COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.MAPPER.OPTION.${mapper.toString().toUpperCase()}.LABEL`) }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label for="defaults" :title="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.DEFAULTS.DESCRIPTION')">
                        <FaIcon icon="layer-group" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.DEFAULTS.LABEL') }}
                    </label>
                </div>
                <div class="col">
                    <ObjectConfigurator
                        :subject="action.params.defaults"
                        :placeholder="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.DEFAULTS.PLACEHOLDER')"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label for="defaults" :title="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.OVERRIDES.DESCRIPTION')">
                        <FaIcon icon="layer-group" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.OVERRIDES.LABEL') }}
                    </label>
                </div>
                <div class="col">
                    <ObjectConfigurator
                        :readonly="scriptLive"
                        :subject="action.params.overrides"
                        :placeholder="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.OVERRIDES.PLACEHOLDER')"
                    />
                </div>
            </div>
        </div>

        <dl class="legend">
            <dt>
                <FaIcon icon="cloud" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.CONNECTOR.LABEL') }}
            </dt>
            <dd>
                {{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.CONNECTOR.DESCRIPTION') }}
            </dd>

            <dt>
                <FaIcon icon="project-diagram" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.MAPPER.LABEL') }}
            </dt>
            <dd>
                {{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.MAPPER.DESCRIPTION') }}
            </dd>

            <dt>
                <FaIcon icon="layer-group" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.DEFAULTS.LABEL') }}
            </dt>
            <dd>
                {{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.DEFAULTS.DESCRIPTION') }}
            </dd>

            <dt>
                <FaIcon icon="layer-group" />{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.OVERRIDES.LABEL') }}
            </dt>
            <dd>
                {{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.OVERRIDES.DESCRIPTION') }}
            </dd>
        </dl>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import ActionModel from '@/models/action.model';
import CrmApi from '@/apis/crm.api';
import {PLATFORM_SALESFORCE} from '@/helpers/constants';
import ObjectConfigurator from '@/components/chat/configurators/action/ObjectConfigurator.vue';

interface Identifiable {
    id: number;
    name: string;
}

interface ComponentData {
    connectors: Array<Identifiable>;
    mappers: Array<string>;
}

export default vue.extend({
    name: 'Send2ConnectorConfigurator',
    components: {ObjectConfigurator},
    props: {
        action: [Object, ActionModel],
        scriptLive: Boolean,
    },
    beforeMount() {
        // Set parameter defaults.
        this.action.params = this.action.params || {};
        this.action.params.connector = this.action.params.connector || 0;
        this.action.params.mapper = this.action.params.mapper || 'default';

        ['defaults', 'overrides'].forEach((key) => {
            if (!(typeof this.action.params[key] === 'object')
                || this.action.params[key] instanceof Array
            ) {
                this.action.params[key] = Object.create({});
            }
        });

        // Fetch connectors for the current community.
        const {communitySlug} = this.$route.params;
        CrmApi.list(communitySlug).then((responseData) => {
            this.connectors = responseData
                ?.filter(
                    // Currently only the Salesforce platform is supported.
                    // This can be solved in a more durable fashion by
                    // introducing an API route that can fetch connectors
                    // for platforms that implement their ::update method.
                    ({platform}: {platform: number}) => platform === PLATFORM_SALESFORCE
                )
                .map(
                    ({id, name}: Identifiable) => ({id, name}),
                ) ?? [];
        });

        CrmApi.listMappers().then((responseData) => {
            this.mappers = responseData;
        });
    },
    data(): ComponentData {
        return {
            connectors: [
                {
                    id: 0,
                    name: String(
                        this.$i18n.t('COMMUNITY.CHAT.INSTRUCTION.SEND2CON.FIELD.CONNECTOR.MISSING'),
                    ),
                },
            ],
            mappers: ['default'],
        };
    },
});
</script>

<style lang="scss" scoped>
.legend,.configurator-fields {
    svg {
        color: #00949e;
        height: 1em;
        width: 1em;
        margin-right: .6em;
    }
}

.legend {
    padding: 1em;

    dt {
        font-weight: 700;
        margin-bottom: .3em;
    }
    dd {
        margin-bottom: 1.6em;
        margin-left: 1.6em;
    }
}

.configurator-fields {
    --line-height: 2.6em;
    --border-radius: 6px;
    --padding: calc(var(--border-radius) / 2);

    display: table;
    border: 2px solid rgba(0, 0, 0, .25);
    background: #fff;
    border-radius: var(--border-radius);
    padding: var(--padding) 0;

    .row {
        display: table-row;
        line-height: var(--line-height);
        border-radius: var(--border-radius);

        .col {
            display: table-cell;
            padding: var(--padding) 0 var(--padding) 1em;

            &:last-of-type {
                padding-right: calc(.4em + var(--padding));
            }
        }

        label {
            display: inline-block;
            font-weight: 700;

            svg {
                margin-right: .5em;
            }

            &[title] {
                cursor: help;
            }
        }

        input,select {
            width: 100%;
        }

        select {
            height: 2em;
            padding: 0 var(--padding);
            border-radius: var(--border-radius);
            background: #fff;
            border-style: solid;
            appearance: auto;
            cursor: pointer;
        }


    }
}
</style>
