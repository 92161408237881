<template>
    <div class="object-configurator">
        <div class="records">
            <div v-for="(value, key) in subject" :key="key" class="record">
                <label class="col" :for="key">
                    <code>{{key}}</code>
                </label>
                <input
                    :readonly="readonly"
                    :value="value"
                    :ref="`record[${key}]`"
                    @input="updateRecord(key, $event.target.value)"
                />
                <div v-if="!readonly">
                    <AbbiButton is-red @click="deleteRecord(key)">
                        <FaIcon icon="minus" />
                    </AbbiButton>
                </div>
            </div>
            <div class="record" v-if="!readonly">
                <div>
                    <input
                        ref="newKey"
                        v-model="newRecordKey"
                        :placeholder="placeholder"
                    />
                </div>
                <input
                    v-model="newRecordValue"
                    placeholder="Value"
                    @focus="addRecord" />
                <div>
                    <AbbiButton is-blue @click="addRecord" :disabled="newRecordKey.length === 0">
                        <FaIcon icon="plus" />
                    </AbbiButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

interface ComponentData {
    newRecordKey: string;
    newRecordValue: string;
}

export default vue.extend({
    name: 'ObjectConfigurator',
    components: {AbbiButton},
    props: {
        subject: Object,
        placeholder: String,
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data(): ComponentData {
        return {
            newRecordKey: '',
            newRecordValue: '',
        };
    },
    methods: {
        deleteRecord(key: string) {
            this.$delete(this.subject, key);
        },
        updateRecord(key: string, value: string) {
            this.subject[key] = value;
        },
        addRecord() {
            if (!this.newRecordKey.length) {
                return;
            }

            const editNewValue = this.newRecordValue.length === 0;
            const key = editNewValue ? `record[${this.newRecordKey}]` : 'newKey';

            this.subject[this.newRecordKey] = this.newRecordValue;
            this.newRecordKey = '';
            this.newRecordValue = '';

            this.$nextTick(() => {
                const $ref = this.$refs[key] as HTMLInputElement[]|HTMLInputElement;
                const input = $ref instanceof HTMLInputElement ? $ref : $ref[0];

                if (input) {
                    input.focus();
                }
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.object-configurator {
    --line-height: 2.6em;
    --border-radius: 6px;
    --padding: calc(var(--border-radius));

    input {
        border-radius: var(--border-radius);
        border: 1px solid rgba(0, 0, 0, .75);
        height: 2em;
        padding: 0 var(--padding);
    }

    .records {
        display: table;
        width: 100%;

        .record {
            display: table-row;

            > * {
                display: table-cell;
            }

            > :first-child {
                min-width: 160px;
                padding-right: 1em;
            }
        }
    }

    .abbi-button {
        margin-left: 1em;
        display: inline-block;

        :focus {
            outline: 2px solid black;
        }
    }

    .new-record-actions {
        input {
            width: calc(160px - 1em);
        }
    }
}
</style>
