<template>
    <div :class="$style.component">
        <h3>Resolve chat conversation</h3>
        <fieldset id="endType" class="mb-4">
            <div class="options">
                <div>
                    <input type="radio" id="endType_finished" value="finished" v-model="endType" />
                    <label for="endType_finished">
                        <span>
                            Mark chat as
                            <FaIcon icon="flag-checkered" />
                            <strong>finished</strong>
                        </span>
                    </label>
                </div>

                <div>
                    <input type="radio" id="endType_screenout" value="screenout" v-model="endType" />
                    <label for="endType_screenout">
                        <span>
                            Mark chat as
                            <FaIcon icon="arrow-right-from-bracket" />
                            <strong>screenout</strong>
                        </span>
                    </label>
                </div>
            </div>
        </fieldset>

        <h3>Perform the following actions</h3>
        <fieldset id="endAction">
            <div class="options">
                <div>
                    <input type="radio" id="endAction_close" value="close" v-model="endAction" />
                    <label for="endAction_close">
                        <span>
                            <FaIcon icon="hand" />
                            Stop the chat
                        </span>
                    </label>
                </div>

                <div>
                    <input type="radio" id="endAction_redirect" value="redirect" v-model="endAction" />
                    <label for="endAction_redirect">
                        <span>
                            <FaIcon icon="signs-post" />
                            Redirect the chat
                        </span>
                    </label>
                </div>

                <div>
                    <input type="radio" id="endAction_livestats" value="livestats" v-model="endAction" />
                    <label for="endAction_livestats">
                        <span>
                            <FaIcon icon="hand" />
                            Stop the chat
                        </span>
                        <span>
                            <FaIcon icon="chart-pie" />
                            Show button for live statistics
                        </span>
                    </label>
                </div>
            </div>

            <div v-if="endAction === 'close'">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <FaIcon class="mr-05" icon="message" />
                            Show the following message
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model.trim="message" max="50" placeholder="De chat is afgerond"/>
                </b-input-group>

<!--                <label for="endAction_message">-->

<!--                </label>-->
<!--                <input-->
<!--                    type="text"-->
<!--                    id="endAction_message"-->
<!--                    v-model.trim="message"-->
<!--                    maxlength="50"-->
<!--                    placeholder="De chat is afgerond"-->
<!--                />-->
            </div>

            <div  v-if="endAction === 'redirect'" class="configurator-fields">
                <div class="abbi-row">
                    <div class="abbi-col">
                        <label for="url" title="Redirect to">
                            <FaIcon class="mr-05" icon="link" /> Redirect to
                        </label>
                    </div>
                    <div class="abbi-col">
                        <UrlInput
                            id="endAction_url"
                            v-model.trim="url"
                            placeholder="https://example.com/thank-you-for-this-chat"
                        />
                    </div>
                </div>
                <div class="abbi-row">
                    <div class="abbi-col">&nbsp;</div>
                    <div class="abbi-col">
                        You can refer to metadata of the sample by inserting it in the url between curly braces '{metadata}'<br />
                        <span style="color: red">Never redirect to just the metadata, always prepend with a website URL.</span>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script lang="ts">
import vue, {PropType} from 'vue';
import ActionModel from '@/models/action.model';
import UrlInput from '@/components/input/UrlInput.vue';
import ObjectConfigurator from '@/components/chat/configurators/action/ObjectConfigurator.vue';

export default vue.extend({
    name: 'EndConfigurator',
    components: {ObjectConfigurator, UrlInput},
    props: {
        action: {
            type: Object as PropType<ActionModel>,
            required: true,
        },
    },
    data() {
        return {
            validForm: true,
            endType: 'finished',
            endAction: 'close',
            url: '',
            message: '',
        };
    },
    watch: {
        endType(endType: string): void {
            this.setParameters({endType});
        },
        endAction(endAction: string): void {
            this.setParameters({endAction});

            if (endAction !== 'redirect') {
                this.url = '';
            }
            if (endAction !== 'close') {
                this.message = '';
            }
        },
        url(url: string): void {
            this.setParameters({url});

            if (url) {
                this.endAction = 'redirect';
            }
        },
        message(message: string): void {
            this.setParameters({message});

            if (message) {
                this.endAction = 'close';
            }
        },
    },
    methods: {
        setParameters(parameters = {}): {
            endAction: string;
            endType: string;
            url: string;
            message: string;
        } {
            const params = {
                endAction: 'close',
                endType: 'finished',
                url: '',
                message: '',
                ...(this.action.params ?? {}),
                ...parameters,
            };

            Object.keys(params).forEach((param) => {
                if (!this.action.params) {
                    this.action.params = {};
                }

                this.action.params[param] = params[param as keyof typeof params];
            });

            return params;
        },
    },
    mounted() {
        const {
            endType,
            endAction,
            url,
            message,
        } = this.setParameters();

        this.endType = endType;
        this.endAction = endAction;
        this.url = url;
        this.message = message;
    },
});
</script>

<style lang="scss" module>
@import 'src/styles/colors';

.component :global {
    .configurator-fields {
        --line-height: 2.6em;
        --border-radius: 6px;
        --padding: calc(var(--border-radius) / 2);

        display: table;
        border: 2px solid rgba(0, 0, 0, .25);
        background: #fff;
        border-radius: var(--border-radius);
        padding: var(--padding) 0;
    }

    //select, fieldset {
    //    border-radius: 6px;
    //    background-color: white;
    //}
    //
    //fieldset + fieldset {
    //    margin-top: 2em;
    //}
    //
    fieldset {
        border-color: rgba(0, 0, 0, .2);

        legend {
            padding: 0 1rem;
            font-weight: 700;
            font-size: 1.2rem;
        }

        label {
            background: rgba(255, 255, 255, 0.2);
            margin-bottom: 0;
        }

        .options + div {
            margin-top: 1em;
        }

        .options {
            display: flex;
            gap: 1em;

            div {
                display: flex;
                gap: 1em;
                width: 100%;
                border: 1px solid rgba(0, 0, 0, .1);
                border-radius: .25em;
            }

            :has(:checked) {
                background: $abbi-green;
            }

            svg {
                margin: 0 6px;
            }

            input[type=radio] {
                margin-left: 1em;
            }

            label {
                display: inline-flex;
                flex-direction: column;
                gap: 1em;
                width: 100%;
                padding: 1em 0 1em 1em;
                border-left: 1px solid rgba(0, 0, 0, .1);
                justify-content: space-around;
            }
        }
    }

    input[type=url] {
        min-width: 48ch;
    }

    #endAction_message {
        width: 50ch;
    }

    .form-control {
        display: inline-flex;
        //background: $abbi-green;
        border-radius: .25em;
        border: 1px solid rgba(0, 0, 0, .1);

        input {
            padding: 1em;
        }

        input, label {
            line-height: 1em !important;
            height: 1lh;
            box-sizing: content-box;
            border: none;
            border-radius: inherit;
        }

        label {
            padding: 1em;
            border-right: 0;
        }

        label + input {
            border-left: 1px solid rgba(0, 0, 0, .1);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
</style>
