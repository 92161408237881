import VuexStore from '@/store';
import CommunityModel from '@/models/community.model';

export default class CommunityService {
    public static GetCurrentCommunity(): CommunityModel {
        return VuexStore.getters.community;
    }

    public static GetCurrentCommunityUuid(): string {
        return VuexStore.getters.community.uuid;
    }

    public static GetCurrentCommunitySlug(): string {
        return VuexStore.getters.communitySlug;
    }
}
