/* eslint-disable @typescript-eslint/naming-convention */
import settings from '@/settings';
import {CommunityStyling} from '@/components/community/styling/CommunityStyling';

const {webBase} = settings;

export default {
    stats: {
        'bar_background-color': '#0088AFFF',
        'body_background-color': '#FFFFFFFF',
        body_color: '#000000FF',
        'button_background-color': '#B8C400FF',
        button_color: '#FFFFFFFF',
        'header_background-color': '#DCDCDCFF',
        'pie1_background-color': '#0088AFFF',
        'pie2_background-color': '#B8C400FF',
        'pie3_background-color': '#E9528DFF',
        'pie4_background-color': '#000000FF',
    },
    web: {
        'abbi-message_background-color': '#F2F2F2FF',
        'abbi-message_button-background-color': '#B8C400FF',
        'abbi-message_button-color': '#FFFFFFFF',
        'abbi-message_color': '#000000',
        'abbi-message_link-color': '#0563C1FF',
        'action-button_background-color': '#B8C400FF',
        'action-button_color': '#FFFFFFFF',
        'action-button_disabled-background-color': '#0000000F',
        'action-button_disabled-color': '#00000017',
        'app_background-color': '#FFFFFFFF',
        'chat_background-color': '#FFFFFFFF',
        chat_color: '#000000FF',
        'choices_active-background-color': '#0088AFFF',
        'choices_active-color': '#FFFFFFFF',
        'choices_background-color': '#B8C400FF',
        choices_color: '#FFFFFF',
        'choices_border-radius': '.75em',
        choices_padding: '.75em',
        'choices_border-width': '0px',
        'choices_inactive-border-color': '#000000',
        'choices_active-border-color': '#000000',
        'body_font-family': '',
        'body_google_font-family': 'Quicksand',
        'body_font-file': '',
        'body_font-size': '1rem',
        'body_font-weight': '400',
        font_source_google: '0',
        header_display: 'flex',
        'header_background-color': '#FFFFFFFF',
        'header_background-image': `${webBase}/img/default/header_background-image.jpeg`,
        'header_background-size': 'cover',
        header_hamburger_menu_color: '',
        'header-logo_display': 'flex',
        'header-logo_content': '',
        'header-expanded-logo_opacity': '0',
        header_color: '#FFFFFF00',
        'input-control_background-color': '#DCDCDCFF',
        'input-control_color': '#000000',
        'input-control_placeholder-color': '#686868FF',
        'input-control_padding': '.75em',
        'input-control_border-radius': '.75em',
        'input-control_border-width': '0px',
        'input-control_border-color': '#000000',
        'input-control_box-shadow-enabled': '0',
        'progress-bar_background-color': '#DCDCDCFF',
        'progress-bar_color': '#B8C400FF',
        sidemenu_display: 'flex',
        'sidemenu_background-color': '#0088AFFF',
        'sidemenu_logo-display': 'inline',
        'sidemenu_copyright-display': 'block',
        'sidemenu_link_1-display': 'none',
        'sidemenu_link_1-content': '',
        'sidemenu_link_1-url': '',
        'sidemenu_link_2-display': 'none',
        'sidemenu_link_2-content': '',
        'sidemenu_link_2-url': '',
        'sidemenu_link_3-display': 'none',
        'sidemenu_link_3-content': '',
        'sidemenu_link_3-url': '',
        'sidemenu_link_4-display': 'none',
        'sidemenu_link_4-content': '',
        'sidemenu_link_4-url': '',
        'branding_message-display': 'block',
        sidemenu_color: '#FFFFFF',
        'slider_background-color-left': '#B8C400FF',
        'slider_background-color-right': '#DCDCDCFF',
        'slider_color-min-max': '#000000',
        'star_rating_active-color': '#FED055FF',
        'star_rating_inactive-color': '#D8D8D8FF',
        'star_rating_border-color': '#000000FF',
        'star_rating_border-width': '0',
        'user-message_background-color': '#0088AFFF',
        'user-message_color': '#FFFFFF',
        'validation-error_color': '#FF0000FF',
        message_padding: '.75em',
        'message_border-radius': '.75em',
        'message_tail-border-radius': '0',
        'message_border-width': '0px',
        'message_box-shadow-enabled': '1',
        'user-message_border-color': '#000000',
        'abbi-message_border-color': '#000000',
    },
} as CommunityStyling;
