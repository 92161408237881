<template>
    <div id="chat-detail-page" v-if="script !== null">
        <div class="chat-info block">
            <h2>{{ script.name }}</h2>
            <div class="actions flex-row flex-1">
                <div class="start">
                    <AbbiLink is-button square :to="{
                        name: 'ChatEditor',
                        params: {
                            communitySlug,
                            scriptUuid: script.uuid,
                        },
                    }">
                        {{ script.status === STATUS_PREPARATION ? $t('EDIT._self') : 'view script' }}
                        <FaIcon v-if="script.status === STATUS_PREPARATION" icon="edit" />
                    </AbbiLink>
                </div>
                <div class="center">
                    <AbbiLink is-button square is-blue is-extern :href="`${livestatsBase}/${communitySlug}/${script.uuid}`">stats
                        <FaIcon icon="chart-pie" />
                    </AbbiLink>
                    <AbbiLink is-button is-extern :href="anonymousChatLink">Go to chat</AbbiLink>
                    <AbbiButton is-red @click="showRemoveDataDialogue">
                        clear data
                        <FaIcon icon="user" transform="shrink-7 down-2" :mask="['fa', 'trash']"></FaIcon>
                    </AbbiButton>
                    <vue-button-spinner
                        v-if="downloadFileIntervalHandle"
                        :is-loading="Boolean(downloadFileIntervalHandle)"
                        :disabled="Boolean(downloadFileIntervalHandle)"
                    >
                        <span>Processing file upload</span>
                    </vue-button-spinner>
                    <AbbiButton v-else-if="isFilesReadyToDownload" is-blue @click="downloadFiles">
                        Download Files
                    </AbbiButton>
                    <AbbiButton v-else is-blue @click="requestDownloadFiles(false)">
                        Request file upload
                    </AbbiButton>
                </div>
                <div class="end badges">
                    <span class="badge" :class="[`badge-${STATUSES[script.status]}`]">{{ STATUSES[script.status] }}</span>
                </div>
            </div>
        </div>
        <div class="horizontal-block-group">
            <div class="export-options block">
                <h3>{{ $t('ACTION.EXPORT._self') }}</h3>
                <div class="content">
                    <AbbiButton grow @click="doExport">{{ $t('ACTION.EXPORT.PREVIEW_ALL') }} ({{ totalCount }})</AbbiButton>
                    <hr />
                    <div class="date-selector">
                        <span>{{ $t('FIELDS.FROM') }}</span>
                        <DatetimePicker v-model="exportOptions.from" />
                    </div>
                    <div class="date-selector">
                        <span>{{ $t('FIELDS.TO') }}</span>
                        <DatetimePicker v-model="exportOptions.to" />
                    </div>
                    <AbbiButton disabled grow>{{ $t('ACTION.EXPORT.PREVIEW_FILTERED') }}</AbbiButton>
                </div>
            </div>
            <div class="create-links block">
                <h3>{{ $t('ACTION.LINKS._self') }}</h3>
                <div class="content">
                    <div class="flex-row">
                        <label class="amount"><input type="number" name="amount-of-links" v-model="linkOptions.amount" /></label>
                        <Toggle class="ml-1" disabled v-model="linkOptions.short">{{ $t('ACTION.LINKS.SHORT') }}</Toggle>
                        <AbbiButton grow class="flex-1 ml-1" @click="openLinkDialogue(linkOptions.short, linkOptions.amount)">
                            {{ linkOptions.short ? $t('ACTION.LINKS.CREATE_SHORT') : $t('ACTION.LINKS.CREATE') }}
                        </AbbiButton>
                    </div>
                    <hr />
                    <AbbiButton grow class="flex-1" @click="openLinkDialogue(true, undefined, true)">
                        {{ $t('ACTION.LINKS.CREATE_FOR_LIST') }}
                    </AbbiButton>
                    <hr />
                    <div class="flex-row">
                        <span class="link-start no-wrap">{{ shortLinkHost }}</span>
                        <AbbiTextInput v-model="linkSlug" is-small name="short-val" :placeholder="$t('ACTION.LINKS.SLUG')" />
                        <AbbiButton @click="saveLinkSlug"  grow class="flex-1 ml-1">
                            {{ $t('ACTION.LINKS.CREATE_SHORT_ANONYMOUS') }}
                        </AbbiButton>
                    </div>
                    <label class="flex-row">
                        <span class="link-start no-wrap">Anonymous Link</span>
                        <button class="flex-row copy-button flex-1" @click="setClipboard(anonymousChatLink)">
                            <input class="link-copy flex-1" type="text" disabled :value="anonymousChatLink" />
                            <AbbiLink class="link-copy" no-radius is-blue><FaIcon icon="copy" /></AbbiLink>
                        </button>
                    </label>
                </div>
            </div>
        </div>
        <div v-if="$route.query.dev == 'true'" class="horizontal-block-group">
            <div class="technical block">
                <h3>
                    <FaIcon icon="gears"/> Technical Info
                </h3>
                <div class="content">
                    <div class="configurator-fields">
                        <div class="abbi-row">
                            <div class="abbi-col">
                                <FaIcon icon="layer-group" />Statistics Transport
                            </div>
                            <div class="abbi-col">
                                <select v-if="!scriptLive"
                                        :value="customTransport"
                                        @change="setCustomTransport($event.target.value)">
                                    <option value="unset">statistics (not overridden)</option>
                                    <option value="statistics">statistics</option>
                                    <option value="statistics-secondary">statistics-secondary</option>
                                    <option value="statistics-tertiary">statistics-tertiary</option>
                                </select>
                                <span v-else class="monospaced">
                                    {{ customTransport }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-alert: 0 */
import Vue from 'vue';
import ScriptModel from '@/models/script.model';
import ChatApi from '@/apis/chat.api';
import LinkApi from '@/apis/link.api';
import AbbiLink from '@/components/interface/AbbiLink.vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import DatetimePicker from '@/components/input/DatetimePicker.vue';
import ExportApi, {ExportOptions} from '@/apis/export.api';
import ExportModal from '@/components/modals/ExportModal.vue';
import Toggle from '@/components/input/Toggle.vue';
import settings from '@/settings';
import LinksModal from '@/components/modals/LinksModal.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import RemoveDataDialogue from '@/components/modals/RemoveDataDialogue.vue';
import {getShortCodeHost} from '@/helpers/utilities';
import {
    STATUS_CLOSED, STATUS_LIVE, STATUS_PREPARATION, STATUSES,
} from '@/helpers/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueButtonSpinner from 'vue-button-spinner';

export default Vue.extend({
    name: 'ChatDetails',
    components: {
        AbbiLink,
        AbbiButton,
        AbbiTextInput,
        DatetimePicker,
        Toggle,
        VueButtonSpinner,
    },
    data() {
        return {
            exportOptions: {
                from: new Date(),
                to: new Date(),
            },
            linkOptions: {
                amount: 1,
                short: true,
            },
            script: null as ScriptModel | null,
            totalCount: 0,
            linkSlug: null,
            exportIntervalHandle: undefined as number | undefined,
            downloadFileIntervalHandle: undefined as number | undefined,
            isFilesReadyToDownload: false,
            STATUS_PREPARATION,
            STATUSES,
        };
    },
    computed: {
        communitySlug(): string {
            const {communitySlug} = this.$route.params;
            return communitySlug;
        },
        webBase(): string {
            return settings.webBase;
        },
        shortLinkHost(): string {
            return getShortCodeHost();
        },
        livestatsBase(): string {
            return settings.statsBase;
        },
        anonymousChatLink(): string {
            if (!this.script) return '';

            let url = `${this.webBase}/${this.communitySlug}/chat/${this.script.uuid}/anonymous`;
            if (this.script.language && this.script.language !== 'nl') {
                url += `?l=${this.script.language}`;
            }
            return url;
        },
        scriptLive(): boolean {
            if (this.script?.status === undefined) return false;
            return [STATUS_LIVE, STATUS_CLOSED].includes(this.script?.status);
        },
        customTransport(): string | undefined {
            return (this.script && this.script.queue_config && this.script.queue_config.transport !== null)
                ? this.script.queue_config.transport : 'unset';
        },
    },
    methods: {
        doExport() {
            const filter = new ExportOptions();
            filter.limit = 10;

            this.$store.commit('setModal', {
                title: this.$t('ACTION.EXPORT._self'),
                component: ExportModal,
                options: {
                    rows: this.totalCount,
                    script: this.script,
                    filter,
                    format: {},
                },
            });
        },
        getLinkSlug(): void {
            const {scriptUuid} = this.$route.params;
            LinkApi.getAnonymousLinkSlug(scriptUuid)
                .then(({slug}) => {
                    this.linkSlug = slug;
                });
        },
        requestDownloadFiles(isCheck = false) {
            const {communitySlug, scriptUuid} = this.$route.params;
            ChatApi.requestFileUpload(communitySlug, scriptUuid, isCheck).then(({result}) => {
                if (result === 'notFound') {
                    return;
                }
                if (result === 'filesNotFound') {
                    alert("Files don't exist");
                    return;
                }
                if (result) {
                    window.clearInterval(this.downloadFileIntervalHandle);
                    this.downloadFileIntervalHandle = undefined;
                    this.isFilesReadyToDownload = true;
                    return;
                }

                if (!this.downloadFileIntervalHandle) {
                    this.downloadFileIntervalHandle = window.setInterval(this.requestDownloadFiles, 2500);
                }
            });
        },
        downloadFiles() {
            const {communitySlug, scriptUuid} = this.$route.params;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { name } = this.script;
            ChatApi.downloadFiles(scriptUuid).then(({result}) => {
                if (result === 'ok') {
                    window.location.assign(`${settings.objectStorage}/uploads/${communitySlug}/files/${scriptUuid}/${name}.zip`);
                    this.downloadFileIntervalHandle = undefined;
                    this.isFilesReadyToDownload = false;
                }
            });
        },
        saveLinkSlug() {
            if (this.linkSlug) {
                const {scriptUuid} = this.$route.params;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                LinkApi.saveAnonymousLinkSlug(scriptUuid, {slug: this.linkSlug})
                    .then(({status, message}) => {
                        if (status === 'error') {
                            alert(message);
                        }
                        if (status === 'ok') {
                            alert('Success!');
                        }
                    });
            }
        },
        openLinkDialogue(short: boolean, amount = 0, list = false) {
            this.$store.commit('setModal', {
                title: this.$t('ACTION.LINKS._self'),
                component: LinksModal,
                options: {
                    short,
                    amount,
                    list,
                    scriptName: this.script?.name,
                },
            });
        },
        showRemoveDataDialogue() {
            this.$store.commit('setModal', {
                title: 'Chat cleanup',
                component: RemoveDataDialogue,
                options: {},
            });
        },
        updateCounts() {
            if (!document.hidden) {
                window.clearInterval(this.exportIntervalHandle);
                const {communitySlug, scriptUuid} = this.$route.params;
                if (scriptUuid) {
                    ExportApi.getConversationCount(communitySlug, scriptUuid)
                        .then((data) => {
                            this.totalCount = data as number;
                            this.exportIntervalHandle = window.setInterval(this.updateCounts, 30 * 1000);
                        });
                }
            }
        },
        setClipboard(content: string) {
            navigator.clipboard.writeText(content).then(() => window.alert('link copied!'));
        },
        setCustomTransport(name: string): void {
            if (this.script === null) return;

            ChatApi.setConfig(this.communitySlug, this.script.uuid, {customTransport: name});
        },
    },
    created() {
        this.exportOptions.from.setHours(0, 0, 0, 0);
        this.exportOptions.from.setMonth(this.exportOptions.from.getMonth() - 1);
        this.exportOptions.to.setHours(23, 59, 59, 999);

        const {communitySlug, scriptUuid} = this.$route.params;
        ChatApi
            .readChat(communitySlug, scriptUuid)
            .then((data) => {
                if (data !== undefined) {
                    this.script = data as ScriptModel;
                }
            });
        this.requestDownloadFiles(true);
        this.updateCounts();
        this.getLinkSlug();
    },
    beforeDestroy() {
        window.clearInterval(this.exportIntervalHandle);
        window.clearInterval(this.downloadFileIntervalHandle);
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

.vue-btn {
    color: #606060 !important;
    border-radius: 6px !important;
    background-color: #9a9a9a !important;
    border: none !important;
    .spinner {
        height: 20px !important;
        width: 20px !important;
    }
}
#chat-detail-page {
    display: flex;
    flex-direction: column;

    margin: auto;
    width: 50vw;
    min-width: 960px;

    .block {
        margin: 1rem;
        padding: 1rem;
        background-color: white;
        border-radius: 6px;
        border: 1px solid $abbi-blue;
        box-shadow: 0 0 2px 0 $abbi-blue;

        .content {
            > * {
                margin-top: 1rem;
            }
            hr {
                margin-top: 0.5rem;
                margin-bottom: -0.5rem;
            }
        }
    }

    .horizontal-block-group {
        display: flex;

        > * {
            flex: 1;
        }
    }

    .chat-info {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: space-between;
        }

        .actions {
            align-self: normal;
            justify-content: space-between;

            > *:not(:last-child) {
                margin-right: 0.5rem;
            }

            .center {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 1em;
            }
        }
    }

    .abbi-text-input.small {
        input[type="text"] {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .export-options {
        .date-selector {
            display: flex;
            align-items: center;

            > * {
                flex: 1;
            }

            > *:first-child {
                flex: 0;
                min-width: 15%;
            }
        }
    }

    .badges {
        padding-left: 20px;
        .badge {
            display: inline-block;
            padding: 10px;

            border-radius: 6px;

            color: white;

            cursor: pointer;

            background-color: grey;

            &.badge-preparation {
                background-color: $abbi-blue;
            }

            &.badge-live {
                background-color: $abbi-green;
            }

            font-size: 14px;
        }
    }

    .create-links {
        #short-val {
            width: 8em;
        }

        .link-start {
            align-self: center;
            margin-right: 0.25em;
        }

        .amount > input {
            height: 100%;
            border: 1px solid $abbi-green;
            padding: 0.5rem;
        }

        .abbi-toggle {
            align-self: center;
        }

        .link-copy {
            border-radius: 0;
            border: 1px solid $abbi-blue;
        }
        .copy-button {
            padding: 0;
            margin: 0;

            outline: 0;
            border: 0;
            background: none;

            &, & * {
                cursor: pointer;
            }

            > input {
                height: 100%;
            }
        }
    }
}

.configurator-fields {
    //--line-height: 2.6em;
    //--border-radius: 6px;
    //--padding: calc(var(--border-radius) / 2);
    //
    //display: table;
    //border: 2px solid rgba(0, 0, 0, .25);
    //background: #fff;
    //border-radius: var(--border-radius);
    //padding: var(--padding) 0;

    svg {
        color: #00949e;
        height: 1em;
        width: 1em;
        margin-right: .6em;
    }

    .abbi-row {
        display: table-row;
        line-height: var(--line-height);
        border-radius: var(--border-radius);

        .abbi-col {
            display: table-cell;
            padding: var(--padding) 0 var(--padding) 1em;

            &:last-of-type {
                padding-right: calc(.4em + var(--padding));
            }

            span.monospaced {
                font-family: monospace;
                background: rgba(0, 0, 0, .05);
                border: 1px solid rgba(0, 0, 0, .25);
                padding: 4px;
            }
        }

        label {
            display: inline-block;
            font-weight: 700;

            svg {
                margin-right: .5em;
            }

            &[title] {
                cursor: help;
            }
        }

        //input {
        //    border-radius: 6px;
        //    border: 1px solid rgba(0, 0, 0, .75);
        //    padding: 0 6px;
        //}
    }

    .list-unstyled {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;

        li {
            margin-right: 1rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
</style>
