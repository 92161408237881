<script lang="ts">
import Instruction from '@/components/chat/Instruction.vue';

export default Instruction.extend({
    props: {
        type: {
            type: String,
            default: 'starRating',
        },
        icon: {
            type: String,
            default: 'star-half-stroke',
        },
        label: {
            type: String,
            default: 'Star Rating',
        },
    },
});
</script>
