<template>
    <v-app id="numeric-configurator" class="dynamic">
        <v-alert
            prominent
            v-if="!validForm && showInvalidError"
            type="error"
        >
            This configuration contains errors. Changes will not be saved. <br />
            Please fix them before continuing or moving away from this page.
        </v-alert>
        <v-form ref="form" v-model="validForm">
            <v-row>
                <v-col>
                    <v-text-field
                        type="number"
                        :readonly="scriptLive"
                        id="minimum-value"
                        placeholder="Number"
                        v-model.number="minimumVal"
                        min="1"
                        :max="maximumVal"
                        label="Minimum value"
                        @input="revalidate"
                        @change="changed"
                        @blur="inputBlurred"
                        filled
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        type="number"
                        :readonly="scriptLive"
                        id="maximum-value"
                        placeholder="Number"
                        v-model.number="maximumVal"
                        :min="minimumVal"
                        label="Maximum value"
                        @input="revalidate"
                        @change="changed"
                        @blur="inputBlurred"
                        filled
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                        type="number"
                        :readonly="scriptLive"
                        id="precision"
                        min="0"
                        max="2"
                        placeholder="Number"
                        v-model.number="precision"
                        label="Decimals"
                        @input="revalidate"
                        @change="changed"
                        @blur="inputBlurred"
                        filled
                    />
                </v-col>
                <v-col>
                    <v-btn-toggle
                        v-model="extensions"
                        @change="onChange"
                        color="secondary"
                        mandatory
                        group
                    >
                        <v-btn v-for="(value, key) in currencyOptions" :value="key" :key="key">
                            {{ value.symbol }}
                            {{ value.name }}
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
        </v-form>
    </v-app>
</template>

<script lang="ts">
import vue from 'vue';
import Toggle from '@/components/input/Toggle.vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';
import {currencies} from '@/helpers/currencies';
import Configurator from '@/components/chat/configurators/Configurator.vue';

export default Configurator.extend({
    name: 'CurrencyConfigurator',
    components: {
        Toggle,
        AbbiNumericInput,
    },
    methods: {
        changed(): void {
            if (!this.validForm) return;

            this.$emit('change', {
                minimum: this.minimumVal,
                maximum: this.maximumVal,
                precision: this.precision,
                currency: this.currency,
            });
        },
        validateInput($event: InputEvent) {
            const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            // eslint-disable-next-line
            // @ts-ignore
            if (!keysAllowed.includes($event.key)) {
                $event.preventDefault();
                $event.stopImmediatePropagation();
            }
        },
    },
    props: {
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    mounted() {
        this.$emit('change', {
            minimum: this.minimumVal,
            maximum: this.maximumVal,
            precision: this.precision,
            currency: this.currency,
        });
    },
    data() {
        return {
            initOptions: this.options || {},
            minimumVal: this.options?.minimum || 1,
            maximumVal: this.options?.maximum || 100000,
            precision: this.options?.precision || 0,
            currency: this.options.currency || Object.keys(currencies)[0],
            currencyOptions: currencies,
        };
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

#numeric-configurator {
    .input-group {
        margin: 0 auto;

        max-width: 960px;
        min-height: 40px;

        display: flex;
        justify-content: space-between;
    }

    #currency {
        width: 189px;
        border-radius: 4px;
    }

    .inputfield {
        display: flex;
        align-items: center;

        > .text {
            width: 150px;

            > * {
                margin: 0;
            }
        }
    }
}
</style>
