<template>
    <div>
        <BootstrapStylesheet />

        <div id="stylesheet-page" v-if="stylingLoaded">
            <div class="styling-menu">
                <div v-if="wizard" class="wizard-mode">
                    <FaIcon icon="wand-magic-sparkles" />
                    {{ $t('COMMUNITY.STYLESHEET.MESSAGE.WIZARD_ENABLED') }}
                </div>

                <div class="styling-configurator">
                    <ul class="nav nav-tabs">
                        <li
                            v-for="(tab, tabKey) in tabs"
                            :key="tabKey"
                            class="nav-item"
                            :title="$t(tab.title)"
                        >
                            <a
                                class="nav-link"
                                :class="{active: activeTab === tab}"
                                href="#"
                                @click.prevent="activeTab = tab">
                                <FaIcon :icon="tab.icon" />
                                {{ $t(tab.label) }}
                            </a>
                        </li>
                    </ul>
                    <div class="configurator">
                        <div v-if="portalLoaded" class="scope">
                            <component
                                :stylesheet="stylesheet"
                                :statsStylesheet="statsStylesheet"
                                :communitySlug="community"
                                :defaults="defaults"
                                :portal="portal"
                                :inheritor="inheritor"
                                :updater="updater"
                                @stateChange="onStateChange"
                                :is="configurator" />
                        </div>
                        <div v-else class="loading-previews">
                            <p v-html="$t('COMMUNITY.STYLESHEET.MESSAGE.LOADING_PREVIEWS')" />
                        </div>
                    </div>
                </div>

                <div class="actions" v-if="portalLoaded">
                    <button v-if="hasNextTab" type="button" @click.prevent="nextTab()">
                        <span>{{ $t('COMMUNITY.STYLESHEET.ACTION.NEXT_TAB.LABEL') }}</span>
                        <FaIcon icon="arrow-right" />
                    </button>
                    <button v-else-if="hasUnsavedChanges || wizard" type="button" @click.prevent="saveChanges()" :disabled="saving">
                        <FaIcon icon="floppy-disk" />
                        <span>{{ $t('COMMUNITY.STYLESHEET.ACTION.SAVE.LABEL') }}</span>
                    </button>
                    <p v-else>
                        <FaIcon icon="check" />
                        {{ $t('COMMUNITY.STYLESHEET.MESSAGE.NO_UNSAVED_CHANGES') }}
                    </p>
                </div>
            </div>

            <div class="styling-preview">
                <BrowserPreview
                    class="desktop-preview"
                    :device="desktop"
                    :target="previewUrl"
                    :addressBarUrl="addressBarUrl"
                    :icon="icon"
                    :windowTitle="windowTitle"
                    :messenger="messenger"
                    @ready="onPreviewLoaded(DeviceType.Desktop)"
                />
                <BrowserPreview
                    class="mobile-preview"
                    :device="mobile"
                    :target="previewUrl"
                    :addressBarUrl="addressBarUrl"
                    :icon="icon"
                    :windowTitle="windowTitle"
                    :messenger="messenger"
                    @ready="onPreviewLoaded(DeviceType.Mobile)"
                />
            </div>
        </div>
        <div v-else class="loader-wrapper">
            <div class="loading-stylesheets">
                {{ $t('COMMUNITY.STYLESHEET.MESSAGE.LOADING_STYLESHEETS') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint no-alert: 0 */
import vue from 'vue';
import BootstrapStylesheet from '@/components/bootstrap/BootstrapStylesheet.vue';
import BrowserPreview, {
    Device,
    DeviceType,
    Messenger,
    Message,
} from '@/components/browser/BrowserPreview.vue';
import settings from '@/settings';
import ChatApplicationConfigurator from '@/components/community/styling/chat/application/ChatApplicationConfigurator.vue';
import LiveStatsConfigurator from '@/components/community/styling/live-stats/LiveStatsConfigurator.vue';
import ChatConversationConfigurator from '@/components/community/styling/chat/conversation/ChatConversationConfigurator.vue';
import ChatAnswersConfigurator from '@/components/community/styling/chat/answers/ChatAnswersConfigurator.vue';
import Configurator, {Portal} from '@/components/community/styling/Configurator.vue';
import CommunityModel from '@/models/community.model';
import normalize from '@/components/community/styling/normalizer';
import StylesheetService from '@/services/stylesheet.service';
import StylesheetInheritor from '@/components/community/styling/StylesheetInheritor';
import {CommunityStyling, Stylesheet} from '@/components/community/styling/CommunityStyling';
import StylesheetUpdater from '@/components/community/styling/StylesheetUpdater';
import ChatSettingsConfigurator from '@/components/community/styling/chat/application/ChatSettingsConfigurator.vue';
import defaultStyling from './defaults/defaultStyling';

const portalIcons: Record<Portal, string> = {
    [Portal.Chat]: 'comment-dots',
    [Portal.Statistics]: 'chart-pie',
};

const portalBaseUrls: Record<Portal, string> = {
    [Portal.Chat]: settings.webBase,
    [Portal.Statistics]: settings.statsBase,
};

const portalStyleSections: Record<Portal, string> = {
    [Portal.Chat]: 'web',
    [Portal.Statistics]: 'stats',
};

interface Tab {
    label: string;
    title: string;
    icon: string;
    portal: Portal;
    configurator: typeof Configurator;
    skipWizard: boolean|null;
}

const tabs = {
    chat: {
        skipWizard: false,
        label: 'COMMUNITY.STYLESHEET.TAB.CHAT.LABEL',
        title: 'COMMUNITY.STYLESHEET.TAB.CHAT.TITLE',
        icon: portalIcons[Portal.Chat],
        portal: Portal.Chat,
        configurator: ChatApplicationConfigurator,
    },
    conversation: {
        skipWizard: false,
        label: 'COMMUNITY.STYLESHEET.TAB.CONVERSATION.LABEL',
        title: 'COMMUNITY.STYLESHEET.TAB.CONVERSATION.TITLE',
        icon: 'comments',
        portal: Portal.Chat,
        configurator: ChatConversationConfigurator,
    },
    answers: {
        skipWizard: false,
        label: 'COMMUNITY.STYLESHEET.TAB.ANSWERS.LABEL',
        title: 'COMMUNITY.STYLESHEET.TAB.ANSWERS.TITLE',
        icon: 'pen-to-square',
        portal: Portal.Chat,
        configurator: ChatAnswersConfigurator,
    },
    statistics: {
        skipWizard: false,
        label: 'COMMUNITY.STYLESHEET.TAB.STATS.LABEL',
        title: 'COMMUNITY.STYLESHEET.TAB.STATS.TITLE',
        icon: portalIcons[Portal.Statistics],
        portal: Portal.Statistics,
        configurator: LiveStatsConfigurator,
    },
    settings: {
        skipWizard: true,
        label: '',
        title: '',
        icon: 'cog',
        portal: Portal.Chat,
        configurator: ChatSettingsConfigurator,
    },
} as Record<string, Tab>;

const mobile = {
    type: DeviceType.Mobile,
    resolution: {
        width: 0,
        maxWidth: 430,
        height: 0,
        maxHeight: 932,
    },
    browser: {
        fullscreen: true,
        offset: {
            x: 0,
            y: 0,
        },
    },
    os: {
        taskbar: false,
    },
} as Device;

const desktop = {
    type: DeviceType.Desktop,
    resolution: {
        width: 0,
        height: 0,
        minWidth: 910,
        minHeight: 860,
    },
    browser: {
        fullscreen: true,
        offset: {
            x: 0,
            y: 0,
        },
    },
    os: {
        taskbar: true,
    },
} as Device;

const defaultPreviewState = {
    [DeviceType.Desktop]: false,
    [DeviceType.Mobile]: false,
} as Record<DeviceType, boolean>;

const defaultCommunityStyling = Object.values(portalStyleSections).reduce(
    (carry: CommunityStyling, section: string) => ({
        ...carry,
        [section]: {},
    }),
    {} as CommunityStyling,
) as CommunityStyling;

const emptyDefaultStyling = Object.keys(defaultCommunityStyling).reduce(
    (carry: CommunityStyling, section: string) => ({
        ...carry,
        [section]: Object.keys(defaultStyling[section] || {}).reduce(
            (stylesheet: object, key: string) => ({
                ...stylesheet,
                [key]: '',
            }),
            {},
        ),
    }),
    {} as CommunityStyling,
) as CommunityStyling;

export default vue.extend({
    components: {
        BrowserPreview,
        BootstrapStylesheet,
    },
    data() {
        const [activeTab] = Object.values(tabs);
        const {portal} = activeTab;
        const messenger = new Messenger();
        const inheritor = new StylesheetInheritor();
        const updater = new StylesheetUpdater();

        return {
            previewLoaded: {...defaultPreviewState},
            communityStyling: {} as Record<string, CommunityStyling>,
            communityOverlay: {} as Record<string, CommunityStyling>,
            communityRequiresWizard: {} as Record<string, boolean>,
            communityTabsSeen: {} as Record<string, Tab[]>,
            tabs,
            activeTab,
            portal,
            mobile,
            desktop,
            messenger,
            inheritor,
            updater,
            DeviceType,
            numChanges: 0,
            saving: false,
        };
    },
    watch: {
        activeTab(newTab: Tab, oldTab: Tab): void {
            if (!(this.community in this.communityTabsSeen)) {
                this.communityTabsSeen = {[this.community]: []};
            }

            const seen = this.communityTabsSeen[this.community];

            [oldTab, newTab].forEach(
                (tab: Tab) => {
                    if (!seen.includes(tab)) {
                        seen.push(tab);
                    }
                },
            );

            if (newTab.portal !== this.portal) {
                this.previewLoaded = {...defaultPreviewState};
                this.portal = newTab.portal;
            }
        },
        portalLoaded(loaded: boolean): void {
            this.updater.removeListener(this.updateProperty);

            if (loaded) {
                this.updater.addListener(this.updateProperty);
                this.onPortalLoaded();
            }
        },
        community(newCommunity: string): void {
            this.previewLoaded = {...defaultPreviewState};
            this.communityTabsSeen = {[newCommunity]: []};
            this.portal = this.activeTab.portal;
            this.numChanges = 0;
        },
        styling: {
            handler(value: CommunityStyling, old: CommunityStyling): void {
                if (
                    !value.web
                    || !old.web
                    || Object.values(value.web).length === 0
                    || Object.values(old.web).length === 0
                ) {
                    this.numChanges = 0;
                    return;
                }

                this.numChanges++;
            },
            deep: true,
        },
        overlay: {
            handler(value: Stylesheet): void {
                const {portal} = this;
                const section = portalStyleSections[portal];
                this.stylingOverlay[section] = value;
                this.updateStyle(value);
            },
            deep: true,
        },
    },
    computed: {
        wizard(): boolean {
            return this.communityRequiresWizard[this.community] ?? false;
        },
        communityModel(): CommunityModel|null {
            return this.$store.getters.community;
        },
        communityTitle(): string {
            return this.communityModel
                ? this.communityModel.name
                : this.community;
        },
        community(): string {
            return this.$route.params.communitySlug;
        },
        configurator(): typeof Configurator {
            return this.activeTab.configurator;
        },
        hasNextTab(): boolean {
            if (!this.wizard) {
                return false;
            }

            // const available = Object.values(this.tabs);
            const available = Object.values(this.tabs).filter((t) => !(t.skipWizard ?? false));
            const offset = available.indexOf(this.activeTab);

            if (offset < available.length - 1) {
                return true;
            }

            const seen = this.communityTabsSeen[this.community] ?? [];

            return available.reduce(
                (numSeen: number, tab: Tab) => (
                    numSeen + (seen.includes(tab) ? 1 : 0)
                ),
                0,
            ) < available.length;
        },
        hasUnsavedChanges(): boolean {
            return this.numChanges > 0 && Object.keys(this.communityOverlay).length > 0;
        },
        previewUrl(): URL {
            const baseUrl = portalBaseUrls[this.portal];
            return new URL(`${baseUrl}/${this.community}/preview`);
        },
        addressBarUrl(): URL {
            return this.previewUrl;
        },
        windowTitle(): string {
            return this.portal === Portal.Chat
                ? `Chat - ${this.communityTitle} preview`
                : `Livestats - ${this.communityTitle} preview`;
        },
        icon(): string {
            return this.getPortalIcon(this.portal);
        },
        stylingLoaded(): boolean {
            return (
                Object.values(this.styling.web || {}).length > 0
                || Object.values(this.communityStyling).length > 0
            );
        },
        styling(): CommunityStyling {
            const {community} = this;

            if (!(community in this.communityStyling)) {
                this.fetchCommunityStyling(community);
            }

            return this.communityStyling[community] ?? JSON.parse(
                JSON.stringify(defaultCommunityStyling),
            );
        },
        stylingOverlay(): CommunityStyling {
            const {community} = this;

            if (!(community in this.communityOverlay)) {
                this.communityOverlay[community] = {...this.styling};
            }

            return this.communityOverlay[community];
        },
        defaults(): Stylesheet {
            return this.getPortalDefaults(this.portal);
        },
        stylesheet(): Stylesheet {
            return this.getPortalStylesheet(this.portal);
        },
        statsStylesheet(): Stylesheet {
            return this.getPortalStylesheet(Portal.Statistics);
        },
        overlay(): Stylesheet {
            return this.createOverlay(this.stylesheet);
        },
        portalLoaded(): boolean {
            return Object.values(this.previewLoaded).reduce(
                (carry: boolean, loaded: boolean) => carry && loaded,
                true,
            );
        },
    },
    beforeRouteLeave(to, from, next): void {
        if (this.numChanges === 0
            // eslint-disable-next-line no-alert
            || window.confirm(
                this.$t('COMMUNITY.STYLESHEET.MESSAGE.LEAVE_WITH_UNSAVED_CHANGES').toString(),
            )
        ) {
            this.reset();
            this.$nextTick(next);
        }
    },
    methods: {
        reset(): void {
            this.saving = false;
            this.numChanges = 0;
            this.previewLoaded = {...defaultPreviewState};
            this.communityStyling = {};
            this.communityOverlay = {};
            this.communityRequiresWizard = {};
            this.communityTabsSeen = {};
        },
        nextTab(): void {
            const available = Object.values(this.tabs);
            const offset = available.indexOf(this.activeTab);
            let nextTab = available[offset + 1] as Tab | undefined;

            if (!nextTab) {
                const seen = this.communityTabsSeen[this.community] ?? [];

                nextTab = available.find(
                    (tab: Tab) => !seen.includes(tab),
                );
            }

            if (nextTab) {
                this.activeTab = nextTab;
            }
        },
        fetchCommunityStyling(community: string): void {
            this.communityStyling = {};
            this.communityOverlay = {};
            this.communityRequiresWizard = {};

            StylesheetService.getStyle(community).then(
                (styling: CommunityStyling) => {
                    this.communityStyling = {
                        [community]: this.mergeStyling([
                            emptyDefaultStyling,
                            styling,
                        ]),
                    };
                    this.communityRequiresWizard = {
                        [community]: Object.values(styling).length === 0,
                    };

                    this.$nextTick(() => {
                        this.numChanges = 0;
                    });
                },
            );
        },
        mergeStyling(stylings: CommunityStyling[]): CommunityStyling {
            return stylings
                // Ensure references to the original are broken.
                .map((styling: CommunityStyling) => JSON.parse(JSON.stringify(styling)))
                .reduce(
                    (carry: CommunityStyling, current: CommunityStyling) => {
                        // Make sure unique sections on either side are represented.
                        const sections = [
                            ...Object.keys(carry),
                            ...Object.keys(current),
                        ];

                        // Merge the styling per section, such that individual
                        // stylesheet properties are merged, rather than whole
                        // sections being replaced at-once.
                        // This is necessary for existing community styling
                        // receiving new default properties.
                        return sections.reduce(
                            (overlay: CommunityStyling, section: string) => ({
                                ...overlay,
                                [section]: {
                                    ...(carry[section] ?? {}),
                                    ...(current[section] ?? {}),
                                },
                            }),
                            carry,
                        );
                    },
                    {} as CommunityStyling,
                );
        },
        onPortalLoaded(): void {
            this.messenger.dispatch(
                {
                    type: 'setTitle',
                    content: {
                        title: this.communityTitle,
                    },
                } as Message,
            );
            this.updateStyle(this.stylesheet);
        },
        onPreviewLoaded(type: DeviceType): void {
            this.previewLoaded[type] = true;
        },
        getPortalIcon(portal: Portal): string {
            return portalIcons[portal];
        },
        getPortalDefaults(portal: Portal): Stylesheet {
            if (portal === Portal.Chat && 'web' in defaultStyling) {
                return {...defaultStyling.web};
            }

            if (portal === Portal.Statistics && 'stats' in defaultStyling) {
                return {...defaultStyling.stats};
            }

            return {};
        },
        getPortalStylesheet(portal: Portal): Stylesheet {
            const styling = (this.styling || {}) as CommunityStyling;
            const section = portalStyleSections[portal];

            return styling[section] || {};
        },
        onStateChange(state: object): void {
            this.messenger.dispatch(
                {
                    type: 'setState',
                    content: state,
                } as Message,
            );
        },
        createOverlay(stylesheet: Stylesheet): Stylesheet {
            const overlay = {...this.defaults, ...stylesheet};
            return Object.keys(overlay).reduce(
                (carry: Stylesheet, property: string) => ({
                    ...carry,
                    [property]: this.getValue(stylesheet, property),
                }),
                {} as Stylesheet,
            ) as Stylesheet;
        },
        getValue(stylesheet: Stylesheet, property: string): string {
            const value = stylesheet[property] ?? '';

            if (value.length) {
                return value;
            }

            const inheritedValue = this.inheritor.calculateInheritance(
                property,
                stylesheet,
            );

            if (inheritedValue.length) {
                return inheritedValue;
            }

            return this.defaults[property] ?? '';
        },
        updateProperty(property: string, value: string): void {
            const section = portalStyleSections[this.portal];
            this.communityStyling[this.community][section][property] = value;
            this.communityOverlay[this.community][section][property] = value;
            this.updateStyle(this.communityStyling[this.community][section]);
            this.numChanges++;
        },
        updateStyle(stylesheet: Stylesheet): void {
            const overlay = this.createOverlay(stylesheet);

            this.messenger.dispatch({
                type: 'updateStyle',
                content: Object.keys(overlay).reduce(
                    (carry: Stylesheet, property: string) => ({
                        ...carry,
                        [property]: normalize(property, overlay[property]),
                    }),
                    {},
                ),
            } as Message);
        },
        saveChanges(): void {
            this.saving = true;
            StylesheetService.save(
                this.communityModel?.uuid ?? '',
                {...this.stylingOverlay},
            )
                .then(() => {
                    this.numChanges = 0;
                    this.communityRequiresWizard = {};
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    const retry = window.confirm(
                        this.$t('COMMUNITY.STYLESHEET.MESSAGE.SAVING_FAILED_RETRY').toString(),
                    );

                    if (retry) {
                        this.$nextTick(() => this.saveChanges());
                    }
                })
                .finally(() => {
                    this.saving = false;
                });
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/colors';

input, select {
    background-color: white;
    border: 1px solid black;
}

.loader-wrapper {
    height: 100vh;

    .loading-stylesheets {
        margin: calc(50vh - 8em) auto 0;
        width: 400px;
        height: 2em;
        line-height: 2em;
        background: $abbi-green;
        color: white;
        border-radius: 1em;
        text-align: center;
        transform: translateY(-50%);
    }
}

#stylesheet-page {
    display: flex;
    width: 100%;
    height: calc(100vh - 48px - 76px);

    &>div {
        flex: 1;
    }

    .nav-link {
        display: flex;
        gap: .3em;
        padding: 0.7rem;

        svg {
            margin-top: .2em;
        }
    }

    .styling-menu {
        display: flex;
        flex-direction: column;
        max-width: 520px;

        .wizard-mode {
            padding: 1em;
            background: white;
            border-bottom: 1px dashed grey;
            min-height: 3em;

            svg {
                margin: .5em .7em 0 0;
                width: auto;
                height: 2em;
                float: left;
            }
        }

        .styling-configurator {
            padding: 1em 0 0;
            flex: 1;
            display: flex;
            flex-direction: column;

            > .nav {
                padding: 0 1em;

                &.nav-tabs {
                    align-items: flex-end;

                    li {
                        &:last-child {
                            margin-left: auto;

                            a {
                                padding-bottom: 16px;
                            }
                        }
                    }
                }
            }

            .configurator {
                flex: 1;
                position: relative;

                .loading-previews {
                    padding: 1em;
                }

                .scope {
                    padding: 0;
                    overflow-y: auto;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .actions {
            button, p {
                margin: 0;
                background: $abbi-green;
                padding: 1em;
                color: white;
                display: flex;
                border: none;
                outline: none;
                width: 100%;
                gap: .5em;
                align-items: center;
                align-content: center;
                justify-content: center;

                &:disabled {
                    opacity: 70%;
                }
            }
        }
    }

    .styling-preview {
        display: flex;

        .browser-preview {
            flex: 1;

            &.desktop-preview {
                flex: 2;
            }
        }
    }
}
</style>
