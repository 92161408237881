<template>
    <div id="community-page">
        <OverviewNavigation :links="links" overview-route="Community" />
        <router-view />
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import OverviewNavigation, {NavLink} from '@/components/layout/OverviewNavigation.vue';

export default vue.extend({
    name: 'Community',
    components: {OverviewNavigation},
    data() {
        return {
            links: [
                {
                    to: {name: 'CommunityStylesheet'},
                    label: 'ROUTER.COMMUNITY.STYLESHEET',
                    icon: 'palette',
                },
                {
                    to: {name: 'CommunityMembers'},
                    label: 'ROUTER.COMMUNITY.MEMBERS',
                    icon: 'users-viewfinder',
                },
                {
                    to: {name: 'CommunityOptions'},
                    label: 'ROUTER.COMMUNITY.OPTIONS',
                    icon: 'gears',
                },
                {
                    to: {name: 'Removal'},
                    label: 'ROUTER.COMMUNITY.REMOVAL',
                    icon: 'trash-can',
                },
            ] as NavLink[],
        };
    },
});
</script>

<style lang="scss" scoped>
#community-page {
    display: flex;
    flex-direction: column;
}
</style>
