<template>
    <div id="password-reset">
        <PasswordResetComponent />
    </div>
</template>

<script lang="ts">
import PasswordResetComponent from '@/components/account/PasswordResetComponent.vue';

export default {
    name: 'PasswordReset',
    components: {PasswordResetComponent},
};
</script>

<style lang="scss">
    #password-reset {
        .password-reset-component {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            margin: 0 auto;
            width: 40vw;
            min-width: max-content;
            max-width: 32em;
        }
    }
</style>
