<script lang="ts">
import Vue from 'vue';
import ObjectConfigurator from '@/components/chat/configurators/action/ObjectConfigurator.vue';
import ActionModel from '@/models/action.model';

export default Vue.extend({
    name: 'EndpointConfigurator',
    components: {ObjectConfigurator},
    props: {
        value: [Object, ActionModel],
        readonly: Boolean,
    },
});
</script>

<template>
    <div>
        <b-input-group class="mb-2">
            <b-input-group-prepend class="p-0 col-md-3 col-12">
                <b-input-group-text>
                    <FaIcon icon="server" />
                    <span class="ml-1">{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.ENDPOINT.LABEL') }}</span>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
                type="text"
                :readonly="readonly"
                id="endpoint"
                name="endpoint"
                placeholder="https://example.com/api/v1/confetti"
                v-model="value.params.endpoint" />
        </b-input-group>
        <b-input-group class="mb-2">
            <b-input-group-prepend class="p-0 col-md-3 col-12">
                <b-input-group-text>
                    <FaIcon icon="code-fork" />
                    <span class="ml-1">Version</span>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select v-model="value.params.version" class="p-2">
                <option value="1">v1: Static (Structure can't be changed)</option>
                <option value="2">v2: Dynamic</option>
            </b-form-select>
        </b-input-group>
        <b-input-group class="mb-2">
            <b-input-group-prepend class="p-0 col-md-3 col-12">
                <b-input-group-text>
                    <FaIcon icon="tags" />
                    <span class="ml-1">{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.LABELS.LABEL') }}</span>
                </b-input-group-text>
            </b-input-group-prepend>
            <ObjectConfigurator
                class="ml-2"
                :subject="value.params.labels"
                :readonly="readonly"
                :placeholder="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.LABELS.PLACEHOLDER')"
            />
        </b-input-group>
    </div>
</template>

<style scoped lang="scss">
    @import 'src/styles/colors';

    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    @import '~bootstrap/scss/input-group';

    @import '~bootstrap/scss/utilities';

    .input-group-text {
        width: 100%;
    }
</style>
