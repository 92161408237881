<template>
    <div id="video-configurator">
        <div id="video-options">
            <div class="flex-row">
                <AbbiTextInput
                    class="flex-1"
                    grow
                    is-small
                    placeholder="video url"
                    v-model="videoUrl"
                    @input="handleChange"
                />
            </div>
            <div class="flex-row">
                <Toggle class="flex-1" v-model="autostart" @input="handleChange">Autostart</Toggle>
            </div>
            <div class="flex-row">
                <Toggle class="flex-1" v-model="waitForFinish" @input="handleChange">Wait until finished</Toggle>
            </div>
            <div class="flex-row">
                <p style="color: red;">
                    For most standard browsers settings it is not allowed to
                    autostart a youtube video unless a user performs an action
                    (click) on the web domain.<br>
                    For our chat this means that a respondent must answer at
                    least 1 question before the autostart functionality works
                    properly.<br>
                    So, if you start a chat with a video without any interaction
                    before the video, it is advisable to ask the respondent to
                    start the video manually by pressing the start button.
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import InstructionModel, {InstructionModelExt} from '@/models/instruction.model';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import Toggle from '@/components/input/Toggle.vue';

export default vue.extend({
    name: 'VideoConfigurator',
    components: {
        AbbiTextInput, Toggle,
    },
    props: {
        instruction: [Object, InstructionModel],
    },
    methods: {
        handleChange() {
            const msg = InstructionModelExt.GetMessageForLocale(this.instr, 'en', 'en');
            if (msg !== null) {
                msg.content = '';
                if (this.videoUrl !== '') {
                    msg.content = `
                        <div
                            id="${this.instruction.uuid}-video"
                            data-vid-url="${this.videoUrl}"
                            ${this.autostart ? ' will-autostart' : ''}
                            ${this.waitForFinish ? ' will-wait-for-end' : ''}
                        ></div>`.replace(/\s+/g, ' ').trim();
                }
                if (this.text !== '') {
                    msg.content = `${msg.content}<span class="vid-msg">${this.text}</span>`;
                }
            }
            this.$emit('change', this.instr);
        },
    },
    data() {
        return {
            instr: this.instruction as InstructionModel,

            text: '',
            videoUrl: '',

            autostart: false,
            waitForFinish: false,
        };
    },
    created() {
        const msg = InstructionModelExt.GetMessageForLocale(this.instruction, 'en', 'en');
        if (msg !== null) {
            this.text = msg.content
                .replace(/\s+/g, ' ')
                .replace(/<div id=.*><\/div>/g, '')
                .replace(/^<span class="vid-msg">/g, '')
                .replace(/<\/span>$/g, '');

            const startOfUrl = msg.content.indexOf('data-vid-url') + 'data-vid-url="'.length;
            const endOfUrl = msg.content.indexOf('"', startOfUrl);
            this.videoUrl = msg.content.substring(startOfUrl, endOfUrl);

            this.autostart = msg.content.indexOf('will-autostart') > -1;
            this.waitForFinish = msg.content.indexOf('will-wait-for-end') > -1;
        }
    },
});
</script>

<style lang="scss">
#video-configurator {
    #video-options {
        max-width: 960px;
        margin: 0 auto;

        .flex-row {
            margin-top: 1rem;
        }
    }
}
</style>
