import Violation from '@/interfaces/violation.interface';

export default class ViolationListModel {
    violations: Violation[] = [];

    apply(violations: Violation[]): void {
        this.violations = violations;
    }

    reset(): void {
        this.apply([]);
    }

    hasError(field: string): boolean {
        return this.violations.reduce(
            (carry: boolean, violation: Violation) => (
                carry || violation.field === field
            ),
            false,
        );
    }

    getErrors(field: string): Array<Violation> {
        return this.violations.filter(
            (candidate: Violation) => candidate.field === field,
        );
    }
}
