<template>
    <div id="app">
        <Header v-if="loaded"></Header>
        <router-view v-if="loaded" />
        <transition name="modal-fade">
            <Modal v-if="$store.state.modal">
                <component :is="$store.state.modal"
                           :options="$store.state.modalOptions"
                           v-on="$store.state.modalEventHandlers"></component>
            </Modal>
        </transition>
        <transition name="loader-fade">
            <Loader v-if="!loaded" />
        </transition>
        <FlashMessage class="flash-msg" position="left top"/>
    </div>
</template>

<style lang="scss">
@import 'src/styles/base';
@import 'src/styles/colors';
@import 'src/styles/utilities';

.flash-msg {
    > div {
        width: 23%;
        z-index: 99999;
        @media only screen and (max-device-width: 768px) {
            width: 70%;
        }
    }
}
#app {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
    max-height: 100vh;
    background: hsl(0, 0, 95%);

    div[id$='-page'] {
        flex: 1;
        max-height: 100%;
        overflow: hidden;
    }
}

.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 0.25s;
}

.modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
}

header {
    display: flex !important;
}
</style>

<script lang="ts">
import Vue from 'vue';
import Header from '@/components/layout/Header.vue';
import Modal from '@/components/modals/Modal.vue';
import Loader from '@/views/Loader.vue';
import AccountApi from '@/apis/account.api';

export default Vue.extend({
    name: 'App',
    components: {Header, Modal, Loader},
    data() {
        return {
            loaded: false,
        };
    },
    created() {
        AccountApi.getCurrentUser()
            .then((userData) => {
                this.loaded = true;
                if (userData?.email !== undefined) {
                    this.$store.dispatch('login', userData);
                } else {
                    this.resetUser();
                }
            })
            .catch(() => {
                this.loaded = true;
                this.resetUser();
            });
    },
    methods: {
        resetUser(): void {
            this.$store.commit('setUser', null);
            this.$store.commit('setLoggedIn', false);
            if (!['ResetPassword', 'ChangePassword'].includes(this.$router.currentRoute.name as string)) {
                this.$router.push('/login');
            }
        },
    },
});
</script>
