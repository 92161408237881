// eslint-disable-next-line import/prefer-default-export
export const currencies = {
    1: {
        name: 'Euro',
        abbreviation: 'EUR',
        symbol: '€',
    },
    2: {
        name: 'Dollar',
        abbreviation: 'USD',
        symbol: '$',
    },
    3: {
        name: 'Sterling',
        abbreviation: 'GBP',
        symbol: '£',
    },
};
