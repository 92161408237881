<template>
    <div id="action-editor">
        <PaymentConfigurator
            v-if="action.type === 'payment'"
            :action="action"
            :script="script"
            :script-live="scriptLive"
            :live-editable="liveEditable" />
        <Send2ConnectorConfigurator
            v-else-if="action.type === 'send2con'"
            :script-live="scriptLive"
            :action="action" />
        <Send2ApiConfigurator
            v-else-if="action.type === 'send2api'"
            @undraft="$emit('undraft')"
            :action="action"
            :script="script"
            :script-live="scriptLive" />
        <CrmAuthConfigurator
            v-else-if="action.type === 'crmAuth'"
            :action="action"
            :script="script"
            :script-live="scriptLive"
            :live-editable="liveEditable" />
        <CrmApiConfigurator
            v-else-if="action.type === 'crmApi'"
            :action="action"
            :script="script"
            :script-live="scriptLive"
            :live-editable="liveEditable" />

        <div id="chart-options">
            <v-app id="v-app-dynamic" class="dynamic">
                <ChartTypeSelectionControl v-if="selectChartOptions.length > 1" :options="selectChartOptions" v-model="selectedChartOption"/>
            </v-app>
        </div>
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import PaymentConfigurator from '@/components/chat/configurators/action/PaymentConfigurator.vue';
import ActionModel from '@/models/action.model';
import ScriptModel from '@/models/script.model';
import Send2ApiConfigurator from '@/components/chat/configurators/action/Send2ApiConfigurator.vue';
import CrmAuthConfigurator from '@/components/chat/configurators/action/CrmAuthConfigurator.vue';
import CrmApiConfigurator from '@/components/chat/configurators/action/CrmApiConfigurator.vue';
import Send2ConnectorConfigurator from '@/components/chat/configurators/action/Send2ConnectorConfigurator.vue';
import ChartTypeSelectionControl from '@/components/chat/controls/ChartTypeSelectionControl.vue';

export default vue.extend({
    name: 'ActionConfigurator',
    components: {
        ChartTypeSelectionControl,
        Send2ConnectorConfigurator,
        PaymentConfigurator,
        Send2ApiConfigurator,
        CrmAuthConfigurator,
        CrmApiConfigurator,
    },
    props: {
        action: [Object, ActionModel],
        script: [Object, ScriptModel],
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data() {
        return ({
            selectedChartOption: this.action?.params?.charttype ?? 'null',
        })
    },
    watch: {
        selectedChartOption(option: string) {
            if (this.action.params === undefined) {
                this.action.params = {};
            }

            const chartType = option !== 'null' ? option : null;

            this.action.params.charttype = chartType ?? undefined;
        },
    },
    computed: {
        selectChartOptions(): Array<{value: string, text: string}> {
            const mergedOptions = Object.entries({
                null: 'FIELDS.CHARTTYPE.NONE',
                ...this.chartTypes,
            });

            return mergedOptions.map(([k, v]) => ({ value: k, text: this.$t(v).toString() }));
        },
        chartTypes(): undefined|Record<string, string> {
            const validTypes = {} as { [key: string]: string };

            switch (this.action.type) {
                case 'score': {
                    validTypes.average = 'Average'; // 'FIELDS.CHARTTYPE.AVERAGE';
                    break;
                }

                default:
                    return undefined;
            }

            return validTypes;
        },
    },
});
</script>

<style lang="scss">

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/grid';

@import '~bootstrap/scss/utilities';

#action-editor {
    height: 100%;
    justify-content: space-between;
}
</style>
