<script lang="ts">
import Vue from 'vue';
import { VBPopover } from 'bootstrap-vue';

export default Vue.extend({
    name: 'ListGroupItemInput',
    directives: { VBPopover },
    props: {
        value: {
            type: [String, Boolean],
        },
        label: String,
        name: String,
        infoText: String,
        exampleText: String,
        header: Boolean,
        disabled: Boolean,
        level: String,
        group: String,
        type: String,
    },
    watch: {
        value(state): void {
            this.$emit('input', state);
        },
    },
    computed: {
        popoverOptions(): object {
            return {
                customClass: 'info-popover',
                content: this.infoText !== undefined ? `<strong>${this.infoText}</strong><br />example: ${this.exampleText}` : null,
                html: true,
            };
        },
    },
});
</script>

<template>
    <b-list-group-item action :class="this.header ? 'header' : ''">
        <div class="d-flex w-100 justify-content-between">
            <div>
                <b-form-checkbox v-if="type === 'checkbox'" v-model="value" inline :disabled="disabled" :value="true" :type="type" :name="group" :id="name">
                    <span :style='{"margin-left": `${level*0.5}em`}'>{{ label }}</span>
                </b-form-checkbox>
                <b-form-radio v-else v-model="value" inline :disabled="disabled" :type="type" :name="group" :value="`${name}`" :id="name">
                    <span :style='{"margin-left": `${level*0.5}em`}'>{{ label }}</span>
                </b-form-radio>
            </div>
            <FaIcon v-if="infoText !== '' || exampleText !== ''"
                    class="align-self-center"
                    :style='{"z-index": 2}'
                    icon="info-circle"
                    v-b-popover.hover.top="popoverOptions" />
        </div>
    </b-list-group-item>
</template>

<style scoped lang="scss">
.header {
    background-color: rgba(0, 0, 0, 0.125);
    border-bottom-width: 2px;
}

.info-popover {
    z-index: 3;
    padding: 1em;
    background-color: white;
    border: 1px solid black;
}
</style>
