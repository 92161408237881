<template>
    <input :style='{display: "block"}' type="text" @input="$emit('input', $event.target.value)"
           :placeholder="placeholder" :value="value"/>
</template>

<script lang="ts">
import Input from '@/components/community/styling/Input/Input.vue';

let increment = 0;

export default Input.extend({
    props: {
        placeholder: String,
        disabled: Boolean,
        value: String,
    },
    data() {
        increment++;
        return {increment};
    },
});
</script>

<style scoped lang="scss" module>

input {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    padding-left: 0.5rem;

    width: 100%;
    justify-self: end;
    &::placeholder {
      opacity: 0.5;
    }
    margin-bottom: 0.2rem;
}
</style>
