// eslint-disable-next-line max-classes-per-file
import AbbiApi from '@/apis/abbi.api';

export class ExportOptions {
    limit = 2;

    offset = 0;

    empties = false;

    filtered = false;

    from?: Date;

    to?: Date;
}

export default class ExportApi extends AbbiApi {
    public static getConversationCount(slug: string, scriptUuid: string, filledOnly = true) {
        return ExportApi.get(`${ExportApi.url}/api/${slug}/${scriptUuid}/export/count?filledonly=${filledOnly}`);
    }

    public static getMetakeys(slug: string, scriptUuid: string) {
        return ExportApi.get(`${ExportApi.url}/api/${slug}/${scriptUuid}/export/meta`);
    }

    public static getExportData(slug: string, scriptUuid: string, filter: ExportOptions | null = null) {
        let options = filter;
        if (options === null) {
            options = new ExportOptions();
        }

        let url = `${ExportApi.url}/api/${slug}/${scriptUuid}/export?limit=${options.limit}&offset=${options.offset}&filledonly=${!options.empties}`;

        if (options.from !== undefined) {
            url += `&from=${options.from.toISOString()}`;
        }

        if (options.to !== undefined) {
            url += `&to=${options.to.toISOString()}`;
        }

        return ExportApi.get(url);
    }
}
