<template>
    <div>
        <div class="mb-3">
            <AccountEmail />
        </div>
    </div>
</template>

<script lang="ts">
import AccountEmail from '@/components/account/AccountEmail.vue';

export default {
    name: 'AccountOverview',
    components: {AccountEmail},
};
</script>
