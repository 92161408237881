<script lang="ts">
import Configurator from '@/components/community/styling/Configurator.vue';

export interface ChatState {
    date: Date;
    isTyping: boolean;
    instructions: object[];
    answers: object[];
    lastAnswered: boolean;
    completed: number;
    validationError: string | null;
    valid: boolean;
    canGoBack: boolean;
}

export function createMessage(uuid: string, message: string): object {
    return {
        uuid,
        type: 'message',
        translations: [
            {
                locale: 'en',
                content: message,
            },
        ],
    };
}

export function createQuestion(
    uuid: string,
    type: string,
    query: string,
    placeholder: string,
    options: object = {},
    skipable = true,
): object {
    return {
        uuid,
        type: 'question',
        translations: [{locale: 'en', content: query}],
        question: {
            placeholder,
            skipable,
            options,
            type,
        },
    };
}

export function createAnswer(
    uuid: string,
    value: string,
): object {
    return {instruction: uuid, value};
}

export interface Scenario {
    instructions: object[];
    answers: object[];
    isTyping: boolean;
    validationError: string | null;
    valid: boolean;
    canGoBack: boolean;
}

const defaultScenario = {
    instructions: [],
    answers: [],
    isTyping: false,
    validationError: null,
    valid: true,
    canGoBack: false,
} as Scenario;

const scenarios = {defaultScenario} as Record<string, Scenario>;

export default Configurator.extend({
    data() {
        return {
            scenarios,
            options: {
                scenario: Object.keys(scenarios)[0] as string,
            },
        };
    },
    computed: {
        date(): Date {
            return new Date();
        },
        scenario(): Scenario {
            return this.scenarios[this.options.scenario];
        },
        instructions(): object[] {
            return this.scenario.instructions;
        },
        isTyping(): boolean {
            return this.scenario.isTyping;
        },
        answers(): object[] {
            return this.scenario.answers;
        },
        validationError(): string | null {
            return this.scenario.validationError;
        },
        valid(): boolean {
            return this.scenario.valid;
        },
        canGoBack(): boolean {
            return this.scenario.canGoBack;
        },
        lastAnswered(): boolean {
            return false;
        },
        completed(): number {
            const {min, max} = Math;
            const {instructions} = this;

            // Default to all but one instruction are completed.
            return max(
                min(
                    (instructions.length - 1) / instructions.length,
                    1,
                ),
                0,
            );
        },
        state(): ChatState {
            const {
                date,
                instructions,
                answers,
                isTyping,
                lastAnswered,
                completed,
                validationError,
                valid,
                canGoBack,
            } = this;

            return {
                date,
                answers,
                instructions,
                isTyping,
                lastAnswered,
                completed,
                validationError,
                valid,
                canGoBack,
            };
        },
    },
});
</script>
