<template>
     <div class="file-upload">
        <input type="file" :id="identifier" :name="identifier" :accept="accepts" @change="onChange" />
    </div>
</template>

<script lang="ts">
import vue from 'vue';
import Input from '@/components/community/styling/Input/Input.vue';

export default Input.extend({
    name: 'FileUpload',
    props: {
        identifier: String,
        accepts: String,
    },
    methods: {
        onChange($e: Event) {
            const [first] = ($e.target as HTMLInputElement).files ?? [];
            this.preview = first;

            this.$emit('input', this.preview);
        },
    },
    data() {
        return {
            preview: null as File | null,
        };
    },
});
</script>
