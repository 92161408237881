<template>
    <fieldset>
        <h1>{{ title }}</h1>
        <div class="instructions">
            <slot/>
        </div>
    </fieldset>
</template>

<script lang="ts">
export default {
    props: {
        title: String,
    },
};
</script>

<style scoped lang="scss">
fieldset {
    border: 0;
    .instructions {
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
        align-items: start;
    }
}
</style>
