<template>
    <div class="abbi-text-checkbox">
        <label class="text-checkbox noselect" :class="{disabled, checked: state, readonly}">
            <span><slot>missing label</slot></span>
            <input type="checkbox" :disabled="disabled || readonly" :name="name" :id="name" v-model="state" @change="changed" />
        </label>
    </div>
</template>

<script lang="ts">
import vue from 'vue';

export default vue.extend({
    name: 'TextCheckbox',
    props: {
        disabled: Boolean,
        value: Boolean,
        name: String,
        readonly: Boolean,
    },
    data() {
        return {
            state: this.value,
        };
    },
    methods: {
        changed() {
            this.$emit('input', this.state);
        },
    },
});
</script>

<style lang="scss">
@import "src/styles/colors";

.abbi-text-checkbox {
    display: inline-flex;
    align-items: center;

    .text-checkbox {
        color: rgb(122, 122, 122);
        text-shadow: 0 0 1px darkgrey;
        font-weight: normal;
        cursor: pointer;

        &.disabled, &.readonly {
            opacity: 0.5;
            cursor: default;

            &.checked {
                --active-color: rgba(0, 128, 0, 0.6);
            }
        }

        &.checked {
            color: var(--active-color, $abbi-green);
            text-stroke: 1px var(--active-color, lighten($abbi-green, 5%));
            -webkit-text-stroke: 1px var(--active-color, lighten($abbi-green, 5%));
            text-shadow: 0 0 2px var(--active-color, $abbi-green), 0 0 1px darken($abbi-green, 25%);
        }

        input {
            display: none;
        }
    }

    .noselect {
        user-select: none;
    }
}
</style>
