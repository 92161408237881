<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
    name: "ConditionInstructionControl",
    props: {
        visible: Boolean,
        locked: Boolean,
    },
    data() {
        return {
            groupDefinitions: {
                "answers": "User provided-answers",
                "metadata": "Additional user information",
                "actions": "Tracked user actions",
                "crm": "External data",
            },
            basicExamples: [
                {
                    "code": "get('answers', 'age') < 18",
                    "description": "If a numeric answer <em>age</em> is <b>less than</b> <em>18</em>",
                },
                {
                    "code": "get('answers', 'age') >= 18",
                    "description": "If a numeric answer <em>age</em> is <b>greater or equal to</b> <em>18</em>",
                },
                {
                    "code": "get('answers', 'age') == 24",
                    "description": "If a numeric answer <em>age</em> is <b>equal to</b> <em>24</em>",
                },
                {
                    "code": "get('answers', 'age') != 43",
                    "description": "If a numeric answer <em>age</em> is <b>not equal to</b> <em>43</em>",
                },
                {
                    "code": "get('crm', 'crm.cdp.email') == 'test@test.nl'",
                    "description": "If an email answer <em>email</em> from external crm data is <b>equal to</b> <em>test@test.nl</em>",
                },
                {
                    "code": "get('answers', 'toelichting') == 'zeg ik liever niet'",
                    "description": "If an open answer <em>toelichting</em> is <b>equal to</b> <em>zeg ik liever niet</em>",
                },
                {
                    "code": "get('answers', 'favor_sport') == 1",
                    "description": "If a single choice answer <em>favor_sport</em> is <b>equal to</b> <em>1 ‘running’</em>",
                },
                {
                    "code": "contains(get('answers', 'sport'), 3)",
                    "description": "If a multiple-choice answer <em>sport</em> is <b>equal to</b> <em>3 ‘soccer’</em>",
                },
                {
                    "code": "count(get('answers', 'sport')) > 2",
                    "description": "If a multiple-choice <em>sport</em> has <b>more than</b> <em>2</em> answers selected",
                },
                {
                    "code": "get('metadata', 'email') == null",
                    "description": "If meta-data <em>email</em> is <b>not available / empty</b>",
                },
                {
                    "code": "not contains(get('answers', 'sport'), 3)",
                    "description": "If a multiple-choice <em>sport</em> <b>not contains</b> <em>3 ‘soccer’</em>",
                },
                {
                    "code": "get('metadata', 'email') != null",
                    "description": "If meta-data <em>email</em> is <b>available / already known</b>",
                },
                {
                    "code": "get('actions','score') == 3",
                    "description": "If a score in a <em>quiz</em> is <b>equal to</b> <em>3</em>",
                },
                {
                    "code": "get('answers', 'satisfaction') == 3",
                    "description": "If an emoji answer <em>satisfaction</em> is <b>equal to</b> <em>3 ‘neutral’</em>",
                },
            ],
        };
    },
})
</script>

<template>
    <b-collapse :visible="visible">
        <div class="mr-4" @mousedown="$emit('isInteracted')">
            <div class="d-flex justify-space-between">
                <h3>Setting conditions on instructions</h3>
                <v-btn
                    icon
                    plain
                    @click="$emit('close')"
                >
                    <FaIcon size="2x" icon="window-close" />
                </v-btn>
            </div>
            <v-row>
                <v-col cols="12" xl="6">
                    <p>Conditions can be set on four different variable-group options:</p>
                    <v-simple-table class="mb-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(description, group) in groupDefinitions" :key="group">
                                    <td><code>'{{ group }}'</code></td>
                                    <td>{{ description }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <p>You can combine multiple conditionals into one by using the following tokens:</p>
                    <v-simple-table class="mb-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Token</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>AND</td>
                                <td><code>&&</code></td>
                            </tr>
                            <tr>
                                <td>OR</td>
                                <td><code>||</code></td>
                            </tr>
                            <tr>
                                <td>priority grouping</td>
                                <td>(parenthesis)</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <p>Multiple selections</p>
                    <v-simple-table class="mb-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Code</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Women older than 18</td>
                                <td><code>get('metadata', 'gender') == 'f' && get('answers', 'age') > 18</code></td>
                            </tr>
                            <tr>
                                <td>Male or beer drinkers</td>
                                <td><code>get('metadata', 'gender') == 'm' || get('answers', 'beer') == 1</code></td>
                            </tr>
                            <tr>
                                <td>Female 18+ or beer drinkers</td>
                                <td><code>(get('metadata', 'gender') == 'f' && get('answers', 'age') >= 18) || get('answers', 'beer') == 1</code></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" xl="6">
                    <p>Examples</p>
                    <v-simple-table class="mb-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Code</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="example in basicExamples" :key="example.code">
                                <td v-html="example.description"/>
                                <td><code>{{ example.code }}</code></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
<!--        <v-row>-->
<!--            <v-col xl="6" offset-xl="6">-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <p>To retrieve an answer for an instruction you can use the following syntax.</p>-->
<!--                    </v-col>-->
<!--                    <v-col>-->
<!--                        <pre><code>get({ variable-group }, {variable short-label})</code></pre>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <div class="flex-row pl-05">-->
<!--                    <em>Valid variable-groups are:</em>-->
<!--                    <pre><code>'answers', 'metadata', 'actions', 'crm'</code></pre>-->
<!--                </div>-->
<!--                <div class="routing-operators pt-05">-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>if email is equal test@mail.com</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('crm', 'crm.cdp.email') == 'test@mail.com'</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>if age is less than 18 (non-inclusive)</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') &lt; 18</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>if age is greater or equal to 18</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') &gt;= 18</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>if age is equal to 24</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') == 24</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>if age is not equal to 24</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') != 24</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </div>-->
<!--                <p>You can combine multiple conditionals into one by using the following tokens:</p>-->
<!--                <pre><code>&& for AND, || for OR, and (parenthesis) for priority grouping</code></pre>-->
<!--                <div class="selection-examples">-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>Selection for only women ages 18 and up (assuming metadata 'gender' has been set, F being-->
<!--                                female):</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') &gt;= 18 && get('metadata', 'gender') == 'F'</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>Selecting only people between (inclusive) the ages of 18 and 24.</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'age') &gt;= 18 && get('metadata', 'age') &lt;= 24</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>Selection for user who didn't give email</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>get('answers', 'email') == null</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>[Multiple] selection for all people who's answer to a multiple included answer-value 3 (e.g.-->
<!--                                Soccer)</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>contains(get('answers', 'sports'), 3)</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <p>[Multiple] selection for all people who's answer to a multiple included more then 2 answers</p>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <pre><code>count(get('answers', 'sports')) > 2</code></pre>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </div>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </b-collapse>
</template>

<style scoped lang="scss">

</style>
