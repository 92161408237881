<template>
    <div class="confirmationDialog">
        <p>{{options.message}}</p>
        <div class="actions pure-g with-gutter">
            <AbbiButton grow class="pure-u-1-2" @click="confirm">{{ options.actions.confirm.message }}</AbbiButton>
            <AbbiButton grow is-red class="pure-u-1-2" @click="cancel">{{ options.actions.cancel.message }}</AbbiButton>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';

class ConfirmationOptions {
        message: string | null = null;

        actions = {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            confirm: {
                message: undefined as string | undefined,
                action: undefined as CallableFunction | undefined,
                argument: undefined as any,
            },
            cancel: {
                message: undefined as string | undefined,
                action: undefined as CallableFunction | undefined,
                argument: undefined as any,
            },
            /* eslint-enable @typescript-eslint/no-explicit-any */
        };
}

export default Vue.extend({
    name: 'ConfirmationModal',
    components: {AbbiButton},
    props: ['options'],
    methods: {
        confirm() {
            const confOptions: ConfirmationOptions = this.options;
            if (confOptions.actions.confirm.action !== undefined) {
                confOptions.actions.confirm.action(confOptions.actions.confirm.argument);
            }
            this.closeModal();
        },
        cancel() {
            const confOptions = this.options as ConfirmationOptions;
            if (confOptions.actions.cancel.action !== undefined) {
                confOptions.actions.cancel.action(confOptions.actions.cancel.argument);
            }
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
            this.$store.dispatch('closeModal');
        },
    },
});
</script>

<style lang="scss">
    .confirmationDialog {
        padding: 0 1em 1em;
    }
</style>
