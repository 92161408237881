import QuestionModel from '@/models/question.model';
import StatModel from '@/models/stat.model';
import {createInstruction} from './helpers';
import Scenario from './Scenario';

const instructions = [
    // Bar chart: single choice.
    createInstruction(
        '6a448e3c-f53d-4feb-b37e-25f5229e72e7',
        'Example of a multiple choice question with bar chart. (4 answer choices)',
        {
            uuid: 'd9051a65-c322-4681-865a-b9c04e6ce504',
            type: 'choice',
            options: {
                columns: 1,
                charttype: 'barchart',
                randomize: true,
                fixated: [],
            },
            choices: [
                {
                    uuid: '2f1a2078-d2dc-4c54-9d6c-dc2f5793c71c',
                    value: 1,
                    translations: {
                        locale: 'en',
                        content: 'Option A',
                    },
                },
                {
                    uuid: '43cf65ac-7c93-47ba-9799-c2f2b509eebc',
                    value: 2,
                    translations: {
                        locale: 'en',
                        content: 'Option B',
                    },
                },
                {
                    uuid: 'dde6f836-e86b-4c0b-8dde-862813fbd85c',
                    value: 3,
                    translations: {
                        locale: 'en',
                        content: 'Option C',
                    },
                },
                {
                    uuid: 'f6173d61-3def-4566-b1de-5f1a9d43ca7e',
                    value: 4,
                    translations: {
                        locale: 'en',
                        content: 'Option D',
                    },
                },
            ],
        } as unknown as QuestionModel,
    ),
    // Bar chart: multiple choice.
    createInstruction(
        '60c0514d-758d-417c-9812-7ddb0f250e84',
        'Example of a multiple choice question with bar chart. (8 answer possibilities)',
        {
            uuid: '2d395482-9420-4252-ac16-50ca7d0dd34d',
            type: 'multiple',
            options: {
                columns: 1,
                charttype: 'barchart',
                maximum: 4,
                randomize: true,
                fixated: [],
            },
            choices: [
                {
                    uuid: 'cef6cf1e-860c-47ca-b924-62185fa30331',
                    value: 1,
                    translations: {
                        locale: 'en',
                        content: 'Answer one',
                    },
                },
                {
                    uuid: '241b845c-adc5-449b-83b2-cffaa6ed7a6f',
                    value: 2,
                    translations: {
                        locale: 'en',
                        content: 'Second answer',
                    },
                },
                {
                    uuid: '62478c20-96c5-481d-88a3-f49bb2fdb910',
                    value: 3,
                    translations: {
                        locale: 'en',
                        content: 'Answer three',
                    },
                },
                {
                    uuid: 'e06f75b8-3e50-44a7-8280-4e543ab4e344',
                    value: 4,
                    translations: {
                        locale: 'en',
                        content: 'Fourth answer',
                    },
                },
                {
                    uuid: '88f648ca-cb8a-41fb-8235-5fd57a23d6f0',
                    value: 5,
                    translations: {
                        locale: 'en',
                        content: 'Answer five',
                    },
                },
                {
                    uuid: '35fa8d95-e376-40a5-b6f9-f803a82f52fb',
                    value: 6,
                    translations: {
                        locale: 'en',
                        content: 'Sixth answer',
                    },
                },
                {
                    uuid: 'e0d855ee-cbca-4dbe-b39f-b7178edb0b33',
                    value: 7,
                    translations: {
                        locale: 'en',
                        content: 'Answer seven',
                    },
                },
                {
                    uuid: 'ad518b14-b9ad-4418-87cc-b91d43f1a089',
                    value: 8,
                    translations: {
                        locale: 'en',
                        content: 'Eight answer',
                    },
                },
            ],
        } as unknown as QuestionModel,
    ),
];

const stats = {
    // Bar chart: single choice.
    '6a448e3c-f53d-4feb-b37e-25f5229e72e7': {
        instruction: '6a448e3c-f53d-4feb-b37e-25f5229e72e7',
        selected: 107,
        answered: 107,
        statistics: {
            1: 2,
            4: 1,
        },
    },
    // Bar chart: multiple choice.
    '60c0514d-758d-417c-9812-7ddb0f250e84': {
        instruction: '60c0514d-758d-417c-9812-7ddb0f250e84',
        selected: 89,
        answered: 89,
        statistics: {
            'cef6cf1e-860c-47ca-b924-62185fa30331': 0,
            '62478c20-96c5-481d-88a3-f49bb2fdb910': 0,
            '88f648ca-cb8a-41fb-8235-5fd57a23d6f0': 1,
            '241b845c-adc5-449b-83b2-cffaa6ed7a6f': 0,
            'e06f75b8-3e50-44a7-8280-4e543ab4e344': 1,
            'ad518b14-b9ad-4418-87cc-b91d43f1a089': 2,
            '35fa8d95-e376-40a5-b6f9-f803a82f52fb': 1,
            'e0d855ee-cbca-4dbe-b39f-b7178edb0b33': 0,
        },
    },
} as Record<string, StatModel>;

export default {
    instructions,
    stats,
} as Scenario;
