/* eslint @typescript-eslint/no-explicit-any: 0 */
export type ApiError = Error & {request: Request; response: Response};

export default class AbbiApi {
    public static url: string | null = null;

    protected static lastError: ApiError | undefined = undefined;

    public static setBase(url: string) {
        if (AbbiApi.url === null) {
            AbbiApi.url = url;
        }
    }

    public static getLastError() {
        return AbbiApi.lastError;
    }

    public static getErrorData() {
        const error = this.getLastError();

        return error !== undefined ? error.response.json() : new Promise(
            (resolve, reject) => reject(new Error('No API error')),
        );
    }

    protected static performRequest(
        method: string,
        url: string,
        body: { [key: string]: any } | null = null,
    ): Promise<any> {
        const headers = new Headers();
        headers.append('Accept', 'application/json, text/plain, */*');
        headers.append('Content-Type', 'application/json');

        // noinspection TypeScriptValidateTypes
        const request = new Request(
            url,
            {
                method,
                mode: 'cors',
                credentials: 'include',
                headers,
                body: body === null ? undefined : JSON.stringify(body),
            },
        );

        return fetch(request).then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    console.log('Unauthorized, are you still logged in?');
                    if (!document.location.href.endsWith('/login')) {
                        document.location.href = '/login';
                    }
                }
                this.lastError = this.createError(request, response);
                return undefined;
            }

            return response.json();
        });
    }

    protected static createError(request: Request, response: Response): ApiError {
        return Object.assign(
            new Error(`HTTP error! Status: ${response.status}`),
            {request, response},
        );
    }

    protected static performFileUpload(url: string, body: FormData): Promise<any> {
        const headers = new Headers();

        // noinspection TypeScriptValidateTypes
        const request = new Request(
            url,
            {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body,
                headers,
            },
        );

        return fetch(request).then((response) => {
            if (!response.ok) {
                this.lastError = this.createError(request, response);
                throw this.lastError;
            }

            return response.json();
        });
    }

    protected static delete(url: string, body: { [key: string]: any }) {
        return AbbiApi.performRequest('POST', url, body);
    }

    protected static get(url: string) {
        return AbbiApi.performRequest('GET', url);
    }

    protected static patch(url: string, body: { [key: string]: any }) {
        return AbbiApi.performRequest('PATCH', url, body);
    }

    protected static post(url: string, body: { [key: string]: any }) {
        return AbbiApi.performRequest('POST', url, body);
    }

    protected static put(url: string, body: { [key: string]: any }) {
        return AbbiApi.performRequest('PUT', url, body);
    }
}
