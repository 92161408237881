import AbbiApi from '@/apis/abbi.api';

export default class LinkApi extends AbbiApi {
    public static generateNumberOfLinks(communitySlug: string, uuid: string, amount: number, short = false) {
        return LinkApi.post(`${LinkApi.url}/api/${communitySlug}/chat-scripts/${uuid}/get-links?amount=${amount}&short=${short}`, {});
    }

    public static generateLinksForList(communitySlug: string, uuid: string, short: boolean, userData: Record<string, string|Record<string, string>[]>[]) {
        return LinkApi.post(`${LinkApi.url}/api/${communitySlug}/chat-scripts/${uuid}/get-unique-links?short=${short}`, userData);
    }

    public static saveAnonymousLinkSlug(uuid: string, userData: Record<string, string|Record<string, string>[]>[]) {
        return LinkApi.post(`${LinkApi.url}/api/chat-scripts/${uuid}/save-slug`, userData);
    }

    public static getAnonymousLinkSlug(uuid: string) {
        return LinkApi.get(`${LinkApi.url}/api/chat-scripts/${uuid}/slug`);
    }
}
