<template>
    <div id="admin-page">
        <OverviewNavigation :links="links" overview-route="Admin" />
        <router-view />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import OverviewNavigation, {NavLink} from '@/components/layout/OverviewNavigation.vue';

export default Vue.extend({
    name: 'Admin',
    components: {OverviewNavigation},
    data() {
        return {
            links: [
                {
                    to: {name: 'UserManagement'},
                    label: 'ROUTER.USER_MANAGEMENT',
                    icon: 'user',
                },
                {
                    to: {name: 'CommunityManagement'},
                    label: 'ROUTER.COMMUNITY_MANAGEMENT',
                    icon: 'people-roof',
                },
                {
                    to: {name: 'RemovalManagement'},
                    label: 'ROUTER.REMOVAL_MANAGEMENT',
                    icon: 'comment-dots',
                },
            ] as NavLink[],
        };
    },
});
</script>

<style lang="scss" scoped>
#admin-page {
    display: flex;
    flex-direction: column;
}
</style>
