<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
});
</script>
