<template>
    <div id="links-dialogue">
        <div v-if="list" class="import-options">
            <FileUpload @input="readCsv" accepts=".csv,.xlsx" />
        </div>
        <div v-if="list && (listOfPeople.length > 0)" class="list-table">
            <table>
                <tr class="header special-font no-wrap">
                    <th v-for="(text, idx) in peopleHeader" :key="text" :class="{'has-dupes': dupes[idx]}">{{ text }}</th>
                </tr>
                <tr v-for="(person, idx) in peopleList" :key="`${idx}_${person.join('-')}`">
                    <td v-for="(data, idxKey) in person" :key="idx + person.join('-') + idxKey + '-' + data">{{ data }}</td>
                </tr>
            </table>
        </div>
        <div v-if="list && (listOfPeople.length > 0)" class="field-definitions">
            <div class="column-splitter"></div>
            <div class="field-definition flex-row" v-for="(field, idx) in peopleHeader" :key="`${idx}-${field}`"
                 :class="{'abbi-col-1': (idx % 2) === 0, 'abbi-col-3': (idx % 2) === 1}">
                <div class="flex-1 flex-column">
                    <h4><label :for="`${field}-type`">{{ field }}</label></h4>
                    <span class="with-ellipsis">{{ previewEntry[idx] }}</span>
                </div>
                <div class="control">
                    <select :id="`${field}-type`">
                        <option value>Ignore</option>
                        <option value="profile.uuid">Abbi ID &#x1F517;</option>
                        <option value="profile.first_name">First name</option>
                        <option value="profile.last_name">Last name</option>
                        <option value="profile.date_of_birth">Date of birth</option>
                        <option value="profile.phone">Phone &#x1F517;</option>
                        <option value="profile.email">Email &#x1F517;</option>
                        <option value="profile.external_id">External ID &#x1F517;</option>
                        <option value="conversation.metadata">Metadata</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="link-panel">
            <div class="link-options">
                <Toggle class="flex-1" disabled v-model="short">{{ $t('ACTION.LINKS.SHORT') }}</Toggle>
                <div id="generationIndicator" class="flex-1" v-if="busy">
                </div>
                <div class="actions flex-1">
                    <AbbiButton :disabled="amount === 0 || busy || !list" :is-red="Object.keys(this.dupes).length !== 0"
                                @click="startForList">{{ $t('ACTION.CREATE') }}
                    </AbbiButton>
                    <AbbiButton class="ml-1" :disabled="tableData.length === 0" @click="downloadLinks">{{ $t('ACTION.DOWNLOAD') }}</AbbiButton>
                </div>
            </div>
            <div class="link-table mt-05">
                <table>
                    <tr class="header special-font no-wrap">
                        <th>{{ $t('GENERAL.UUID') }}</th>
                        <th v-if="list">{{ $t('GENERAL.FOUND') }}</th>
                        <th v-if="list">{{ $t('GENERAL.COUPLE_VAR') }}</th>
                        <th v-if="list">{{ $t('GENERAL.COUPLE_VAL') }}</th>
                        <th>{{ $t('GENERAL.CONVERSATION_UUID') }}</th>
                        <th v-if="short">{{ $t('GENERAL.SHORT_LINK') }}</th>
                        <th v-else>{{ $t('GENERAL.LINK') }}</th>
                        <!-- <th>{{ $t('GENERAL.FORGET_ME') }}</th> -->
                    </tr>
                    <tr v-for="rowData in linkListForView" :key="rowData.uuid">
                        <td>{{ rowData.user }}</td>
                        <td v-if="list">{{ rowData.found || false }}</td>
                        <td v-if="list">{{ rowData.identifier.key }}</td>
                        <td v-if="list">{{ rowData.identifier.value }}</td>
                        <td>{{ rowData.conversation }}</td>
                        <td v-if="short">{{ asShort(rowData.code) }}</td>
                        <td v-else>{{ rowData.code }}</td>
                        <!-- <td>{{ rowData.forgetLink }}</td> -->
                    </tr>
                    <tr v-if="tableData.length === 0">
                        <td colspan="6">&hellip;</td>
                    </tr>
                </table>
            </div>
            <div class="pagination mt-05">
                <div class="generation-stats" style="display:flex;margin-right:auto;">
                    <span class="wm-4 ta-center sa-center">Generated {{ tableData.length }} of requested {{ amount }}</span>
                </div>
                <AbbiButton square
                            :disabled="page <= 0"
                            @click="page = Math.max(page - 1, 0)">
                    <FaIcon icon="play" rotation="180" />
                </AbbiButton>
                <span class="wm-4 ta-center sa-center">{{ page + 1 }} / {{ Math.ceil(tableData.length / 100) + 1 }}</span>
                <AbbiButton square
                            :disabled="page >= Math.ceil(tableData.length / 100) - 1"
                            @click="page = Math.min(page + 1, Math.ceil(tableData.length / 100) - 1)">
                    <FaIcon icon="play" />
                </AbbiButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import Toggle from '@/components/input/Toggle.vue';
import FileUpload from '@/components/input/FileUpload.vue';
import CsvReader from 'papaparse';
import LinkApi from '@/apis/link.api';
import {abbiToIFY} from '@/helpers/utilities';

export default vue.extend({
    name: 'LinksModal',
    components: {AbbiButton, FileUpload, Toggle},
    props: {
        options: {
            type: Object,
            default() {
                return {
                    short: Boolean,
                    amount: Number,
                    list: Boolean,
                    scriptName: String,
                };
            },
        },
    },
    methods: {
        readCsv($event: File | null | undefined) {
            this.listOfPeople = [];
            this.tableData = [];
            this.dupes = {};
            if ($event === null || $event === undefined) {
                return;
            }

            // noinspection ES6ShorthandObjectProperty
            CsvReader.parse($event, {
                complete: this.setList,
            });
        },
        asShort(code: string) {
            return abbiToIFY(code);
        },
        getRandomListEntry() {
            return this.listOfPeople[Math.floor(Math.random() * (this.listOfPeople.length - 1)) + 1];
        },
        setList(result: any) {
            this.listOfPeople = result.data;
            this.amount = this.listOfPeople.length - 1;
            if (this.amount > 1) {
                // eslint-disable-next-line prefer-destructuring
                this.previewEntry = this.getRandomListEntry();

                if (this.previewUpdaterHandle === undefined) {
                    this.previewUpdaterHandle = window.setInterval(() => {
                        this.previewEntry = this.getRandomListEntry();
                    }, 2500);

                    this.$emit('registerCloseHandler', () => {
                        window.clearInterval(this.previewUpdaterHandle);
                    });
                }
            }
        },
        startForList() {
            if (this.busy) return;
            if (!this.dataPointMarked()) return;
            this.busy = true;

            const map = {} as Record<string, number>;
            const header = this.listOfPeople[0];
            for (let i = 0; i < header.length; i++) {
                const val = (document.getElementById(`${header[i]}-type`) as HTMLSelectElement)?.value || '';
                if (val !== '') {
                    map[val] = i;
                }
            }
            const dupeChecker = {} as Record<string, string[]>;
            // iterate to the uploaded sheet exc. the header to find duplicates
            for (let i = 1; i < this.listOfPeople.length; i++) {
                const person = this.listOfPeople[i];
                Object.keys(map).forEach((val: string) => {
                    if (['profile.uuid', 'profile.external_id', 'profile.email', 'profile.phone'].indexOf(val) >= 0) {
                        if (!dupeChecker[val]) dupeChecker[val] = [];
                        if (person[map[val]]) {
                            dupeChecker[val].push(person[map[val]].toLowerCase());
                        }
                    }
                });
            }

            this.dupes = {} as Record<number, boolean>;
            Object.keys(map).forEach((val: string) => {
                const list = dupeChecker[val];
                if (list && (new Set(list)).size !== list.length) {
                    this.dupes[map[val]] = true;
                }
            });

            if (Object.keys(this.dupes).length > 0) {
                this.busy = false;
            } else {
                this.busy = false;
                this.generateLinksForPeople(1);
            }
        },
        generateLinksForPeople(nextIndex = 1) {
            if (nextIndex < 1) return;
            if (nextIndex === 1 && this.busy) return;
            if (!this.dataPointMarked()) return;
            if (nextIndex === 1 && !this.busy) this.tableData = [];
            this.busy = true;

            const header = this.listOfPeople[0];

            const BATCH_SIZE = 5000;
            const {communitySlug, scriptUuid} = this.$route.params;

            const batchEnd = Math.min(nextIndex + BATCH_SIZE, this.listOfPeople.length);
            const sliceOfPeople = this.listOfPeople.slice(nextIndex, batchEnd);

            const listForLinks = sliceOfPeople.map((person: any) => {
                const persona = {};
                for (let i = 0; i < person.length; i++) {
                    const key = header[i];
                    const val = (document.getElementById(`${key}-type`) as HTMLSelectElement).value;
                    // eslint-disable-next-line
                    if (val === '') continue;

                    if (val === 'conversation.metadata') {
                        // eslint-disable-next-line
                        // @ts-ignore-next-line TS7053
                        const md = persona[val] || {};
                        md[key.trim()] = person[i].trim();
                        // eslint-disable-next-line
                        // @ts-ignore-next-line TS7053
                        persona[val] = md;
                        // eslint-disable-next-line
                        continue;
                    }

                    // eslint-disable-next-line
                    // @ts-ignore-next-line TS7053
                    persona[val] = person[i].trim();
                }

                return persona;
            });

            // sent the listForLinks to the server to request the links;
            LinkApi
                .generateLinksForList(communitySlug, scriptUuid, this.short, listForLinks)
                .then((data) => {
                    this.tableData.push(...data.process);

                    if (this.tableData.length < this.listOfPeople.length - 1) {
                        this.generateLinksForPeople(this.tableData.length + 1);
                    } else {
                        this.busy = false;
                    }
                })
                .catch(() => {
                    this.busy = false;
                });
        },
        generateLinks(amount = 0) {
            const {communitySlug, scriptUuid} = this.$route.params;

            LinkApi
                .generateNumberOfLinks(communitySlug, scriptUuid, Math.min(amount, 5000), this.short)
                .then((data) => {
                    this.tableData.push(...data.process);

                    if (data.status !== 'finished' || this.tableData.length < this.options.amount) {
                        this.generateLinks(amount - data.process.length);
                        return;
                    }
                    this.busy = false;
                })
                .catch(() => {
                    this.busy = false;
                });
        },
        dataPointMarked() {
            if (!this.listOfPeople || !this.listOfPeople.length) return false;
            const header = this.listOfPeople[0];
            for (let i = 0; i < header.length; i++) {
                const val = (document.getElementById(`${header[i]}-type`) as HTMLSelectElement)?.value || '';
                if (val !== '') {
                    return true;
                }
            }

            return false;
        },
        downloadLinks() {
            const {communitySlug} = this.$route.params;

            const header = ['userId', 'found'];
            if (this.list) header.push('couple variable', 'couple value');
            header.push('conversationToken', this.short ? 'shortlink' : 'link');

            const data = [
                header,
            ];

            for (let i = 0; i < this.tableData.length; i++) {
                const row = [];
                row.push(this.tableData[i].user);
                if (this.options.list) row.push(this.tableData[i].found);

                if (this.tableData[i].identifier) {
                    row.push(this.tableData[i].identifier.key);
                    row.push(this.tableData[i].identifier.value);
                }

                row.push(this.tableData[i].conversation);
                row.push(abbiToIFY(this.tableData[i].code));
                data.push(row);
            }

            let csvDataUri = 'data:text/csv;charset=utf-8,';
            data.forEach((row: string[]) => {
                csvDataUri += `${row.join(',')}\n`;
            });
            const encodedUri = encodeURI(csvDataUri);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `${communitySlug}_${this.options.scriptName}_links.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        removeDuplicateEmails(list: any) {
            // eslint-disable-next-line
            const emails: string[] = [];
            const emailsToRemove: any[] = [];
            list.forEach((el: any) => {
                // eslint-disable-next-line max-len
                const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let email = el.filter((v: string) => v.match(emailRegexp))[0];
                if (!email) {
                    return;
                }
                email = email.toLowerCase();
                if (!emails.includes(email)) {
                    emails.push(email);
                    return;
                }

                emailsToRemove.push(el);
            });
            return list.filter((item: any) => !emailsToRemove.includes(item));
        },
    },
    computed: {
        peopleHeader(): Record<string, string | number> {
            return this.listOfPeople[0];
        },
        peopleList(): Record<string, string | number>[] {
            const [, ...rest] = (this as any).listOfPeople;
            return rest.slice(0, 5);
        },
        linkListForView(): Array<Record<string, any>> {
            return this.tableData.slice(this.page * 100, this.page * 100 + 100);
        },
    },
    data() {
        return {
            short: this.options.short,
            amount: this.options.amount,
            list: this.options.list,

            tableData: [] as { [key: string]: any }[],
            listOfPeople: [] as Record<string, string | any>[],

            page: 0,

            dupes: {} as Record<number, boolean>,

            busy: false,

            previewEntry: null as Record<string, string | number> | null,

            previewUpdaterHandle: undefined as undefined | number,
        };
    },
    mounted() {
        if (!this.options.list) {
            this.busy = true;
            this.generateLinks(this.amount);
        }
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';

#links-dialogue {
    > * {
        margin: 1rem;
    }

    .link-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            display: inline-block;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
        }
    }

    .field-definitions {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        grid-template-rows: auto [last-row];
        gap: 1.5rem;
        column-gap: 2rem;
        row-gap: 1rem;

        .field-definition {
            min-height: 2.25em;

            > .control {
                select {
                    height: 100%;
                }
            }
        }

        .column-splitter {
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc(50% - 0.5px);
            width: 1px;
            background-color: black;
        }

        .abbi-col-1 {
            grid-column: 1;
        }

        .abbi-col-3 {
            grid-column: 3;
        }
    }

    .link-table {
        // height: 30vh;
    }

    .link-table, .list-table {
        overflow-x: auto;
        max-width: 75vw;
        width: 50vw;
        min-width: 960px;

        max-height: 30vh;
        min-height: 30vh;

        padding: 0.5rem;
        box-shadow: inset 0 0 0.4rem 0.1rem black;

        table {
            min-width: 100%;
            min-height: 100%;
            border-collapse: collapse;

            tr {
                border-bottom: 1px solid transparentize($abbi-blue, 0.5);

                &:last-child {
                    border-bottom: none;
                }

                &.header {
                    border-bottom: 1px solid $abbi-blue;
                }
            }

            th, td {
                min-width: max-content;

                position: relative;
                padding: 0.25rem 0.5rem;

                border-right: 1px solid $abbi-blue;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .list-table {
        box-shadow: inset 0 0 0.2rem 0.05rem rgba(0, 0, 0, 0.25);
        min-height: 128px;

        .has-dupes {
            background-color: $abbi-red;
            color: white;
        }
    }

    .link-panel {
        margin: 0;
        padding: 1rem;
        color: white;
        background-color: $abbi-blue;

        box-shadow: inset 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);

        tr {
            border-color: white !important;
        }

        th, td {
            border-color: white !important;
        }

        .pagination {
            display: flex;
            justify-content: flex-end;

            > *:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }

    #generationIndicator {
        flex: 1;
        height: 1rem;

        border-radius: 666px;
        position: relative;
        overflow: hidden;

        background-color: white;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 666px;
            left: 50%;
            height: 0.6rem;
            width: 2rem;

            background-color: $abbi-green;
            box-shadow: 0 0 3px 1px $abbi-green;

            animation: loading 1.5s infinite linear;

            @keyframes loading {
                0% {
                    left: -2.125rem;
                }
                100% {
                    left: calc(100% + 0.125rem);
                }
            }
        }
    }
}
</style>
