<script lang="ts">
import Vue, {PropType} from 'vue';
import {TranslateResult} from 'vue-i18n';
import InstructionModel from '@/models/instruction.model';

export default Vue.extend({
    name: 'QuestionsConfigurator',
    components: {},
    props: {
        value: {
            type: Array as PropType<string[] | null>,
            required: false,
        },
        instructions: Array as PropType<InstructionModel[]>,
    },
    watch: {
        selectAllQuestionsToggle(toggleState: boolean): void {
            if (this.selectAllQuestionsToggleWatchIgnoreNext) {
                this.selectAllQuestionsToggleWatchIgnoreNext = false;
                return;
            }

            this.updateSelectedQuestions(toggleState);
        },
        selectedQuestionsUuids: {
            handler(newUuids: string[]|null): void {
                if (newUuids === null) {
                    this.$emit('input', newUuids);

                    if (this.includeAllQuestions === null) {
                        this.includeAllQuestions = true;
                    }

                    return;
                }

                const toggleIgnored = this.selectAllQuestionsToggleWatchIgnoreNext;

                if (this.selectAllQuestionsToggle && !toggleIgnored && this.questionInstructions.length > (newUuids?.length ?? 0)) {
                    this.selectAllQuestionsToggleWatchIgnoreNext = true;
                    this.selectAllQuestionsToggle = false;
                }

                if (!this.selectAllQuestionsToggle && !toggleIgnored) {
                    const allMatch = this.questionInstructions.every(qi =>
                        newUuids.includes(qi.question?.uuid ?? '')
                    );

                    if (allMatch) {
                        this.selectAllQuestionsToggleWatchIgnoreNext = true;
                        this.selectAllQuestionsToggle = true;
                    }
                }

                this.$emit('input', newUuids);
            },
            deep: true,
        },
        includeAllQuestions(include: boolean): void {
            if (include) {
                this.selectedQuestionsUuids = null;

                return;
            }

            this.selectedQuestionsUuids = this.instructions?.map((i) => i.question?.uuid);
        },
    },
    data() {
        return {
            includeAllQuestions: null as boolean|null,
            selectedQuestionsUuids: [] as (string|null|undefined)[]|null,
            selectAllQuestionsToggle: false,
            selectAllQuestionsToggleWatchIgnoreNext: false,
            output: '{ }',
        };
    },
    computed: {
        questionInstructions(): InstructionModel[] {
            return this.instructions.filter((i) => (i.type === 'question')) || [];
        },
        selectedQuestionInstructions(): InstructionModel[] {
            return this.questionInstructions?.filter((qi) => this.selectedQuestionsUuids?.includes(qi.question?.uuid || 'none'));
        },
    },
    beforeMount() {
        this.selectedQuestionsUuids = this.value !== null ? [...this.value] : null;
    },
    methods: {
        getQuestionLabel(i: InstructionModel): string {
            if (i.label === `q${i.order + 1}`) {
                return `q${i.order + 1}`;
            }

            return `q${i.order + 1}${i.label ? ': ' : ''}${i.label ?? ''}`;
        },
        resolveTranslation(key: string, optional = false): TranslateResult {
            const v = this.$t(key);

            if (!optional) return v;

            return v !== key ? v : '';
        },
        updateSelectedQuestions(state: boolean): void {
            this.selectedQuestionsUuids = state ? this.questionInstructions.map((qi) => qi.question?.uuid ?? '') : [];
        },
    },
});
</script>

<template>
    <div class="configurator-fields m-4">
        <h3>Questions</h3>
        <v-switch
            v-model="includeAllQuestions"
            label="Include all questions"
        />
        <div class="d-flex flex-grow-1 flex-row">
            <div v-if="!includeAllQuestions" class="all mr-2">
                <h4>Available</h4>
                <b-list-group class="user-select-none">
                    <b-list-group-item class="list-group-item-header">
                        <b-form-checkbox v-model="selectAllQuestionsToggle" inline id="selectAllToggle">
                            (Select all)
                        </b-form-checkbox>
                    </b-list-group-item>
                    <b-list-group-item v-for="qi in questionInstructions"
                                       :key="qi.uuid">
                        <b-form-checkbox v-model="selectedQuestionsUuids" inline :id="qi.question.uuid" :value="qi.question.uuid">
                            {{ getQuestionLabel(qi) }}
                            <b-badge class="text-light text-light bg-info">{{ qi.question.type }}</b-badge>
                        </b-form-checkbox>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div class="selected ml-2">
                <h4>Selected</h4>
                <b-list-group v-if="!includeAllQuestions" class="overflow-hidden">
                    <b-list-group-item v-for="question in selectedQuestionInstructions"
                                       :key="question.uuid">
                        {{ getQuestionLabel(question) }}
                        <b-badge class="text-light bg-info">{{ question.question.type }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
                <b-list-group v-else class="overflow-hidden">
                    <b-list-group-item v-for="question in questionInstructions"
                                       :key="question.uuid">
                        {{ getQuestionLabel(question) }}
                        <b-badge class="text-light bg-info">{{ question.question.type }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/mixins';

@import 'src/styles/colors';

@import '~bootstrap/scss/utilities';

@import "~bootstrap-vue/src/index.scss";

@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/list-group';

@import "~bootstrap/scss/utilities/stretched-link";

h4 {
    font-size: 1rem;
    font-weight: bolder;
}
</style>
