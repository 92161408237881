<template>
    <AccountResetPassword />
</template>

<script lang="ts">
import AccountResetPassword from '@/components/account/AccountResetPassword.vue';

export default {
    name: 'ChangePassword',
    components: {AccountResetPassword},
};
</script>
