<template>
    <div id="choice-configurator">
        <div class="special-options">
            <h4 class="pt-1">Question options</h4>
            <hr>

            <div class="flex-row">
                <Toggle class="inputfield flex-1" name="dropdown" v-model="options.dropdown" @change="changeOptions(options)">
                    Dropdown
                </Toggle>
            </div>

            <div class="flex-row" v-if="options.dropdown">
                <Toggle class="inputfield flex-1" name="search" v-model="options.search" @change="changeOptions(options)">
                    Allow searching
                </Toggle>
                <div class="inputfield flex-1" v-if="options.search">
                    <div class="text">
                        <p>Search pattern</p>
                    </div>
                    <div class="options">
                        <fieldset class="option" :class="{checked: options.searchPattern === 'any'}">
                            <legend>
                                <strong>Contains:</strong>
                                %<span class="selection">Hag</span>%
                            </legend>
                            <label>
                                <input
                                    type="radio"
                                    value="any"
                                    v-model="options.searchPattern"
                                    :checked="options.searchPattern === 'any'"
                                />The <span class="selection">Hag</span>ue
                            </label>
                        </fieldset>

                        <fieldset class="option" :class="{checked: options.searchPattern === 'start'}">
                            <legend>
                                <strong>Starts with:</strong>
                                <span class="selection">The H</span>%
                            </legend>
                            <label>
                                <input
                                    type="radio"
                                    value="start"
                                    v-model="options.searchPattern"
                                    :checked="options.searchPattern === 'start'"
                                /><span class="selection">The H</span>ague
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div class="flex-row">
                <Toggle
                    class="inputfield flex-1"
                    name="randomize"
                    :value="rand"
                    @input="randomize"
                    :readonly="scriptLive && !liveEditable"
                >
                    {{ $t('FIELDS.RANDOMIZE') }}
                </Toggle>
                <Toggle v-if="!multiple" class="inputfield flex-1" name="payment-amount" :readonly="scriptLive"
                        :value="options.payment || false" v-model="options.payment" @change="changeOptions(options)">
                    Payment amounts
                </Toggle>
            </div>
            <div class="flex-row">
                <Toggle class="inputfield flex-1" name="quiz-part" :readonly="scriptLive"
                        :value="options.scores || false" v-model="options.scores" @change="changeOptions(options)">
                    Quiz scores
                </Toggle>
            </div>
            <div v-if="multiple" class="flex-row">
                <div class="flex-1 mr-3">
                    <div class="text">
                        <p>Minimum selected answers</p>
                    </div>
                    <AbbiNumericInput
                        id="minimum-value"
                        placeholder="Number"
                        is-small
                        :min="1"
                        :max="hasChoices ? choices.length - 1 : 0"
                        :value="options.minimum || 1"
                        @input="setMinimum"
                    />
                </div>
                <div class="flex-1">
                    <div class="text">
                        <p>Maximum selected answers</p>
                    </div>
                    <AbbiNumericInput
                        :key="hasChoices ? internalChoices.length : 1"
                        id="maximum-value"
                        :min="1"
                        :max="hasChoices ? internalChoices.length : 0"
                        :value="options.maximum"
                        placeholder="Number"
                        is-small
                        @input="setMaximum"
                    />
                </div>
            </div>
        </div>
        <h5 class="mt-1">{{ $t('FIELDS.ANSWER_POSSIBILITIES') }} <span class="ml-3" v-if="scriptLive">##### Do not change ordering #####</span></h5>
        <template v-if="hasChoices">
            <draggable
                v-if="internalChoices.length > 0"
                handle=".handle"
                :value="internalChoices"
                @change="choiceOrderChanged"
                :disabled="scriptLive && !liveEditable"
            >
                <div
                    class="choice-entry"
                    v-for="(choice, idx) in internalChoices"
                    :key="String(choice.order) + String(choice.uuid) + String(choice.value) + getCurrentTranslationForChoice(choice).locale"
                    @change="choiceChanged(idx)"
                >
                    <AbbiButton :disabled="scriptLive" is-red @click="removeCategory(idx, choice.order)">
                        <FaIcon icon="trash" />
                    </AbbiButton>
                    <a class="handle">
                        <FaIcon icon="arrows-alt-v" />
                    </a>
                    <label>
                        <input
                            :disabled="scriptLive"
                            type="text"
                            class="choice-numeric-value"
                            @keypress="filterNumbers"
                            :value="choice.value"
                            @change="choice.value = Number($event.target.value)"
                            maxlength="3"
                        />
                    </label>
                    <AbbiTextInput is-small :name="`choice-${choice.order}`" v-model="getCurrentTranslationForChoice(choice).content" />
                    <TextCheckbox v-if="rand" :name="`choice-${choice.order}-fixed`" :readonly="scriptLive" v-model="choice.fixed">Fixed</TextCheckbox>
                    <TextCheckbox v-if="multiple" :name="`choice-${choice.order}-single`" :aria-readonly="scriptLive" v-model="choice.single">Single
                    </TextCheckbox>
                    <AbbiNumericInput v-if="options.payment" class="amount-input" :readonly="scriptLive" is-small placeholder="euro"
                                      :value="choice.amount" :step="0.01" @input="internalChoices[idx].amount = $event || null"></AbbiNumericInput>
                    <AbbiNumericInput v-if="options.scores" class="score-input" :readonly="scriptLive" is-small placeholder="score"
                                      :value="choice.score" :step="0.1" @input="choice.score = $event || null"></AbbiNumericInput>
                </div>
            </draggable>
        </template>
        <hr v-if="!scriptLive">
        <div v-if="!scriptLive" class="choice-entry">
            <label :key="hasChoices ? internalChoices.length : 0">
                <input type="text" id="new-numeric-val" class="choice-numeric-value" @keypress="filterNumbers"
                       :value="hasChoices ? internalChoices.length + 1 : 1" maxlength="3" />
            </label>
            <AbbiTextInput
                ref="choiceRtf"
                has-autofocus
                is-small
                name="choice-default-translation"
                @keypress="addIfEnter"
                max="256"
                :placeholder="$t('FIELDS.ANSWER')"
            />
            <AbbiButton @click="addCategory">{{ $t('ACTION.QUESTION.CHOICE.ADD') }}</AbbiButton>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
/* eslint @typescript-eslint/no-explicit-any: 0 */
import Vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ChoiceModel, {ChoiceModelExt} from '@/models/choice.model';
import TranslationModel from '@/models/translation.model';
import Toggle from '@/components/input/Toggle.vue';
import AbbiNumericInput from '@/components/input/AbbiNumericInput.vue';
import TextCheckbox from '@/components/input/TextCheckbox.vue';
import draggable from 'vuedraggable';

export default Vue.extend({
    name: 'ChoiceConfigurator',
    components: {
        AbbiButton,
        AbbiTextInput,
        Toggle,
        AbbiNumericInput,
        TextCheckbox,
        draggable,
    },
    props: {
        choices: [Array],
        multiple: Boolean,
        options: Object,
        scriptLive: Boolean,
        liveEditable: Boolean,
    },
    data() {
        return {
            rand: this.options?.randomize || false,
            withAmounts: this.options?.withAmounts || false,
            internalChoices: [] as ChoiceModel[],
            isMultipleCountChanged: false,
        };
    },
    computed: {
        hasChoices(): boolean {
            return !!this.internalChoices;
        },
    },
    methods: {
        filterNumbers($e: KeyboardEvent) {
            if (!/^[0-9]$/.test($e.key)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
            }
        },
        stopDrop($e: DragEvent) {
            $e.preventDefault();
            $e.stopImmediatePropagation();
        },
        addIfEnter($e: KeyboardEvent) {
            if ($e.code === 'Enter' || $e.key === 'Enter') this.addCategory();
        },
        addCategory() {
            const textIn = document.getElementById('choice-default-translation') as HTMLInputElement;
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            const { value } = textIn;
            const numericIn = document.getElementById('new-numeric-val') as HTMLInputElement;

            const defaultTranslation = new TranslationModel();
            defaultTranslation.locale = 'en';
            defaultTranslation.content = value;

            const numericValue = Number(numericIn.value);
            const choice = new ChoiceModel();
            choice.value = numericValue;
            choice.order = this.internalChoices.length;
            choice.translation = defaultTranslation;

            const choices = this.internalChoices as ChoiceModel[] || [];
            choices.push(choice);
            choices.sort((a: ChoiceModel, b: ChoiceModel) => a.order - b.order);
            this.internalChoices = choices;
            this.$emit('change', this.internalChoices);

            if (this.multiple) {
                this.changeOptions({maximum: this.internalChoices.length});
                if (!this.isMultipleCountChanged) {
                    this.flashMessage.info({
                        message: this.$i18n.t('WARNING.MULTIPLE_CHOICE_COUNT_INCREASE'),
                        icon: '/img/icons/info.svg',
                        time: 5000,
                    });
                    this.isMultipleCountChanged = true;
                }
            }

            if (textIn) {
                textIn.value = '';
                textIn.focus();
            }
        },
        removeCategory(index: number, order: number) {
            this.internalChoices.splice(index, 1);

            if (this.options?.fixated && this.options.fixated[order] !== undefined) { delete this.options.fixated[order]; }
            if (this.options?.singles && this.options.singles[order] !== undefined) { delete this.options.singles[order]; }
            if (this.options?.scores && this.options.scores[order] !== undefined) { delete this.options.scores[order]; }
            if (this.options?.amounts && this.options.amounts[order] !== undefined) { delete this.options.amounts[order]; }

            if (this.multiple) {
                if (!this.isMultipleCountChanged) {
                    this.flashMessage.info({
                        message: this.$i18n.t('WARNING.MULTIPLE_CHOICE_COUNT_DECREASE'),
                        icon: '/img/icons/info.svg',
                        time: 5000,
                    });
                    this.isMultipleCountChanged = true;
                }
                this.changeOptions({maximum: this.internalChoices.length});
            }
            this.changeOptions(this.options);
            this.$emit('change', this.internalChoices);
            this.$forceUpdate();
        },
        getCurrentTranslationForChoice(choice: ChoiceModel) {
            return ChoiceModelExt.GetMessageForLocale(choice, 'en', 'en');
        },
        randomize($e: Event) {
            this.rand = $e;
            this.changeOptions({randomize: $e, fixated: $e ? [] : undefined});
        },
        column($e: number) {
            const options = this.options || {};
            options.columns = $e;
            this.changeOptions(options);
        },
        changeOptions($e: Record<string, any>) {
            const options = this.options || {};
            this.$emit('optionsChanged', Object.assign(options, $e));
        },
        setMinimum($e: Event) {
            this.changeOptions({minimum: $e});
        },
        setMaximum($e: Event) {
            this.changeOptions({maximum: $e});
        },
        choiceChanged() {
            this.$emit('change', this.internalChoices);
        },
        choiceOrderChanged($event: CustomEvent & Record<string, any>) {
            const oldIdx = $event.moved.oldIndex;
            const newIdx = $event.moved.newIndex;

            const direction = Math.round((oldIdx - newIdx) / Math.abs(oldIdx - newIdx));
            const lowerBound = Math.min(oldIdx, newIdx);
            const upperBound = Math.max(oldIdx, newIdx) + 1;

            const choices = [] as ChoiceModel[];
            const oldChoices = (this.internalChoices || []) as ChoiceModel[];

            const bottomSlice = oldChoices.slice(0, lowerBound);
            choices.push(...bottomSlice);

            const reordered = [] as ChoiceModel[];
            for (let i = lowerBound; i < upperBound; i++) {
                const choice = oldChoices[i];
                choice.order = i === oldIdx ? newIdx : i + direction;
                reordered.push(choice);
            }

            reordered.sort((choiceA: ChoiceModel, choiceB: ChoiceModel) => choiceA?.order - choiceB?.order);
            choices.push(...reordered);
            choices.push(...oldChoices.slice(upperBound));

            this.internalChoices = choices;

            this.$emit('change', this.internalChoices);
        },
    },
    mounted() {
        this.internalChoices = (this.choices || []) as ChoiceModel[];
        if (!this.internalChoices.length) {
            this.isMultipleCountChanged = true;
        }

        if (!['any', 'start'].includes(this.options.searchPattern)) {
            this.options.searchPattern = 'any';
        }
    },
});
</script>

<style lang="scss">
@import 'src/styles/colors';
@import 'src/styles/base';
@import 'src/styles/utilities';

#choice-configurator {
    max-width: 1280px;
    margin: 0 auto;

    .abbi-text-input {
        width: 800px;
    }

    .inputfield {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .text {
            width: 200px;
        }

        .options {
            .option {
                display: inline-block;
                border-radius: .5em;
                border-color: lightgrey;

                legend {
                    padding: 0 .5em;
                }

                input[type=radio] {
                    margin-right: .5em;
                }

                &.checked {
                    border-color: $abbi-green;
                }
            }

            .selection {
                color: $abbi-blue;
            }
        }
    }

    .special-options {
        .abbi-toggle {
            height: 2.4em;
            line-height: 2.4em;
        }
    }

    .choice-entry {
        display: flex;
        margin-top: 0.5em;

        .choice-numeric-value {
            display: flex;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 2px solid $abbi-green;
            border-radius: 6px;
            outline: none;
            line-height: 0.8em;
            background-color: white;
            width: 3em;
            align-items: center;
            text-align: center;

            &[readonly], &[disabled] {
                border-color: grey;
                background-color: lightgrey;
            }
        }

        .handle {
            display: flex;
            cursor: grab;
            align-items: center;
            justify-content: center;
            color: hsl(0deg, 0%, 60%);
            min-width: 1em;
        }

        > :not(:last-child) {
            margin-right: 1rem;
        }

        > *:last-child {
            min-width: 2em;
        }

        .fixed {
            align-self: center;
            margin-left: 0.5rem;
        }

        .amount-input {
            max-width: 5rem;

            > * {
                text-align: right !important;
            }
        }
    }
}
</style>
