<template>
    <button
        :style="`--color: ${value}`"
        @click.prevent="toggle"
    />
</template>

<script lang="ts">
import Input from './Input.vue';

export default Input.extend({
    methods: {
        toggle(): void {
            this.$emit('toggle', this.$el);
        },
    },
});
</script>

<style lang="scss" scoped>
button {
    --color: white;
    --size: 1.2em;
    width: calc(var(--size) * 2);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    border: none;
    box-shadow: rgba(0, 0, 0, .7) 0 0 4px -1px;
    margin: 3px;
    position: relative;
    background: white;

    &:before, &:after {
        display: block;
        content: '';
        position: absolute;
        border-radius: inherit;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:before {
        z-index: 1;
        filter: opacity(10%);
        background-blend-mode: exclusion;
        background:
            repeating-linear-gradient(
                    black 0,
                    black .5em,
                    white .5em,
                    white 1em,
            ),
            repeating-linear-gradient(
                    .25turn,
                    black 0,
                    black .5em,
                    white .5em,
                    white 1em,
            );
    }

    &:after {
        z-index: 2;
        background: var(--color);
    }
}
</style>
