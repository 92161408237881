<script lang="ts">
import ChatConfigurator from '@/components/community/styling/chat/ChatConfigurator.vue';
import ChatAnswersForm from './ChatAnswersForm.vue';
import scenarios from './scenarios';

export default ChatConfigurator.extend({
    data() {
        return {
            scenarios,
            options: {
                scenario: Object.keys(scenarios)[0] as string,
            },
        };
    },
    computed: {
        form(): typeof ChatAnswersForm {
            return ChatAnswersForm;
        },
    },
});
</script>
