<template>
    <Card class="row" title="ACCOUNT.2FA.HEADING">
        <template #subtitle v-if="!saved">
            <span class="text-success">
                {{ $t('ACCOUNT.2FA.DISABLE.INTRODUCTION') }}
            </span>
        </template>
        <template #subtitle v-else>
            <div class="alert alert-warning mb-0 mt-3" role="alert">
                <i class="bi-exclamation-triangle-fill"></i>
                {{ $t(successMessage) }}
            </div>
        </template>

        <form class="mt-3" @submit.prevent="submitForm()" v-if="!saved">
            <div class="mb-3 row">
                <label for="currentPassword" class="col-sm-3 col-form-label">
                    {{ $t('ACCOUNT.FIELD.CURRENT_PASSWORD.LABEL') }}
                </label>
                <div class="col-sm-9">
                    <div class="input-group has-validation">
                        <i class="input-group-text bi-key"></i>
                        <input
                            :type="currentPasswordVisible ? 'text' : 'password'"
                            class="form-control"
                            :class="{
                                    'is-invalid': violations.hasError('currentPassword'),
                                    'is-valid': currentPassword.length > 0,
                                }"
                            @input="mutateForm()"
                            id="currentPassword"
                            name="currentPassword"
                            autocomplete="current-password"
                            required
                            aria-describedby="currentPasswordHelp"
                            v-model="currentPassword" />
                        <TogglePasswordVisibility
                            :visible="currentPasswordVisible"
                            @toggle="currentPasswordVisible = !currentPasswordVisible" />
                        <ViolationList field="currentPassword" :violations="violations" />
                    </div>
                    <div id="currentPasswordHelp" class="form-text">
                        {{ $t('ACCOUNT.2FA.DISABLE.FIELD.CURRENT_PASSWORD.HELP') }}
                    </div>
                </div>
            </div>

            <div class="mt-4 input-group has-validation" v-if="currentPassword.length > 0">
                <button
                    type="submit"
                    :disabled="!enabled"
                    class="btn btn-lg btn-danger w-100"
                    :class="{'is-valid': saved, 'is-invalid': errorMessage.length > 0}">
                    <span v-if="enabled">
                        <i class="bi-exclamation-diamond-fill"></i>
                        {{ $t('ACCOUNT.2FA.DISABLE.SUBMIT.LABEL') }}
                    </span>
                    <span v-else class="spinner-border text-light" role="status">
                        <span class="visually-hidden">
                            {{ $t('ACCOUNT.FIELD.SUBMIT.ACTION') }}
                        </span>
                    </span>
                </button>
                <div v-if="errorMessage" class="invalid-feedback">
                    {{ $t(errorMessage) }}
                </div>
            </div>
        </form>
    </Card>
</template>
<script lang="ts">
import Vue from 'vue';
import Card from '@/components/bootstrap/Card.vue';
import ViolationListModel from '@/models/violation.list.model';
import TogglePasswordVisibility from '@/components/account/TogglePasswordVisibility.vue';
import ViolationList from '@/components/form/ViolationList.vue';
import AccountApi from '@/apis/account.api';

interface ComponentData {
    currentPasswordVisible: boolean;
    currentPassword: string;
    violations: ViolationListModel;
    enabled: boolean;
    errorMessage: string;
    saved: boolean;
    successMessage: string;
}

export default Vue.extend({
    name: 'AccountDisableTwoFactorAuthentication',
    components: {ViolationList, TogglePasswordVisibility, Card},
    data(): ComponentData {
        return {
            currentPasswordVisible: false,
            currentPassword: '',
            violations: new ViolationListModel(),
            enabled: true,
            errorMessage: '',
            saved: false,
            successMessage: 'ACCOUNT.2FA.DISABLE.SUBMIT.SUCCESS',
        };
    },
    methods: {
        mutateForm() {
            this.violations.reset();
            this.errorMessage = '';
            this.enabled = true;
        },
        submitForm() {
            if (this.currentPassword.length < 1) {
                return;
            }

            this.enabled = false;
            this.violations.reset();
            this.errorMessage = '';

            AccountApi.totpDisable(this.currentPassword)
                .then((responseData) => {
                    this.enabled = true;

                    if (responseData) {
                        this.saved = true;
                        return;
                    }

                    AccountApi.getErrorData().then((errorData) => {
                        const violations = errorData.violations ?? [];

                        this.violations.apply(violations);

                        if (violations.length === 0) {
                            this.errorMessage = 'ACCOUNT.2FA.DISABLE.SUBMIT.FAILURE';
                        }
                    });
                })
                .catch(() => {
                    this.enabled = true;
                    this.errorMessage = 'ACCOUNT.2FA.DISABLE.SUBMIT.FAILURE';
                });
        },
    },
});
</script>
