<template>
    <div :class="componentClass">
        <input
            type="range"
            :value="parsedValue"
            :name="inputId"
            :id="inputId"
            :list="listId"
            :min="min"
            :max="max"
            :step="step"
            @change="onInput"
        />
        <datalist v-show="legend" :id="listId">
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="option.value"
                :label="getOptionLabel(option)"
                @click="onClickOption(option)"
            />
        </datalist>
    </div>
</template>

<script lang="ts">
import Input from '@/components/community/styling/Input/Input.vue';
import {PropType} from 'vue';

interface Option {
    value: number;
    label?: string;
}

export interface DataRangeOptions {
    options: Array<Option>;
    unit: string;
    legend?: boolean;
    vertical?: boolean;
    decimals?: number;
}

let increment = 0;

export default Input.extend({
    props: {
        options: {
            type: Array as PropType<Array<Option>>,
            default: [],
        },
        unit: {
            type: String,
            required: true,
        },
        legend: {
            type: Boolean,
            default: false,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        decimals: {
            type: Number,
            default: 0,
        },
    },
    data() {
        increment++;
        return {increment};
    },
    computed: {
        min(): number {
            const {min} = Math;

            return this.options.reduce(
                (carry: number, option: Option) => min(carry, option.value),
                Number.MAX_SAFE_INTEGER,
            );
        },
        max(): number {
            const {max} = Math;

            return this.options.reduce(
                (carry: number, option: Option) => max(carry, option.value),
                0,
            );
        },
        step(): number {
            if (!this.isConsistentGap) return 1;

            const values = Object.values(this.options).map((v) => v.value);

            const {abs} = Math;

            return abs(values[1] - values[0]);
        },
        isConsistentGap(): boolean {
            if (this.options.length < 2) return true;

            const values = Object.values(this.options).map((v) => v.value);

            const gap = values[1] - values[0];

            return values.slice(1).every((val, idx, arr) => (idx === 0 || val - arr[idx - 1] === gap));
        },
        parsedValue(): number {
            return this.parseValue(this.value);
        },
        inputId(): string {
            return `dataList-input-${this.increment}`;
        },
        listId(): string {
            return `dataList-values-${this.increment}`;
        },
        style(): {[key: string]: string} {
            const {$style} = this as unknown as {$style: {[key: string]: string}};
            return $style;
        },
        componentClass(): Array<string> {
            const classes = [this.style.component];

            if (this.legend && this.vertical) {
                classes.push(this.style['legend-vertical']);
            }

            return classes;
        },
    },
    methods: {
        getOptionLabel(option: Option): string {
            return option.label ?? this.formatCssValue(option.value);
        },
        formatCssValue(value: number): string {
            const numberDigits = value.toString().length;
            return `${value.toPrecision(numberDigits + this.decimals)}${this.unit}`;
        },
        parseValue(value: string): number {
            return parseFloat(
                value.toLowerCase().endsWith(this.unit.toLowerCase())
                    ? value.slice(0, -1 * this.unit.length)
                    : value,
            );
        },
        onClickOption(option: Option): void {
            this.$emit('input', this.formatCssValue(option.value));
        },
        onInput(event: Event): void {
            const target = event.target as HTMLInputElement;

            if (target instanceof HTMLInputElement) {
                this.$emit(
                    'input',
                    this.formatCssValue(
                        this.parseValue(target.value),
                    ),
                );
            }
        },
    },
});
</script>

<style lang="scss" module>
.component {
    width: 100%;
    min-width: 200px;

    input[type=range] {
        width: 100%;
        cursor: pointer;
    }

    option {
        padding: 0;
        text-align: left;
        cursor: pointer;
    }

    datalist {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &.legend-vertical {
        datalist {
            flex-direction: column;
            writing-mode: vertical-lr;
        }
    }
}
</style>
