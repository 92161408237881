<template>
    <div class="w-100">
        <FieldSet title="body">
            <Field property="chat_background-color" />
            <Field property="chat_color" />
        </FieldSet>

        <FieldSet title="scripted" icon="message">
            <Field property="abbi-message_background-color" />
            <Field property="abbi-message_color" />

            <FieldSet title="hyperlink" icon="link">
                <Field property="abbi-message_link-color" />
            </FieldSet>

            <FieldSet title="button" icon="square">
                <Field property="abbi-message_button-background-color" />
                <Field property="abbi-message_button-color" />
            </FieldSet>
        </FieldSet>

        <FieldSet title="user" icon="message" :iconStyle="{transform: 'scaleX(-1)'}">
            <Field property="user-message_background-color" />
            <Field property="user-message_color" />
        </FieldSet>

        <FieldSet title="message_shape" icon="shapes" can-collapse>
            <ShapeField
                tail="message_tail-border-radius"
                border="message_border-radius"
                padding="message_padding"
                message="message"
                reply="reply"
                :shapes="messageShapes"
            />

            <FieldSet title="message_outline">
                <Field
                    property="message_border-width"
                    :input="inputs.dataRange"
                    :options="messageBorderWidthOptions"
                />
                <Field
                    v-show="hasWidth('message_border-width')"
                    property="abbi-message_border-color"
                />
                <Field
                    v-show="hasWidth('message_border-width')"
                    property="user-message_border-color"
                />
            </FieldSet>

            <FieldSet title="message_shadow">
                <Field property="message_box-shadow-enabled" :input="inputs.toggle" />
            </FieldSet>
        </FieldSet>
    </div>
</template>

<script lang="ts">
import ChatForm from '@/components/community/styling/chat/ChatForm.vue';
import FieldSet from '@/components/community/styling/form/FieldSet.vue';
import Field from '@/components/community/styling/form/Field.vue';
import ShapeField from '@/components/community/styling/form/ShapeField.vue';
import Shape from '@/components/community/styling/form/ShapeField/Shape';
import { DataRangeOptions } from '@/components/community/styling/Input/DataRangeInput.vue';

export default ChatForm.extend({
    components: {ShapeField, Field, FieldSet},
    data() {
        return {
            messageBorderWidthOptions: {
                options: [
                    {value: 0, label: 'No'},
                    {value: 1},
                    {value: 2},
                    {value: 3},
                ],
                unit: 'px',
                legend: true,
            } as DataRangeOptions,
            messageShapes: [
                {
                    tailRadius: '0',
                    borderRadius: '.75em',
                    padding: '.75em',
                },
                {
                    tailRadius: '.75em',
                    borderRadius: '.75em',
                    padding: '.75em',
                },
                {
                    tailRadius: '0',
                    borderRadius: '1.75em',
                    padding: '1em',
                },
                {
                    tailRadius: '1.75em',
                    borderRadius: '1.75em',
                    padding: '1em',
                },
            ] as Shape[],
        };
    },
});
</script>
