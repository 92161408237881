const environment = process.env.VUE_APP_ABBI_ENVIRONMENT || 'local';

const objectStorage = {
    local: 'http://localhost:1024/abbi-insights-local',
    production: 'https://abbi-insights.cdn.cloudbear.nl',
    staging: 'https://abbi-insights-staging.cdn.cloudbear.nl',
};

export default {
    apiBase: (process.env.VUE_APP_ABBI_API_BASE || `${window.location.protocol}//${window.location.hostname}:3000`),
    webBase: (process.env.VUE_APP_ABBI_WEB_BASE || `${window.location.protocol}//${window.location.hostname}`),
    statsBase: (process.env.VUE_APP_ABBI_STATS_BASE || `${window.location.protocol}//${window.location.hostname}:82`),

    version: (process.env.VUE_APP_ABBI_VERSION || (environment === 'local' ? 'v.local' : '')),
    // eslint-disable-next-line
    // @ts-ignore
    objectStorage: objectStorage[environment],
    environment,
};
