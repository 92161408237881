export interface UpdateListener {
    (property: string, value: string): void;
}

export default class StylesheetUpdater {
    listeners: UpdateListener[] = [];

    addListener(listener: UpdateListener): void {
        this.listeners.push(listener);
    }

    removeListener(listener: UpdateListener): void {
        this.listeners = this.listeners.filter(
            (current: UpdateListener) => current !== listener,
        );
    }

    update(property: string, value: string): void {
        this.listeners.forEach(
            (listener: UpdateListener) => listener(property, value),
        );
    }
}
