<template>
    <div id="remove-data-dialogue">
        <AbbiButton grow @click="clearLivestats">
            Clear live stats
        </AbbiButton>
        <AbbiButton is-blue grow @click="clearChatData">
            Clear chat data
        </AbbiButton>
        <AbbiButton is-red grow @click="clearBoth">
            Clear both
        </AbbiButton>
    </div>
</template>

<script lang="ts">
/* eslint no-alert: 0 */
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import ChatApi from '@/apis/chat.api';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

export default vue.extend({
    name: 'RemoveDataDialogue',
    components: {
        AbbiButton,
    },
    methods: {
        clearLivestats() {
            const {communitySlug, scriptUuid} = this.$route.params;
            ChatApi.getClearLiveStatsCSRF(communitySlug, scriptUuid)
                .then((csrfResponseData) => {
                    const csrf = csrfResponseData.clearToken;
                    this.$store.commit('setModal', {
                        title: this.$t('ACTION.CONFIRMATION'),
                        component: ConfirmationModal,
                        options: {
                            message: 'Do you really want to clear the live stats of this chat? '
                                + 'You can\'t undo this action. '
                                + 'The unique links that have already been created will continue to work.',
                            actions: {
                                confirm: {
                                    message: this.$t('ACTION.YES'),
                                    action: this.performLiveClear,
                                    argument: {slug: communitySlug, uuid: scriptUuid, csrf},
                                },
                                cancel: {
                                    message: this.$t('ACTION.NO'),
                                },
                            },
                        },
                    });
                });
        },
        clearChatData() {
            const {communitySlug, scriptUuid} = this.$route.params;
            ChatApi.getClearChatDataCSRF(communitySlug, scriptUuid)
                .then((csrfResponseData) => {
                    const csrf = csrfResponseData.clearToken;
                    this.$store.commit('setModal', {
                        title: this.$t('ACTION.CONFIRMATION'),
                        component: ConfirmationModal,
                        options: {
                            message: 'Do you really want to erase the data of this chat? '
                                + 'You can\'t undo this action. '
                                + 'The unique links are no longer usable after erasing.',
                            actions: {
                                confirm: {
                                    message: this.$t('ACTION.YES'),
                                    action: this.performChatClear,
                                    argument: {slug: communitySlug, uuid: scriptUuid, csrf},
                                },
                                cancel: {
                                    message: this.$t('ACTION.NO'),
                                },
                            },
                        },
                    });
                });
        },
        clearBoth() {
            const {communitySlug, scriptUuid} = this.$route.params;

            Promise.all([
                ChatApi.getClearLiveStatsCSRF(communitySlug, scriptUuid),
                ChatApi.getClearChatDataCSRF(communitySlug, scriptUuid),
            ]).then((responseData) => {
                this.$store.commit('setModal', {
                    title: this.$t('ACTION.CONFIRMATION'),
                    component: ConfirmationModal,
                    options: {
                        message: 'Do you really want to erase the data and clear the live stats of this chat? '
                            + 'You can\'t undo this action. '
                            + 'The unique links are no longer usable after erasing.',
                        actions: {
                            confirm: {
                                message: this.$t('ACTION.YES'),
                                action: this.performAllClear,
                                argument: {
                                    slug: communitySlug,
                                    uuid: scriptUuid,
                                    liveCsrf: responseData[0].clearToken,
                                    chatCsrf: responseData[1].clearToken,
                                },
                            },
                            cancel: {
                                message: this.$t('ACTION.NO'),
                            },
                        },
                    },
                });
            });
        },
        performLiveClear({slug = '', uuid = '', csrf = ''}) {
            ChatApi.clearLiveStats(slug, uuid, csrf)
                .then((result) => {
                    if (result === 'success') {
                        alert('livestats have been cleared');
                        this.$store.dispatch('closeModal');
                    }
                });
        },
        performChatClear({slug = '', uuid = '', csrf = ''}) {
            ChatApi.clearChatData(slug, uuid, csrf)
                .then((result) => {
                    if (result === 'success') {
                        alert('Chat data has been cleared');
                        this.$store.dispatch('closeModal');
                    }
                });
        },
        performAllClear({
            slug = '',
            uuid = '',
            liveCsrf = '',
            chatCsrf = '',
        }) {
            const livePromise = ChatApi.clearLiveStats(slug, uuid, liveCsrf)
                .then((result) => result === 'success');

            const chatPromise = ChatApi.clearChatData(slug, uuid, chatCsrf)
                .then((result) => result === 'success');

            Promise.all([livePromise, chatPromise])
                .then((response) => {
                    let succeeded = true;
                    response.forEach((success) => {
                        succeeded = success && succeeded;
                    });
                    if (succeeded) {
                        alert('cleared all chat data!');
                        this.$store.dispatch('closeModal');
                    }
                });
        },
    },
});
</script>

<style lang="scss">
#remove-data-dialogue {
    padding: 1rem;

    > *:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}
</style>
